import clsx from "clsx";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";
import { PATH_APP } from "~/routes/paths";
import MyAvatar from "~/components/MyAvatar";
import React, { useRef, useState, useContext, useEffect } from "react";
import PopoverMenu from "~/components/PopoverMenu";
import settingsFill from "@iconify-icons/eva/settings-outline";
import accountiFill from "@iconify-icons/eva/save-outline";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import { Button, Box, Divider, MenuItem, Typography } from "@material-ui/core";
import { MIconButton } from "~/@material-extend";
import { userContext, tokenContext } from "~/ContextProvider";
import { GoogleLogout } from "react-google-login";
import closeFill from "@iconify-icons/eva/close-fill";
import { gapi } from "gapi-script";

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  menuItem: {
    ...theme.typography.body2,
    padding: theme.spacing(1, 2.5)
  },
  btnAvatar: {
    padding: 0,
    width: 44,
    height: 44
  },
  isSelected: {
    "&:before": {
      zIndex: 1,
      content: "''",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      position: "absolute",
      background: alpha(theme.palette.grey[900], 0.8)
    }
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));

// ----------------------------------------------------------------------
const MENU_OPTIONS_SADMIN = [
  // {
  //   label: 'Home',
  //   icon: homeFill,
  //   linkTo: '/'
  // },
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: PATH_APP.management.user.profile
  // },
  {
    label: "Profile Settings",
    icon: settingsFill,
    linkTo: PATH_APP.app2.account
    // linkTo: PATH_APP.management.user.account
  },
  {
    label: "Master Data",
    icon: accountiFill,
    linkTo: PATH_APP.app2.masterData.root
    // linkTo: PATH_APP.management.user.account
  }
];
const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: homeFill,
  //   linkTo: '/'
  // },
  // {
  //   label: 'Profile',
  //   icon: personFill,
  //   linkTo: PATH_APP.management.user.profile
  // },
  {
    label: "Profile Settings",
    icon: settingsFill,
    linkTo: PATH_APP.app2.account
    // linkTo: PATH_APP.management.user.account
  }
];
function Account() {
  const classes = useStyles();
  const history = useHistory();
  const anchorRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const [user, setUser] = useContext(userContext);
  const userRole = JSON.parse(localStorage.getItem("user_details")).userRole;
  const [token, setToken] = useContext(tokenContext);
  const clientId =
    "856060495668-mdi9ua0dgrbjj9qlhgbmhbeoo1h0l1o8.apps.googleusercontent.com";
  const { displayName, email } =
    user || JSON.parse(localStorage.getItem("user_details")) || {};
  const OPTIONS =
    userRole === 0 || userRole === 1 ? MENU_OPTIONS_SADMIN : MENU_OPTIONS;
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken = decodeToken(token);
        if (decodedToken.exp * 1000 < Date.now()) {
          handleLogout();
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000); // Check token expiration every second

    return () => {
      clearInterval(interval);
    };
  }, [token]);

  const decodeToken = token => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );

    return JSON.parse(jsonPayload);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    console.log("[Google Logout Success]:");
    setToken(null);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user_details");
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(auth2.disconnect());
    }
    enqueueSnackbar("Logout Successful", {
      variant: "success",
      action: key => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
    history.push("/");
  };

  return (
    <div>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        className={clsx(classes.btnAvatar, { [classes.isSelected]: isOpen })}
      >
        <MyAvatar />
      </MIconButton>

      <PopoverMenu
        width={220}
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
      >
        <Box sx={{ my: 2, px: 2.5 }}>
          <Typography variant="subtitle1" color="textPrimary" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            {email}
          </Typography>
        </Box>

        <Divider className={classes.divider} />

        {OPTIONS.map(option => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            className={classes.menuItem}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <GoogleLogout
            clientId={clientId}
            onLogoutSuccess={handleLogout}
            onLogoutFailure={err =>
              console.log("[Google Logout Failed]: ", err)
            }
            render={renderProps => (
              <Button
                fullWidth
                color="inherit"
                variant="outlined"
                onClick={renderProps.onClick}
              >
                Logout
              </Button>
            )}
          ></GoogleLogout>
        </Box>
      </PopoverMenu>
    </div>
  );
}

export default Account;
