import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { reject } from 'lodash';

const initialState = {
  isLoading: false,
  industries: [],
};

const slice = createSlice({
  name: 'industry',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getIndustriesSuccess(state, action) {
      state.isLoading = false;
      state.industries = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addIndustryStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addIndustrySuccess(state, action) {
      state.isLoading = false;
      state.industries.push(action.payload)
    },
    addIndustryFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateIndustrySuccess(state, action) {
      state.isLoading = false;
      const updatedIndustry = action.payload;
      state.industries = state.industries.map(industry => {
        return industry.id === updatedIndustry.id ? updatedIndustry : industry;
      });
    },
    
    // DELETE EVENT
    deleteIndustrySuccess(state, action) {
      console.log(action.payload,"asdasdasd")
      state.industries = action.payload;
    },
  },
});

export const { startLoading, getIndustriesSuccess, hasError } = slice.actions;

export const getAllIndustries = () => async (dispatch) => {
 
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get("/api/industries/get")
    if(Array.isArray(response.data)){
      dispatch(slice.actions.getIndustriesSuccess(response.data))
    }else{
      throw new Error("Invalid response form")
    }
  } catch (error) {
    dispatch(slice.actions.hasError(error))
  }

};

export const addIndustry = (industryData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/industries/post', industryData);
      dispatch(slice.actions.addIndustrySuccess(industryData))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

};

export const updateIndustry = (industryData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(industryData,"responseresponse")
      const response = await axios.put(`/api/industries/update`, industryData);
      dispatch(slice.actions.updateIndustrySuccess(industryData))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const deleteIndustry = (industryData) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let industryId = industryData.id
    const response = await axios.post(`/api/industries/delete`,{industryId});
      // Fetch updated industries data after deletion
    dispatch(slice.actions.deleteIndustrySuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default slice.reducer;
