import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { reject } from 'lodash';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  accounts: [],
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getAccountsSuccess(state, action) {
      state.isLoading = false;
      state.accounts = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addAccountStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addAccountSuccess(state, action) {
        state.isLoading = false;
        const updatedAccount = action.payload;
        state.accounts = state.accounts.map(account => {
          return account.area_id === updatedAccount.area_id ? updatedAccount : account;
        });
    },
    addAccountFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateAccountsSuccess(state, action) {
      state.isLoading = false;
      console.log(state,"Updatestate")
      const updatedAccount = action.payload;
      state.accounts = state.accounts.map(account => {
        return account.area_id === updatedAccount.area_id ? updatedAccount : account;
      });
    },
    
    // DELETE EVENT
    deleteAccountSuccess(state, action) {
      state.accounts = action.payload;
    },
  },
});

export const { startLoading, getAccountsSuccess , hasError } = slice.actions;

export const getAllAccounts = () => async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `master_data/areas`
      const {res} = await Factory('get',url,{});
      if(Array.isArray(res)){
        dispatch(slice.actions.getAccountsSuccess(res));
      }else{
        throw new Error("Invalid response form")
      }
    } catch (error) {
      console.log(error,"fetching error")
      dispatch(slice.actions.hasError(error))
    }
  
  };

export const addAccount = (newAccount) => {
  
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      //console.log(newSubarea)
      let url = "master_data/accounts/";
      let { res, variant, message } = await Factory("put", url, newAccount);
      console.log(res);
      dispatch(slice.actions.addAccountSuccess(newAccount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }

};

export const updateAccount = (accountData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
        let url = `master_data/accounts/`
        const {res} = await Factory('put',url,accountData);
      dispatch(slice.actions.updateAccountsSuccess(accountData))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const deleteAccount = (accountData) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let accountId = accountData.id
    const response = await axios.post(`/api/industries/delete`,{accountId});
      // Fetch updated accounts data after deletion
    dispatch(slice.actions.deleteAccountSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default slice.reducer;
