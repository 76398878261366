import mock from '~/utils/mock'; 

const Data =[
    {
        "id": "1",
        "phases": [
            {
                "phase_name": "Substantive Testing",
                "subareas": [
                    {
                        "subarea_name": "Reserves & Surplus",
                        "accounts": [
                            {
                                "account_name": "Account 1",
                                "steps": [
                                    {
                                        "step_name": "TAN"
                                    },
                                    {
                                        "step_name": "TDS"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "firm_id": 1,
        "user_id": 1
    },
    {
        "id": "2",
        "phases": [
            {
                "phase_name": "Compliance Testing",
                "subareas": [
                    {
                        "subarea_name": "Sub Area 2",
                        "accounts": [
                            {
                                "account_name": "Account 2",
                                "steps": [
                                    {
                                        "step_name": "TAN"
                                    },
                                    {
                                        "step_name": "TDS"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "firm_id": 1,
        "user_id": 1
    },
    {
        "id": "3",
        "phases": [
            {
                "phase_name": "Compliance Testing",
                "subareas": [
                    {
                        "subarea_name": "SubArea 3",
                        "accounts": [
                            {
                                "account_name": "Account 3",
                                "steps": [
                                    {
                                        "step_name": "TAN"
                                    },
                                    {
                                        "step_name": "ABC"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "firm_id": 1,
        "user_id": 1
    },
];

mock.onGet('/api/getStep').reply(200, Data);

mock.onPost('/api/postStep').reply(200, Data);

mock.onPut('/api/updateStep/:id').reply(200, { message: 'Post updated successfully' });

export const step = () => {
    return Data;
  }