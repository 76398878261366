import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// import AuthProtect from '~/components/Auth/AuthProtect';
import DashboardLayout from '~/layouts/DashboardLayout';
import DocumentDraftingRoute from './DocumentsRoutes';
// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  // guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: PATH_APP.app2.documentDrafting.root,
      component: lazy(() =>
        import('src/views/finance-box/DocumentDrafting/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.advancetax.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/AdvanceTax/advancetaxmenu.js'
        )
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.documentDrafting.requirements,
    //   component: lazy(() =>
    //     import('src/views/finance-box/DocumentDrafting/Requirements')
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.app2.documentDrafting.numberOfDocumentsGenerated,
      component: lazy(() =>
        import(
          'src/views/finance-box/DocumentDrafting/NumberOfDocumentsGenerated'
        )
      )
    },
    {
      exact: true,
      path: `${PATH_APP.app2.documentDrafting.doc}/:docName`,
      component: DocumentDraftingRoute,
      // component: lazy(() =>
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/Directors/Directors'
      //   // )
      //   import(
      //     '~/views/finance-box/DocumentDrafting/Networth/Networth'
      //   )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/TurnOver/TurnOver'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/FormAoc/FormAOC'
      //   // )
      //   //import(
      //   //   '~/views/finance-box/DocumentDrafting/AuditReport/AuditReport'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/AuditorAppointment/AuditorAppointment'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/OrdinaryResolution/OrdinaryResolution'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/DIR2/DIR2'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/BR/BR'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/LetterOfAppointment/LetterOfAppointment'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/MGT/FormMGT'
      //   // )
      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/BoardReport/BoardReport'
      //   // )


      //   // import(
      //   //   '~/views/finance-box/DocumentDrafting/AuditConsent/AuditConsent'
      //   // )

      // )
    },
    {
      exact: true,
      path: PATH_APP.app2.duediligence.root,
      component: lazy(() => import('~/views/finance-box/DueDiligence/index'))
    },
    {
      exact: true,
      path: PATH_APP.app2.duediligence.basicInfo,
      component: lazy(() =>
        import('~/views/finance-box/DueDiligence/BasicInfo')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.duediligence.basicinfo,
      component: lazy(() =>
        import('~/views/finance-box/DueDiligence/BasicInfo')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.duediligence.dueDiligencePlan,
      component: lazy(() => import('src/views/finance-box/DueDiligence/Ddp'))
    },
    {
      exact: true,
      path: PATH_APP.app2.duediligence.engagementAndscoping,
      component: lazy(() =>
        import('~/views/finance-box/DueDiligence/EngagementAndScoping')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.dic.root,
      component: lazy(() =>
        import('~/views/finance-box/DueDiligence/DICLandingPage')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.InputData,
      component: lazy(() =>
        import('src/views/finance-box/DueDiligence/InputData/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.trendAnalysis,
      component: lazy(() =>
        import('src/views/finance-box/DueDiligence/TrendAnalysis')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.analyticsAndSubstantiveTesting.analytics,
      component: lazy(() =>
        import(
          '~/views/finance-box/DueDiligence/AnalyticsAndSubstantiveTesting/Analytics'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.analyticsAndSubstantiveTesting.substantiveTesting,
      component: lazy(() =>
        import(
          '~/views/finance-box/DueDiligence/AnalyticsAndSubstantiveTesting/SubstantiveTesing'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceTesting,
      component: lazy(() =>
        import('~/views/finance-box/DueDiligence/ComplianceTesting')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.duediligence.queriesAndConclusion,
      component: lazy(() =>
        import('~/views/finance-box/DueDiligence/QueriesAndConclusions')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.account,
      component: lazy(() => import('~/views/finance-box/Account/Account'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.root,
      component: lazy(() => import('~/views/finance-box/MasterData/index.js'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.departmentCategories,
      component: lazy(() =>
        import('~/views/finance-box/MasterData/DepartmentCategories.js')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.employeeData,
      component: lazy(() =>
        import('~/views/finance-box/MasterData/EmployeeData.js')
      )
    },
    // anand
    {
      exact: true,
      path: PATH_APP.app2.masterData.onboardingchecklistData,
      component: lazy(() =>
        import('~/views/finance-box/MasterData/OnboardingCheklistData.js')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Phase,
      component: lazy(() => import('~/views/finance-box/MasterData/Phase'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Phasestep,
      component: lazy(() => import('~/views/finance-box/MasterData/Phasestep'))
    },

    {
      exact: true,
      path: PATH_APP.app2.masterData.Status,
      component: lazy(() => import('~/views/finance-box/MasterData/Status'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Entitytype,
      component: lazy(() => import('~/views/finance-box/MasterData/Entitytype'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Industry,
      component: lazy(() => import('~/views/finance-box/MasterData/Industry'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Area,
      component: lazy(() => import('~/views/finance-box/MasterData/Area'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.SubArea,
      component: lazy(() => import('~/views/finance-box/MasterData/Subarea'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Account,
      component: lazy(() => import('~/views/finance-box/MasterData/Account'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Step,
      component: lazy(() => import('~/views/finance-box/MasterData/Step'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.Checklist,
      component: lazy(() => import('~/views/finance-box/MasterData/Checklist'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.template,
      component: lazy(() => import('~/views/finance-box/MasterData/Template'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.analysis,
      component: lazy(() => import('~/views/finance-box/MasterData/Analysis'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.sector,
      component: lazy(() => import('~/views/finance-box/MasterData/Sector'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.compliance,
      component: lazy(() => import('~/views/finance-box/MasterData/Compliance'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.department,
      component: lazy(() => import('~/views/finance-box/MasterData/Department'))
    },
    {
      exact: true,
      path: PATH_APP.app2.masterData.addDepartment,
      component: lazy(() =>
        import('~/views/finance-box/MasterData/AddDepartment')
      )
    },

    // anand
    {
      exact: true,
      path: PATH_APP.app2.newPost,
      component: lazy(() =>
        import('src/views/finance-box/blog/NewPostView/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Payments,
      component: lazy(() => import('src/views/finance-box/Payments'))
    },

    // SIDEBAR routes
    {
      exact: true,
      path: PATH_APP.app2.services.root,
      component: lazy(() =>
        import('src/views/finance-box/services/ServicesView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.products.root,
      component: lazy(() =>
        import('src/views/finance-box/services/ProductsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.statutory,
      component: lazy(() =>
        import('src/views/finance-box/StatutoryFilingStatus')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.track.trackService,
      component: lazy(() => import('~/views/finance-box/TrackYourServicesView'))
    },
    {
      exact: true,
      path: PATH_APP.app2.track.serviceStatus,
      component: lazy(() =>
        import('~/views/finance-box/ServiceStatusView/ServiceStatusView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.investment,
      component: lazy(() => import('src/views/finance-box/MyInvestmentsView'))
    },
    {
      exact: true,
      path: PATH_APP.app2.insurance,
      component: lazy(() => import('src/views/finance-box/MyInsuranceView'))
    },
    {
      exact: true,
      path: PATH_APP.app2.incomeTaxReturns,
      component: lazy(() =>
        // import('src/views/finance-box/IncomeTaxReturnsView')
        import(
          'src/views/finance-box/Clients/ComplianceManager/IncomeTaxReturns2024/index.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.info_and_docs,
      component: lazy(() =>
        // import('src/views/finance-box/IncomeTaxReturnsView')
        import(
          'src/views/finance-box/Clients/ComplianceManager/IncomeTaxReturns2024/ITRTabs.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.chat.newQuery,
      component: lazy(() => import('src/views/finance-box/Query'))
    },
    {
      exact: true,
      path: PATH_APP.app2.chat.myQueries,
      component: lazy(() => import('src/views/finance-box/QueryChat/index'))
    },
    {
      exact: true,
      path: PATH_APP.app2.services.ReviewTracking,
      component: lazy(() =>
        import('src/views/finance-box/review-tracking/rt-summary')
      )
    },

    ///Home
    {
      exact: true,
      path: PATH_APP.app2.notifications,
      component: lazy(() =>
        import('src/views/finance-box/ManagerNotifications/index')
      )
    },
    //  Businesses list
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.business.businesslist,
      component: lazy(() =>
        import('src/views/finance-box/ManagerNotifications/Businesses/index')
      )
    },
    //  Businesses Profile
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.business.businessProfile,
      component: lazy(() =>
        import(
          'src/views/finance-box/FirmAdmin/ClientManagement/BusinessProfile/index.js'
        )
      )
    },
    //compliance manager
    {
      exact: true,
      path: PATH_APP.app2.IncomeTax.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/IncomeTaxReturns2024/index.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTax.dashboard,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/IncomeTaxReturns2024/Dashboard'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs2bvsBooks.overAllSummary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR2BvsBooks/GSTR3Bvs2BvsFinancial'
        )
      )
    },
    //IncomeTax Returns 2024
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs2bvsBooks.overAllSummary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR2BvsBooks/GSTR3Bvs2BvsFinancial'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs2bvsBooks.annexure,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR2BvsBooks/Annexure'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs2bvsBooks.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR2BvsBooks/services'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR2avsPurchases.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/services'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR2avsPurchases.summary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/Summary'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs1vsBooks.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/services'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs1vsBooks.overAllSummary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/OverallSummary'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs1vsBooks.annexureOverallSummary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureOverallSummary'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs1vsBooks.annexureTaxableSupplies,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureTaxableSupplies'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs1vsBooks.annexureZeroRated,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureZeroRated'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR3Bvs1vsBooks.annexureOthers,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureOthers'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.gstServices.root,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ComplianceManager/GSTServices')
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .filingStatus,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/FilingStatus'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices.GST3BForm,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST3BForm'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR2avsPurchases.annexure,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/Annexure'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .GSTR2avsPurchases.summary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/Summary'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .monthlySummary,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ComplianceManager/MonthlySummary')
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .detailedReport,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ComplianceManager/DetailedReport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.root,
      component: lazy(() =>
        import('src/views/finance-box/ComplianceManager/ComplianceManager')
      )
    },
    /// track your services

    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.trackYourService,
      component: lazy(() =>
        import('src/views/finance-box/Clients/TrackClientServices')
      )
    },
    /// mis
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.P$LAccount,
      component: lazy(() =>
        import('src/views/finance-box/Clients/MISEmployeeForms/P&LActions')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.MISReports,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/MISEmployeeForms/MISReportsActions'
    //     )
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.misBalanceAccount,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BalanceAccountActions'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.misBudgetsVsActuals,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BudgetsVsActuals/BudgetsVsActualsActions'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.misBudgetsVsActualsDataSheet,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BudgetsVsActuals/DataSheet'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.misBudgetsVsActualsPL,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BudgetsVsActuals/PLSheet'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.balanceAccountPreliminaryData,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BalanceAccount/PreliminaryData/index'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.balanceAccountBalanceSheet,
      component: lazy(() =>
        import('src/views/finance-box/Clients/MISEmployeeForms/BalanceSheet')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.balanceAccountBalanceSheetReport,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BalanceSheetReport'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.workingCapitalManagementReport,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/Reports/WorkingCapitalManagement'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.balanceAccountDataSheet,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BalanceAccount/DataSheet'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.createPLReport,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/Reports/PLReports'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.createPLForm,
      component: lazy(() =>
        import('src/views/finance-box/Clients/MISEmployeeForms/PLForm')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.createPreliminaryForm,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/PreliminaryData/index'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.budgetsVsactuals,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BudgetsVsActuals/PreliminaryData/index'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.misReports,
      component: lazy(() =>
        import('src/views/finance-box/Clients/MISEmployeeForms/Reports/index')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.cashFlowSheet,
    //   component: lazy(() =>
    //     import('src/views/finance-box/Clients/MISEmployeeForms/CashFlowSheet')
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.cashFlowReports,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/Reports/CashFlowReports'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.oprRevenueReports,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/Reports/RevenueOperationsReports'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.expenseAnalysisReports,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/Reports/ExpenseAnalysisReports'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.budgetActualsReports,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/Reports/BudgetsActuals'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.createPLDataSheetForm,
      component: lazy(() =>
        import('src/views/finance-box/Clients/MISEmployeeForms/PLDatasheet')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.createDataSheet,
      component: lazy(() =>
        import('src/views/finance-box/Clients/MISEmployeeForms/PreliminaryForm')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.balanceSheet,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/MISEmployeeForms/BalanceSheetReport'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.RevenueReports,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/RevenueReports')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.rootIndexPath,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/Reports/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.root,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/Reports/MISReportCards')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.MISReports.root,
    //   component: lazy(() =>
    //     import('src/views/finance-box/MISReports/MISReportCards')
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.RatioAnalysisReports,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/MISRatioReportCards')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.RatioAnalysisChartReports,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/RatioAnalysisCharts')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.SalaryReport,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/SalaryReport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.BalanceSheetReport,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/BalanceSheetReport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.PLBalanceSheetReport,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/PLBalanceSheet')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.ProfitAndLossReport,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/ProfitAndLossReport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.ExpensesReport,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/ExpensesReport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.MISReports.RatioAnalysisNumberReports,
      component: lazy(() =>
        import('src/views/finance-box/MISReports/RatioAnalysisNumbers')
      )
    },
    /////////////
    //GSTR9
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9Overview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9AdvancesOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/Advances.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9OutwardSuppliesOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/OutwardSupplies.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9ItcAvailedOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/ITCAvailed.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9ItcReversedOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/ITCReversed.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9ItcOtherOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/ITCOther.js'
        )
      )
    },

    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9TaxPaidOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/TAXPaid.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9ParticularsofTransactionsOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/ParticularsofTransactions.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9DifferentialTaxOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/DifferentialTax.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9DemandsandRefundsOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/DemandsandRefunds.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9CompositionTaxpayersOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/CompositionTaxpayers.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9HsnOutwardSummaryOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/HsnOutwardSummary.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst9HsnInwardSummaryOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR9/HsnInwardSummary.js'
        )
      )
    },

    ////// tds services
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.tdsServices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSServiceCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus.root,
      component: lazy(() =>
        import('src/views/finance-box/ComplianceManager/TDSServices/TDSPayment')
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus
          .challanDetails,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSPayment/ChallanDetails'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.fillingDetails.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSFillings'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.fillingDetails
          .returnStatus,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSFillings/TDSFilingReturnStatus'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.reconciliation.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSReconcilation'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.defaultSummary.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSDefaultSummary'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.downloadForm16.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSDownloadForm'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.business.root,
      component: lazy(() => import('src/views/finance-box/MyBusiness/index'))
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.businessForm,
      component: lazy(() =>
        import('src/views/finance-box/MyBusiness/AddBusiness/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.representativeForm,
      component: lazy(() =>
        import('src/views/finance-box/MyBusiness/AddRepresentative/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complainceManagerCards,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ComplianceManagerServices')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.serviceStatus,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ServiceStatus')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complainceManagerCards,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ComplianceManagerServices')
      )
    },

    // tds forms
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.addChallan,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/challanDeductee/AddChallan/AddChallan'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.tdsServices.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/TDSServices/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices
          .challanDeductee,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/challanDeductee'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices
          .defaultSummary,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/defaultSummary'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices
          .tdsFillings,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/tdsFillings'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices
          .uploadForm16A,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/uploadForm16'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices
          .tdsReconciliation,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDSReconciliation'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.tdsFilings
          .root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDS/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.tdsFilings
          .payment,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDS/TDSPayment'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.tdsFilings
          .filing.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDS/TDSFiling'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.tdsFilings
          .filing.form26q,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDS/Forms/Form26Q'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.tdsFilings
          .filing.form24q,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDS/Forms/Form24Q'
        )
      )
    },
    //fema service
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.FEMAServices.fema,
      component: lazy(() =>
        import('src/views/finance-box/ComplianceManager/FEMAServices/FEMA')
      )
    },
    // Fema employee
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.femaService.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/FEMAServices/FEMA'
        )
      )
    },
    // Advance Tax employee
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.advanceTaxService.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/AdvanceTax/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.assesseedetails.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/AdvanceTax/Assesseedetails.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.taxcalculation.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/AdvanceTax/Taxcalculation.js'
        )
      )
    },

    // payroll employee
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .statutoryFiling.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/StatutoryFilingStatus/StatutoryFilingStatusCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .reconciliation.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Reconcilation/ReconcilationCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService.notices,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Notices'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .register,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Register'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .statutoryFiling.esi,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/StatutoryFilingStatus/ESI'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .statutoryFiling.pf,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/StatutoryFilingStatus/PF'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .statutoryFiling.pt,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/StatutoryFilingStatus/PT'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .reconciliation.esi,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Reconcilation/ESI'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .reconciliation.pf,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Reconcilation/PF'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .reconciliation.pt,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Reconcilation/PT'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.payrollService
          .reconciliation.differenceSheet,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/PayrollServices/Reconcilation/DifferenceSheet'
        )
      )
    },
    // sebi employee
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.sebiService.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/SEBIServices/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.sebiService.quarterly,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/SEBIServices/QuarterlyReporting'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.sebiService.halfYearly,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/SEBIServices/HalfYearlyReporting'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.sebiService.annual,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/SEBIServices/AnnualReporting'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.sebiService.eventBased,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/SEBIServices/EventBasedReporting'
        )
      )
    },

    // roc employee
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.rocService.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.mcaFiling,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/MCAFiling'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService
          .mcaNonFiling,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/MCANonFiling'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers
          .root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/index'
        )
      )
    },

    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.declaration,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Declaration'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers
          .directors,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/Directors'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers
          .shareHolders,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/ShareHolders'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers
          .loansInvestmentsbycompanies,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/Loans-Investmentsbycompanies'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers
          .relatedParties,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/RelatedParties'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.rocService.registers
          .kmp,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/ROCServices/Registers/KMP'
        )
      )
    },
    // Income Tax Employee
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.complianceManager.itService.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/index'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.filingStatus,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/FilingStatus/FilingStatus'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService
          .detailsOfTaxes.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/DetailsOfTaxCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService
          .detailsOfTaxes.advanceTaxPaid,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/AdvanceTaxPaid'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService
          .detailsOfTaxes.otherTaxPaid,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/OtherTaxesPaid'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService
          .detailsOfTaxes.tdstcsCredit,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/TDS&TCSCredit'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices.root,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/NoticesCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices
          .adjustingRefund,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/AdjustingRefund'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices
          .assessmentReAssessment,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/AssessmentOrReAssessment'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices
          .intimations,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/Intimations'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices
          .returnFiling,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/ReturnFilingOrAdditionalInformation'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices
          .scrutiny,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/Scrutiny'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.itService.notices
          .QandA,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/IncomeTaxServices/Notices/QuestionsAndResponse'
        )
      )
    },
    //GSTR1
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1Overview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/GST1Overview.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1B2BOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/B2B/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1CDRNOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/CDRN/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1CDNUROverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/CDNUR/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1B2CLOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/B2CL/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1ExportsOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/GSTExports/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1B2CSOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/B2CS/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1NilRatedOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/NilRated/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1TaxLiabilityOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/TaxLiability/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1AdjustmentAdvancesOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/AdjustmentAdvances/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1HSNOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/HSN/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1DocumentsOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/DocumentsIssued/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1SuppliesMade,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/SuppliesMade/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1SuppliesUnder95,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/SuppliesU-S/index.js'
        )
      )
    },

    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1B2BAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/B2BA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1CDRNAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/CDNRA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1CDNURAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/CDNURA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1B2CLAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/B2CLA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1ExportsAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/ExportsA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1B2CSAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/B2CSA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices.gst1asm,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/SuppliesMade/SuppliesMageAmend.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices.gst1asu,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/SuppliesU-S/SuppliesUnser95Amend.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1TaxLiabilityAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/TaxLiabilityA/index.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.gstServices
          .gst1AdjustmentAdvancesAOverview,
      component: lazy(() =>
        import(
          '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST1/AdjustmentAdvancesA/index.js'
        )
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.services.GST3BSubmit,
    //   component: lazy(() =>
    //     import(
    //       '~/views/finance-box/Clients/ComplianceManager/GSTServices/GST3BForm/PaymentPage/submitPage'
    //     )
    //   )
    // },

    //

    {
      exact: true,
      path: PATH_APP.app2.taskManagement.root,
      component: lazy(() =>
        import('src/views/finance-box/TaskManagementNew/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.taskManagement.jobManagement,
      component: lazy(() =>
        import('src/views/finance-box/TaskManagementNew/JobManagement')
      )
    },
    // annad
    {
      exact: true,
      path: PATH_APP.app2.taskManagement.dashboards,
      component: lazy(() =>
        import('src/views/finance-box/TaskManagementNew/Dashboards')
      )
    },
    // annad
    {
      exact: true,
      path: PATH_APP.app2.taskReview,
      component: lazy(() =>
        import('src/views/finance-box/TaskManagement/TasksReview')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.myTasks,
      component: lazy(() =>
        import('src/views/finance-box/TaskManagement/MyTasks')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.timeTracking,
      component: lazy(() =>
        import('src/views/finance-box/EmployeeTimeTracking')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.employeeTimeTracking,
      component: lazy(() =>
        import('src/views/finance-box/ManagerEmployeeTimeTracking')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.root,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.employeeMaster.root,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/EmployeeMaster/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.employeeMaster.employeeAdd,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/EmployeeMaster/employeePopup'
        )
      )
    },
    // Payroll create profile
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.createProfile.root,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/CreateProfile.js')
      )
    },
        // Payroll create profile
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.leavesAndCompOff,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/leavesAndCompOff'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.salaryRevision,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/salaryRevision'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.salaryRevisionEdit,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/salaryRevisionEdit'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.lop,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/PayrollInput/lop')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.variablePay,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/variablePay'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.deduction,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/deduction'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.reimbursment,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/reimbusment'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.salaryAdvance,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/salaryAdvance'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.salaryArrers,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/salaryArrears'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.salaryOnHold,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/salaryOnHold'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.attendanceAndLeaveRegister.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/AttendanceAndLeaveRegister/AttendanceAndLeaveRegister'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.overTime,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/PayrollInput/overTime')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.overrideTDS,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/overrideTDS'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.employeeExistDetails,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/employeeExistDetails'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.payrollInput.newJoinee,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/PayrollInput/newJoinee'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.salaryregister,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/Processpayroll/Salaryregister'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.payrollCompliances.processpayroll.professionaltaxreport,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/Processpayroll/professionaltaxreport'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.providentfundreport,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/Processpayroll/Providentfundreport'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.payslip,
      component: lazy(() =>
        import(
          'src/views/finance-box/PayrollCompliances/Processpayroll/Payslip'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.esireport,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/Processpayroll/Esireport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.tdsreport,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/Processpayroll/Tdsreport')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.payrun,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/Processpayroll/Payrun')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.payrolldashboard,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/Processpayroll/Payrolldashboard')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.payrollCompliances.processpayroll.taxcomputation,
      component: lazy(() =>
        import('src/views/finance-box/PayrollCompliances/Processpayroll/Taxcomputation')
      )
    },
    
    {
      exact: true,
      path: PATH_APP.app2.compliances,
      component: lazy(() => import('src/views/finance-box/Compliances'))
    },

    {
      exact: true,
      path: PATH_APP.app2.notificationForm,
      component: lazy(() =>
        import('src/views/finance-box/ManagerNotifications/FormView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.requestCallback,
      component: lazy(() =>
        import('src/views/finance-box/RequestCallBack/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.LoansView,
      component: lazy(() => import('src/views/finance-box/LoansView'))
    },

    //Document Wallet
    {
      exact: true,
      path: PATH_APP.app2.documentWallet.root,
      component: lazy(() =>
        import('src/views/finance-box/DocumentWallet/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.documentWallet.folders,
      component: lazy(
        () => import('src/views/finance-box/DocumentWallet/DW-Folders')
        // import('src/views/finance-box/DocumentWallet/folders')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.documentWallet.files,
      component: lazy(() =>
        import('src/views/finance-box/DocumentWallet/foldersFiles')
      )
    },

    // user Document Wallet
    {
      exact: true,
      path: PATH_APP.app2.userDocumentWallet.root,
      component: lazy(() =>
        import('src/views/finance-box/DocumentWallet/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.userDocumentWallet.folders,
      component: lazy(() =>
        import('src/views/finance-box/DocumentWallet/folders')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.userDocumentWallet.files,
      component: lazy(() =>
        import('src/views/finance-box/DocumentWallet/foldersFiles')
      )
    },
    // user Document Wallet
    {
      exact: true,
      path: PATH_APP.app2.sales.root,
      component: lazy(() => import('src/views/finance-box/Sales/index'))
    },

    // loans
    {
      exact: true,
      path: PATH_APP.app2.clientLoans.root,
      component: lazy(() => import('src/views/finance-box/Loans/ClientLoans'))
    },
    //Firm Management
    {
      exact: true,
      path: PATH_APP.app2.firmManagement.root,
      component: lazy(() => import('~/views/finance-box/FirmManagement/index'))
    },
    {
      exact: true,
      path: PATH_APP.app2.firmManagement.customisation,
      component: lazy(() =>
        import('~/views/finance-box/FirmManagement/Customisation')
      )
    },

    //My Business
    {
      exact: true,
      path: PATH_APP.app2.myBusiness.root,
      component: lazy(() => import('src/views/finance-box/MyBusiness/index'))
    },
    {
      exact: true,
      path: PATH_APP.app2.myBusiness.addBusiness,
      component: lazy(() =>
        import('src/views/finance-box/MyBusiness/AddBusiness/index')
      )
    },

    // firm user management

    {
      exact: true,
      path: PATH_APP.app2.firmUserManagement.root,
      component: lazy(() =>
        import('src/views/finance-box/FirmAdmin/FirmUserManagement/index')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.root,
    //   component: lazy(() => import('src/views/finance-box/Clients/Clients'))
    // },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.root,
      component: lazy(() =>
        import('src/views/finance-box/FirmAdmin/ClientManagement/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.clientRegistration,
      component: lazy(() =>
        import(
          'src/views/finance-box/FirmAdmin/ClientManagement/ClientRegistration.js'
        )
      )
    },
    // anand
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.invoicing,
      component: lazy(() =>
        import(
          'src/views/finance-box/FirmAdmin/ClientManagement/Invoicing/index.js'
        )
      )
    },
    //
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.clientManagement,
      component: lazy(() =>
        import('src/views/finance-box/FirmAdmin/ClientManagement/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.clientSelection,
      component: lazy(() =>
        import('src/views/finance-box/Clients/ClientManagement')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.misForms,
      component: lazy(() => import('src/views/finance-box/Clients/MISActions'))
    },
    // Review Tracking(Manager)
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.reviewStatusCard,
      component: lazy(() =>
        import('src/views/finance-box/review-tracking/rt-home')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.reviewStatus.accounting,
      component: lazy(() =>
        import('src/views/finance-box/review-tracking/rt-accounting')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.reviewStatus.compliance,
      component: lazy(() =>
        import('src/views/finance-box/review-tracking/rt-compliance')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.reviewStatus.preliminaryData,
      component: lazy(() =>
        import('src/views/finance-box/review-tracking/rt-preliminary')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ClientManagement.reviewStatus.summary,
      component: lazy(() =>
        import('src/views/finance-box/review-tracking/rt-summary')
      )
    },

    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.preliminaryData,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/MISEmployeeForms/PreliminaryData/index'
    //     )
    //   )
    // },
    // tagging
    {
      exact: true,
      path: PATH_APP.app2.tagging,
      component: lazy(() =>
        import('src/views/finance-box/Tagging/ManagerTagging')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.viewTagging,
      component: lazy(() => import('src/views/finance-box/Tagging/ViewTagging'))
    },
    // BUSINESS SERVICES
    {
      exact: true,
      path: PATH_APP.app2.services.BusinessServices,
      component: lazy(() => import('~/views/finance-box/services/index.js'))
    },
    {
      exact: true,
      path: PATH_APP.app2.services.BusinessIncorporationandRegistrations,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/BusinessServicesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.OtherGovernmentRegistrations,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/OtherGovernmentRegistrationsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.BusinessIncorporations,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/BusinessIncorporationsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.Company,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/CompanyView')
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.services.Audit,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/AuditServices.js'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.services.AccountingAndBookkeeping,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/AccountingAndBookkeepingView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.AccountingServicesUnderPackage,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/AccountingServicesUnderPackage'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.ComplianceServicesUnderPackage,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/ComplianceServicesUnderPackage'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.GSTServices,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/GSTServicesView')
      )
    },

    /// anand business incorporation
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.Privatelimitedcompany,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/Company/PvtLtdCompanyIncorporationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.OPCIncorporation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/Company/OPCIncorporationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.Partnershipfirm,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/PatnershipFirmView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.LLPIncorporation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/LLPIncorporationView'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.BusinessIncorporations.SoleProprietorshipIncorporation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/SoleProprietorshipView.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.Sec8CompanyIncorporationView,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/Sec8CompanyIncorporationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.ProducerCompanyView,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/ProducerCompanyView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.BusinessIncorporations.NidhiCompanyView,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/NidhiCompany'
        )
      )
    },
    /// gst
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.registrationIndividual,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTRegistationIndividual'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTR9AnuualReturn,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTR9AnuualReturnView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.EwayBillRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/EwayBillRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTCancellation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTCancellationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/AmendmentInGSTRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTNotice,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTNoticeView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.LUTFilings,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/LUTFilingsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.Reconcilliations,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/Reconcilliations'
        )
      )
    },
    // tds
    {
      exact: true,
      path: PATH_APP.app2.services.TDSServices,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/TDSServicesView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PaymentinTDS.PaymentsinTDS,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/TDSServicesViews/PaymentsinTDS'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.TDSServices.Form24Q,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/TDSServicesViews/TDS24QView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.TDSServices.Form26Q,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/TDSServicesViews/TDS26QView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.TDSServices.Form26QB,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/TDSServicesViews/Form26QBView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.TDSServices.Form27Q,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/TDSServicesViews/Form27QView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.TDSServices.Form27EQ,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/TDSServicesViews/Form27EQView'
        )
      )
    },

    // Income Tax filings
    {
      exact: true,
      path: PATH_APP.app2.services.IncomeTaxReturnsBusiness,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/IncomeTaxReturnsBusinessView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.IndividualsIncomeTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/IncomeFromBusinessProfessionView'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturnsBusiness.SoleProprietorIncomeTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/SoleProprietorReturns'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturnsBusiness
          .PrivateLimitedCompanyIncomeTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/PrivateLimitedCompanyReturns'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.PatnershipFirmTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/PartnershipFirmReturnsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.PatnershipLLPReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/PatnershipLLPReturnsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.TrustReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/TrustReturnsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.SocietyReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/SocietyReturnsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.Sec8CompanyIncomeTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/Sec8CompanyReturns'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturnsBusiness.OnePersonCompanyIncomeTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/OnePersonCompanyReturns'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.NidhiCompanyReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/NidhiCompanyReturns'
        )
      )
    },

    // roc
    {
      exact: true,
      path: PATH_APP.app2.services.AnnualFilingsCompany,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/AnnualFilingsCompanyView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.AnnualFilingsLLP,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/AnnualFilingsLLPView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.CompanyFilings,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/CompanyFilingsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.LLPFilings,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/LLPFilingsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.ROCCompliances,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/ROCCompliancesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.CommencementOfBusiness,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/CommencementOfBusinessView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.AuditorAppointment,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/AuditorAppointmentView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.AOC4,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/AOC4View'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.MGT7,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/MGT7View'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.DirectorReport,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/DirectorsReport'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ClientManagement.complianceManager.tdsServices.tdsFilings
          .masterData,
      component: lazy(() =>
        import(
          'src/views/finance-box/Clients/ComplianceManager/TDSServices/TDS/MasterData'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.Form11,

      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/Form11View'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.Form8,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/Form8View'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.DIR3KYC,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/DIR3KYCView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.DirectorAppointment,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/DirectorAppointment'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.DirectorRemoval,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/DirectorRemoval'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.AuditorRemoval,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/AuditorRemovalView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.ChangeInAuthorizedShareCapital,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/ChangeInAuthorizedShareCapitalView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.ChangeInObjects,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/ChangeInObjectsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.AddDesignatedPartner,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/AddDesignatedPartner'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.ChangestoLLPAgreement,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/ChangestoLLPAgreement'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.LLPClosing,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/LLPClosing'
        )
      )
    },
    // payroll compliances

    {
      exact: true,
      path: PATH_APP.app2.services.ESI,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/Compliance')
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.services.PayrollCompliances,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/PayrollCompliancesView'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PayrollPreparation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/PayrollPreparationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PayrollGeneration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/PayrollGeneration'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.Form16Generation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/Form16Generation'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.EmployeeAgreements,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/EmployeeAgreements'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.SalaryStructure,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/SalaryStructure'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.ESOPStructure,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/ESOPStructure'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.ESIRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/ESIRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.ProvidentFund,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/ProvidentFund'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.ProfessionalTax,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/ProfessionalTax'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.Registration,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/PayrollRegistration'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.PayrollPreparation,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/PayrollPreparation'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PaymentAndReturn,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/PaymentandReturn.js'
        )
      )
    },
    // here one more should be
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.ESIReturnFiling,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/ESIReturnFilingView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.POSH,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/POSH'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.Bonus,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/Bonus'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.Gratuity,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/Gratuity'
        )
      )
    },

    // accounting
    {
      exact: true,
      path: PATH_APP.app2.AccountingandComplinaces.BookKeeping,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/AccountingandCompliances/BookKeeping.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.AccountingandComplinaces.MonthEndReviews,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/AccountingandCompliances/MonthEndReviews.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.AccountingandComplinaces.MISReport,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/AccountingandCompliances/MISReport.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.AccountingandComplinaces.PayrollMaintanance,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/AccountingandCompliances/PayrollMaintanance.js'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.AccountingandComplinaces
          .PreparingAndFilingofStatutoryPaymentsandReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/AccountingandCompliances/PreparingAndFilingofStatutoryPaymentsandReturns.js'
        )
      )
    },
    // audit
    {
      exact: true,
      path: PATH_APP.app2.Audit.StatutoryAudit,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/StatutoryAudit.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.InternalAudit,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/InternalAudit.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.TaxAudit,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/TaxAudit.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.SystemsAudit,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/SystemsAudit.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.ManagementAudit,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/ManagementAudit.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.AgreedUponProcedures,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/AgreedUponProcedures.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.CertificationServices,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/CertificationServices.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Audit.SpecialAudits,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Audit/SpecialAudits.js'
        )
      )
    },
    // registrations in buisness incorporation
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.TrustDetails,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/Trust'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.MSMERegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/MSMERegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.IECRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/IECRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.FoodLicenseRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/FSSAIRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.TradeLicense,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/TradeLicense'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.DigitalSignature,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/DigitalSignatureView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.PANRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/PANRegistrationView'
        )
      )
    },
    //ROC service
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ROCServices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/ROCServiceCards'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ROCServices.filing,
      component: lazy(() =>
        import('src/views/finance-box/ComplianceManager/ROCServices/Filing')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ROCServices.nonFiling,
      component: lazy(() =>
        import('src/views/finance-box/ComplianceManager/ROCServices/NonFiling')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ROCServices.declaration,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Declaration'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Registers/RegisterServiceCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.directors,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Registers/Directors'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ROCServices.registers
          .shareHolders,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Registers/ShareHolders'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ROCServices.registers
          .liByCompanies,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Registers/Loans-Investmentsbycompanies'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ROCServices.registers
          .relatedParties,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Registers/RelatedParties'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.kmp,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/ROCServices/Registers/KMP'
        )
      )
    },

    //payroll service
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/PayRollServiceCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation
          .root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/Reconcilation/ReconcilationCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.PF,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/Reconcilation/PF'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.PT,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/Reconcilation/PT'
        )
      )
    },
    // {
    //   exact: true,
    //   path:
    //     PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.ESI,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/ComplianceManager/PayRollServices/Reconcilation/ESI'
    //     )
    //   )
    // },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation
          .DifferenceSheet,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/Reconcilation/DifferenceSheet'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.PayrollServices.notices,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/Notices'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/FilingStatus/FilingStatusCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.PF,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/FilingStatus/PF'
        )
      )
    },
    // {
    //   exact: true,
    //   path:
    //     PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.ESI,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/ComplianceManager/PayRollServices/FilingStatus/ESI'
    //     )
    //   )
    // },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.PT,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/FilingStatus/PT'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.PayrollServices.register,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/PayRollServices/Register'
        )
      )
    },
    //sebi service
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.SEBIServices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/SEBIServices/SEBIServiceCards'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.SEBIServices.quarterly,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/SEBIServices/QuarterlyReporting'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.SEBIServices.halfYearly,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/SEBIServices/HalfYearlyReporting'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.SEBIServices.annual,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/SEBIServices/AnnualReporting'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.SEBIServices.eventBased,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/SEBIServices/EventBasedReporting'
        )
      )
    },
    //Income Tax Services

    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ITServices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/ITServiceCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/DetailsOfTaxesCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes
          .advanceTaxPaid,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/AdvanceTaxPaid'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes
          .otherTaxesPaid,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/OtherTaxesPaid'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes
          .tdstcsCredit,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/DetailsOfTaxes/TDS&TCSCredit'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/NoticesCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.notices.intimations,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/Intimations'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.scrutiny,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/Scrutiny'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.assessment,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/AssessmentReAssessment'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.notices.returnFiling,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/ReturnFiling'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.notices
          .adjustingRefund,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/AdjustingRefund'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.ITServices.filingStatus,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/FilingStatus'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.ITServices.notices
          .questionResponse,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/IncomeTaxServices/Notices/QuestionsAndResponse'
        )
      )
    },

    //Compliance Manager CLIENT

    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.MonthlySummary,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/MonthlySummary'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.gstServices,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/GSTServiceCards'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.turnoverReconciliation,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/TurnOverReconciliation'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.itcReconciliation,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/ItcReconciliation'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.purchaseReconciliation,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/PurchaseReconciliation'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.filingStatus,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/FilingStatus'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.detailedReportCards,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/GSTServices/DetailedReportsCards'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.Labourlicense,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/LabourLicense'
        )
      )
    },
    /////////////////

    //tds Services
    {
      exact: true,
      path: PATH_APP.app2.ComplianceManagerClient.tdsServices.root,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSServiceCards'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus.root,
      component: lazy(() =>
        import('src/views/finance-box/ComplianceManager/TDSServices/TDSPayment')
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus
          .challanDetails,
      component: lazy(() =>
        import(
          'src/views/finance-box/ComplianceManager/TDSServices/TDSPayment/ChallanDetails'
        )
      )
    },
    //tds Services

    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.complianceManager.payrollService
    //       .providentFund,
    //   component: lazy(() =>
    //     import(
    //       '~/views/finance-box/Clients/ComplianceManager/PayrollServices/'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.complianceManager.payrollService.employeeStateInsurance,
    //   component: lazy(() =>
    //     import(
    //       '~/views/finance-box/Clients/ComplianceManager/PayrollServices/EmployeeStateInsurance/index'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.complianceManager.payrollService.professionalTax,
    //   component: lazy(() =>
    //     import(
    //       '~/views/finance-box/Clients/ComplianceManager/PayrollServices/ProfessionalTax/index'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.complianceManager.payrollService.espo,
    //   component: lazy(() =>
    //     import(
    //       '~/views/finance-box/Clients/ComplianceManager/PayrollServices/ESPO/index'
    //     )
    //   )
    // },

    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.AnnexureOverallSummary,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureOverallSummary'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.overallSummary,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/OverallSummary'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.detailedReport,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/DetailedReport'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.complianceManager,
    //   component: lazy(() =>
    //     import('src/views/finance-box/Clients/ComplianceManagerServices')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.AnnexureTaxableSupplies,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureTaxableSupplies'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.AnnexureOthers,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureOthers'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.annexure,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR2BvsBooks/Annexure'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.purchasesInBooks,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/PurchasesInBooks'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.purchasesInvoice,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/PurchaseInvoice'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.purchaseCreditDebit,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR2AvsPurchases/PurchaseCreditDebitNote'
    //     )
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.ClientManagement.annexureZeroRated,
    //   component: lazy(() =>
    //     import(
    //       'src/views/finance-box/Clients/ComplianceManager/GSTServices/GSTR3BvsGSTR1vsBooks/AnnexureZeroRated'
    //     )
    //   )
    // },

    //BUSINESS SERVICES

    // payment page after 3b form
    // {
    //   exact: true,
    //   path: PATH_APP.app2.services.GST3BPayment,
    //   component: lazy(() =>
    //     import('~/views/finance-box/Clients/ComplianceManager/GSTServices/GST3BForm/PaymentPage')
    //   )
    // },
    // submitPage

    ///////////////
    {
      exact: true,
      path: PATH_APP.app2.services.TDSTCS,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/TDSServicesView')
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.services.Loans,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/LoansView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.FinancialAdviosryAndLegalServices,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/FinancialAdviosryAndLegalServicesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.Investments,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/InvestmentsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.InsuranceAdvisory,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/InsuranceAdvisoryView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.GSTRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/GSTRegistrationView'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.myBusiness.editBusiness,
      component: lazy(() =>
        import('src/views/finance-box/MyBusiness/AddBusiness/index')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.EwayBillServices,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/EwayBillServicesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.GSTCancellationAndRevocation,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/GSTCancellationAndRevocationView'
        )
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.services.ESI,
    //   component: lazy(() =>
    //     import('~/views/finance-box/services/business-services/ESIView')
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.app2.services.PF,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/PFView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.PT,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/PTView')
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.services.OtherLLPFilings,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/OtherLLPView')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.OtherCompanyFilings,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/OtherCompanyView'
        )
      )
    },

    // GST Services

    {
      exact: true,
      path: PATH_APP.app2.GSTServices.registrationcompany,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTRegistrationCompany'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTReturns'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTRegistrationIndividualForm,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/Forms/GSTRegistrationIndividual'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTRegistrationCompanyForm,
      component: lazy(() =>
        import('~/views/finance-box/ServiceViews/Forms/GSTCompany')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTReturnsForm,
      component: lazy(() =>
        import('~/views/finance-box/ServiceViews/Forms/GSTReturns')
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.GSTServices.EwayBillGeneration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/EwayBillGenerationView'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.GSTServices.GSTRevocation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/GSTServices/GSTRevocationView'
        )
      )
    },

    //Business Incorporation
    // {
    //   exact: true,
    //   path: PATH_APP.app2.BusinessIncorporations.PatnershipFirm,
    //   component: lazy(() =>
    //     import(
    //       '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/PatnershipFirmView'
    //     )
    //   )
    // },

    // {
    //   exact: true,
    //   path: PATH_APP.app2.BusinessIncorporations.Privatelimitedcompany,
    //   component: lazy(() => import('~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/Company/PvtLtdCompanyIncorporationView'))
    // },
    {
      exact: true,
      path:
        PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
          .PvtLtdCompanyIncorporation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/Company/PvtLtdCompanyIncorporationView'
        )
      )
    },

    //Other Govt REgistrations

    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.Form15CAAnd15CB,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/Form15CA15CBView'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.ShopsAndEshtablishments,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/ShopsAndEshtablishmentsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.OtherGovtRegistrations.TrademarkRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/OtherGovtRegistrations/TrademarkRegistrationView'
        )
      )
    },

    // Income Tax Returns : Business

    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.ProducerCompanyReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/ProducerCompanyReturns'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturnsBusiness.CompanyReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/IncomeTaxReturns/CompanyReturnsView'
        )
      )
    },

    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturnsBusiness.IndividualsIncomeTaxReturnsForm,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/Forms/ITR/IncomeFromBusinessProfession'
        )
      )
    },

    //Payroll Compliances

    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.Registration,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/RegistrationView.js'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.payrollprocessingservice,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/business-services/PayrollPreparation.js'
        )
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.Compliance,
      component: lazy(() =>
        import('~/views/finance-box/services/business-services/Compliance.js')
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PFRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/PFRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PFFiling,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/PFFilingView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PTRegistration,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/PTRegistrationView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.PayrollCompliances.PTReturnFiling,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/PayrollCompliances/PTReturnFilingView'
        )
      )
    },

    //Loans
    {
      exact: true,
      path: PATH_APP.app2.Loans.TermLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Loans/TermLoanView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Loans.HomeLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Loans/HomeLoanView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Loans.VehicleLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Loans/VehicleLoanView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.Loans.WorkingCapitolLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/Loans/WorkingCapitolLoanView'
        )
      )
    },

    //Financial Advisory and Legal Services
    {
      exact: true,
      path: PATH_APP.app2.FinancialAdviosryAndLegalServices.DraftingOfDocuments,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/FinancialAdvisoryAndLegalServices/DraftingOfDocumentsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.FinancialAdviosryAndLegalServices.LegalReview,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/FinancialAdvisoryAndLegalServices/LegalReviewView'
        )
      )
    },

    //ROC Compliances

    {
      exact: true,
      path: PATH_APP.app2.InsuranceServices.CoronavirusInsurance,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/Forms/CoronaVirusInsuranceForm'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.InsuranceServices.HealthInsurance,
      component: lazy(() =>
        import('~/views/finance-box/ServiceViews/Forms/HealthInsuranceForm')
      )
    },

    {
      exact: true,
      path: PATH_APP.app2.ROCCompliances.DPT3,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/ROCCompliances/DPT3View'
        )
      )
    },

    //ADD REPRESENTATIVES
    {
      exact: true,
      path: PATH_APP.app2.addRepresentatives,
      component: lazy(() =>
        import('~/views/finance-box/services/AddRepresentativeView')
      )
    },

    //INDIVIDUAL SERVICES
    {
      exact: true,
      path: PATH_APP.app2.services.IndividualServices,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/IndividualServicesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.IncomeTaxReturns,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/IncomeTaxReturnsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.TdsOnSaleOfProperty,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/TdsOnSaleOfPropertyView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.InsuranceServices,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/InsuranceServicesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.LoanServices,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/LoanServicesView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.FinancialAndLegalAdvisory,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/FinancialAndLegalAdvisoryView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.SalariedEmployee,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/SalariedEmployeeView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.HealthInsuranceIndividual,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/HealthInsuranceIndividualView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.services.VehicleInsuranceIndividual,
      component: lazy(() =>
        import(
          '~/views/finance-box/services/individual-services/VehicleInsuranceIndividualView'
        )
      )
    },

    //Income tax returns
    // {
    //   exact: true,
    //   path: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.LessThan50Lakh,
    //   component: lazy(() => import('~/views/finance-box/ServiceViews/Forms/IncomeTaxReturns'))
    // },
    {
      exact: true,
      path: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.MoreThan50Lakh,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/IncomeTaxReturns/MoreThan50LakhView'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
          .SalaryHousePropertyOtherIncome,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/IncomeTaxReturns/SalaryHousePropertyOtherIncomeView'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
          .SalaryHousePropertyCapitalGainOtherIncome,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/IncomeTaxReturns/SalaryHousePropertyCapitalGainOtherIncomeView'
        )
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.MoreThan50Lakh,
    //   component: lazy(() => import('~/views/finance-box/ServiceViews/IndividualServicesViews/IncomeTaxReturns/MoreThan50LakhView'))
    // },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
          .SalaryHousePropertyOtherIncomeForm,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/Forms/ITR/SalaryHouseOtherIncome'
        )
      )
    },
    {
      exact: true,
      path:
        PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
          .SalaryHousePropertyCapitalGainOtherIncomeForm,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/Forms/ITR/SalaryHouseCapitalGainOtherIncome'
        )
      )
    },

    //TDS on Sale of Property
    {
      exact: true,
      path: PATH_APP.app2.TDSOnSaleOfProperty.Form26QB,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/TDSOnSaleOfProperty/Form26QBView'
        )
      )
    },

    //Financial And Legal Advisory
    {
      exact: true,
      path: PATH_APP.app2.FinancialAndLegalAdvisory.DraftingOfDocuments,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/FinancialAndLegalAdvisory/DraftingOfDocumentsView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.FinancialAndLegalAdvisory.LegalReview,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/FinancialAndLegalAdvisory/LegalReviewView'
        )
      )
    },

    //Loan Services
    {
      exact: true,
      path: PATH_APP.app2.LoanServices.TermLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/LoanServices/TermLoanView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.LoanServices.HomeLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/LoanServices/HomeLoanView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.LoanServices.VehicleLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/LoanServices/VehicleLoanView'
        )
      )
    },
    {
      exact: true,
      path: PATH_APP.app2.LoanServices.WorkingCapitolLoan,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/IndividualServicesViews/LoanServices/WorkingCapitolLoanView'
        )
      )
    },

    // MAIN DASHBOARD
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.main.dashboard,
    //   component: lazy(() => import('src/views/general/DashboardAppView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.main.ecommerce,
    //   component: lazy(() => import('src/views/general/DashboardEcommerceView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.main.analytics,
    //   component: lazy(() => import('src/views/general/DashboardAnalyticsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.root,
    //   component: () => <Redirect to={PATH_APP.main.root} />
    // },

    // // MANAGEMENT : E-COMMERCE
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.management.eCommerce.products,
    //   component: lazy(() => import('~/views/e-commerce/ShopView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.eCommerce.product,
    //   component: lazy(() => import('~/views/e-commerce/ProductDetailsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.eCommerce.list,
    //   component: lazy(() => import('~/views/e-commerce/ProductListView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.eCommerce.checkout,
    //   component: lazy(() => import('~/views/e-commerce/CheckoutView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.eCommerce.invoice,
    //   component: lazy(() => import('~/views/e-commerce/InvoiceView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.eCommerce.root,
    //   component: () => <Redirect to={PATH_APP.management.eCommerce.products} />
    // },

    // // MANAGEMENT : BLOG
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.management.blog.root,
    //   component: lazy(() => import('~/views/blog/BlogView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.blog.post,
    //   component: lazy(() => import('~/views/blog/PostDetailsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.blog.newPost,
    //   component: lazy(() => import('~/views/blog/NewPostView'))
    // },

    // // MANAGEMENT : USER
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.profile,
    //   component: lazy(() => import('~/views/user/ProfileView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.cards,
    //   component: lazy(() => import('~/views/user/UserCardsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.list,
    //   component: lazy(() => import('~/views/user/UserListView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.account,
    //   component: lazy(() => import('~/views/user/AccountView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.user.root,
    //   component: () => <Redirect to={PATH_APP.management.user.profile} />
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.management.root,
    //   component: () => <Redirect to={PATH_APP.management.user.profile} />
    // },

    // // APP : CHAT
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.app.chat.conversation,
    //   component: lazy(() => import('src/views/finance-box/Chat/Sidebar/index'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app.chat.root,
    //   component: () => <Redirect to={PATH_APP.app.chat.new} />
    // },

    // APP : MAIL
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.app.clients.labels,
    //   component: lazy(() => import('src/views/app/MailView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.app.clients,
    //   component: () => <Redirect to={PATH_APP.app.mail.all} />
    // },

    // // APP : CALENDAR
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.app.calendar,
    //   component: lazy(() => import('src/views/app/CalendarView'))
    // },

    // // FOUNDATIONS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.foundations.colors,
    //   component: lazy(() => import('src/views/uikit/foundations/ColorsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.foundations.typography,
    //   component: lazy(() =>
    //     import('src/views/uikit/foundations/TypographyView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.foundations.shadows,
    //   component: lazy(() => import('src/views/uikit/foundations/ShadowsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.foundations.grid,
    //   component: lazy(() => import('src/views/uikit/foundations/GridView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.foundations.icons,
    //   component: lazy(() => import('src/views/uikit/foundations/IconsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.foundations.root,
    //   component: () => <Redirect to={PATH_APP.foundations.colors} />
    // },

    // // COMPONENTS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.components.accordion,
    //   component: lazy(() => import('src/views/uikit/components/AccordionView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.alert,
    //   component: lazy(() => import('src/views/uikit/components/AlertView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.autocomplete,
    //   component: lazy(() =>
    //     import('src/views/uikit/components/AutocompleteView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.avatar,
    //   component: lazy(() => import('src/views/uikit/components/AvatarView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.badge,
    //   component: lazy(() => import('src/views/uikit/components/BadgeView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.breadcrumbs,
    //   component: lazy(() => import('src/views/uikit/components/BreadcrumbView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.buttons,
    //   component: lazy(() => import('src/views/uikit/components/ButtonsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.chip,
    //   component: lazy(() => import('src/views/uikit/components/ChipsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.dialog,
    //   component: lazy(() => import('src/views/uikit/components/DialogView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.textfield,
    //   component: lazy(() => import('src/views/uikit/components/TextFieldView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.label,
    //   component: lazy(() => import('src/views/uikit/components/LabelView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.lists,
    //   component: lazy(() => import('src/views/uikit/components/ListsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.menu,
    //   component: lazy(() => import('src/views/uikit/components/MenusView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.pagination,
    //   component: lazy(() => import('src/views/uikit/components/PaginationView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.pickers,
    //   component: lazy(() => import('src/views/uikit/components/PickersView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.popover,
    //   component: lazy(() => import('src/views/uikit/components/PopoverView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.progress,
    //   component: lazy(() => import('src/views/uikit/components/ProgressView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.rating,
    //   component: lazy(() => import('src/views/uikit/components/RatingView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.selectionControls,
    //   component: lazy(() =>
    //     import('src/views/uikit/components/SelectionControlsView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.snackbar,
    //   component: lazy(() => import('src/views/uikit/components/SnackbarView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.slider,
    //   component: lazy(() => import('src/views/uikit/components/SliderView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.stepper,
    //   component: lazy(() => import('src/views/uikit/components/StepperView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.tabs,
    //   component: lazy(() => import('src/views/uikit/components/TabsView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.table,
    //   component: lazy(() => import('src/views/uikit/components/TableView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.timeline,
    //   component: lazy(() => import('src/views/uikit/components/TimelineView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.tooltip,
    //   component: lazy(() => import('src/views/uikit/components/TooltipView'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.transferList,
    //   component: lazy(() =>
    //     import('src/views/uikit/components/TransferListView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.treeView,
    //   component: lazy(() => import('src/views/uikit/components/TreesView'))
    // },

    // // EXTRA COMPONENTS
    // // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.components.chart.apexcharts,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/ChartsView/Apexcharts')
    //   )
    // },
    {
      exact: true,
      path: PATH_APP.components.chart.recharts,
      component: lazy(() =>
        import('src/views/uikit/extra-components/ChartsView/Recharts')
      )
    },
    // {
    //   exact: true,
    //   path: PATH_APP.components.map.google,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/MapView/GoogleMaps')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.map.mapbox,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/MapView/ReactMapGL')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.editor,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/EditorView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.copyToClipboard,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/CopyToClipboardView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.upload,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/UploadView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.carousel,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/CarouselView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.multiLanguage,
    //   component: lazy(() =>
    //     import('src/views/uikit/extra-components/MultiLanguageView')
    //   )
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.root,
    //   component: () => <Redirect to={PATH_APP.components.accordion} />
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.chart.root,
    //   component: () => <Redirect to={PATH_APP.components.chart.apexcharts} />
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.components.map.root,
    //   component: () => <Redirect to={PATH_APP.components.map.google} />
    // },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
