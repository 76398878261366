export const TDSServicesListCards = [
  {
    id: 0,
    service: 'TDS Payment',
    color: 'primary',
    icon: 'ic_user',
    href: '/tds-filings'
  },
  // {
  //   id: 1,
  //   service: 'Challan & Deductee Details',
  //   color: 'primary',
  //   icon: 'ic_user',
  //   href: '/challan-deductee'
  // },
  {
    id: 2,
    service: 'TDS Filing',
    color: 'primary',
    icon: 'ic_user',
    href: '/fillings'
  },
  {
    id: 3,
    service: 'TDS Default Summary',
    color: 'primary',
    icon: 'ic_user',
    href: '/default-summary'
  },
  {
    id: 4,
    service: 'Upload Form 16A / 16',
    color: 'primary',
    icon: 'ic_user',
    href: '/upload-form-16'
  },
  {
    id: 5,
    service: 'TDSReconciliation',
    color: 'primary',
    icon: 'ic_user',
    href: '/tds-reconciliation'
  }
];

// export const SECTION = {
//   '192': 'Salary',
//   '192-A':
//     'Premature withdrawal from Employee Provident Fund (Payment of accumulated balance of provident fund which is taxable in the hands of an employee)',
//   '193': 'Interest on securities',
//   '194': 'Dividend other than the dividend as referred to in Section 115-O',
//   '194-A':
//     'Interest other than interest on securities – Banks Time deposits, Recurring deposit and Deposit in Co-op Banks',
//   '194-B':
//     'Income by way of winnings from lotteries, crossword puzzles, card games and other games of any sort',
//   '194-BB': 'Income by way of winnings from horse races',
//   '194-C': 'Payment to Contractors',
//   '194-D': 'Insurance commission',
//   '194-DA':
//     'Payment in respect of life insurance policy, the tax shall be deducted on the amount of income comprised in insurance pay - out',
//   '194-E': 'Payment to non -resident sportsmen/ sports association',
//   '194-EE': 'Payment in respect of deposit under National Savings scheme',
//   '194-G': 'Commission on sale of lottery tickets',
//   '194-H': 'Commission or brokerage',
//   '194-I': 'RENT',
//   '194-I(a)': 'Rent - Plant & Machinery',
//   '194-I(b)': 'Rent - Land or building or furniture or fitting',
//   '194-IA':
//     'Transfer of certain immovable property other than agriculture land',
//   '194-IB': 'Payment of Rent by Individuals or HUF not liable for Tax Audit',
//   '194-IC':
//     'Payment of monetary consideration under Joint Development Agreements',
//   '194-J': 'Fees for professional or technical services',
//   '194-K':
//     'Payment of any income in respect of Units of Mutual fund as per section 10(23D) or Units of administrator or from a specified company',
//   '194-LA':
//     'TDS on compensation for compulsory acquisition of immovable Property',
//   '194-LBA(1)':
//     'Business trust shall deduct tax while distributing, any interest received or receivable by it from a SPV or any income received from renting or leasing or letting out any real estate asset owned directly by it, to its unit holders',
//   '194-LBA(2)':
//     'Distribution of, any interest received or receivable from SPV by Business trust',
//   '194-LBA(3)':
//     'Distribution of, any income received from renting or leasing or letting out any real estate asset owned directly by Business trust',
//   '194-LB': 'Payment of interest on infrastructure debt fund to Non Resident',
//   '194-LBB':
//     'Investment fund paying an income to a unit holder [other than income which is exempt under Section 10(23FBB)]',
//   '194-LBC':
//     'Income in respect of investment made in a securitization trust (specified in Explanation of section 115TCA)',
//   '194-M':
//     'Payment of commission, brokerage, contractual fee, professional fee to a resident person by an Individual or a HUF who are not liable to deduct TDS under section 194C, 194H, or 194J.',
//   '194-N': 'Cash withdrawal',
//   '194-O':
//     'Applicable for E -Commerce operator for sale of goods or provision of service facilitated by it through its digital or electronic facility or platform.',
//   '194-Q': 'Purchase of goods (applicable w.e.f 01.07.2021)',
//   '194-P': 'TDS on Senior Citizen above 75 Years',
//   '195': 'Payment of any other sum to a Non -resident',
//   '206-AB': 'TDS on non -filers of ITR'
// };

export const TDS_SECTION = {
  '193': 'Interest on Securities',
  '194': 'Dividend',
  '195': 'Other sums payable to a nonresident',
  '4BA': 'Certain income from units of a business trust',
  '4EE': 'Payments in respect of Deposits under National Saving Schemes',
  '4LA': 'Payment of Compensation on Acquisition of Certain Immovable property',
  '4LB':
    'Income by way of interest from infrastructure debt fund payable to a nonresident',
  '4LC':
    'Income by way of interest from specified Company payable to a nonresident',
  '4LD':
    'Interest on Rupee denominated bond of Company or Government Securities',
  '92A': 'Payment to Govt. Employees other than Union Govt. employees',
  '92B': 'Payment of Employees other than Govt. Employees',
  '94A': 'Interest other than Interest on Securities',
  '94B': 'Winning from lotteries and crossword puzzles    ',
  '94C': 'Payment of contractors and subcontractors',
  '94D': 'Insurance commission',
  '94E': 'Payments to nonresident Sportsmen/Sport Associations',
  '94F': 'Payments on account of Repurchase of Units by Mutual Funds of UTI',
  '94G': 'Commission, prize etc. on sale of Lottery tickets',
  '94H': 'Commission or Brokerage ',
  '94I': 'Rent',
  '94J': 'Fees for Professional or Technical Services',
  '94K':
    'Income Payable to a resident assessee in respect of units of a specified Mutual Fund or of the Units of the UTI',
  '94N': 'Payment of certain amounts in cash',
  '94P': 'Payment to Specified Senior Citizen',
  '94Q':
    'Deduction of tax at source on payment of certain sum for purchase of goods',
  '96A': 'Income in respect of Units of nonresidents',
  '96B': 'Payments in respect of Units to an Offshore Fund',
  '96C':
    'Income from foreign currency Bonds or Shares of Indian Company payable to a nonresident',
  '96D': 'Income of Foreign Institutional investors from securities',
  '2AA': 'Payment of accumulated balance due to an employee',
  LBB: 'Income in respect of units of investment fund',
  LBC: 'Income in respect of investment in securitization trust',
  '4IC': 'Payment under specified agreement',
  '94O': 'TDS on Ecommerce transactions'
};

export const TCS_SECTION = {
  '6CA': 'Alcoholic liquor for human consumption',
  '6CB': 'Timber obtained under forest lease',
  '6CC': 'Timber obtained other than forest lease',
  '6CD': 'Any other forest produce not being timber or tendu leaves',
  '6CE': 'Scrap',
  '6CF': 'Parking Lot',
  '6CG': 'Toll Plaza',
  '6CH': 'Mining and Quarrying',
  '6CI': 'Tendu Leaves',
  '6CJ': 'Minerals',
  '6CK': 'Bullion and Jewelry',
  '6DA':
    'Income of specified fund from securities referred to in clause (a) of subsection (1) of section 115AD (other than interest income referred to in section 194LD)',
  '6CL': 'TCS on sale of Motor vehicle',
  '6CM': '6CTCS on sale in cash of any goods (other than bullion/jewelry)',
  '6CN': 'TCS on providing of any services (other than Ch-XVII-B)',
  '6CO': 'TCS on foreign remittance',
  '6CP':
    '6Remittance under LRS for educational loan taken from financial institution mentioned in section 80E',
  '6CQ':
    'Remittance under LRS for purpose other than for purchase of overseas tour package or for educational loan taken from financial institution',
  '6CR': 'TCS on sale of Goods'
};

export const SECTION = {
  '193': 'Interest on Securities',
  '194': 'Dividend',
  '195': 'Other sums payable to a nonresident',
  '4BA': 'Certain income from units of a business trust',
  '4EE': 'Payments in respect of Deposits under National Saving Schemes',
  '4LA': 'Payment of Compensation on Acquisition of Certain Immovable property',
  '4LB':
    'Income by way of interest from infrastructure debt fund payable to a nonresident',
  '4LC':
    'Income by way of interest from specified Company payable to a nonresident',
  '4LD':
    'Interest on Rupee denominated bond of Company or Government Securities',
  '6CA': 'Alcoholic liquor for human consumption',
  '6CB': 'Timber obtained under forest lease',
  '6CC': 'Timber obtained other than forest lease',
  '6CD': 'Any other forest produce not being timber or tendu leaves',
  '6CE': 'Scrap',
  '6CF': 'Parking Lot',
  '6CG': 'Toll Plaza',
  '6CH': 'Mining and Quarrying',
  '6CI': 'Tendu Leaves',
  '6CJ': 'Minerals',
  '6CK': 'Bullion and Jewelry',
  '6DA':
    'Income of specified fund from securities referred to in clause (a) of subsection (1) of section 115AD (other than interest income referred to in section 194LD)',
  '92A': 'Payment to Govt. Employees other than Union Govt. employees',
  '92B': 'Payment of Employees other than Govt. Employees',
  '94A': 'Interest other than Interest on Securities',
  '94B': 'Winning from lotteries and crossword puzzles    ',
  '94C': 'Payment of contractors and subcontractors',
  '94D': 'Insurance commission',
  '94E': 'Payments to nonresident Sportsmen/Sport Associations',
  '94F': 'Payments on account of Repurchase of Units by Mutual Funds of UTI',
  '94G': 'Commission, prize etc. on sale of Lottery tickets',
  '94H': 'Commission or Brokerage ',
  '94I': 'Rent',
  '94J': 'Fees for Professional or Technical Services',
  '94K':
    'Income Payable to a resident assessee in respect of units of a specified Mutual Fund or of the Units of the UTI',
  '94N': 'Payment of certain amounts in cash',
  '94P': 'Payment to Specified Senior Citizen',
  '94Q':
    'Deduction of tax at source on payment of certain sum for purchase of goods',
  '96A': 'Income in respect of Units of nonresidents',
  '96B': 'Payments in respect of Units to an Offshore Fund',
  '96C':
    'Income from foreign currency Bonds or Shares of Indian Company payable to a nonresident',
  '96D': 'Income of Foreign Institutional investors from securities',
  '2AA': 'Payment of accumulated balance due to an employee',
  LBB: 'Income in respect of units of investment fund',
  '6CL': 'TCS on sale of Motor vehicle',
  '6CM': '6CTCS on sale in cash of any goods (other than bullion/jewelry)',
  '6CN': 'TCS on providing of any services (other than Ch-XVII-B)',
  LBC: 'Income in respect of investment in securitization trust',
  '4IC': 'Payment under specified agreement',
  '6CO': 'TCS on foreign remittance',
  '6CP':
    '6Remittance under LRS for educational loan taken from financial institution mentioned in section 80E',
  '6CQ':
    'Remittance under LRS for purpose other than for purchase of overseas tour package or for educational loan taken from financial institution',
  '6CR': 'TCS on sale of Goods',
  '94O': 'TDS on Ecommerce transactions'
};

export const ReasonForLowerDeduction = [
  'A - Lower deduction/No deduciton on account of certificate under section 197',
  'C - Dedduction of tax at higher Rate due to non-availability of PAN(apllicable from FY 2010-11 onwards)',
  'E - No deduction is on account  of payment being made  to a person refferred to in Board circular No.3 of 2002 dated 28th june, 2002 or board circular no.11 of 2002 dated 22nd novermerb, 2002 or board circular no.18 of 2017 dated 28th May,2017',
  'T - Transporter transaction and If valid PAN is provided',
  'Y - Transaction where tax not beeb deducted as amount paid/credited to the vendor/party has not exceeded the thereshold limit',
  'Z - No deduction on account of payment under section 197A (1F) (Applicable from FY 2013-14 onwards'
];

export const ReasonForLowerDeduction_26Q = [
  'Lower Deduction/No Deduction on account of Certificate u/s 197',
  'No Deduction on account of Certificate u/s 197A',
  'Deduction of Tax at higher rate due to Non availability of PAN',
  'In case of Transporter transaction and valid PAN is provided',
  'Software acquired u/s 194J',
  'Transaction where Tax not been deducted as amount paid to vendor/party has not exceeded the thrashold limit',
  'No Deduction on account of payment u/s 197A (1F)',
  'Software acquired u/s 195',
  'No Deduction upto Rs. 50,000 in respect of interest income from deposits held by Senior Citizen u/s 80TTB for section 194A',
  'No deduction on account of payment made to person referred to in clause (iii) or clause (iv) or on account of notification issued under clause (v) for section 194N',
  'No deduction or lower deduction on account of payment made to a persion or class of person on account of notification issued under sub-section (5) for section 194A',
  'No deduction as per the provisions of sub-section (2A) of section 194LBA',
  'No deduction or lower deduction on account of notification issued under second provision to section 194N',
  'No deduction on account of payment being made to a person referred to in board circular no. 3 of 2002 dated 28th june, 2002',
  'No deduction on account of payment being made to a person referred to in board circular no. 11 of 2002 dated 22nd november, 2002',
  'No deduction on account of payment being made to a person referred to in board circular no. 18 of 2017 dated 28th may, 2017'
];

export const TDS_SECTION_26Q = [
  '193 - Interest on Securities',
  '194 - Dividend',
  '194A - Other interest',
  '194B - Lotteries / Puzzle / Game',
  '194B-P - Winnings from lotteries and crossword puzzles where consideration is made in kind or cash is not sufficient to meet the tax liability and tax has been paid before such winnings are released',
  '194BB - Winnings from Horse Race',
  '194C - Contracts',
  '194D - Insurance',
  '194EE - NSS Deposit',
  '194F - UTI',
  '194G - Commission on Sale of Lottery tickets',
  '194H - Commission or Brokerage',
  '194I(a) - Rent on Plant and Machinery',
  '194I(b) - Rent on Land & Building',
  '194LA - Acquisition of certain immovable properties',
  '194LBA - Certain incomes from Units of Business Trust',
  '194DA - Payment in respect of life insurance policy',
  '192A - Payment of accumulated balance of provident fund, taxable in the hands of the employee',
  '194LBB - Investment fund paying an income to a unit holder',
  '194IA - TDS on sale of property',
  '194LBC - Income in respect of investment in securitization trust',
  '194IC - Payment under specified agreement',
  '194N - Payment of certain amount in cash',
  '194K - Income from mutual fund units',
  '194J(a) - Fees for technical services, royalty for sale, distribution or exhibition of cinematographic films and call centre',
  '194J(b) - Fee for professional service or royalty',
  '194LBA(a) - Certain income in the form of interest from units of a business trust to a residential unit holder',
  '194LBA(b) - Certain income in the form of dividend from units of a business trust to a resident unit holder    ',
  '194NF - Payment of certain amounts in cash to non-filers',
  '194O - TDS on E-commerce transactions',
  '192 - Payment to Government Employees other than Union Government Employees',
  '192 - Payment to Employees other than Government Employees',
  '192 - Payments made to Union Government Employees',
  'Lower deduction on account of certificate under section 197',
  'No deduction on account of certificate under section 197',
  'Deduction of tax at higher rate due to non-availability of PAN',
  '194E - Payments to non-resident Sportsmen/Sport Associations',
  '195 - Other sums payable to Non-Residents',
  '196A - Income in respect of Units of Non-Residents',
  '196B - Payments in respect of Units to an Offshore Fund',
  '196C - Income from Foreign Currency Bonds or Shares of Indian Company Payable to Non-Resident',
  '196D - Income of Foreign institutional investors from securities',
  '194LB - Income by way of interest from Infrastructure Debt fund',
  '194LC - Income by way of interest from Indian company engaged in certain Business',
  '194LD - Income by way of interest on certain bonds and Government Securities',
  '194LBA(c) - Income referred to in section 10 (23FCA) from units of a business trust',
  '194Q - Payment of certain sums for purchase of goods',
  '194S - Payment of consideration for transfer of virtual digital asset by persons other than specified persons',
  '194SP - Payment for transfer of virtual digital asset where payment is in kind or in exchange of another virtual digital asset and tax required to be deducted is paid before such payment is released',
  '194R - Benefits or perquisites of business or profession',
  '194RP - Benefits or perquisites of business or profession where such benefit is provided in kind or where part in cash is not sufficient to meet tax liability and tax required to be deducted is paid before such benefit is released',
  '194P - Deduction of tax in case of specified senior citizens'
];

export const RETURN_TYPE = {
  Regular: 'regular',
  Revised: 'revised'
};

export const FORM_TYPE = {
  '24Q': '24q',
  '26Q': '26q',
  '27Q': '27q',
  '27EQ': '27eq'
};

export const NATURE_OF_DEFAULT = {
  'Short Payment': 'shortPayment',
  'Short Deduction': 'shortDuration',
  Interest: 'interest',
  'Late Fee': 'lateFee',
  Penalty: 'penalty',
  Others: 'others'
};

export const STATUS = {
  Open: 'open',
  'In Process': 'inProcess',
  Close: 'close'
};

export const MONTHS_MAP = {
  January: { val: '01', lastDay: '31', quarter: 'Q4' },
  February: { val: '02', lastDay: '28', quarter: 'Q4' },
  March: { val: '03', lastDay: '31', quarter: 'Q4' },
  April: { val: '04', lastDay: '30', quarter: 'Q1' },
  May: { val: '05', lastDay: '31', quarter: 'Q1' },
  June: { val: '06', lastDay: '30', quarter: 'Q1' },
  July: { val: '07', lastDay: '31', quarter: 'Q2' },
  August: { val: '08', lastDay: '31', quarter: 'Q2' },
  September: { val: '09', lastDay: '30', quarter: 'Q2' },
  October: { val: '10', lastDay: '31', quarter: 'Q3' },
  November: { val: '11', lastDay: '30', quarter: 'Q3' },
  December: { val: '12', lastDay: '31', quarter: 'Q3' }
};
