import mock from '~/utils/mock'; 

// Define mock data for the post
const Data =[
  { 
    "project_id": "PI003",
    "target_company_id": 1,
    "target_company": "HCL Ltd",
    "client_company_id": 2,
    "client_company": "ABC Ltd",
    "execution_partner_id": 3,
    "execution_partner": "Priyanka",
    "date_of_incorporation": "2024-04-08T06:59:39.584Z",
    "entity_type_id": 1,
    "entity_type": "LLP",
    "industry_id": 1,
    "industry": "Textile",
    "PAN": "QAosIxq4XC",
    "GST": "vN8XZykm4E",
    "CIN": "VqMHxoQD2o",
    "address": "23-6/1,Hyderabad",
    "type_of_DD": "Buy Sell DD",
    "sector_id": 1,
    "sector_name": "Marketing",
    "reviewTeam": {
           "engagement_partner_id": 4,
           "engagement_partner":"Maneesha",
           "review_partner_id": 5,
           "review_partner": "Surya",
           "manager_id": 6,
           "manager":"Rambabu",
           "associate_id": 7, 
           "associate":"Sowmya"
        },
    "period_from_date": "2024-04-08",
    "period_to_date": "2024-04-08",
    "no_of_fys": 2,
    "timeline_start_date": "2024-04-08",
    "timeline_end_date": "2024-04-08",
    "status": "In Progress",
    "firm_id": 1,
    "user_id": 1
  }
  ];

// Define the mock API endpoint for fetching all posts
mock.onGet('/api/projectget').reply((config)=>{
  // Find the index of the industry to update in the mock data
  const index = Data.findIndex(ele => ele.project_id == config.params.projectData);

  if (index !== -1) {
    return [200, Data[index]];
  } else {
    return [200, Data[0]];
  }
});

// Define the mock API endpoint for creating a post
// mock.onPost('/api/phasepost').reply(200, Data);

// Define the mock API endpoint for updating a post
mock.onPut('/api/projectupdate/:id').reply(200, { message: 'Post updated successfully' });

// Define the mock API endpoint for deleting a post
// mock.onDelete('/api/posts/:id/delete').reply(200, { message: 'Post deleted successfully' });

// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: Data }];
});*/

export const phase = () => {
  return Data;
}
