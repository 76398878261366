import React, { lazy } from 'react';
import { PATH_HOME } from './paths';
import { Redirect } from 'react-router-dom';
import HomeLayout from '~/layouts/HomeLayout';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: PATH_HOME.home,
      component: lazy(() => import('src/views/home/LandingPageView'))
    },
    {
      exact: true,
      path: PATH_HOME.aboutUs,
      component: lazy(() => import('src/views/home/LandingPageView/AboutUs'))
    },
    {
      exact: true,
      path: PATH_HOME.contactUs,
      component: lazy(() => import('src/views/home/LandingPageView/ContactUs'))
    },
    // Temporary Loansform
    {
      exact: true,
      path: PATH_HOME.Loans,
      component: lazy(() => import('src/views/home/LandingPageView/Loans'))
    },
    // Temporary ITRForm
    {
      exact: true,
      path: PATH_HOME.ITR,
      component: lazy(() => import('src/views/home/LandingPageView/ITR'))
    },
    // Application form
    {
      exact: true,
      path: PATH_HOME.application,
      component: lazy(() => import('src/views/finance-box/Application/index'))
    },

    // Representative Registration Form
    {
      exact: true,
      path: PATH_HOME.representativeRegistration,
      component: lazy(() =>
        import('src/views/home/LandingPageView/RepresentativeResistration')
      )
    },

    {
      exact: true,
      path: PATH_HOME.thankYou,
      component: lazy(() => import('src/views/home/LandingPageView/ThankYou'))
    },
    {
      exact: true,
      path: PATH_HOME.careers.root,
      component: lazy(() => import('src/views/home/LandingPageView/JoinUs'))
    },
    {
      exact: true,
      path: PATH_HOME.careers.post,
      component: lazy(() =>
        import('src/views/home/LandingPageView/JoinUs/JobDescriptionComponent')
      )
    },
    {
      exact: true,
      path: PATH_HOME.pitchDeck,
      component: lazy(() => import('src/views/home/LandingPageView/PitchDeck'))
    },
    {
      exact: true,
      path: PATH_HOME.privacyPolicy,
      component: lazy(() => import('~/views/finance-box/PrivacyPolicy'))
    },
    {
      exact: true,
      path: PATH_HOME.termsAndConditions,
      component: lazy(() => import('~/views/finance-box/TermsAndConditions'))
    },
    {
      exact: true,
      path: PATH_HOME.blog.root,
      component: lazy(() => import('~/views/finance-box/blog/BlogView/index'))
    },
    //Test
    {
      exact: true,
      path:
        PATH_HOME.BusinessIncorporations.CompanyIncorporations
          .PvtLtdCompanyIncorporation,
      component: lazy(() =>
        import(
          '~/views/finance-box/ServiceViews/BusinessServicesViews/BusinessIncorporationsViews/Company/PvtLtdCompanyIncorporationView'
        )
      )
    },

    {
      exact: true,
      path: PATH_HOME.blog.post,
      component: lazy(() =>
        import('~/views/finance-box/blog/PostDetailsView/index')
      )
    },
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
