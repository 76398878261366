import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import ProductItem from './ProductItem';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {}
}));

// ----------------------------------------------------------------------


ProductList.propTypes = {
  services: PropTypes.object.isRequired,
  className: PropTypes.string
};

function ProductList({ services, className, ...other }) {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      className={clsx(classes.root, className)}
      {...other}
    >
      {services.map(([key, value], idx) => (
        <Grid key={idx} item xs={4} sm={4} md={2} lg={2}>
          <ProductItem service={value} />
        </Grid>
      ))}
    </Grid>
  );
}

export default ProductList;
