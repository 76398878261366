import React from "react";
import Logo from "~/components/Logo";

const watermarkstyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    opacity: '0.6',
    fontSize: '16px',
    color: '#1890ff',
    transform: 'rotate(0deg)',
    zIndex: 100
}
const Watermark = () => {
    return (
        <>
            <Logo />
            <div className="watermark" style={watermarkstyle}>
                Powered by Finance box
            </div>
        </>
    );
};
export default Watermark