import clsx from 'clsx';
import Logo from '~/components/Logo';
import { Icon } from '@iconify/react';
import React, { useState, useRef } from 'react';
import useOffSetTop from '~/hooks/useOffSetTop';
import PopoverMenu from '~/components/PopoverMenu';
import homeFill from '@iconify-icons/eva/home-fill';
import { PATH_HOME, PATH_APP } from '~/routes/paths';
import roundSpeed from '@iconify-icons/ic/round-speed';
// import faceAgent from '@iconify-icons/ic/face-agent';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import bookOpenFill from '@iconify-icons/eva/book-open-fill';
import roundStreetview from '@iconify-icons/ic/round-streetview';
import {
  NavLink as RouterLink,
  useLocation,
  useHistory
} from 'react-router-dom';
import { makeStyles, alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Link,
  AppBar,
  Hidden,
  Toolbar,
  MenuItem,
  Container,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { MIconButton } from '~/@material-extend';
import { Link as ScrollLink } from 'react-scroll';
import Header from './Header';
import { RenderMenuMobileFunc } from './Header';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

const MENU_LINKS = [
  // { title: 'Home', icon: homeFill, href: '/' },
  // {
  //   title: 'Services',
  //   // icon: faceAgent,
  //   href: '/app/services'
  // },
  {
    title: 'About Us',
    // icon: roundSpeed,
    href: '/about-us'
  },
  {
    title: 'Contact Us',
    // icon: bookOpenFill,
    href: '/contact-us'
  }
  // {
  //   title: 'Careers',
  //   //  icon: bookOpenFill,
  //   href: '/careers'
  // },
  // {
  //   title: 'Blog',
  //   //  icon: bookOpenFill,
  //   href: '/blog'
  // }
];
const MOBILE_MENU_LINKS = [
  // { title: 'Home', icon: homeFill, href: '/' },
  // {
  //   title: 'Services',
  //   // icon: faceAgent,
  //   href: '/app/services'
  // },
  {
    title: 'About Us',
    // icon: roundSpeed,
    href: '/about-us'
  },
  {
    title: 'Contact Us',
    // icon: bookOpenFill,
    href: '/contact-us'
  }
  // {
  //   title: 'Careers',
  //   //  icon: bookOpenFill,
  //   href: '/careers'
  // },
  // {
  //   title: 'Blog',
  //   //  icon: bookOpenFill,
  //   href: '/blog'
  // }
];

const AUTH_LINKS = [
  // {
  //   title: 'Register',
  //   // icon: homeFill,
  //   href: PATH_HOME.register
  // },
  {
    title: 'Login',
    // icon: roundStreetview,
    href: PATH_HOME.login
  },
  {
    title: 'Sign Up',
    // icon: roundStreetview,
    href: PATH_HOME.Loans
  }
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    background: theme.palette.primary.main
  },
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(['height', 'background-color'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up('md')]: {
      height: APP_BAR_DESKTOP
    }
  },
  toolbarContainer: {
    lineHeight: 0,
    display: 'contents',
    alignItems: 'center'
    // justifyContent: 'space-between',
  },
  toolbarShadow: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    content: "''",
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.shadows[25].z8
  },
  desktopMenu: {
    '& > * ': {
      color: theme.palette.text.primary,
      marginRight: `${theme.spacing(3)} !important`
    },
    marginLeft: '5px',
    display: 'flex',
    flex: 'auto'
  },
  isDesktopActive: {
    // color: `${theme.palette.primary.main} !important`
    color: 'white'
  },
  mobileMenu: {
    color: theme.palette.text.secondary
  },
  isMobileActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    )
  },
  isHome: { color: theme.palette.common.white },
  onScroll: {
    '& $toolbar': {
      backgroundColor: theme.palette.primary.main
    },
    '& $isHome': { color: 'white' },
    [theme.breakpoints.up('md')]: {
      '& $toolbar': { height: APP_BAR_DESKTOP - 20 }
    }
  },
  logoName: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Montserrat, sans-serif !important',
    marginRight: 20,
    textDecoration: 'none',
    color: 'white'
  },
  navItem: {
    color: 'white'
  }
}));

// ----------------------------------------------------------------------

function HomeTopBar() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === '/';
  const history = useHistory();
  let userData = useSelector(e => e?.landingpagedetails?.res);
  const getRouteLink = ({ userRole }) => {
    switch (userRole) {
      case 0:
        return PATH_APP.app2.notifications;

      case 1:
        return PATH_APP.app2.notifications;

      case 2:
        return PATH_APP.app2.notifications;

      case 3:
        return PATH_APP.app2.services.root;

      case 4:
        return PATH_APP.app2.notifications;

      case 5:
        return PATH_APP.app2.notifications;

      default:
        break;
    }
  };

  const renderMenuDesktop = (
    <div className={classes.desktopMenu}>
      {/* <ScrollLink
        to='home'
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        activeClass="active"
      >
        <Link
          to=''
          exact
          color="initial"
          underline="none"
          variant="body1"
          component={RouterLink}
          activeClassName={classes.isDesktopActive}
          // className={clsx({
          //   [classes.isHome]: isHome
          // })}
          className={classes.navItem}
        >
          Home
        </Link>
      </ScrollLink> */}
      <Header isLandingPage={true} />

      {/* {MENU_LINKS.map(link => (
        <Link
          exact
          to={link.href}
          key={link.title}
          color="initial"
          underline="none"
          variant="body1"
          component={RouterLink}
          // activeClassName={classes.isDesktopActive}
          // className={clsx({
          //   [classes.isHome]: isHome
          // })}
          className={classes.navItem}
        >
          {link.title}
        </Link>
      ))} */}
      {/* <ScrollLink
        to='contact-us'
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
        activeClass="active"
      >
        <Link
          to=''
          exact
          color="initial"
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          activeClassName={classes.isDesktopActive}
          // className={clsx({
          //   [classes.isHome]: isHome
          // })}
          className={classes.navItem}
        >
          Contact Us
        </Link>
      </ScrollLink> */}
      <Box sx={{ flexGrow: 1 }} />
      {AUTH_LINKS.map(link => (
        <>
          {/* {console.log(
            localStorage.user_details !== undefined
              ? getRouteLink(JSON.parse(localStorage.getItem('user_details')))
              : link.href
          )} */}
          <Link
            exact
            to={
              localStorage.user_details !== undefined
                ? getRouteLink(JSON.parse(localStorage.getItem('user_details')))
                : link.href
            }
            style={{ alignSelf: 'center' }}
            key={link.title}
            color="initial"
            underline="none"
            variant="body1"
            component={RouterLink}
            activeClassName={classes.isDesktopActive}
            // className={clsx({
            //   [classes.isHome]: isHome
            // })}
            className={classes.navItem}
          >
            {link.title}
          </Link>
        </>
      ))}
      <RenderMenuMobileFunc />
    </div>
  );

  const renderMenuMobile = (
    <PopoverMenu
      width={220}
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <List>
        {MOBILE_MENU_LINKS.map(link => (
          <MenuItem
            exact
            to={link.href}
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            activeClassName={classes.isMobileActive}
            className={classes.mobileMenu}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText>{link.title}</ListItemText>
          </MenuItem>
        ))}
        {AUTH_LINKS.map(link => (
          <MenuItem
            exact
            to={
              localStorage.user_details !== undefined
                ? getRouteLink(JSON.parse(localStorage.getItem('user_details')))
                : link.href
            }
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            activeClassName={classes.isMobileActive}
            className={classes.mobileMenu}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText>{link.title}..</ListItemText>
          </MenuItem>
        ))}
      </List>
    </PopoverMenu>
  );
  return (
    <AppBar
      color="transparent"
      className={clsx(classes.root, { [classes.onScroll]: offset })}
    >
      <Toolbar
        disableGutters
        className={classes.toolbar}
        style={{
          display: 'flex',
          flexFlow: 'row',
          flexGrow: 1,
          flexDirection: 'row'
        }}
      >
        <RouterLink to="/">
          {userData?.url !== 'anthill.financebox.in' && (
            <Logo style={{ marginLeft: '10px' }} />
          )}
        </RouterLink>
        <RouterLink to="/" className={classes.logoName}>
          {/* <p>Financebox</p> */}
          <p
            style={{
              fontSize: '1.5rem',
              paddingLeft: userData?.url === 'anthill.financebox.in' ? 10 : 0
            }}
          >
            {userData?.admin_details?.firmName}
          </p>
        </RouterLink>
        <Container maxWidth="lg" className={classes.toolbarContainer}>
          <Hidden mdUp>
            <MIconButton
              ref={anchorRef}
              onClick={() => setOpenMenu(true)}
              className={clsx({
                [classes.isHome]: isHome
              })}
            >
              <Icon icon={menu2Fill} />
            </MIconButton>
            {renderMenuMobile}
          </Hidden>
          {/* <Box sx={{ flexGrow: 1 }} /> */}
          <Hidden mdDown>{renderMenuDesktop}</Hidden>
          {/* <Button
            underline="none"
            variant="contained"
            component={Link}
            href={PATH_HOME.purchase}
          >
            Purchase Now
          </Button> */}
        </Container>
      </Toolbar>
      {offset && <span className={classes.toolbarShadow} />}
    </AppBar>
  );
}

export default HomeTopBar;
