import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  selectedEmployees: [],
  error: null,
};

const slice = createSlice({
  name: 'salaryRevisionEmployees',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getSelectedEmployeesSuccess(state, action) {
      state.isLoading = false;
      state.selectedEmployees = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addSelectedEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.selectedEmployees.push(action.payload);
    },
    updateSelectedEmployeeSuccess(state, action) {
      state.isLoading = false;
      const updatedEmployee = action.payload;
      state.selectedEmployees = state.selectedEmployees.map(employee =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee
      );
    },
    deleteSelectedEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.selectedEmployees = state.selectedEmployees.filter(employee =>
        employee.id !== action.payload
      );
    },
  },
});

export const {
  startLoading,
  getSelectedEmployeesSuccess,
  hasError,
  addSelectedEmployeeSuccess,
  updateSelectedEmployeeSuccess,
  deleteSelectedEmployeeSuccess,
} = slice.actions;

// export const fetchSelectedEmployees = () => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Assume you have a function to fetch the selected employees from local storage or an API
//     const selectedEmployees = []; // Replace with actual data fetching logic
//     dispatch(getSelectedEmployeesSuccess(selectedEmployees));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

export const getAllSalaryRevision = (year,businessId)=>async(dispatch)=>{
  try{
    let url = `payroll/getEmployPayroll?type=SalaryRevision&financialYear=${year}&business_id=${businessId}`
    let {res} = await Factory("get",url,{});
    console.log(res,"Reeeeessss")
    return res;
  }catch(err){
    console.err(err)
  }
}

export const getEmployeeSalaryRevision = (id) => async (dispatch) => {
    dispatch(startLoading());
    try {
      // Assume you have a function to fetch the selected employees from local storage or an API
      let url  = `payroll/getSalaryRevision/${id}`;
      let {res} = await Factory("get",url,{});
      console.log(res,"Reeeeessss")

      return res;
      // const selectedEmployees = []; // Replace with actual data fetching logic
      // dispatch(getSelectedEmployeesSuccess(selectedEmployees));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };

export const addSelectedEmployee = (employee) => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Simulate adding employee logic, e.g., saving to local storage or an API call
    dispatch(addSelectedEmployeeSuccess(employee));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const updateSalaryAccount = (updatedSalarypayload,id) => {

  console.log("Ggggeggeggee")
  
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      //console.log(newSubarea)
      let url = `payroll/updateSalaryRevision/${id}`;
      let { res, variant, message } = await Factory("patch", url, updatedSalarypayload);
      console.log(res,"ssdfdsfrees");
      // dispatch(slice.actions.addAccountSuccess(newAccount));
      return variant;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }

};

export const updateSelectedEmployee = (employee) => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Simulate updating employee logic, e.g., saving to local storage or an API call
    dispatch(updateSelectedEmployeeSuccess(employee));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const deleteSelectedEmployee = (employeeId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    // Simulate deleting employee logic, e.g., removing from local storage or an API call
    dispatch(deleteSelectedEmployeeSuccess(employeeId));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export default slice.reducer;
