

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const slice = createSlice({
    name: 'landingpageData',
    initialState: {},
    reducers: {
        setLandingPageData: (state, action) => {
            state.res = action.payload.resObj;
            state.serviceConstants = action.payload.serviceConstants;
        },
        resetLandingPageData: () => initialState
        
    }
});

export default slice.reducer;

export const { setLandingPageData, resetLandingPageData } = slice.actions;
export function getlandingpageData(landingpage) {
    // console.log(landingpage)
    return async dispatch => {
        // dispatch(resetLandingPageData());
        dispatch(setLandingPageData(landingpage));
    };
}
