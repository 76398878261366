import { PATH_APP, PATH_PAGE } from '~/routes/paths';

export const PaymentStatus = {
  title: 'TDS Payment Status',
  icon: '/static2/ServiceIcons/ComplianceManager/paymentStatus.svg',
  href: PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus.root,
  description: '',
  heading: 'TDS Payment Status',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'TDS Services',
      href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
    },
    { name: 'TDS Payment Status' }
  ],
  children: {
    payementDetails: {
      title: 'TDS Challan Details',
      icon: '',
      href:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus
          .challanDetails,
      description: '',
      heading: 'TDS Challan Details',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'TDS Services',
          href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
        },
        {
          name: 'TDS Payment Status',
          href:
            PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus.root
        },
        {
          name: 'TDS Challan Details'
        }
      ],
      children: {
        deducteeDetails: {
          title: 'TDS Deductee Details',
          icon: '',
          href:
            PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus
              .challanDetails.deducteeDetails,
          description: '',
          heading: 'TDS Deductee Details',
          breadCrumbs: [
            {
              name: 'Compliance Manager',
              href: PATH_APP.app2.ComplianceManagerClient.root
            },
            {
              name: 'TDS Services',
              href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
            },
            {
              name: 'TDS Payment Status',
              href:
                PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus
                  .root
            },
            {
              name: 'TDS Challan Details',
              href:
                PATH_APP.app2.ComplianceManagerClient.tdsServices.paymentStatus
                  .challanDetails
            },
            {
              name: 'TDS Deductee Details'
            }
          ]
        }
      }
    }
  }
};

export const FilingDetails = {
  title: 'TDS Filing Details',
  icon: '/static2/ServiceIcons/ComplianceManager/filingStatus.svg',
  href: PATH_APP.app2.ComplianceManagerClient.tdsServices.fillingDetails.root,
  description: '',
  heading: 'TDS Filing Details',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'TDS Services',
      href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
    },
    { name: 'TDS Filing Details' }
  ],
  children: {
    returnStatus: {
      title: 'TDS Filing Return Status',
      icon: '',
      href:
        PATH_APP.app2.ComplianceManagerClient.tdsServices.fillingDetails
          .returnStatus,
      description: '',
      heading: 'TDS Filing Return Status',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'TDS Services',
          href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
        },
        {
          name: 'TDS Filing Details',
          href:
            PATH_APP.app2.ComplianceManagerClient.tdsServices.fillingDetails
              .root
        },
        {
          name: 'TDS Filing Return Status'
        }
      ]
    }
  }
};

export const Reconciliation = {
  title: 'TDS Reconciliation',
  icon: '/static2/ServiceIcons/ComplianceManager/reconcilation.svg',
  href: PATH_APP.app2.ComplianceManagerClient.tdsServices.reconciliation.root,
  description: '',
  heading: 'TDS Reconciliation',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'TDS Services',
      href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
    },
    { name: 'TDS Reconciliation' }
  ]
};

export const DefaultSummary = {
  title: 'TDS Default Summary',
  icon: '/static2/ServiceIcons/ComplianceManager/monthlySummary.svg',
  href: PATH_APP.app2.ComplianceManagerClient.tdsServices.defaultSummary.root,
  description: '',
  heading: 'TDS Default Summary',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'TDS Services',
      href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
    },
    { name: 'TDS Default Summary' }
  ]
};

export const DownloadForm16A = {
  title: 'TDS Download Form',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.tdsServices.downloadForm16.root,
  description: '',
  heading: 'TDS Download Form',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'TDS Services',
      href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
    },
    { name: 'TDS Download form' }
  ]
};

export const FilingStatus = {
  title: 'Filing Status',
  icon: '/static2/ServiceIcons/ComplianceManager/filingStatus.svg',
  href: PATH_APP.app2.ComplianceManagerClient.filingStatus,
  description: '',
  heading: 'Filing Status',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'GST Services',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices
    },
    { name: 'Filing Status' }
  ]
};

export const MonthlySummary = {
  title: 'Monthly Summary',
  icon: '/static2/ServiceIcons/ComplianceManager/monthlySummary.svg',
  href: PATH_APP.app2.ComplianceManagerClient.MonthlySummary,
  description: '',
  heading: 'Monthly Summary',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'GST Services',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices
    },
    { name: 'Monthly Summary' }
  ]
};

export const TurnoverReconciliation = {
  title: 'Turnover Reconciliation',
  icon: '/static2/ServiceIcons/ComplianceManager/turnover.svg',
  href: PATH_APP.app2.ComplianceManagerClient.turnoverReconciliation,
  description: '',
  heading: 'Turnover Reconciliation ...Coming Soon',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'GST Services',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices
    },
    {
      name: 'Detailed Reports',
      href: PATH_APP.app2.ComplianceManagerClient.detailedReportsCards
    },
    { name: 'Turnover Reconciliation' }
  ]
};

export const ItcReconciliation = {
  title: 'ITC Reconciliation',
  icon: '/static2/ServiceIcons/ComplianceManager/itc.svg',
  href: PATH_APP.app2.ComplianceManagerClient.itcReconciliation,
  description: '',
  heading: 'ITC Reconciliation ...Coming Soon',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'GST Services',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices
    },
    {
      name: 'Detailed Reports',
      href: PATH_APP.app2.ComplianceManagerClient.detailedReportsCards
    },
    { name: 'ITC Reconciliation' }
  ]
};

export const PurchaseReconciliation = {
  title: 'Purchase Reconciliation',
  icon: '/static2/ServiceIcons/ComplianceManager/purchase.svg',
  href: PATH_APP.app2.ComplianceManagerClient.purchaseReconciliation,
  description: '',
  heading: 'Purchase Reconciliation ...Coming Soon',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'GST Services',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices
    },
    {
      name: 'Detailed Reports',
      href: PATH_APP.app2.ComplianceManagerClient.detailedReportsCards
    },
    { name: 'Purchase Reconciliation' }
  ]
};

export const DetailedReports = {
  title: 'Detailed Reports',
  icon: '/static2/ServiceIcons/ComplianceManager/detailedReports.svg',
  href: PATH_APP.app2.ComplianceManagerClient.detailedReportsCards,
  description: '',
  heading: 'Detailed Reports',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'GST Services',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices
    },
    { name: 'Detailed Reports' }
  ],
  children: {
    TurnoverReconciliation: TurnoverReconciliation,
    ItcReconciliation: ItcReconciliation,
    PurchaseReconciliation: PurchaseReconciliation
  }
};

export const QuarterlyReporting = {
  title: 'Quarterly Reporting',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.quarterly,
  description: '',
  heading: 'Quarterly Reporting',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'SEBI Services',
      href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.root
    },
    { name: 'Quarterly Reporting' }
  ]
};

export const HalfYearlyReporting = {
  title: 'Half Yearly Reporting',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.halfYearly,
  description: '',
  heading: 'Half Yearly Reporting',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'SEBI Services',
      href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.root
    },
    { name: 'Half Yearly Reporting' }
  ]
};

export const AnnualReporting = {
  title: 'Annual Reporting',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.annual,
  description: '',
  heading: 'Annual Reporting',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'SEBI Services',
      href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.root
    },
    { name: 'Annual Reporting' }
  ]
};

export const EventBasedReporting = {
  title: 'Event Based Reporting',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.eventBased,
  description: '',
  heading: 'Event Based Reporting',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'SEBI Services',
      href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.root
    },
    { name: 'Event Based Reporting' }
  ]
};

export const ESIFiling = {
  title: 'Employee State Insurance',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.ESI,
  description: '',
  heading: 'Employee State Insurance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Filing Status',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.root
    },
    { name: 'Employee State Insurance' }
  ]
};

export const PFFiling = {
  title: 'Provident Fund',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.PF,
  description: '',
  heading: 'Provident Fund',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Filing Status',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.root
    },
    { name: 'Provident Fund' }
  ]
};

export const PTFiling = {
  title: 'Professional Tax',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.PT,
  description: '',
  heading: 'Professional Tax',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Filing Status',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.root
    },
    { name: 'Professional Tax' }
  ]
};
export const PFDifferenceSheetReconciliation = {
  title: 'PF Difference Sheet',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  description: '',
  heading: 'DifferenceSheet',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Reconciliation',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.root
    },
    {
      name: 'Provident Fund',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.PF
    },

    { name: 'PF Difference Sheet' }
  ]
};
export const ESIDifferenceSheetReconciliation = {
  title: 'DifferenceSheet',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  description: '',
  heading: 'ESI Difference Sheet',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Reconciliation',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.root
    },
    {
      name: 'Employee State Insurance',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.ESI
    },

    { name: 'ESI Difference Sheet' }
  ]
};
export const ESIReconciliation = {
  title: 'Employee State Insurance',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.ESI,
  description: '',
  heading: 'Employee State Insurance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Reconciliation',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.root
    },
    { name: 'Employee State Insurance' }
  ],
  children: {
    ESIDifferenceSheetReconciliation: ESIDifferenceSheetReconciliation
  }
};

export const PFReconciliation = {
  title: 'Provident Fund',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.PF,
  description: '',
  heading: 'Provident Fund',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Reconciliation',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.root
    },
    { name: 'Provident Fund' }
  ],
  children: {
    PFDifferenceSheetReconciliation: PFDifferenceSheetReconciliation
  }
};

export const PTReconciliation = {
  title: 'Professional Tax',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.PF,
  description: '',
  heading: 'Professional Tax',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    {
      name: 'Reconciliation',
      href:
        PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.root
    },
    { name: 'Professional Tax' }
  ]
};

export const StatutoryFilingStatus = {
  title: 'Statutory Filing Status',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.filingStatus.root,
  description: '',
  heading: 'Statutory Filing Status',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    { name: 'Statutory Filing Status' }
  ],
  children: {
    ESIFiling: ESIFiling,
    PFFiling: PFFiling,
    PTFiling: PTFiling
  }
};

export const Notices = {
  title: 'Notices',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.notices,
  description: '',
  heading: 'Notices',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    { name: 'Notices' }
  ]
};

export const PayrollReconciliation = {
  title: 'Payroll Reconciliation',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.PayrollServices.reconcilation.root,
  description: '',
  heading: 'Payroll Reconciliation',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    { name: 'Payroll Reconciliation' }
  ],
  children: {
    ESIReconciliation: ESIReconciliation,
    PFReconciliation: PFReconciliation,
    PTReconciliation: PTReconciliation
    // DifferenceSheetReconciliation: DifferenceSheetReconciliation
  }
};

export const rocFiling = {
  title: 'Filing Compliance',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ROCServices.filing,
  description: '',
  heading: 'Filing Compliance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    { name: 'Filing Compliance' }
  ]
};

export const rocNonFiling = {
  title: 'Non Filing Compliance',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ROCServices.nonFiling,
  description: '',
  heading: 'Non Filing Compliance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    { name: 'Non Filing Compliance' }
  ]
};

export const declaration = {
  title: 'Declaration',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ROCServices.declaration,
  description: '',
  heading: 'Declaration Compliance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    { name: 'Declaration Compliance' }
  ]
};

export const directors = {
  title: 'Directors',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.directors,
  description: '',
  heading: 'Directors',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    {
      name: 'Registers',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root
    },
    { name: 'Directors' }
  ]
};

export const KMP = {
  title: 'KMP',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.kmp,
  description: '',
  heading: 'KMP',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    {
      name: 'Registers',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root
    },
    { name: 'KMP' }
  ]
};

export const relatedParties = {
  title: 'Related Parties Compliance',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.relatedParties,
  description: '',
  heading: 'Related Parties Compliance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    {
      name: 'Registers',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root
    },
    { name: 'Related Parties Compliance' }
  ]
};

export const shareHolders = {
  title: 'Share Holders Compliance',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.shareHolders,
  description: '',
  heading: 'Share Holders Compliance',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    {
      name: 'Registers',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root
    },
    { name: 'Share Holders Compliance' }
  ]
};

export const liByCompanies = {
  title: 'Loans/Investements By Companies',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.liByCompanies,
  description: '',
  heading: 'Loans/Investements By Companies',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    {
      name: 'Registers',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root
    },
    { name: 'Loans/Investements By Companies' }
  ]
};

export const registers = {
  title: 'Registers',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ROCServices.registers.root,
  description: '',
  heading: 'Registers',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'ROC Services',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root
    },
    { name: 'Registers' }
  ],
  children: {
    directors: directors,
    KMP: KMP,
    relatedParties: relatedParties,
    shareHolders: shareHolders,
    liByCompanies: liByCompanies
  }
};

export const Register = {
  title: 'Register',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.register,
  description: '',
  heading: 'Register',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'Payroll Services',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root
    },
    { name: 'Register' }
  ]
};

export const AdvanceTaxPaid = {
  title: 'Advance Tax Paid',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes
      .advanceTaxPaid,
  description: '',
  heading: 'Advance Tax Paid',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Details Of Taxes',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes.root
    },
    { name: 'Advance Tax Paid' }
  ]
};

export const OtherTaxPaid = {
  title: 'Other Tax Paid',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes
      .otherTaxesPaid,
  description: '',
  heading: 'Other Tax Paid',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Details Of Taxes',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes.root
    },
    { name: 'Other Tax Paid' }
  ]
};

export const TDSTCSCredit = {
  title: 'TDS and TCS Credit',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes
      .tdstcsCredit,
  description: '',
  heading: 'TDS TCS Credit',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Details Of Taxes',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes.root
    },
    { name: 'TDS TCS Credit' }
  ]
};

export const DetailsOfTaxes = {
  title: 'Details Of Taxes',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.detailsOfTaxes.root,
  description: '',
  heading: 'Details Of Taxes',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    { name: 'Details Of Taxes' }
  ],
  children: {
    AdvanceTaxPaid: AdvanceTaxPaid,
    OtherTaxPaid: OtherTaxPaid,
    TDSTCSCredit: TDSTCSCredit
  }
};
export const ITFilingStatus = {
  title: 'Filing Status',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.filingStatus,
  description: '',
  heading: 'Filing Status',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    { name: 'Filing Status' }
  ]
};

export const Intimations = {
  title: 'Intimations',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.intimations,
  description: '',
  heading: 'Intimation',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Notices',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root
    },
    {
      name: 'Intimations',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.intimations
    }
  ]
};

export const Assessment = {
  title: 'Assessment Or Re-Assessment',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.assessment,
  description: '',
  heading: 'Assessment Or Re-Assessment',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Notices',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root
    },
    {
      name: 'Assessment Or Re-Assessment',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.assessment
    }
  ]
};

export const Scrutiny = {
  title: 'Scrutiny',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.scrutiny,
  description: '',
  heading: 'Scrutiny',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Notices',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root
    },
    {
      name: 'Scrutiny',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.scrutiny
    }
  ]
};

export const ReturnFiling = {
  title: 'Return Filing',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.returnFiling,
  description: '',
  heading: 'Return Filing',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Notices',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root
    },
    {
      name: 'Return Filing',
      href:
        PATH_APP.app2.ComplianceManagerClient.ITServices.notices.returnFiling
    }
  ]
};

export const AdjustingRefund = {
  title: 'Adjusting Refund',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href:
    PATH_APP.app2.ComplianceManagerClient.ITServices.notices.adjustingRefund,
  description: '',
  heading: 'Adjusting Refund',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    {
      name: 'Notices',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root
    },
    {
      name: 'Adjusting Refund',
      href:
        PATH_APP.app2.ComplianceManagerClient.ITServices.notices.adjustingRefund
    }
  ]
};

export const ITNotices = {
  title: 'Notices',
  icon: '/static2/ServiceIcons/ComplianceManager/downloadForm.svg',
  href: PATH_APP.app2.ComplianceManagerClient.ITServices.notices.root,
  description: '',
  heading: 'Register',
  breadCrumbs: [
    {
      name: 'Compliance Manager',
      href: PATH_APP.app2.ComplianceManagerClient.root
    },
    {
      name: 'IT Services',
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root
    },
    { name: 'Notices' }
  ],
  children: {
    Intimations: Intimations,
    Scrutiny: Scrutiny,
    Assessment: Assessment,
    AdjustingRefund: AdjustingRefund,
    ReturnFiling: ReturnFiling
  }
};

export const ComplianceManagerConstants = {
  heading: 'Compliance Manager',
  breadCrumbs: [
    { name: 'Compliance Manager', href: PATH_APP.app2.MISReports.root }
  ],
  children: {
    GSTServices: {
      title: 'GST',
      href: PATH_APP.app2.ComplianceManagerClient.gstServices,
      icon: '/static2/ServiceIcons/GSTServices/Company.svg',
      description: 'This is GST Services',
      heading: 'GST Services',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'GST Services',
          href: PATH_APP.app2.ComplianceManagerClient.gstServices
        }
      ],
      children: {
        FilingStatus: FilingStatus,
        MonthlySummary: MonthlySummary,
        DetailedReports: DetailedReports
      }
    },
    //tds Services

    TDSServices: {
      title: 'TDS',
      href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root,
      icon: '/static2/ServiceIcons/TDSServices/TCS.svg',
      description: 'This is TDS Services',
      heading: 'TDS Services',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'TDS Services',
          href: PATH_APP.app2.ComplianceManagerClient.tdsServices.root
        }
      ],
      children: {
        PaymentStatus: PaymentStatus,
        FilingDetails: FilingDetails,
        Reconciliation: Reconciliation,
        DownloadForm16A: DownloadForm16A,
        DefaultSummary: DefaultSummary
      }
    },
    FEMA: {
      title: 'FEMA',
      href: PATH_APP.app2.ComplianceManagerClient.FEMAServices.fema,
      // href: PATH_PAGE.comingSoon,
      icon: '/static2/ServiceIcons/TDSServices/Non-Resident.svg',
      description: 'This is FEMA',
      heading: 'FEMA   ...Coming Soon',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'FEMA',
          href: ''
        }
      ],
      children: {}
    },

    payroll: {
      title: 'Payroll',
      href: PATH_APP.app2.ComplianceManagerClient.PayrollServices.root,
      // href: PATH_PAGE.comingSoon,
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      description: 'This is Payroll',
      heading: 'Payroll   ...Coming Soon',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'Payroll',
          href: ''
        }
      ],
      children: {
        StatutoryFilingStatus: StatutoryFilingStatus,
        Notices: Notices,
        PayrollReconciliation: PayrollReconciliation,
        Register: Register
      }
    },
    ROC: {
      title: 'ROC',
      href: PATH_APP.app2.ComplianceManagerClient.ROCServices.root,
      // href: PATH_PAGE.comingSoon,
      icon: '/static2/ServiceIcons/ROCServices/AOC-4.svg',
      description: 'ROC',
      heading: 'ROC   ...Coming Soon',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'ROC',
          href: ''
        }
      ],
      children: {
        rocFiling: rocFiling,
        rocNonFiling: rocNonFiling,
        registers: registers,
        declaration: declaration
      }
    },
    SEBI: {
      title: 'SEBI',
      // href: PATH_PAGE.comingSoon,
      href: PATH_APP.app2.ComplianceManagerClient.SEBIServices.root,
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Pan Card.svg',
      description: 'This is SEBI',
      heading: 'SEBI   ...Coming Soon',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'SEBI',
          href: ''
        }
      ],
      children: {
        QuarterlyReporting: QuarterlyReporting,
        HalfYearlyReporting: HalfYearlyReporting,
        AnnualReporting: AnnualReporting,
        EventBasedReporting: EventBasedReporting
      }
    },
    incomeTax: {
      title: 'Income Tax',
      // href: PATH_PAGE.comingSoon,
      href: PATH_APP.app2.ComplianceManagerClient.ITServices.root,
      icon: '/static2/ServiceIcons/individual/income tax/Salary+hp+Cg.svg',
      description: 'This is Income Tax',
      heading: 'Income Tax   ...Coming Soon',
      breadCrumbs: [
        {
          name: 'Compliance Manager',
          href: PATH_APP.app2.ComplianceManagerClient.root
        },
        {
          name: 'Income Tax',
          href: ''
        }
      ],
      children: {
        DetailsOfTaxes: DetailsOfTaxes,
        ITFilingStatus: ITFilingStatus,
        ITNotices: ITNotices
      }
    }
  }
};
