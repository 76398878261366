import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import Factory from '~/utils/factory';
const initialState = {
  master: [],
  isLoading: false,
  error: null
};

const masterSlice = createSlice({
  name: 'MasterData',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getmasterSuccess(state, action) {
      state.isLoading = false;
      state.master = action.payload;
    },

   


   
    fetchanalysisFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = masterSlice.actions;

export const getmaster = () => async (dispatch) => {
  dispatch(masterSlice.actions.startLoading());
  try {
    let url = "master_data/getmasterdatacount";
    let { res } = await Factory("get", url, {}); // Destructure only 'res' from the response
    // console.log(res);
    dispatch(masterSlice.actions.getmasterSuccess(res)); // Dispatch response data
  } catch (error) {
    console.log(error.message, "Error");
    dispatch(masterSlice.actions.hasError(error.message)); // Dispatch error message
  }
};

  
 
export default masterSlice.reducer;
