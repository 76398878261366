import mock from '~/utils/mock';
import {  reject } from 'lodash';

let templates = [
    {
      id : 1,
        "analysis_name": "template1",
        "template_analysis":[
              {
                "type_of_analysis": "analysis1"
              },
              {
                "type_of_analysis": "analysis2"
              },
              {
                "type_of_analysis": "analysis3"
              }
          ]
      },
      {
        id:2,
        "analysis_name": "template2",
        "template_analysis":[
              {
                "type_of_analysis": "analysis2"
              },
              {
                "type_of_analysis": "analysis3"
              },
              {
                "type_of_analysis": "analysis4"
              }
          ]
      },
]

mock.onGet("/api/templates/get").reply(200,templates);

mock.onPost('/api/templates/post').reply((config) => {
  // Parse the request data if needed
  const requestData = JSON.parse(config.data);
  
  // Add the new templates to the mock data
  templates.push(requestData);

  // Return a 200 response with updated mock data
  return [200, templates];
});

mock.onPut('/api/templates/update').reply((config) => {
  const requestData = JSON.parse(config.data);
  const index = templates.findIndex(template => template.id === requestData.id);

  if (index !== -1) {
    templates[index] = requestData;
    return [200, templates];
  } else {
    return [404];
  }
});

mock.onPost('/api/templates/delete').reply(request => {
  try {
    const { templateId } = JSON.parse(request.data);
    templates =templates.filter(template => template.id !== templateId);
    return [200,  templates ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
