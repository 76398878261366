import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import { BASE_URL } from '~/constants';
import Factory from '~/utils/factory';
import { useEffect } from 'react';

const initialState = {
  phase: [],
  isLoading: false,
  error: null
};

const phasesSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getPhasesSuccess(state, action) {
      state.isLoading = false;
      state.phase = action.payload;
    },

    // POST PHASE
    createPhasesSuccess(state, action) {
      state.isLoading = false;
      state.phase.push(action.payload);
      console.log('action',action.payload);
    },


    fetchPhasesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //UPDATE PHASE
    updatePhasesSuccess(state, action) {
      state.isLoading = false;
      const updatedPhase = action.payload;
     // console.log(updatedPhase)
      state.phase = state.phase.map(EditPhase => {
       return EditPhase.id === updatedPhase.id ? updatedPhase : EditPhase;
      });
      //console.log(state.phase)
    },
  }
});

export const { onToggleFollow } = phasesSlice.actions;

export const getPhases = () => async dispatch => {
   /*dispatch(phasesSlice.actions.startLoading());
    console.log("True")
    try {
      console.log("TryIn")
      const response = await axios.get('/api/phaseget');
   {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        }
      );
     // dispatch({ type: getPhasesSuccess, payload: response.data });
     // http://dev-new.financebox.in:8000/user_management/customisation_url?url=paadarathi.financebox.in
      console.log("GetPhases")
      if (Array.isArray(response.data)) {
        dispatch(phasesSlice.actions.getPhasesSuccess(response.data));
        //console.log(response.data)
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log(error.message,"Error")
      dispatch(phasesSlice.actions.hasError(error.message));
    }*/
    
      //	Overall Compliances
      dispatch(phasesSlice.actions.startLoading());
    try {
      let url = "master_data/getphases";
      let { res, variant, message } = await Factory("get", url, {});
      //console.log(res)
      if (Array.isArray(res)) {
        dispatch(phasesSlice.actions.getPhasesSuccess(res));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log(error.message,"Error")
      dispatch(phasesSlice.actions.hasError(error.message));
    }
       
  };



  export function createPhase(newPhase) {
    return async dispatch => {
      /*dispatch(phasesSlice.actions.startLoading());
      try {
       // console.log(newPhase);
        const response = await axios.post('/api/phasepost', newPhase);
        dispatch(phasesSlice.actions.createPhasesSuccess(newPhase));
      } catch (error) {
        dispatch(phasesSlice.actions.hasError(error));
      }*/
      dispatch(phasesSlice.actions.startLoading());
    try {
      console.log(newPhase)
      let url = "master_data/addphases";
      let { res, variant, message } = await Factory("post", url, newPhase);
      console.log(res)
      if (Array.isArray(res)) {
        dispatch(phasesSlice.actions.createPhasesSuccess(newPhase));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log(error.message,"Error")
      dispatch(phasesSlice.actions.hasError(error.message));
    }
    };
  }

  export function updatePhase(newPhase) {
    return async dispatch => {
      dispatch(phasesSlice.actions.startLoading());
      try {
       // console.log(newPhase);

        const response = await axios.put('master_data/updatephases/:id', newPhase);
        dispatch(phasesSlice.actions.updatePhasesSuccess(newPhase));
      } catch (error) {
        dispatch(phasesSlice.actions.hasError(error));
      }
    dispatch(phasesSlice.actions.startLoading());
    try {
      console.log(newPhase)
      let url =  `master_data/updatephases/${newPhase.id}`;
      console.log("New Phase ID:", newPhase.id);

      let { res, variant, message } = await Factory("put", url, newPhase);
      console.log(res)
      if (Array.isArray(res)) {
        dispatch(phasesSlice.actions.updatePhasesSuccess(newPhase));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log(error.message,"Error")
      dispatch(phasesSlice.actions.hasError(error.message));
    }
    };
  }


export default phasesSlice.reducer;
