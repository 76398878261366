import React, { Suspense } from 'react';
import { Route, useParams } from 'react-router-dom';
import componentMapping from './DocumentComponentsMapping';

const DocumentDraftingRoute = () => {
  const { docName } = useParams();
  const ComponentToRender = componentMapping[docName] || null;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {ComponentToRender ? <ComponentToRender /> : <div>Component not found</div>}
    </Suspense>
  );
};

export default DocumentDraftingRoute;