import { createSlice } from '@reduxjs/toolkit';


const initialState = {};

const slice = createSlice({
  name: 'privilegesDetails',
  initialState: {},
  reducers: {
    getPrivilegesData: (state = initialState, action) => {
      state.repPrivileges = action.payload.privilegesObj;
    }
  }
});

// Reducer
export default slice.reducer;


export function getPrivileges(privileges) { 
  return async dispatch => {
    dispatch(slice.actions.getPrivilegesData(privileges));
  };
}
 