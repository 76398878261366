import mock from '~/utils/mock';
import {  reject } from 'lodash';

let sectors = [
    {
        id : 1,
        sector_name :"finance",
    },
    {
        id:2,
        sector_name :"software"
    },
    {
        id:3,
        sector_name :"mechnical"
    },
    {
        id:4,
        sector_name :"electrical"
    },
]

mock.onGet("/api/sectors/get").reply(200,sectors);

mock.onPost('/api/sectors/post').reply((config) => {
  // Parse the request data if needed
  const requestData = JSON.parse(config.data);
  
  // Add the new sector to the mock data
  sectors.push(requestData);

  // Return a 200 response with updated mock data
  return [200, sectors];
});

mock.onPut('/api/sectors/update').reply((config) => {
  const requestData = JSON.parse(config.data);
  const index = sectors.findIndex(sector => sector.id === requestData.id);

  if (index !== -1) {
    sectors[index] = requestData;
    return [200, sectors];
  } else {
    return [404];
  }
});

mock.onPost('/api/sectors/delete').reply(request => {
  try {
    const { sectorId } = JSON.parse(request.data);
    sectors =sectors.filter(sector => sector.id !== sectorId);
    return [200,  sectors ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
