import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  sectors: [],
};

const slice = createSlice({
  name: 'sector',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getSectorsSuccess(state, action) {
      state.isLoading = false;
      state.sectors = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addSectorsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addSectorsSuccess(state, action) {
      state.isLoading = false;
      state.sectors.push(action.payload)
    },
    addSectorsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateSectorsSuccess(state, action) {
      state.isLoading = false;
      const updatedSector = action.payload;
      state.sectors = state.sectors.map(sector => {
        return sector.id === updatedSector.id ? updatedSector : sector;
      });
    },
    
    // DELETE EVENT
    deleteSectorsSuccess(state, action) {
      state.sectors = action.payload;
    },
  },
});

export const { startLoading, getSectorsSuccess, hasError } = slice.actions;

export const getAllSectors = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get("/api/sectors/get")
    if(Array.isArray(response.data)){
      dispatch(slice.actions.getSectorsSuccess(response.data))
    }else{
      throw new Error("Invalid response form")
    }
  } catch (error) {
    console.log(error,"fetching error")
    dispatch(slice.actions.hasError(error))
  }

};

export const addSector = (sectorData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/sectors/post', sectorData);
      dispatch(slice.actions.addSectorsSuccess(sectorData))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

};

export const updateSector = (sectorData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/sectors/update`, sectorData);
      dispatch(slice.actions.updateSectorsSuccess(sectorData))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const deleteSector = (sectorData) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let sectorId = sectorData.id
    const response = await axios.post(`/api/sectors/delete`,{sectorId});
    dispatch(slice.actions.deleteSectorsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default slice.reducer;
