import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import Factory from '~/utils/factory';

const initialState = {
    step: [],
    isLoading: false,
    error: null,
};

const stepSlice = createSlice({
    name: 'Steps',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
          state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
      // GET STEPS
      getStepSuccess(state, action) {
        state.isLoading = false;
        state.step = action.payload;
      },
  
      // POST STEPS
      createStepSuccess(state, action) {
        state.isLoading = false;
        state.step.push(action.payload);
      },

      //UPDATE STEPS
      updateStepSuccess(state, action) {
        state.isLoading = false;
        const updatedStep = action.payload;
        const index = state.step.findIndex(step => step.step_id === updatedStep.step_id);
        if (index !== -1) {
          state.step[index] = {
            ...updatedStep,
            phases: updatedStep.phases.map(phase => ({
              ...phase,
              subareas: phase.subareas.map(subarea => ({
                ...subarea,
                accounts: subarea.accounts.map(account => ({
                  ...account,
                  steps: account.steps.map(step => ({
                    ...step,
                  }))
                }))
              }))
            }))
          };
        } 
      }
      
    }
});

export const { onToggleFollow } = stepSlice.actions;


export const getSteps = () => async dispatch => {
  
  dispatch(stepSlice.actions.startLoading());
  try {
    let url = "master_data/step";
    let { res, variant, message } = await Factory("get", url, {});
   // console.log(res)
    if (Array.isArray(res)) {
     dispatch(stepSlice.actions.getStepSuccess(res))
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    dispatch(stepSlice.actions.hasError(error.message));
  }

};

export function createStep(newStep) {
  return async dispatch => {
   
    dispatch(stepSlice.actions.startLoading());
    try {
     //console.log(newStep);
     let url = "master_data/step";
     let { res, variant, message } = await Factory("post", url, newStep);
     //console.log(res)
      dispatch(stepSlice.actions.createStepSuccess(res));
    } catch (error) {
      dispatch(stepSlice.actions.hasError(error));
    }
  };
}

export function updateStep(updatedStep,stepId) {
  return async dispatch => {
    dispatch(stepSlice.actions.startLoading());
    try {
      //console.log("StepID",stepId);
      let url = `master_data/step/${stepId}`;
      console.log("Slice",updatedStep)
      let { res, variant, message } = await Factory("put", url, updatedStep);
      dispatch(stepSlice.actions.updateStepSuccess(res));
    } catch (error) {
      dispatch(stepSlice.actions.hasError(error));
    }
  };
}



export default stepSlice.reducer;