import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';
import { BASE_URL } from '~/constants';

const initialState = {
  isLoading: false,
  dic: [],
};

const slice = createSlice({
  name: 'dic',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getDICSuccess(state, action) {
      state.isLoading = false;
      state.dic = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    // addAreasStart(state) {
    //   state.isLoading = true;
    //   state.error = null;
    // },
    // addAreasSuccess(state, action) {
    //   state.isLoading = false;
    //   state.areas.push(action.payload)
    // },
    // addAreasFailure(state, action) {
    //   state.isLoading = false;
    //   state.error = action.payload;
    // },
    // updateAreasSuccess(state, action) {
    //   state.isLoading = false;
    //   const updatedArea = action.payload;
    //   state.areas = state.areas.map(area => {
    //     return area.area_id === updatedArea.area_id ? updatedArea : area;
    //   });
    // },
    
    // DELETE EVENT
    // deleteAreasSuccess(state, action) {
    //   state.areas = action.payload;
    // },
  },
});

export const { startLoading, getDICSuccess, hasError } = slice.actions;

export const getAllDic = (data) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  console.log("ITs coming")
  try {
    let url = `due_diligence/dic/${data.project_id}/${data.taskAssigmentId}`;
 
    const { res,variant } = await Factory('get', url, {});
    console.log(res, "getresresresDic")
  
    if (res && variant === "success") {
      dispatch(slice.actions.getDICSuccess(res));
      return { success: true }; // Return success status
    } else {
      console.log(res,"serresserooorrds")
      console.error("Error: Unable to get DIC");
      return { success: false, error: res.data.error }; // Return error message
    }
  } catch (error) {
    console.log(error, "fetching error")
    dispatch(slice.actions.hasError(error))
  }
};


// export const addArea = (areaData) => {
//   return async dispatch => {
//     dispatch(slice.actions.startLoading());
//     try {
//       let url = `master_data/areas`
//       const {res} = await Factory('post',url,areaData);
//       console.log(res,"res")
//       dispatch(slice.actions.addAreasSuccess(res))
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };

// };

// export const updateArea = (areaData,areaId) => {
//   return async dispatch => {
//     dispatch(slice.actions.startLoading());
//     try {
//       let url = `master_data/areas/${areaId}`
//       await Factory('put',url,areaData);
//       // const {res} = await Factory('get',url,{});
//        // const response = await axios.put(`/api/areas/update`, areaData);
//       dispatch(slice.actions.updateAreasSuccess({...areaData,area_id:areaId}))
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// };

// export const deleteArea = (areaId) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     const response = await axios.post(`/api/areas/delete`,{areaId});
//     dispatch(slice.actions.deleteAreasSuccess(response.data));
//   } catch (error) {
//     dispatch(hasError(error));
//   }
// };

export default slice.reducer;