import mock from '~/utils/mock';
import {  reject } from 'lodash';

let industries = [
    {
        id : 1,
        type_name :"Tata",
    },
    {
        id:2,
        type_name :"Tvs"
    },
    {
        id:3,
        type_name :"Infosys"
    },
    {
        id:4,
        type_name :"IBM"
    },
]

mock.onGet("/api/industries/get").reply(200,industries);

mock.onPost('/api/industries/post').reply((config) => {
  // Parse the request data if needed
  const requestData = JSON.parse(config.data);
  
  // Add the new industry to the mock data
  industries.push(requestData);

  // Return a 200 response with updated mock data
  return [200, industries];
});

// Mock PUT request to update an industry
mock.onPut('/api/industries/update').reply((config) => {
  // Parse the request data if needed
  const requestData = JSON.parse(config.data);

  // Find the index of the industry to update in the mock data
  const index = industries.findIndex(industry => industry.id === requestData.id);

  if (index !== -1) {
    // Update the industry in the mock data
    industries[index] = requestData;

    // Return a 200 response with updated mock data
    return [200, industries];
  } else {
    // Return a 404 response if industry not found
    return [404];
  }
});

mock.onPost('/api/industries/delete').reply(request => {
  try {
    const { industryId } = JSON.parse(request.data);
    industries = reject(industries, { id: industryId });
    console.log(industries,"responsesss")
    return [200,  industries ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
