import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  templates: [],
};

const slice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    gettemplatesSuccess(state, action) {
      state.isLoading = false;
      state.templates = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addtemplatesStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addtemplatesSuccess(state, action) {
      state.isLoading = false;
      state.templates.push(action.payload)
    },
    addtemplatesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updatetemplatesSuccess(state, action) {
      state.isLoading = false;
      const updatedtemplate = action.payload;
      state.templates = state.templates.map(template => {
        return template.id === updatedtemplate.id ? updatedtemplate : template;
      });
      // state.areas = state.areas.map(area => {
      //   return area.area_id === updatedArea.area_id ? updatedArea : area;
      // });
    },
    
    // DELETE EVENT
    deletetemplatesSuccess(state, action) {
      state.templates = action.payload;
    },
  },
});

export const { startLoading, gettemplatesSuccess, hasError } = slice.actions;

export const getAllTemplates = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    let url = `master_data/gettemplates`
    const {res} = await Factory('get',url,{});
    // const response = await axios.get("/api/templates/get");
    if(Array.isArray(res)){
      dispatch(slice.actions.gettemplatesSuccess(res));
    }else{
      throw new Error("Invalid response form")
    }
  } catch (error) {
    console.log(error,"fetching error")
    dispatch(slice.actions.hasError(error))
  }

};

export const addtemplate = (templateData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `master_data/templates/create_with_templateanalyses`
      const {res,variant} = await Factory('post',url,templateData);
      if (res && variant === "success") {
        dispatch(slice.actions.addtemplatesSuccess(res))
        return { success: true }; // Return success status
      } else {
        console.error("Error: Unable to add template");
        return { success: false, error: "Unable to add template" }; // Return error message
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

};

export const updatetemplate = (templateData,templateId) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `master_data/updatetemplate/${templateId}`
      const { res, variant } = await Factory('put',url,templateData);
      console.log(variant,"variant")
      if (res && variant === "success") {
        dispatch(slice.actions.updatetemplatesSuccess(res));
        return { success: true }; // Return success status
      } else {
        console.error("Error: Unable to update template");
        return { success: false, error: "Unable to update template" }; // Return error message
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const deletetemplate = (templateId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.post(`/api/templates/delete`,{templateId});
    dispatch(slice.actions.deletetemplatesSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default slice.reducer;
