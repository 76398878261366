import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';

const initialState = {
  analysis: [],
  isLoading: false,
  error: null
};

const analysisSlice = createSlice({
  name: 'AnalysisName',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getanalysisSuccess(state, action) {
      state.isLoading = false;
      state.analysis = action.payload;
    },

    // POST PHASE
    createanalysisSuccess(state, action) {
      state.isLoading = false;
      state.analysis.push(action.payload);
    },


    updateanalysisSuccess(state, action) {
      state.isLoading = false;
      state.analysis = state.analysis.map(analysis => 
        analysis.id === action.payload.id ? action.payload : analysis
      );
    },
    fetchanalysisFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = analysisSlice.actions;

export const getAnalysis = () => async dispatch => {
    dispatch(analysisSlice.actions.startLoading());
    try {
      console.log('true');
      const response = await axios.get('/api/getanalysis');
      console.log('yes');
      console.log('Response:', response); // Log the entire response object
      console.log('Response Data:', response.data); // Log the data property
      if (Array.isArray(response.data)) {
          dispatch(analysisSlice.actions.getanalysisSuccess(response.data));
          console.log('Data:', response.data); // Log the data again if it's an array
      } else {
          throw new Error('Invalid response format');
      }
  } catch (error) {
      console.error('Error:', error); // Log any errors
      dispatch(analysisSlice.actions.hasError(error.message));
  }
}

  export function createanalysis(newStatus) {
    return async dispatch => {
      dispatch(analysisSlice.actions.startLoading());
      try {
        const response = await axios.post('/api/postcompliance', newStatus);
        dispatch(analysisSlice.actions.createanalysisSuccess(newStatus));
        console.log(newStatus);
      } catch (error) {
        dispatch(analysisSlice.actions.hasError(error));
      }
    };
  }
  export function updateanalysis(newStatus) {
    return async dispatch => {
      dispatch(analysisSlice.actions.startLoading());
      try {
        const response = await axios.post('/api/puteanalysis', newStatus);
        dispatch(analysisSlice.actions.updateanalysisSuccess(newStatus));
        console.log(newStatus);
      } catch (error) {
        dispatch(analysisSlice.actions.hasError(error));
      }
    };
  }
  export function toggleVisibility(id, isVisible) {
    return async dispatch => {
      dispatch(analysisSlice.actions.startLoading());
      try {
        const response = await axios.post('/api/analysis/${id}/toggleVisibility',  { isVisible });
        dispatch(analysisSlice.actions.createentitySuccess(response.data)); // Assu
        console.log(response.data); // Log the updated analysis
    } catch (error) {
      dispatch(analysisSlice.actions.hasError(error));
    };
    }
  }

export default analysisSlice.reducer;
