
import mock from '~/utils/mock'; 

// Define mock data for the post
const Data = [
    {
      "area_name": "Assests",
      "subareas": [
        {
          "subarea_name": "Non Current Assets"
        },
        {
          "subarea_name": "Current Assests"
        }
      ],
      "firm_id": 2,
      "user_id": 2
    },
    {
      "area_name": "Income",
      "subareas": [
        {
          "subarea_name": "Revenue from operations"
        },
        {
          "subarea_name": "Other Income"
        }
      ],
      "firm_id": 3,
      "user_id": 3
    }
  ];
// Define the mock API endpoint for fetching all posts
mock.onGet('/api/getcompliance').reply(200, Data);
// console.log('Mock API response:', Data);


// Define the mock API endpoint for creating a post
mock.onPost('/api/postcompliance').reply(200, Data);

// Define the mock API endpoint for updating a post
mock.onPut('/api/putcompliance').reply(200, { message: 'Post updated successfully' });

// Define the mock API endpoint for deleting a post
mock.onDelete('/api/compliance/${id}/toggleVisibility').reply(200, { message: 'Post deleted successfully' });

// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: postData }];
});*/

export const status = () => {
  return Data;
}
