import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import Factory from '~/utils/factory';
const initialState = {
  compliance: [],
  isLoading: false,
  error: null
};

const analysisSlice = createSlice({
  name: 'Compliancename',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET PHASE
    getcomplianceSuccess(state, action) {
      state.isLoading = false;
      state.compliance = action.payload;
    },

    // POST PHASE
    createcomplianceSuccess(state, action) {
      state.isLoading = false;
      state.compliance.push(action.payload);
    },

    // updatecomplianceSuccess(state, action) {
    //   state.isLoading = false;
    //   state.compliance = state.compliance.map(compliance =>
    //     compliance.id === action.payload.id ? action.payload : compliance
    //   );
    // },
    //
    updatecomplianceSuccess(state, action) {
      state.isLoading = false;
      const updatedCompliance = action.payload;

      console.log('Updated compliance:', updatedCompliance);

      // Find the index of the compliance object in the state array based on its compliance_id
      const index = state.compliance.findIndex(
        compliance =>
          compliance.compliance_id === updatedCompliance.compliance_id
      );
      // console.log('Index:', index); // Log the index for debugging
      if (index !== -1) {
        // If the compliance object exists, update its properties
        state.compliance[index] = {
          ...updatedCompliance,
          subareas: updatedCompliance.subareas.map(subarea => ({
            ...subarea,
            // Assuming each category has a property called 'category_name', extract only category names
            categories: subarea.categories.map(
              category => category.category_name
            )
          }))
        };
      }
    },

    fetchcomplianceFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = analysisSlice.actions;

export const getCompliance = () => async dispatch => {
  dispatch(analysisSlice.actions.startLoading());
  try {
    let url = 'master_data/compliance-testing';
    let { res } = await Factory('get', url, {});
    // console.log(res)
    if (Array.isArray(res)) {
      dispatch(analysisSlice.actions.getcomplianceSuccess(res));
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    // console.log(error.message,"Error")
    dispatch(analysisSlice.actions.hasError(error.message));
  }
};

export function createcompliance(newStatus) {
  return async dispatch => {
    dispatch(analysisSlice.actions.startLoading());
    try {
      // console.log(newStatus)
      let url = 'master_data/compliance-testing';
      let { res } = await Factory('post', url, newStatus);
      // console.log(res)
      if (Array.isArray(res)) {
        dispatch(analysisSlice.actions.createcomplianceSuccess(newStatus));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      // console.log(error.message,"Error")
      dispatch(analysisSlice.actions.hasError(error.message));
    }
  };
}
// export function updatecompliance(updatedCompliance,complianceId) {
//   return async dispatch => {
//     dispatch(analysisSlice.actions.startLoading());
//     try {
//     console.log(updatedCompliance,"Update");
//      let url = `master_data/compliance-testing/${complianceId}`;
//      let { res, variant, message } = await Factory("put", url, updatedCompliance[0]);
//     console.log(res)
//       dispatch(analysisSlice.actions.updatecupdatecomplianceSuccessomplianceSuccess(updatedCompliance[0]));
//      console.log(updatedCompliance)
//     } catch (error) {
//       dispatch(analysisSlice.actions.hasError(error));
//     }
//   };
// }
export function updatecompliance(updatedCompliance, complianceId) {
  return async dispatch => {
    dispatch(analysisSlice.actions.startLoading());
    try {
      // console.log(updatedCompliance, "Update");
      let url = `master_data/compliance-testing/${complianceId}`;
      let { res, variant, message } = await Factory(
        'put',
        url,
        updatedCompliance
      );
      // console.log(res);
      dispatch(
        analysisSlice.actions.updatecomplianceSuccess(updatedCompliance)
      );
      // console.log(updatedCompliance);
    } catch (error) {
      dispatch(analysisSlice.actions.hasError(error));
    }
  };
}

export function toggleVisibility(id, isVisible) {
  return async dispatch => {
    dispatch(analysisSlice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/compliance/${id}/toggleVisibility',
        { isVisible }
      );
      dispatch(analysisSlice.actions.createentitySuccess(response.data)); // Assu
      console.log(response.data); // Log the updated analysis
    } catch (error) {
      dispatch(analysisSlice.actions.hasError(error));
    }
  };
}

export default analysisSlice.reducer;
