import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import { BASE_URL } from '~/constants';
const initialState = {
  status: [],
  isLoading: false,
  error: null
};

const statusSlice = createSlice({
  name: 'statuses',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getstatusSuccess(state, action) {
      state.isLoading = false;
      state.status = action.payload;
    },

    // POST PHASE
    createstatusSuccess(state, action) {
      state.isLoading = false;
      state.status.push(action.payload);
    },


    updateStatusSuccess(state, action) {
      state.isLoading = false;
      state.status = state.status.map(status => 
        status.id === action.payload.id ? action.payload : status
      );
    },
    fetchPhasesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = statusSlice.actions;

export const getStatus = () => async dispatch => {
    dispatch(statusSlice.actions.startLoading());
    try {
      console.log('true');
      console.log(BASE_URL);
      
      const response = await axios.get(BASE_URL + '/master_data/status');
      console.log('yes');
      console.log('Response:', response); // Log the entire response object
      console.log('Response Data:', response.data); // Log the data property
      if (Array.isArray(response.data)) {
          dispatch(statusSlice.actions.getstatusSuccess(response.data));
          console.log('Data:', response.data); // Log the data again if it's an array
      } else {
          throw new Error('Invalid response format');
      }
  } catch (error) {
      console.error('Error:', error); // Log any errors
      dispatch(statusSlice.actions.hasError(error.message));
  }
}

  export function createStatus(newStatus) {
    return async dispatch => {
      dispatch(statusSlice.actions.startLoading());
      try {
        const response = await axios.post(BASE_URL + '/master_data/status', newStatus);
        dispatch(statusSlice.actions.createstatusSuccess(newStatus));
        console.log(newStatus);
      } catch (error) {
        dispatch(statusSlice.actions.hasError(error));
      }
    };
  }
  export function updateStatus(newStatus) {
    return async dispatch => {
      dispatch(statusSlice.actions.startLoading());
      try {
        const response = await axios.post('/master_data/status', newStatus);
        dispatch(statusSlice.actions.updateStatusSuccess(newStatus));
        console.log(newStatus);
      } catch (error) {
        dispatch(statusSlice.actions.hasError(error));
      }
    };
  }
  export function toggleVisibility(id, isVisible) {
    return async dispatch => {
      dispatch(statusSlice.actions.startLoading());
      try {
        const response = await axios.post('/api/status/${id}/toggleVisibility',  { isVisible });
        dispatch(statusSlice.actions.createstatusSuccess(response.data)); // Assu
        console.log(response.data); // Log the updated status
    } catch (error) {
      dispatch(statusSlice.actions.hasError(error));
    };
    }
  }

export default statusSlice.reducer;
