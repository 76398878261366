import { lazy } from 'react';

const componentMapping = {
  'DIR8': lazy(() => import('~/views/finance-box/DocumentDrafting/Directors/Directors')),
  'NetworthCertificates': lazy(() => import('~/views/finance-box/DocumentDrafting/Networth/Networth')),
  'TurnoverCertificate': lazy(() => import('~/views/finance-box/DocumentDrafting/TurnOver/TurnOver')),
  'AOC2': lazy(() => import('~/views/finance-box/DocumentDrafting/FormAoc/FormAOC')),
  'AuditorReport': lazy(() => import('~/views/finance-box/DocumentDrafting/AuditReport/AuditReport')),
  'AuditorAppointment': lazy(() => import('~/views/finance-box/DocumentDrafting/AuditorAppointment/AuditorAppointment')),
  'OrdinaryResolution': lazy(() => import('~/views/finance-box/DocumentDrafting/OrdinaryResolution/OrdinaryResolution')),
  'DIR-2': lazy(() => import('~/views/finance-box/DocumentDrafting/DIR2/DIR2')),
  'BR-Document': lazy(() => import('~/views/finance-box/DocumentDrafting/BR/BR')),
  'Letter-of-Appointment': lazy(() => import('~/views/finance-box/DocumentDrafting/LetterOfAppointment/LetterOfAppointment')),
  'FormMGT9': lazy(() => import('~/views/finance-box/DocumentDrafting/MGT/FormMGT')),
  'BoardReport': lazy(() => import('~/views/finance-box/DocumentDrafting/BoardReport/BoardReport')),
  'AuditConsent': lazy(() => import('~/views/finance-box/DocumentDrafting/AuditConsent/AuditConsent')),
};

export default componentMapping;