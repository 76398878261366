import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  areas: [],
};

const areaslice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getAreasSuccess(state, action) {
      state.isLoading = false;
      state.areas = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addAreasStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addAreasSuccess(state, action) {
      state.isLoading = false;
      state.areas.push(action.payload)
    },
    addAreasFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateAreasSuccess(state, action) {
      state.isLoading = false;
      const updatedArea = action.payload;
      state.areas = state.areas.map(area => {
        return area.id === updatedArea.id ? updatedArea : area;
      });
    },
    
    // DELETE EVENT
    deleteAreasSuccess(state, action) {
      state.areas = action.payload;
    },
  },
});

export const { startLoading, getAreasSuccess, hasError } = areaslice.actions;

export const getAllAreas = () => async (dispatch) => {
  /*dispatch(areaslice.actions.startLoading());
  try {
    const response = await axios.get("/api/areas/get")
    if(Array.isArray(response.data)){
      dispatch(areaslice.actions.getAreasSuccess(response.data));
      //console.log(response.data,"reeeee")
    }else{
      throw new Error("Invalid response form")
    }
  } catch (error) {
    console.log(error,"fetching error")
    dispatch(areaslice.actions.hasError(error))
  }*/
  dispatch(areaslice.actions.startLoading());
  try {
    let url = "master_data/areas";
    let { res, variant, message } = await Factory("get", url, {});
    console.log(res)
    if(Array.isArray(res)){
      dispatch(areaslice.actions.getAreasSuccess(res));
      //console.log(response.data,"reeeee")
    }else{
      throw new Error("Invalid response form")
    }
  } catch (error) {
    console.log(error,"fetching error")
    dispatch(areaslice.actions.hasError(error))
  }

};

export const addArea = (areaData) => {
  return async dispatch => {
    dispatch(areaslice.actions.startLoading());
    try {
      const response = await axios.post('/api/areas/post', areaData);
      dispatch(areaslice.actions.addAreasSuccess(areaData))
    } catch (error) {
      dispatch(areaslice.actions.hasError(error));
    }
  };

};

export const updateArea = (areaData) => {
  return async dispatch => {
    dispatch(areaslice.actions.startLoading());
    try {
      const response = await axios.put(`/api/areas/update`, areaData);
      dispatch(areaslice.actions.updateAreasSuccess(areaData))
    } catch (error) {
      dispatch(areaslice.actions.hasError(error));
    }
  };
};

export const deleteArea = (areaId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.post(`/api/areas/delete`,{areaId});
    dispatch(areaslice.actions.deleteAreasSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default areaslice.reducer;
