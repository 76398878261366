import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

Logo.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  logo: {
    width: 40,
    height: 40,
    marginRight: 10
  }
}));


function Logo({ className, ...other }) {
  let userData = useSelector((e) => e?.landingpagedetails?.res)
  const classes = useStyles();
  return (
      <Box
        component="img"
        alt="logo"
        id={"header_image"}
        // src="/static2/FinanceBoxLogo.svg"
        src={userData?.logoPath}
        height={40}
        className={clsx(className, classes.logo)}
        {...other}
      />
  );
}

export default Logo;
