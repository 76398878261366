import { PATH_APP } from '~/routes/paths';

//GST Services
export const GSTRegistrationIndividual = {
    pageTitle: 'GST Registration Individual',
    heading: 'Goods and Service Tax Registration - Individual',
    serviceKey: 'GSTRegistrationIndividual',
    // price: 1499,
    defaultDiscount: 0,
    formURL: PATH_APP.app2.GSTServices.GSTRegistrationIndividualForm,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'GST Registration', href: PATH_APP.app2.services.GSTRegistration },
        { name: 'GST Registration' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who Should Buy this Plan :',
            content: {
                step1: {
                    title: "For Sale of Goods:",
                    substeps: [
                        'Turnover exceeding Rs.40 Lakhs in a Financial year for all the states other than North Eastern States',
                        'Turnover exceeding Rs.20 Lakhs in a Financial year for North Eastern States'
                    ]
                }, 
                step2: {
                    title: "For Providing Services:", 
                    substeps: [
                        'Turnover exceeding Rs.20 lakhs in a Financial year for all the states other than North Eastern states',
                        'Turnover exceeding Rs.10 lakhs in a Financial year for North Eastern States'
                    ]
                }, 
                step3: {
                    title: "Voluntary Basis", 
                }, 
                step4: {
                    title: "Vendors selling through e commerce platform", 
                }, 
                step5: {
                    title: "Non Resident taxable person operating business in India", 
                }, 
            }
        },
    ],
    tabPanels: [    
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to the Finance Box portal',
                },
                step2 : {
                    title : 'Upload of required Documents',
                },
                step3 : {
                    title : 'Purchase of plan',
                },
                step4 : {
                    title : 'Clarification if any needed by Finance box expert',
                },
                step5 : {
                    title : 'Submission of Registration form on GST portal',
                },
                step6 : {
                    title : 'Generation of ARN no.',
                },
                step7 : {
                    title : 'Generation of GSTIN no.',
                },
            }
        },
        // {
        //     panelTitle: 'Documents',
        //     panelContent: {
        //         step1 : {
        //             title : 'Digital signatures',
        //         }, 
        //         step2 : {
        //             title : 'PAN',
        //         },
        //         step3 : {
        //             title : 'Aadhar',
        //         },
        //         step4 : {
        //             title : 'Voter id/ driving license/ passport',
        //         },
        //         step5 : {
        //             title : 'Bank statement/ electricity bill/ telephone bill',
        //         },
        //         step6 : {
        //             title : 'passport size photos',
        //         },
        //         step7 : {
        //             title : 'MOA',
        //         },
        //         step8 : {
        //             title : 'AOA',
        //         },
        //         step9 : {
        //             title : 'Electricity bill of the registered office of the company',
        //         },
        //         step10 : {
        //             title : 'NOC from the owner',
        //         },
        //     }
        // },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card copies of the applicant',
                },
                step2 : {
                    title : 'Aadhar card (in case of Sole proprietor)',
                },
                step3 : {
                    title : 'Proof of Business Registration / Incorporation certificate (in case of Company /LLP)',
                },
                step4 : {
                    title : 'Photos of Directors & Promotors (for company/LLP / Partnership firm )',
                },
                step5 : {
                    title : 'Identity Proof of Directors & promotors (for company/LLP / Partnership firm )',
                },
                step6 : {
                    title : 'Address proof of Directors & Promotors (for company/LLP / Partnership firm )',
                },
                step7 : {
                    title : 'Address proof of Place of business (Electricity bill / Rental agreement / Property tax receipt)',
                },
                step8 : {
                    title : 'No Objection certificate from the owner of Property ( we will give the format )',
                },
                step9 : {
                    title : 'Bank Account Statement (Latest not exceeding 3 months, it should show name, account no., IFSC code and address)',
                },
                step10 : {
                    title : 'Digital Signatures (in case of Company/ LLP)',
                },
                step11: {
                    title : 'Letter of Authorization / Board resolution for Authorised signatory ( in case of Company / LLP / Partnership firm) ( We will give the format )',
                },
            }
        }
    ]
}

export const GSTRegistrationCompany = {
    pageTitle: 'GST Registration Company',
    heading: 'Goods and Service Tax Registration - Company',
    serviceKey: 'GSTRegistrationCompany',
    // price: 1499,
    defaultDiscount: 0,
    formURL: PATH_APP.app2.GSTServices.GSTRegistrationCompanyForm,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'GST Registration', href: PATH_APP.app2.services.GSTRegistration },
        { name: 'GST Registration Company' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who Should Buy this Plan :',
            content: {
                step1: {
                    title: "For Sale of Goods:",
                    substeps: [
                        'Turnover exceeding Rs.40 Lakhs in a Financial year for all the states other than North Eastern States',
                        'Turnover exceeding Rs.20 Lakhs in a Financial year for North Eastern States'
                    ]
                }, 
                step2: {
                    title: "For Providing Services:", 
                    substeps: [
                        'Turnover exceeding Rs.20 lakhs in a Financial year for all the states other than North Eastern states',
                        'Turnover exceeding Rs.10 lakhs in a Financial year for North Eastern States'
                    ]
                }, 
                step3: {
                    title: "Voluntary Basis", 
                }, 
                step4: {
                    title: "Vendors selling through e commerce platform", 
                }, 
                step5: {
                    title: "Non Resident taxable person operating business in India", 
                }, 
            }
        },
    ],
    tabPanels: [    
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to the Finance Box portal',
                },
                step2 : {
                    title : 'Upload of required Documents',
                },
                step3 : {
                    title : 'Purchase of plan',
                },
                step4 : {
                    title : 'Clarification if any needed by Finance box expert',
                },
                step5 : {
                    title : 'Submission of Registration form on GST portal',
                },
                step6 : {
                    title : 'Generation of ARN no.',
                },
                step7 : {
                    title : 'Generation of GSTIN no.',
                },
            }
        },
        // {
        //     panelTitle: 'Documents',
        //     panelContent: {
        //         step1 : {
        //             title : 'Digital signatures',
        //         }, 
        //         step2 : {
        //             title : 'PAN',
        //         },
        //         step3 : {
        //             title : 'Aadhar',
        //         },
        //         step4 : {
        //             title : 'Voter id/ driving license/ passport',
        //         },
        //         step5 : {
        //             title : 'Bank statement/ electricity bill/ telephone bill',
        //         },
        //         step6 : {
        //             title : 'passport size photos',
        //         },
        //         step7 : {
        //             title : 'MOA',
        //         },
        //         step8 : {
        //             title : 'AOA',
        //         },
        //         step9 : {
        //             title : 'Electricity bill of the registered office of the company',
        //         },
        //         step10 : {
        //             title : 'NOC from the owner',
        //         },
        //     }
        // },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card copies of the applicant',
                },
                step2 : {
                    title : 'Aadhar card (in case of Sole proprietor)',
                },
                step3 : {
                    title : 'Proof of Business Registration / Incorporation certificate (in case of Company /LLP)',
                },
                step4 : {
                    title : 'Photos of Directors & Promotors (for company/LLP / Partnership firm )',
                },
                step5 : {
                    title : 'Identity Proof of Directors & promotors (for company/LLP / Partnership firm )',
                },
                step6 : {
                    title : 'Address proof of Directors & Promotors (for company/LLP / Partnership firm )',
                },
                step7 : {
                    title : 'Address proof of Place of business (Electricity bill / Rental agreement / Property tax receipt)',
                },
                step8 : {
                    title : 'No Objection certificate from the owner of Property ( we will give the format )',
                },
                step9 : {
                    title : 'Bank Account Statement (Latest not exceeding 3 months, it should show name, account no., IFSC code and address)',
                },
                step10 : {
                    title : 'Digital Signatures (in case of Company/ LLP)',
                },
                step11: {
                    title : 'Letter of Authorization / Board resolution for Authorised signatory ( in case of Company / LLP / Partnership firm) ( We will give the format )',
                },
            }
        }
    ]
}

export const GSTReturnsDetails = {
    pageTitle: 'GST Returns',
    heading: 'Goods and Service Tax Retuns',
    serviceKey: 'GSTReturns',
    // price: 999,
    defaultDiscount: 0,
    formURL: PATH_APP.app2.GSTServices.GSTReturnsForm,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'GST Returns' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who Should Buy this Plan :',
            content: {
                step1: {
                    title: "A registered tax payer, has to file two -monthly returns and one annual return.",
                    substeps: [
                        'GSTR-1 – Form reporting monthly outward supplies of taxable and/or services affected.',
                        'GSTR -3B- Form reporting summary of month’s outward taxable supplies along with the input tax credit available for that month.',
                        'GSTR-9 - Annual return to be filed by registered taxpayers disclosing the outward taxable supplies along with the input tax credit for that particular financial year.'
                    ]
                }, 
                step2: {
                    title: "Note: GST audit is applicable in case the turnover for the financial year exceeds Rs. 5cr, GSTR-9C has to be filed in that case.", 
                }, 
                step3: {
                    title: "GSTR-1 can be filed monthly or quarterly on the basis of turnover. If the Annual turnover is upto Rs. 1.5 crores, quarterly filing can be opted.", 
                }, 
            }
        },
        {
            question: 'Due dates:',
            content: {
                step1: {
                    title: 'GSTR-3B : 22nd day of the following month'
                },
                step2: {
                    title: 'GSTR-1 : 11th day of the following month'
                },
                step3: {
                    title: 'GSTR-9 : 31st December of the year following the financial year'
                }
            }
        },
        {
            content:{
                step1: {
                    title: 'Note: The due dates may vary based on states, you may visit www.gst.gov.in for complete details.'
                },
                step2: {
                    title: 'A registered tax payer who has opted for Composite scheme has to file two returns.',
                    substeps: [
                        'CMP-8– To be filed quarterly disclosing the outward taxable supplies along with the input tax credit for that quarter.',
                        'GSTR-4 – Annual return to be filed by composite dealers disclosing the outward taxable supplies along with the input tax credit for that particular financial year.'
                    ]
                }
            }
        },
        {
            question: 'Due dates:',
            content: {
                step1: {
                    title: 'CMP-8 : 22nd day of the following month'
                },
                step2: {
                    title: 'GSTR-9 : 31st December of the year following the financial year'
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to finance box portal',
                },
                step2 : {
                    title : 'Purchase the plam',
                },
                step3 : {
                    title : 'Fill in the required information – you may contact finance box expert in case of any queries',
                },
                step4 : {
                    title : 'Upload the documents',
                },
                step5 : {
                    title : 'verification of data by Finance Box team',
                },
                step6 : {
                    title : 'Confirmation email on the data',
                },
                step7 : {
                    title : 'Filing of GST returns',
                },
                step8 : {
                    title : 'Acknowledgent on filing of return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Company',
                    substeps: [
                        'Json file',
                        'Digital signature',
                        'Summary of monthly sales and purchases ( workings)',
                        'Sale invoices',
                        'Purchase invoices'
                    ]
                }, 
                step2 : {
                    title : 'Individual',
                    substeps: [
                        'Json file',
                        'Digital signature (Not mandatory)',
                        'Summary of monthly sales and purchases ( workings)',
                        'Sale invoices',
                        'Purchase invoices'
                    ]
                },
                step3 : {
                    title : 'Partnership',
                    substeps: [
                        'Json file',
                        'Digital signature (Not mandatory)',
                        'Summary of monthly sales and purchases ( workings)',
                        'Sale invoices',
                        'Purchase invoices'
                    ]
                },
                step4 : {
                    title : 'GST logins',
                },
                step5 : {
                    title : 'Name of authorised signatory',
                },
            }
        },
    ]
}

export const GSTR9AnnualReturn = {
    pageTitle: 'GST Annual Return',
    heading: 'Goods and Service Tax Annual Return',
    serviceKey: 'GSTR9AnnualReturn',
    // price: 2999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'GST  Annual Return' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "GSTR-9 is the GST annual return form to be filed by all registered taxpayers under subject to the turnover limit. ",
                }, 
                step2: {
                    title: "The return consists of cosolidated details of CGST, SGST and IGST of the entire year.", 
                }, 
                step3: {
                    title: "The following persons are exempt from filing of GSTR-9:", 
                    substeps: [
                        'Casual taxpayers',
                        'Input Service Distributors',
                        'Non-resident taxpayers'
                    ]
                },
                step4: {
                    title: 'Taxpayers deducting/collecting tax at source under Section 51 or Section 52'
                },
                step5: {
                    title: '(Note: Composition taxpayers have to file GSTR-9A, and E-commerce operators have to file GSTR-9B.)'
                }
            }
        },
        {
            question: 'Applicability of GSTR-9 and GST audit based on the turnover criteria for FY 2019-20:',
            content: {
                step1: {
                    title: '(Turnover) Up to 2 Cr : (GSTR 9) Optional: (GSTR 9C) N/A'
                },
                step2: {
                    title: '(Turnover) More than 2Cr – 5 Cr : (GSTR 9) Filling is mandatory: (GSTR 9C) Optional (Benefit Given)'
                },
                step3: {
                    title: '(Turnover) More than 5Cr : (GSTR 9) Filling is mandatory: (GSTR 9C) Filling is mandatory'
                }
            }
        },
        {
            content:{
                step1: {
                    title: 'Note: GST audit is no longer applicable w.e.f FY 2020-21'
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to finance box portal',
                },
                step2 : {
                    title : 'Purchase the plam',
                },
                step3 : {
                    title : 'Share required documents and information',
                },
                step4 : {
                    title : 'Addressing queries relating to filing',
                },
                step5 : {
                    title : 'Submission of the return',
                },
                step6 : {
                    title : 'Filing of the return',
                },
                step7 : {
                    title : 'Generation of ARN',
                },
                step8: {
                    title: 'GSTR-9',
                    substeps: [
                        'GST logins',
                        'GSTR-3B and GSTR-9 workings for all the months',
                        'Digital signatuture',
                        'Access to accounts'
                    ]
                },
                step9: {
                    title: 'GST- Audit facilitation',
                    substeps: [
                        'contact us'
                    ]
                }
            }
        },
    ]
}

export const LUTFilings = {
    pageTitle: 'LUT Filings',
    heading: 'LUT Filings',
    serviceKey: 'LUTFilings',
    // price: 699,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'LUT Filings' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "LUT is a letter of undertaking given by exporters to government for export of goods and service without paying GST. ",
                }, 
                step2: {
                    title: "Under LUT, an exporter declares he will export the goods/services without payment of IGST within time specified in rule 96A.", 
                }, 
                step3: {
                    title: "He further declares that he will abide by all GST Law and pay IGST, if he fails to export goods/services.", 
                },
                step4: {
                    title: 'Due date: LUT has to be filed once in every financial year'
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to GST portal',
                },
                step2 : {
                    title : 'Select Furnish Letter of Untertaking(LUT)',
                },
                step3 : {
                    title : 'elect the financial year for which year LUT is applies',
                },
                step4 : {
                    title : 'Fill up the form GST RFD-11',
                },
                step5 : {
                    title : 'Verify the form',
                },
                step6 : {
                    title : 'Submit with DSC/EVC',
                },
                step7 : {
                    title : 'Download the acknowledgement',
                },
            }
        },
        {
            panelTitle: 'Documents Required',
            panelContent: {
                
            step1 : {
              
                title : 'LUT cover letter- request for acceptance-duly signed by an authorised person',
            },
            step2 : {
              
                title : 'Copy of GST registration',
            },
            step3 : {
              
                title : 'PAN card of the entity',
            },
            step4 : {
              
                title : 'KYC of the authorized person/ ignatory',
            },
            step5 : {
              
                title : 'GST RFD11 form',
            },
            step6: {
              
                title : 'Copy of the IEC code',
            },
            step7: {
              
                title : 'Canceled Cheque',
            },
            step8: {
              
                title : 'Authorised letter',
            },
        },
        }
    ]
}
export const Reconcilliations = {
    pageTitle: 'Reconcilliations',
    heading: 'Reconcilliations',
    serviceKey: 'Reconcilliations',
    // price: 699,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'Reconcilliations' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "LUT is a letter of undertaking given by exporters to government for export of goods and service without paying GST. ",
                }, 
                step2: {
                    title: "Under LUT, an exporter declares he will export the goods/services without payment of IGST within time specified in rule 96A.", 
                }, 
                step3: {
                    title: "He further declares that he will abide by all GST Law and pay IGST, if he fails to export goods/services.", 
                },
                step4: {
                    title: 'Due date: LUT has to be filed once in every financial year'
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Collecting all the necessary Data',
                },
                step2 : {
                    title : 'Match the data filed by the busines with the data provided by thier suppliers and customers',
                },
                step3 : {
                    title : 'Identify any descripancies are there',
                },
                step4 : {
                    title : 'Rectify the error if there are any',
                },
                step5 : {
                    title : 'Preapare the Reconciliation statement to document the entire reconciliation process',
                },
            }
        },
        {
            panelTitle: 'Documents Required',
            panelContent: {
                
                step1 : {
                    title : 'GST returns',
                },
                step2 : {
                    title : 'Sales and Purchases invoices',
                },
                step3 : {
                    title : 'Credit and Debit notes',
                },
                step4 : {
                    title : 'Bank statements',
                },
                step5 : {
                    title : 'Ledgers and General accounting records',
                },
                step6: {
                    title : 'E-Way bills',
                },
                step7 : {
                    title : 'ITC register',
                },
                step8 : {
                    title : 'Reconciliation statement',
                },
                step9: {
                    title : 'Other supporting documents',
                },

            },
        }
    ]
}
export const EWayBillRegistration = {
    pageTitle: 'EWay Bill Registration',
    heading: 'EWay Bill Registration',
    serviceKey: 'EWayBillRegistration',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },       
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'EWay Bill Registration' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Reistration under E-way bill portal is mandatory under following cases:",
                    substeps: [
                        'When the movement of goods to or from a registered person is more than Rs.50,000 in value',
                        'Transporters, transporting goods through air,rail,water,etc.will have to generate Eway bill if the supplier has not generated under Eway Bill.',
                        'When the movement of goods to or from a registered person is beyond the boundaries of state.'
                    ]
                }, 
                step2: {
                    title: "If any registered person is receiving goods from unregistered person,the registered person(Receiver) will have to ensure whether all the compliances are met as if they were the supplier of the goods", 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Go to the portal',
                },
                step2 : {
                    title : 'Generate new E-Way bill',
                },
                step3 : {
                    title : 'Fill in the details required similar to creating a GST invoice',
                },
                step4 : {
                    title : 'Enter the Goods description',
                },
                step5 : {
                    title : 'Generate E-way bill',
                },
                step6 : {
                    title : 'Consolidate E-way Bill Generation',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1: {
                    title: 'Invoice/ Bill of supply/Challan relevant to the consignment of goods',
                },
                step2: {
                    title: 'In case of Transport by road- Transporter ID or the vehicle number',
                },
                step3: {
                    title: 'Transport by rail, air or ship -Transporter ID, Transport documnet number, and date',
                }
            }
        }
    ]
}

export const EWayBillGeneration = {
    pageTitle: 'EWay Bill Generation',
    heading: 'EWay Bill Generation',
    serviceKey: 'EWayBillGeneration',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'Eway Bill Services', href: PATH_APP.app2.services.EwayBillServices },
        { name: 'EWay Bill Generation' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "A registered tax payer under E-way bill has to generate E-way bills when the supply of goods is made outside his state or when the supply of goods exceed Rs. 50,000.",
                }, 
                step2: {
                    title: "An E-way bill generated is valid for a period of 1 day.", 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to finance box portal',
                },
                step2 : {
                    title : 'Purchase the plan',
                },
                step3 : {
                    title : 'Share required documents and information',
                },
                step4 : {
                    title : 'Generation of E-way bill',
                },
                step5 : {
                    title : 'Copy of E-way bill',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1: {
                    title: 'Eway bill logins',
                },
                step2: {
                    title: 'Tax Invoice',
                },
                step3: {
                    title: 'Transporter ID/ Vehicle Number when transport is by road ',
                },
                step4: {
                    title: 'When the transport is through rail, air or ship then transporter ID, transport document number and date of the document.',
                },
            }
        }
    ]
}

export const GSTCancellation = {
    pageTitle: 'GST Cancellation',
    heading: 'GST Cancellation',
    serviceKey: 'GSTCancellation',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'GST Cancellation' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Any registered tax payer can apply for cancellation of GST registration in an case, provided the GST officer has to be satisfied with the reasons and explanations given by the registered tax payer.",
                }, 
                step2: {
                    title: "GST officer also has the right to cancel the registration on certain grounds such as:", 
                    substeps: [
                        'Non filing of GST returns by the tax payer for a period of continuous six months.',
                        'tax payer has not conducted any business from the place of business ',
                        'Issues invoices or bills without supply of goods/services (i.e., in violation of the provisions)'
                    ]
                }, 
                step3: {
                    title: "The person should reply in FORM REG–18 within 7 days from date of on which notice has been served as to  why his registration should not be cancelled.",
                },
                step4: {
                    title: "If the reply is found to be satisfactory, the  officer will drop the proceedings and pass an order in FORM GST REG–20.",
                },
                step5: {
                    title: "If the registration is liable to be cancelled, the officer shall issue an order in FORM GST REG-19. The order will be sent within 30 days from the date of reply to the show cause.",
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Log in to the portal',
                },
                step2 : {
                    title : 'Application for Termination of Registration 4',
                },
                step3 : {
                    title : 'Fill the required details and save and continue',
                },
                step4 : {
                    title : 'Fill the resons for the termination of GST registration',
                },
               
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1: {
                    title: 'Address proofs, Mail Id, and registeres moobile number',
                },
                step2: {
                    title: 'Reasons for termination of GET registration and the most desires date to effect termination',
                },
                step3: {
                    title: 'Details of the last return filed by the taxpayer',
                },
                step4: {
                    title: 'The GST registration details of the new entity(if any)',
                },
                step5: {
                    title: 'Particulars on which tax is payable',
                },
            }
        }
    ]
}

export const GSTRevocation = {
    pageTitle: 'GST Revocation',
    heading: 'Goods and Service Tax Revocation',
    serviceKey: 'GSTRevocation',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },

        { name: 'GST Revocation' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "A registered tax payer whose GST regisrtration has been cancelled can apply for revocation of he is willing to by making an application and mentioning the reasons for the same to the GST officer.",
                }, 
                step2: {
                    title: "This provision is applicable when the GST officer has cancelled the registration and not when the registration was applied for cancellation by the tax payer.", 
                }, 
                step3: {
                    title: "Revocation can be applied for only within 30 days of cancellation of registration by the tax officer.",
                },
                step4: {
                    title: "If the GST officer is satisfied with the explanation given in writing  by the tax payer, the officer can revoke the GST registration within 20days from the receipt of application.",
                },
                step5: {
                    title: "If the officer is not satisfied with the explanation he may cancel the registration after giving show cause notice to the tax payer as to why the registration shouldn't be cancelled.",
                },
                step6: {
                    title: "This is only for one time reply, additional charges shall apply for further replies.",
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Log in to the portal',
                },
                step2 : {
                    title : 'Enter all the required information and reasons',
                },
                step3 : {
                    title : 'Apply form GST REG-21 with DSC or EVC',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1: {
                    title: 'Reference number and date of the notice',
                },
                step2: {
                    title: 'Reference number and date of the application',
                },
                step3: {
                    title: 'GSTIN',
                },
                step4: {
                    title: 'Reasons based on which the taxpayer is demanding revocation',
                },
            }
        }
    ]
}

export const AmendmentInGSTRegistration = {
    pageTitle: 'Amendment In GST Registration',
    heading: 'Amendment In GST Registration',
    serviceKey: 'AmendmentInGSTRegistration',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'Amendment In GST Registration' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Any registered tax payer can apply for change in any of the registration details.",
                }, 
                step2: {
                    title: "Following are few cases where amendment in GST registration can be applied for:", 
                    substeps: [
                        'legal name of business',
                        'address of the principal place of business or any additional place of business.',
                        'addition, deletion of the name of the person responsible for the day to day affairs of the business'
                    ]
                }, 
                step3: {
                    title: "The GST officer shall approve or reject the application based on the documents provided by the tax payer. ",
                },
                step4: {
                    title: "If found satisfactory the officer shall approve the same, in any other case, he shall serve a show cause notice/ request for clarifications to provide additional informaton.",
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Log in  to GST portal',
                },
                step2 : {
                    title : 'Select which Amendment of Registration',
                },
                step3 : {
                    title : 'Verification',
                },
               
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1: {
                    title: 'Individual`s PAN card details',
                },
                step2: {
                    title: 'Letter of Authorization is required or the Board Resolution for Authorized Signatory(Only companies, Patnership firms and LLPs)',
                },
                step3: {
                    title: 'Supporting documents which are needed for the amendment',
                   
                },
            }
        }
    ]
}

export const GSTNotice = {
    pageTitle: 'GST Notice',
    heading: 'Goods and Service Tax Notice',
    serviceKey: 'GSTNotice',
    // price: 799,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
        { name: 'GST Notice' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "A registered tax payer who has received any notice from GST department has to reply to the notice within stipulated time online, in the GST portal.",
                }, 
            }
        },
    
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Notice generation',
                },
                step2 : {
                    title : 'Notice availability',
                },
                step3 : {
                    title : 'Notice view and download',
                },
                step4 : {
                    title : 'Document submission',
                },
                step5 : {
                    title : 'Response submission',
                },
                step6 : {
                    title : 'Acknowledgement and Tracking',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1: {
                    title: 'GST registration documents',
                },
                step2: {
                    title: 'Tax returns and statements',
                },
                step3: {
                    title: 'Sales and Purchases invoices',
                },
                step4: {
                    title: 'Accounting records',
                },
                step5: {
                    title: 'ITC records',
                },
                step6: {
                    title: 'Business contracts',
                },
                step7: {
                    title: 'Transporatation documents',
                },
                step8: {
                    title: 'Import/Export documents',
                },
                step9: {
                    title: 'Financial statements',
                },
                step10: {
                    title: 'Other additional documents',
                },
            }
        }
    ]
}
export const PaymentsinTDS = {
    pageTitle: 'Payment',
    heading: 'Payment', 
    serviceKey: 'PaymentsinTDS',
    // price: 999,
    defaultDiscount: 99,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'TDS Services', href: PATH_APP.app2.services.TDSServices },
        { name: 'Payment'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "At the time of paying salaries to Employee, the employer deducts TDS on the salary if the amount paid to employee is taxable as per the slabs rates under which the employee falls. ", 
                }, 
                step2: {
                    title: "Total amount paid during the quarter and TDS amount deducted on such payments have to be reported in 26Q.", 
                }, 
            }
        },
        {   
            question: 'Due dates:',
            content: {
                step1: {
                    title: "April to June: 31st July", 
                }, 
                step2: {
                    title: "July to September: 31st Oct", 
                }, 
                step3: {
                    title: "October to December: 31st Jan", 
                },
                step4: {
                    title: "January to March: 30th April", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'TAN registration',
                },
                step2 : {
                    title : 'Deduction',
                },
                step3 : {
                    title : 'Payment',
                },
                step4 : {
                    title : 'Return Filing',
                },
                step5 : {
                    title : 'Certificate Issuance',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Company PAN card',
                }, 
                step2 : {
                    title : 'PAN card of Authorised signatory',
                },
                step3 : {
                    title : 'Aadhar card of Authorised signatory',
                },
                step4 : {
                    title : 'Form 27A of previous quarter',
                },
                step5 : {
                    title : 'TDS payment challans',
                },
                step6 : {
                    title : 'Acknowledgement of previous quarter',
                },
                step7 : {
                    title : 'Entity details',
                    substeps: [
                        'Type of Deductor (drop down with Firm/ Indivdial or HUF/ BOI/ AJP/ AOP/ Company/ Trust/ any govt bodies )',
                        'Traces logins',
                        'TAN number',
                        'Email id',
                        'Mobile number',
                        'Registered office address',
                        'Quarter (drop down with year and month)',
                        'Deductee details'
                    ]
                },
                step8 : {
                    title : 'Authorised signatory',
                    substeps: [
                        'Email id',
                        'Mobile number',
                        'Digital signature certificate',
                    ]
                },
            }
        },
    ]
}

//TDS Services
export const TDS24QViewDetails = {
    pageTitle: 'Form 24Q',
    heading: 'Form 24Q - Salaries',
    serviceKey: 'Form24Q',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'TDS Services', href: PATH_APP.app2.services.TDSServices },
        { name: 'Form 24Q - Salaries' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "At the time of paying salaries to Employee, the employer deducts TDS  on the salary if the amount paid to emloyee is taxable as per the slabs rates under which the employee falls.", 
                }, 
                step2: {
                    title: "The employee has to deduct the tax on such amount and remit the same to the governement on/ before 7th of every month.", 
                }, 
                step3: {
                    title: "On a quarterly basis, TDS return has to be filed disclosing the details as per the regulations.", 
                }, 
            }
        },
        {   
            question: 'Due dates:',
            content: {
                step1: {
                    title: "April to June: 31st July", 
                }, 
                step2: {
                    title: "July to September: 31st Oct", 
                }, 
                step3: {
                    title: "October to December: 31st Jan", 
                },
                step4: {
                    title: "January to March: 30th April", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Preparation of TDS Return Form',
                },
                step2 : {
                    title : 'Submission of required documents',
                },
                step3 : {
                    title : 'Submission of application',
                },
                
                step4 : {
                    title : 'Acknowledgement',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Company PAN card',
                }, 
                step2 : {
                    title : 'PAN card of authorised signatory',
                },
                step3 : {
                    title : 'Aadhar card of authorised signatory',
                },
                step4 : {
                    title : 'Form 27A of previous quarter ',
                },
                step5 : {
                    title : 'TDS payment challan',
                },
                step6 : {
                    title : 'Acknowledgment of previous quarter',
                },
                step7 : {
                    title : 'Entity Details :',
                    substeps: ['Type of deductor',
                    'Traces login',
                    'TAN number',
                    'Email-id',
                    'Mobile Number',
                    'Registered Office address',
                    'Quarter',
                    'Deductee Details',
                    ]
                },
                step7 : {
                    title : 'Authorised signatory',
                    substeps: ['Email-id',
                    'Mobile Number',
                    'Digital Signature Certificate',
                    ]
                },
                
                
            }
        },
    ]
}


export const TDS26QViewDetails = {
    pageTitle: 'Form 26Q',
    heading: 'Form 26Q - Other payments',
    serviceKey: 'Form26Q',
    // price: 999,
    defaultDiscount: 99,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'TDS Services', href: PATH_APP.app2.services.TDSServices },
        { name: 'Form 26Q - Other payments' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Form 26Q is applicable for TDS under Section 200(3) of the Income Tax Act, 1961 under Sections 193, 194, 194A, 194B, 194BB, 194C, 194D, 194EE, 194F, 194G, 194H, 194I and 194J. It is a statement for tax deducted at source on all payments except salaries. ", 
                }, 
                step2: {
                    title: "Total amount paid during the quarter and TDS amount deducted on such payments have to be reported in 26Q.", 
                }, 
            }
        },
        {   
            question: 'Due dates:',
            content: {
                step1: {
                    title: "April to June: 31st July", 
                }, 
                step2: {
                    title: "July to September: 31st Oct", 
                }, 
                step3: {
                    title: "October to December: 31st Jan", 
                },
                step4: {
                    title: "January to March: 30th April", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Obtain TAN',
                },
                step2 : {
                    title : ' Fill out the TAN application form',
                },
                step3 : {
                    title : 'Submit the application',
                },
                step4 : {
                    title : 'Pay the application fee',
                },
                step5 : {
                    title : ' Verification and Processing',
                },
                step6 : {
                    title : ' TAN allotment',
                },
                step7 : {
                    title : 'Update TAN in records',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Company PAN card',
                }, 
                step2 : {
                    title : 'PAN card of authorised signatory',
                },
                step3 : {
                    title : 'Aadhar card of authorised signatory',
                },
                step4 : {
                    title : 'Form 27A of previous quarter',
                },
                step5 : {
                    title : 'TDS payment challan',
                },
                step6 : {
                    title : 'Acknowledgment of previous quarter',
                },
                step7 : {
                    title : 'Entity details',
                    substeps: [
                        'Type of Deductor',
                        'Traces logins',
                        'TAN number',
                        'Email id',
                        'Mobile number',
                        'Registered office address',
                        'Quarter',
                        'Deductee details'
                    ]
                },
                step8 : {
                    title : 'Authorised signatory',
                    substeps: [
                        'Email id',
                        'Mobile number',
                        'Digital signature certificate',
                    ]
                },
            }
        },
    ]
}

export const Form26QB = {
    pageTitle: 'Form 26QB',
    heading: 'Form 26QB',
    serviceKey: 'Form26QB',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'TDS Services', href: PATH_APP.app2.services.TDSServices },
        { name: 'Form 26QB' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Form 26QB is a return-cum-challan form for the payment of Tax Deducted at Source (TDS) to the government for deductions made under Section 194-IA of the Income Tax Act, 1961. This section of the Act specifically deals with transactions involving the sale of immovable property and the applicable TDS along with Form 26QB needs to be submitted within 30 days counted from the end of the month in which TDS was deducted. For instance, if the transaction occurred on 14th March then Form 26QB must be mandatorily submitted by 30th April.", 
                },  
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Obtain TAN',
                },
                step2 : {
                    title : 'Fill out form 26QB',
                },
                step3 : {
                    title : ' Generate TDS certificate',
                },
                step4 : {
                    title : ' Deposit TDS amount',
                },
                step5 : {
                    title : 'File TDS return',
                },
                step6 : {
                    title : 'Issue TDS certificate to the seller',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN of Buyer and Seller',
                }, 
                step2 : {
                    title : 'Complete Address of Buyer and Seller',
                },
                step3 : {
                    title : 'Mail id and phone number of Buyer and Seller',
                },
                step4 : {
                    title : 'Status of whether buyer/seller if resident/non-resident',
                },  
                step5 : {
                    title : 'Confirmation whether more than one buyer/seller or not',
                }, 
                step6 : {
                    title : 'Complete address of property',
                }, 
                step7 : {
                    title : 'Date of purchase',
                }, 
                step8 : {
                    title : 'Amount of sale consideration',
                }, 
                step9 : {
                    title : 'Details of payment whether done in lumpsum or in installments',
                }, 
                step10 : {
                    title : 'Date on which the amount is paid or credited',
                }, 
            }
        },
    ]
}

export const Form27Q = {
    pageTitle: 'Form 27Q',
    heading: 'Form 27Q - Payments to Non-resident',
    serviceKey: 'Form27Q',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'TDS Services', href: PATH_APP.app2.services.TDSServices },
        { name: 'Form 27Q - Payments to Non-resident' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Form 27Q is a TDS Return which is required to be filed at the time when TDS is deducted and deposited while making payments (other than Salary) to Non-Resident Individual (On or after it will be called as NRI).", 
                },  
                step2: {
                    title: "In simple words, when the buyer of a Property from NRI or Payer of an Amount to an NRI Person makes certain payments, to A Non Resident person, then he is required to deduct TDS (Tax Deducted at Source ) at the time of the making of the payment. Such TDS should be deposited by the buyer on or before 7th of Next Month in which TDS has been deducted.", 
                },  
                step3: {
                    title: "Once the TDS has deposited, Buyer is also required to file TDS return in form 27Q in TDS return. This return is required to be furnished quarterly.", 
                },  
            }
        },
        {   
            question: 'Due dates:',
            content: {
                step1: {
                    title: "1st April – 30th June: On or before 31st July", 
                }, 
                step2: {
                    title: "1st July – 30th September: On or before 31st October", 
                }, 
                step3: {
                    title: "1st October – 31st December: On or before 31st January", 
                },
                step4: {
                    title: "1st January – 31st March: On or before 31st May", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Application for TAN',
                },
                step2 : {
                    title : 'Document submission',
                },
                step3 : {
                    title : ' Payment of fees',
                },
                step4 : {
                    title : 'Verification and issuance',
                },
                step5 : {
                    title : 'Compliance and Reporting',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Quarter ended for which return has been filed.',
                }, 
                step2 : {
                    title : 'Financial year for which return is to be filed',
                },
                step3 : {
                    title : 'Details of Deductor',
                    substeps: [
                        ' PAN',
                         'TAN',
                         'Complete Address',
                         'Contact Details',
                    ]
                },
                step3 : {
                    title : 'Details of Responsible Person',
                    substeps: [
                        ' PAN',
                         'Name',
                         'Address and Contact Details',
                    ]
                },
                step4 : {
                    title : 'In case of non-availability of PAN, additional details of NRI such as TIN, permanent address, country of residence, Email and contact details are needed.',
                },  
                step5 : {
                    title : 'The breakup of the name of NRI in seperate annexure.',
                }, 
                step6 : {
                    title : 'DSC by the deductor of tax.',
                },                 
            }
        },
    ]
}

export const Form27EQ = {
    pageTitle: 'Form 27EQ',
    heading: 'Form 27EQ - TCS',
    serviceKey: 'Form27EQ',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'TDS Services', href: PATH_APP.app2.services.TDSServices },
        { name: 'Form 27EQ - TCS' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Tax collected at source (TCS) is the tax payable by a seller which he collects from the buyer at the time of sale.", 
                },  
                step2: {
                    title: "Form 27EQ contains all details pertaining to tax that is collected at source. According to Section 206C of the Income Tax Act 1961, this form must be filed every quarter. The form has to be submitted by both the corporate and government collectors and deductors.", 
                },  
                step3: {
                    title: "When the below-mentioned goods are utilized for the purpose of manufacturing, processing, or producing things, the taxes are not payable. If the same goods are utilized for trading purposes then tax is payable. The tax payable is collected by the seller at the point of sale.", 
                },  
                step4: {
                    title: "The rate of TCS is different for goods specified under different categories :", 
                    substeps: [
                        'Liquor of alcoholic nature, made for consumption by humans : 1.00%',
                        'Timber wood under a forest leased : 2.50%',
                        'Tendu leaves : 5.00%',
                        'Timber wood by any other mode than forest leased : 2.50%',
                        'A forest produce other than Tendu leaves and timber : 2.50%',
                        'Scrap : 1.00%',
                        'Minerals like lignite, coal and iron ore : 1.00%',
                        'Bullion that exceeds over Rs. 2 lakhs/ Jewellery that exceeds over Rs. 5 lakhs : 1.00%',
                        'Purchase of Motor vehicle exceeding Rs. 10 Lakhs : 1.00%',
                        'Parking lot, Toll Plaza and Mining and Quarrying : 2.00%'
                    ]
                },  
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Determine applicability',
                },
                step2 : {
                    title : 'Obtain TAN',
                },
                step3 : {
                    title : 'Register for TCS',
                },
                step4 : {
                    title : 'Document submission',
                },
                step5 : {
                    title : 'Verification and Approval',
                },
                step6 : {
                    title : 'Compliance and Reporting',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Collector/Seller',
                    substeps: [
                        'TAN',
                        'PAN',
                        'Name',
                        'Address and Contact details',
                    ]
                }, 
                step2 : {
                    title : 'Responsible person',
                    substeps: [
                        'Name',
                        'PAN',                        
                        'Address and Contact details',
                    ]
                },
                step3 : {
                    title : 'Challan',
                    substeps:[
                        'Challan Serial Number',
                        'BSR Code',
                        'TDS',
                        'Surcharge and Education cess',
                    ]
                },
                step4 : {
                    title : 'Deduction',
                    substeps:[
                    'Name of Buyer',
                    'PAN',
                    'Total Value of purchase',
                    'TCS amount collected and deposited',
                ]
                },  
               
            }
        },
    ]
}

//Business Incorporation
export const PatnershipFirmViewDetails = {
    pageTitle: 'Patnership Firm',
    heading: 'Patnership Firm',
    serviceKey: 'PatnershipFirm',
    // price: 1399,
    defaultDiscount: 99,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },
        { name: 'Patnership Firm' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who should buy this plan?',
            content: {
                step1:{
                    title: 'A group of 2 or more individuals with a business idea'
                },
                step2:{
                    title: 'Small businesses that want to leverage a collective resource pool made up of many people'
                },
                step3:{
                    title: 'Any existing unregistered partnership firm'
                },
                
            }
        }
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Decide on a name for partnership',
                },
                step2 : {
                    title : 'Drafting of partnership deed',
                },
                step3 : {
                    title : 'Declarations from partners',
                },
                step4 : {
                    title : 'application with registrar for registration',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN copy of Partners with self-attestation',
                }, 
                step2 : {
                    title : 'Aadhar/ Driving License Copy of partners with self-attestation',
                },
                step3 : {
                    title : 'Passport size photos of all partners',
                },
                step4 : {
                    title : 'Rental agreement',
                },
                step5 : {
                    title : 'Electricity bill of the registered office of the partnership firm',
                },
                step6 : {
                    title : 'Form I- application for registration of partnership',
                },
                step7 : {
                    title : 'Declaration by partners',
                },
                step8 : {
                    title : 'Specimen of affidavit',
                },
                step9 : {
                    title : 'Occupation of Partners',
                },
                step10 : {
                    title : 'Capital contribution',
                },
                step11: {
                    title : 'Profit sharing ratio',
                },
                step12: {
                    title : 'Salary, interest and remuneration terms',
                },
                step13: {
                    title : 'Nature and objects of business',
                },
                step14: {
                    title : 'Authorized partner for dealing with bank transactions & to act as authorized signatory.',
                },
                step15: {
                    title : 'Email-id',
                },
                step16: {
                    title : 'Contact Number',
                },
                step17: {
                    title : 'Residence address of partners',
                },
            }
        },
    ]
}

export const SoleProprietorshipIncorporationViewDetails = {
    pageTitle: 'Sole Proprietorship',
    heading: 'Sole Proprietorship Incorporation',
    serviceKey: 'SoleProprietorshipIncorporation',
    // price: 9999,
    defaultDiscount: 99,
    formURL: '',
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },
         
        { name: 'Sole Proprietorship Incorporation' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Businesses manufacturing goods.", 
                }, 
                step2: {
                    title: "Traders and Merchants selling goods", 
                }, 
                step3: {
                    title: "Service Providers offering customers offline or online services", 
                }, 
               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Choose a name for the firm',
                },
                step2 : {
                    title : 'Apply for a PAN card in the name of Sole Proprietorship',
                },
                step3 : {
                    title : 'Apply for business registration certificate',
                },
                step4 : {
                    title : 'Obtain other registrations such as GST, MSME and others',
                },
                step5 : {
                    title : 'Open a current account in the name of Sole proprietorship',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Pan Card',
                }, 
                step2 : {
                    title : 'Aadhar Card',
                },
                step3 : {
                    title : 'Photograph',
                },
                step4 : {
                    title : 'KYC Documents',
                },
                step5 : {
                    title : 'Rental agreement',
                },
                step6 : {
                    title : 'Sale deed or utility bill of the owned property',
                },
                step7 : {
                    title : 'Architectural blueprints of the business',
                },
               
            }
        },
    ]
}


export const Sec8CompanyIncorporationViewDetails = {
    pageTitle: 'Section 8 Company',
    heading: 'Section 8 Company Incorporation',
    serviceKey: 'Sec8CompanyIncorporation',
    // price: 9999,
    defaultDiscount: 99,
    formURL: '',
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },
         
        { name: 'Section 8 Company Incorporation' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "An individual and HUF ", 
                }, 
                step2: {
                    title: "Company having a social or welfare object", 
                }, 
                step3: {
                    title: "Company with one resident director and no profit distribution", 
                }, 
               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Registration on the MCA portal',
                    
                },
                step2 : {
                    title : 'Fill Part-A of SPICe+ form',
                    substeps: [
                        'Type,class,sub category of the company',
                        'Main division of industrial activity and its description',
                        'Proposed names',
                    ]
                },
                step3 : {
                    title : 'Fill Part-B of SPICe+ form',
                },
                step4 : {
                    title : 'Filing other relevant forms (SPICe-AOA, SPICe-MOA and AGILE PRO)',
                },
                step5 : {
                    title : 'Upload the documents for final submission',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'MOA',
                }, 
                step2 : {
                    title : 'AOA',
                },
                step3 : {
                    title : 'Form INC-12',
                },
                step4 : {
                    title : 'PAN',
                },
                step5 : {
                    title : 'TAN',
                },
                step6 : {
                    title : 'Rental agreement or Lease deed',
                },
                step7 : {
                    title : 'No objection certificate',
                },
                step8 : {
                    title : 'Recent electricity bill',
                },
                step9 : {
                    title : 'For Directors',
                },
                step10 : {
                    title : 'Aadhar Card',
                },
               
            }
        },
    ]
}
export const NidhiCompanyViewDetails = {
    pageTitle: 'Nidhi Company',
    heading: 'Nidhi Company',
    serviceKey: 'NidhiCompany',
    // price: 9999,
    defaultDiscount: 99,
    formURL: '',
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },         
        { name: 'Nidhi Company' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Company having atleast 200 members.", 
                }, 
                step2: {
                    title: "Company having net owned funds of 10 Lakhs.", 
                }, 
                step3: {
                    title: "Company which does not engage in any other business except borrowing and lending among its members.", 
                }, 
                step4: {
                    title: "Company with an object of cultivating the habit of thrift and savings amongst its members.", 
                }, 
                
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Obtain Digital Signature Certificate and Director Identification number',
                   
                },
                step2 : {
                    title : 'Apply for name approval',
                },
                step3 : {
                    title : 'Draft MOA and AOA.',
                },
                step4 : {
                    title : 'Filing of SPICe form',
                },
                step5 : {
                    title : 'Obtain certificate of incorporation',
                },
                step6 : {
                    title : 'Apply for PAN, TAN and GST registration.',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Name approval certificate from ROC',
                }, 
                step2 : {
                    title : 'Memorandum of Association',
                },
                step3 : {
                    title : 'Articles of Association',
                },
                step4 : {
                    title : 'Pan Card of subscribers',
                },
                step5 : {
                    title : 'ID proof of first directors',
                },
                step6 : {
                    title : 'Address proof of first directors',
                },
                step7 : {
                    title : 'Rent agreement',
                },
                step8 : {
                    title : 'Latest Utility bill of registered office',
                },
                step9 : {
                    title : 'No objection certificate',
                },
                step10 : {
                    title : 'Consent and declaration from first director in form DIR-2',
                },
                step10 : {
                    title : 'Self declaration from first directors and subscribers in form INC-9',
                },
                
            }
        },
    ]
}
export const ProducerCompanyViewDetails = {
    pageTitle: 'Producer Company',
    heading: 'Producer Company',
    serviceKey: 'ProducerCompany',
    // price: 9999,
    defaultDiscount: 99,
    formURL: '',
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },         
        { name: 'Producer Company' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Any 10 or more individuals each of them being a producer", 
                }, 
                step2: {
                    title: "Any two or more producer institutions", 
                }, 
                step3: {
                    title: "Combination of 10 or more individuals and producer institutions", 
                },  
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Name reservation',                    
                },
                step2 : {
                    title : 'Preparation of MOA and AOA',
                },
                step3 : {
                    title : 'Preparation of E-form SPICe',
                },
                step4 : {
                    title : 'Filling of forms with MCA',
                },
                step5 : {
                    title : 'Certificate of Incorporation',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Copy of Utility bill (not older than two months)',
                }, 
                step2 : {
                    title : 'Scanned copy of rent agreement with NOC',
                },
                step3 : {
                    title : 'Sale deed or ownership documents in case of owned property',
                },
                step4 : {
                    title : 'Directors and Shareholders',
                    substeps : [
                        'PAN',
                        'Aadhaar card',
                        'Bank Statement',
                        'Photograph',
                        'Sarpanch letter or Khasra-Khatuni or ITR with agricultural incomes',
                    ]
                },
                
            }
        },
    ]
}


export const LLPIncorporationViewDetails = {
    pageTitle: 'LLP',
    heading: 'Limited Liability Patnership',
    serviceKey: 'LLPIncorporation',
    // price: 1399,
    defaultDiscount: 99,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },
        
        { name: 'Limited Liability Patnership' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question:'Who Should Buy this Plan:',
            content: {
                step1: {
                    title: 'Minimum two directors and two shareholders'
                },
                step2: {
                    title: 'Companies, body corporates or already existing partnerships'
                },
                step3: {
                    title: 'LLPs registered outside India'
                },
                step4: {
                    title: 'Start-ups and SMEs looking for carrying business with minimal legal formalities'
                },
                step5: {
                    title: 'To Individuals who would like to do business opting for lability, less compliance and regulations'
                }
            }
        }

    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Apply for a name on mca website',
                    substeps: [
                        'Two names with proper prefix or sufix which are not common to existing companies',
                        'Brief objects of the company'
                    ]
                },
                step2 : {
                    title : 'Once the name is approved, Filip form has to be filled and submitted',
                },
                step3 : {
                    title : 'Granting of certificate of incorporation',
                },
                step4 : {
                    title : 'Preparing LLP agreement',
                },
                step5 : {
                    title : 'Filing of form 3',
                },
                step6 : {
                    title : 'Apply for a PAN card',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Digital signatures',
                }, 
                step2 : {
                    title : 'PAN',
                },
                step3 : {
                    title : 'Aadhar',
                },
                step4 : {
                    title : 'Voter id/ driving license/ passport',
                },
                step5 : {
                    title : 'Bank statement/ electricity bill/ telephone bill',
                },
                step6 : {
                    title : 'passport size photos',
                },
                step7 : {
                    title : 'Draft LLP agreement',
                },
                step8 : {
                    title : 'Subscriber sheet',
                },
                step9 : {
                    title : 'Electricity bill of the registered office of the entity',
                },
                step10 : {
                    title : 'Consent letter- to act as designated partner',
                },
                step11: {
                    title : 'Form 9- consent to act as partner',
                },
                step12: {
                    title : 'NOC from the owner ',
                },
                step13 : {
                    title : 'LLP details',
                    substeps: [
                        'Contribution (in Rs.)',
                        'Shareholding pattern',
                        'Profit and loss sharing ratio',
                        'LLP registered office address as per electricity bill',
                        'LLP email-id',
                        'LLP contact number',
                        'Significance of the coined name of the LLP'
                    ]
                },
                step14 : {
                    title : 'Partners details',
                    substeps: [
                        'Name',
                        'Share of profit',
                        'Share in the LLP',
                        'DIN ( If you are already a director in some other company)',
                        'Designation(Partner/ Designated Partner)',
                        'Email-id',
                        'Education qualification',
                        'Occupation',
                        'Contact Number',
                        'Place of Birth (District and State)',
                        'Permanent Address',
                        'Present Address',
                        'Duration of stay at present address'
                    ]
                },
            }   
        },
    ]
}

export const PvtLtdCompanyIncorporationViewDetails = {
    pageTitle: 'PvtLtd Company',
    heading: 'Private Limited Company Incorporation',
    serviceKey: 'PvtLtdCompany',
    // price: 9999,
    defaultDiscount: 99,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },
        { name: 'Private Limited Company' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who Should Buy this Plan',
            content: {
                step1: {
                    title: "Businesses looking to expand or scale operations", 
                }, 
                step2: {
                    title: "Start-ups looking to raise capital and issue ESOPs", 
                }, 
                step3: {
                    title: "Businesses looking to convert their existing firm structure into private limited company", 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Obtain DSC',
                 
                },
                step2 : {
                    title : 'Apply for DIN for all the proposed directors',
                },
                step3 : {
                    title : 'Apply for the name on MCA website.',
                },
                step4 : {
                    title : 'Part-A of SPICe+ form allows for name reservation with two proposed names and one re-submission',
                },
                step5 : {
                    title : 'Form SPICe+ (INC-32) Part-B',
                    substeps: [
                        'Application for PAN and TAN',
                        'Application for EPFO registration',
                        'Application for ESIC registration',
                        'Application for professional tax registration (only for maharastra)',
                        'Application for opening a bank account for the company',
                        'Allotment of GSTIN if applied',
                    ]
                },
                step6 : {
                    title : 'The details filled in Part-A and Part-B of SPICe+ will be automatically linked in the forms AGILE-PRO, eAoA, eMoA,URC1, INC-9',
                },
                step7 : {
                    title : 'Granting the certificate of incorporation',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Pan Card',
                }, 
                step2 : {
                    title : ' Rent agreement',
                },
                step3 : {
                    title : 'No objection certificate',
                },
                step4 : {
                    title : 'Memorandum of Association',
                },
                step5 : {
                    title : 'Articles of Association',
                },
                step6 : {
                    title : 'Latest Electricity Bill',
                },
                step7 : {
                    title : 'Latest Telephone Bill',
                },
                step8 : {
                    title : ' Directors/Shareholders Details',
                },
                step9 : {
                    title : ' Passport size photo',
                },
                step10: {
                    title : 'Aadhar Card',
                },
                step11 : {
                    title : ' Digital signature certificate',
                },
                step12 : {
                    title : 'Director Identification number',
                },
                step13 : {
                    title : 'Board Resolution Authorising investment',
                },
            }
        },
    ]
}

export const OPCIncorporationViewDetails = {
    pageTitle: 'OPC Incorporation',
    heading: 'OPC Incorporation',
    serviceKey: 'OPCIncorporation',
    // price: 9999,
    defaultDiscount: 99,
    formURL: '',
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         { name: 'Business Incorporations', href: PATH_APP.app2.services.Company },
       
        { name: 'OPC Incorporation' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who Should Buy this Plan',
            content: {
                step1: {
                    title: "Entrepreneurs who wants to set-up a company with limited liability", 
                }, 
                step2: {
                    title: "Proprietorship seeking business status", 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Obtain a Digital Signature Certificate',                
                },
                step2 : {
                    title : 'Apply for DIN',
                },
                step3 : {
                    title : 'Filling of forms via SPICe+',
                },
                step4 : {
                    title : 'Part-A - Name Approval',
                },
                step5 : {
                    title : 'Part-B - Incorporation of the company',
                },
                step6 : {
                    title : 'Certificate of Incorporation',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Pan Card', 
                }, 
                step2 : {
                    title : 'Passport',
                },
                step3 : {
                    title : 'Aadhar',
                },
                step4 : {
                    title : 'Rental agreement',
                },
                step5 : {
                    title : 'Recent Electricity Bill',
                },
                step6 : {
                    title : 'Copy of property papers (if owned property)',
                },
                step7 : {
                    title : 'No objection certificate',
                },
                step8 : {
                    title : 'Memorandum of Association',
                },
                step9 : {
                    title : 'Articles of Association',
                },
                step10: {
                    title : 'Affidavit or declaration that the director is not a member of another OPC',
                },
                step11 : {
                    title : 'Digital signature certificate',
                },
                step12 : {
                    title : 'Consent to act as director in Form DIR-2',
                    
                },
                step13 : {
                    title : 'Consent to act as nominee in Form INC-3',
                    
                },

            }
        },
    ]
}

//Other Govt Registrations
export const DigitalSignature = {
    pageTitle: 'Digital Signature',
    heading: 'Digital Signature',
    serviceKey: 'DigitalSignature',
    // price: 1699,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'Digital Signature'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'What is DSC?',
            content: {
                step1: {
                    title: 'Digital Signature Certificates (DSC) are the digital equivalent (that is electronic format) of physical or paper signatures. ',
                },
                step2: {
                    title: 'A digital certificate can be presented electronically to prove one’s identity, to access information or services on the Internet or to sign certain documents digitally.'
                }
            }
        },
        {
            question: 'Who should apply for DSC?',
            content: {
                step1: {
                    title: 'Physical documents are signed manually, similarly, electronic documents, for example e-forms are required to be signed digitally using a Digital Signature Certificate.',
                },
                step2: {
                    title: 'Any person who is a director in any company or who is willing to incorporate a company should hold DSC for filing of necessary documents with ROC.'
                },
            }
        }
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Choose a certified registration authority',
                }, 
                step2 : {
                    title : 'Select the type of DSC',
                },
                step3 : {
                    title : 'Generate key pair',
                },
                step4 : {
                    title : 'Fill the application form',
                },
                step5 : {
                    title : 'Submit supporting documents',
                },
                step6 : {
                    title : 'Verification process',
                },
                step7 : {
                    title : 'Payment of fees',
                },
                step8 : {
                    title : 'Issue of DSC',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Recent utility bill',
                },
                step3 : {
                    title : 'Passport-sized photos',
                },
                step4 : {
                    title : 'Organization documents (if applicable)',
                },
                step5 : {
                    title : 'Authorization letter (if applicable)',
                },
            }
        }
    ]
}
export const TrustDetails = {
    pageTitle: 'Trust/Society',
    heading: 'Trust/Society',
    serviceKey: 'TrustDetails',
    // price: 4999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'Trust/Society'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1:{
                    title: 'FSSAI registration is mandatory compliance that ensures the safety of food products supplied or manufactured by various establishments in India.',
                },
                step2:{
                    title: 'It is a food safety certificate issued by the respective food authority of India.',
                },
                step3:{
                    title: 'This governing body responsible for food security is known as the Food Safety and Standards Authority of India (FSSAI) that are regulated under the Food Safety and Standards Act, 2006.',
                },
                step4:{
                    title: 'Obtaining an FSSAI license is mandatory before starting any food business operation in India.',
                },
                step5:{
                    title: 'All the traders, manufacturers, restaurants who are involved in the food business must obtain a 14-digit license number which is printed on their food products.'
                },
                step6:{
                    title: "The above plan doesn't include Govt liaisoning charges, additional charges shall be communicated during the process."
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1:{
                    title: 'Choosing the name of the trust',
                },
                step2:{
                    title: 'Choose the Trust`s settlers and trustees',
                },
                step3:{
                    title: 'Making Deed',
                },
                step4:{
                    title: 'Registrations of Trusts',
                },
                step5:{
                    title: 'Purchase the registrations certificate'
                },
                step6:{
                    title: "Create a bank account, a TAN and a PAN"
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Trust Deed',
                },
                step2 : {
                    title : 'ID proofs',
                },
                step3 : {
                    title : 'Photographs',
                },
                step4 : {
                    title : 'Aadhaar cards',
                },
                step5 : {
                    title : 'PAN cards',
                },
                step6 : {
                    title : 'Address proofs',
                },
                step7 : {
                    title : 'Schedule(registration application attest with a court fee INR 100)',
                },
                step8 : {
                    title : 'Declaration signed by all trustees',
                },
                step9 : {
                    title : 'With a court fee of INR 10, the settler`s attested letter of permission',
                },
                step10 : {
                    title : 'Certificate of No Objection(NOC) from the property`s owner',
                },
                step11: {
                    title : 'Signatures of the settlor on each page of the Trust Deed',
                },
            }
        }
    ]
}
export const FoodLicenseRegistration = {
    pageTitle: 'FSSAI Registration',
    heading: 'FSSAI Registration',
    serviceKey: 'FoodLicenseRegistration',
    // price: 4999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'FSSAI Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1:{
                    title: 'Food manufacturers',
                },
                step2:{
                    title: 'Food traders and retailers',
                },
                step3:{
                    title: 'Food importers and exporters',
                },
                step4:{
                    title: 'Food caterers',
                },
                step5:{
                    title: 'Food service establishments'
                },
                step6:{
                    title: 'Food storage and transport facilities'
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit FSSAI website',
                }, 
                step2 : {
                    title : 'Fill out online application',
                },
                step3 : {
                    title : 'Choose licensing authority',
                },
                step4 : {
                    title : 'Pay the applicable fee',
                },
                step5 : {
                    title : 'Application review and inspection',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Recent utility bill',
                },
                step3 : {
                    title : 'Proof of ownership or possession of premises',
                },
                step4 : {
                    title : 'Food safety management plan',
                },
                step5 : {
                    title : 'MOA or partnership deed',
                },
                step6 : {
                    title : 'List of food products',
                },
                step7 : {
                    title : 'Manufacturing or Processing unit layout plan',
                },
                step8 : {
                    title : 'Equipment and Machinery list',
                },
                step9 : {
                    title : 'Food safety management system',
                },
                step10 : {
                    title : 'Recall plan',
                },
                step11: {
                    title : 'NOC from municipality or local authority',
                },
                step12: {
                    title : 'Photographs',
                },
                
            }
        }
    ]
}
export const TradeLicense = {
    pageTitle: 'Trade License',
    heading: 'Trade License',
    serviceKey: 'TradeLicense',
    // price: 4999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'Trade License'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1:{
                    title: 'Shops and Establishments', 
                    
                },
                step2:{
                    title: 'Restaurants and Food establishments',
                },
                step3:{
                    title: 'Hotels and Lodges',
                },
                step4:{
                    title: 'Manufacturing and Industrial units',
                },
                step5:{
                    title: 'Entertainment and Recreational establishments',
                },
                step6:{
                    title: "Health and wellness establishments",
                },
                step7:{
                    title: 'Liquor and Tobacco Businesses',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Determine the licensing authority',
                }, 
                step2 : {
                    title : 'Fill the application form',
                },
                step3 : {
                    title : 'Attach the required documents',
                },
                step4 : {
                    title : 'Pay the fee',
                },
                step5 : {
                    title : 'Verification and inspection',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Recent utility bill',
                },
                step3 : {
                    title : 'Proof of ownership or possession of premises',
                },
                step4 : {
                    title : 'Business related documents',
                },
                step5 : {
                    title : 'Trade related documents',
                },
                step6 : {
                    title : 'NOC from municipality or local authority',
                },
                step7 : {
                    title : 'Health and safety clearance',
                },
                step8 : {
                    title : 'Photographs',
                },
               
            }
        }
    ]
}

export const Form15CA15CB = {
    pageTitle: 'Form 15CA and 15CB',
    heading: 'Form 15CA and 15CB',
    serviceKey: 'Form15CA15CB',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'Form 15CA and 15CB'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who should file form 15CA and 15CB?',
            content: {
                step1: {
                    title: 'Any payment made to a non-resident or a foreign company is subject to various rules and regulations.',
                },
                step2: {
                    title: 'As per provisions of Section 195 of Income tax Act,1961 any person responsible for paying money to a non-resident including foreign company shall deduct income tax for payment made to non-resident.',
                },
                step3: {
                    title: 'The remitter making payment to non-resident should furnish an undertaking in Form 15CA containing the information relating to payment of such sum along with certificate attested by Chartered Accountant in Form 15CB.',
                }  
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to finance box portal',
                }, 
                step2 : {
                    title : 'Upload the required documents',
                },
                step3 : {
                    title : 'Online filling of application',
                },
                step4 : {
                    title : 'Attestation by Chartered Accountant',
                },
                step5 : {
                    title : 'Submission of the form',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Remitter details',
                },
                step2 : {
                    title : 'Remittee details',
                },
                step3 : {
                    title : 'Amount of remittance',
                },
                step4 : {
                    title : 'Date of remittance',
                },
                step5 : {
                    title : 'Country to which remittance is made',
                },
                step6 : {
                    title : 'Bank details',
                },
                step7 : {
                    title : 'Other remittance details',
                },
            }
        }
    ]
}

export const IECRegistration = {
    pageTitle: 'IEC Registration',
    heading: 'IEC Registration',
    serviceKey: 'IECRegistration',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'IEC Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1:{
                    title:"Importers",
                },
                step2:{
                    title:" Exporters",
                },
                step3:{
                    title:'Manufacturers or Suppliers',
                },
                step4:{
                    title:"Service Providers",
                },
                step5:{
                    title:'E-commerce operators',
                } 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit DGFT website',
                }, 
                step2 : {
                    title : 'Fill out the application',
                },
                step3 : {
                    title : 'Pay the application fees',
                },
                step4 : {
                    title : 'Submit application',
                },
                step5 : {
                    title : 'Process and verification',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Aadhaar card',
                },
                step3 : {
                    title : 'Bank certificate or cancelled cheque',
                },
                step4 : {
                    title : 'Digital signature certificate',
                },
                step5 : {
                    title : 'Photograph',
                },
                step6 : {
                    title : 'MOA or Partnership deed',
                },
                step7 : {
                    title : 'Board resolution (for companies)',
                },
                step8 : {
                    title : 'GST registration certificate',
                },
                step9 : {
                    title : 'Trade related documents',
                },
            }
        }
    ]
}

export const MSMERegistration = {
    pageTitle: 'MSME Registration',
    heading: 'MSME Registration',
    serviceKey: 'MSMERegistration',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'MSME Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who can register themselves as an MSME?',
            content: {
                step1: {
                    title: 'An enterprise shall be classified as a micro, small or medium enterprise on the basis of the following criteria, namely: --',
                    substeps: [
                    'An individual or a company or any Association of Persons who wants to do international business has to get an IEC(Import Export Code).',
                    'Individuals can use either the name of their conpany or their name directly to apply for IEC',
                    'It is a 10-digit code issued by DGFT ( Director General of Foreign Trade ) which have a life time validity'
                    ]
                }  
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Access the Udyam registration portal',
                }, 
                step2 : {
                    title : 'Gather required information',
                },
                step3 : {
                    title : 'Self declaration',
                },
                step4 : {
                    title : 'Online registration',
                },
                step5 : {
                    title : 'Verification through Aadhaar OTP',
                },
                step6 : {
                    title : 'Upload the required documents',
                },
                step7 : {
                    title : 'Verification and Final submission',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Aadhar Card',
                },
                step3 : {
                    title : 'Proof of business registeation or Incorporation certificate',
                },
                step4 : {
                    title : 'Address proof of the place of business',
                },
                step5 : {
                    title : 'Identity and address proof of promoters/directors',
                },
                step6 : {
                    title : 'Bank account statement/Cancelled cheque',
                },
                step7 : {
                    title : 'Digital signature',
                },
                
            }
        }
    ]
}

export const PANRegistration = {
    pageTitle: 'PAN Registration',
    heading: 'PAN Registration',
    serviceKey: 'PANRegistration',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'PAN Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'What is a PAN card?',
            content: {
                step1: {
                    title: 'Pan Card Permanent Account Number or PAN is a means of identifying various taxpayers in the country.',
                },
                step2: {
                    title: 'PAN is a 10-digit unique identification alphanumeric number (containing both alphabets and numbers) assigned to Indians, mostly to those who pay tax.',
                },
                step3: {
                    title: 'Even the foreign citizens can apply for a PAN card using form 49AA.',
                }  
            }
        },
        {
            question: 'Who should have a PAN card?',
            content: {
                step1: {
                    title: 'All existing assessees or taxpayers or persons who are required to file a return of income, even on behalf of others, must have a PAN.',
                },
                step2: {
                    title: 'Any person, who intends to enter into economic or financial transactions where quoting PAN is mandatory, must also have a PAN.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Obtain PAN application form',
                }, 
                step2 : {
                    title : 'Fill the application',
                },
                step3 : {
                    title : 'Attach supporting documents',
                },
                step4 : {
                    title : 'Choose appropriate category',
                },
                step5 : {
                    title : 'Sign the form',
                },
                step6 : {
                    title : 'Submit the application',
                },
                step7 : {
                    title : 'Pay the application fee',
                },
                step8 : {
                    title : 'Track application status',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Form 49A',
                },
                step2 : {
                    title : 'Proof of address',
                },
                step3 : {
                    title : 'Aadhaar card',
                },
                step4 : {
                    title : 'Recent utility bill',
                },
                step5 : {
                    title : 'Proof of birth',
                },
                step6 : {
                    title : 'Passport-sized photos',
                },
                step7 : {
                    title : 'Business registration certificate (for entities)',
                },
                step8 : {
                    title : 'Additional documents (if applicable)',
                },
            }
        }
    ]
}

export const ShopsAndEstablishmentActRegistrations = {
    pageTitle: 'Shops And Establishment Registrations',
    heading: 'Shops And Establishment Registrations',
    serviceKey: 'ShopsAndEstablishmentActRegistrations',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'Shops And Establishment Registrations'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Applicability of Shops and Establishment license',
                    substeps: [
                        'Every shop and establishment needs to register itself compulsorily under this Act within 30 days of commencement of work. As this is such a basic license, many other licenses require this as proof of a commercial business.'
                    ]
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Application in Form I',
                }, 
                step2 : {
                    title : 'Submission of the application',
                },
                step3 : {
                    title : 'Payment of online fees',
                },
                step4 : {
                    title : 'Registration certificate',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Form No-I i.e Application for Registration',
                },
                step2 : {
                    title : 'Telugu Name Board Photograph of the particular shop or establishment',
                },
                step3 : {
                    title : 'List of Employees',
                },
                step4 : {
                    title : 'Copy of Rental deed or sale deed of the particular shop or establishment',
                },
                step5 : {
                    title : 'I.D. proof of the employer',
                },
                step6 : {
                    title : 'One passport size photographs of the employer',
                },
                step7 : {
                    title : 'Certificate of Incorporationt',
                },
                step8 : {
                    title : 'Memorandum of Articles in case of Companies',
                },
            }
        }
    ]
}

export const TrademarkRegistrations = {
    pageTitle: 'Trademark Registrations',
    heading: 'Trademark Registrations',
    serviceKey: 'TrademarkRegistrations',
    // price: 5999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Other Government Registrations', href: PATH_APP.app2.services.OtherGovernmentRegistrations },
        { name: 'Trademark Registrations'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'What is a Trademark?',
            content: {
                step1: {
                    title: 'A trademark is a unique symbol or word(s) used to represent a business or its products. Once registered, that same symbol or series of words cannot be used by any other organization, forever, as long as it remains in use and proper paperwork and fees are paid.',
                }, 
            }
        },
        {
            question: 'Why does someone need to apply for a trademark?',
            content: {
                step1: {
                    title: 'A trademark not only gives the trademark owner the exclusive right to use the mark but also allows the owner to prevent others from using a similar mark that can be confusing for the general public.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Conduct a trademark search',
                }, 
                step2 : {
                    title : 'Determine the jurisdiction',
                },
                step3 : {
                    title : 'Fill out the application',
                },
                step4 : {
                    title : 'Submit the application',
                },
                step5 : {
                    title : 'Registration and Certificate issuance',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Trademark application form',
                },
                step2 : {
                    title : 'Trademark representation',
                },
                step3 : {
                    title : 'PAN card',
                },
                step4 : {
                    title : 'Recent utility bill',
                },
                step5 : {
                    title : 'Power of attorney (if applicable)',
                },
                step6 : {
                    title : 'Goods or services description',
                },
                step7 : {
                    title : 'Priority claim documents',
                },
                step8 : {
                    title : 'Proof of trademark use',
                },
            }
        }
    ]
}

//Financial Advisory and Legal Services
export const DraftingOfDocuments = {
    pageTitle: 'Drafting Of Documents',
    heading: 'Drafting Of Documents',
    serviceKey: 'DraftingOfDocuments',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Financial Advisory and Legal Services', href: PATH_APP.app2.services.FinancialAdviosryAndLegalServices },
        { name: 'Drafting Of Documents'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: 'Any Individual or a business entity who would like to get any document drafted by an expert should by this plan. ',
                }, 
                step2: {
                    title: 'The documents can be anything such as partnership agreements, rental deeds, supplier agreements, franchisee agreements, co-working agrements, employee agreements etc.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Purchase the plan',
                },
                step3 : {
                    title : 'Select the agreement you want to get drafted',
                },
                step4 : {
                    title : 'Provide necessary information and documents',
                },
                step5 : {
                    title : 'Final agreement/ document copy',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'KYC documents of both the parties to contract',
                },
                step2 : {
                    title : 'Any specific clauses you want to get included',
                },
                step3 : {
                    title : 'Subject matter of the agreement',
                },
            }
        }
    ]
}

export const LegalReview = {
    pageTitle: 'Legal Review',
    heading: 'Legal Review',
    serviceKey: 'LegalReview',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Financial Advisory and Legal Services', href: PATH_APP.app2.services.FinancialAdviosryAndLegalServices },
        { name: 'Legal Review'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: 'Any Individual or a business entity who would like to get the documents reviewed by an expert who would suggest you with the inclusitivity and exclusitivity of certain terms should opt for this plan. ',
                }, 
                step2: {
                    title: 'The documents can be anything such as partnership agreements, rental deeds, supplier agreements, franchisee agreements, co-working agrements, employee agreements etc.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Purchase the plan',
                },
                step3 : {
                    title : 'Select the agreement you want to get drafted',
                },
                step4 : {
                    title : 'Review of the documents',
                },
                step5 : {
                    title : 'Final suggestions',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Agreement copy',
                },
            }
        }
    ]
}

//Loans
export const HomeLoans = {
    pageTitle: 'Home Loans',
    heading: 'Home Loans',
    serviceKey: 'HomeLoans',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Loans', href: PATH_APP.app2.services.Loans },
        { name: 'Home Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Home loan is a form of credit that helps you buy, construct, renovate and extend a residential property without breaking your savings and investments.',
                }, 
                step2: {
                    title: 'In this, the concerned property is mortgaged to a lender as security till the repayment of loan.',
                }, 
                step3: {
                    title: 'Financial institutions offer lowest possible interest rates to make home loans more affordable',
                }, 
                step4: {
                    title: 'Home loan repayment period usually extends till 30 years, giving you a benefit of lower EMIs and a greater flexibility of repayment.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Proof of identity',
                },
                step2 : {
                    title : 'Proof of residence',
                },
                step3 : {
                    title : 'Proof of age',
                },
                step4 : {
                    title : 'Proof of Income',
                },
                step5 : {
                    title : 'Property related documents',
                },
                step6 : {
                    title : 'Additional details as demanded by the lendor.',
                },
            }
        }
    ]
}

export const TermLoans = {
    pageTitle: 'Term Loans',
    heading: 'Term Loans',
    serviceKey: 'TermLoans',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Loans', href: PATH_APP.app2.services.Loans },
        { name: 'Term Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Term loan is a short-term or long-term loan approved and disbursed by any financial institution, typically repaid in instalments.',
                }, 
                step2: {
                    title: 'Term loans can be taken for various business purposes, such as for business expansion, to purchase equipment, machinery or raw materials, to manage cash flow, to meet working capital requirements, buying office or business space/land, paying-off rent and salaries, hiring new staff, debt consolidation, etc.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Passport-sized photographs',
                },
                step2 : {
                    title : 'Business Plan',
                },
                step3 : {
                    title : 'Filled application form',
                },
                step4 : {
                    title : 'KYC Documents (Identity, Address and Age Proofs) – Passport, PAN card, Aadhar Card, Voter’s ID card, Driving License, Utility Bills (Electricity, Telephone or Water), etc.',
                },
                step5 : {
                    title : 'Business address proof: Property papers, rent agreement or lease document',
                },
                step6 : {
                    title : 'Income Proof: Last 12 months’ salary slips',
                },
                step7 : {
                    title : 'CIBIL report, if required by lender (Above 750 is preffered)',
                },
                step8 : {
                    title : 'Last 12 months’ bank statement',
                },
                step9 : {
                    title : 'ITR, Sales Tax report, Profit and loss statement of last 2 years',
                },
                step10 : {
                    title : 'Any other document required by financial institution or lender',
                },
            }
        }
    ]
}

export const VehicleLoans = {
    pageTitle: 'Vehicle Loans',
    heading: 'Vehicle Loans',
    serviceKey: 'VehicleLoans',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Loans', href: PATH_APP.app2.services.Loans },
        { name: 'Vehicle Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Vehicle loans are taken on purchase of various vehicles such as Car, truck, lorries etc. and are repayable generally in instalments.',
                }, 
                step2: {
                    title: 'These loans are mostly secured i.e If you fall behind payments, your lender has the legal right to seize, or repossess, the vehicle.',
                }, 
                step3: {
                    title: 'Once the loan is paid off, the lien is lifted and the lender no longer has the right to repossess the vehicle.',
                }, 
                step4: {
                    title: "Since they're less risky for lenders, secured loans tend to carry lower interest rates than comparable unsecured loans.",
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Identity proof',
                },
                step2 : {
                    title : 'Address',
                },
                step3 : {
                    title : 'Form 16',
                },
                step4 : {
                    title : 'Salary slips',
                },
                step5 : {
                    title : 'Latest Income tax returns',
                },
                step6 : {
                    title : 'Last 6 months bank statements',
                },
            }
        }
    ]
}

export const WorkingCapitalLoans = {
    pageTitle: 'Working Capital Loans',
    heading: 'Working Capital Loans',
    serviceKey: 'WorkingCapitalLoans',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Loans', href: PATH_APP.app2.services.Loans },
        { name: 'Working Capital Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Working Capital Loan is a type of funding or credit required by several startups, enterprises or companies to manage their day-to-day business operations and to manage business cash flow. ',
                }, 
                step2: {
                    title: 'These loans are short-term loans to fulfill instant business requirements and can not be used to buy long-term assets or for investment purposes.',
                }, 
                step3: {
                    title: 'This loan is a type of business finance that is availed majorly by companies doing businesses related to manufacturing, providing services, retailing, stocking, distribution, restaurants, supermarkets, multi-brand outlets, departmental stores, etc.',
                }, 
                step4: {
                    title: "Working capital loans are largely offered to MSMEs and are not for large corporate companies. ",
                }, 
                step5: {
                    title: "The loan is intended only for Micro, Small, and Medium Enterprises for meeting their daily operational needs and ensuring they have funds for the daily operational expenditure.",
                },
                step6: {
                    title: "The majority of the working capital loans are unsecured.",
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Applicant’s and Business PAN Card',
                },
                step2 : {
                    title : 'Application form with Passport Sized Photographs',
                },
                step3 : {
                    title : 'Self-written business plan',
                },
                step4 : {
                    title : 'Identity, Age, Address and Income Proofs',
                },
                step5 : {
                    title : 'Last 3 years’ ITR and Income statement',
                },
                step6 : {
                    title : 'CMA (Credit Monitoring Arrangement) report, if business turnover exceeds ₹ 5 crore',
                },
                step7 : {
                    title : 'Last 2 years’ audit report financials',
                },
                step8 : {
                    title : 'Last 12 months’ bank statement along with last years’ P&L statement',
                },
                step9 : {
                    title : 'Partnership deed',
                },
                step10 : {
                    title : 'Certificate of registration and incorporation',
                },
                step11: {
                    title : 'Rent or Lease documents',
                },
                step12: {
                    title : 'Name of all the present directories on company letterhead',
                },
                step13: {
                    title : 'Memorandum of Association (MoA) and Articles of Association (AoA)',
                },
                step14: {
                    title : 'Any other document required by lender',
                },

            }
        }
    ]
}

export const ProfessionalTax = {
    pageTitle: 'Professional Tax',
    heading: 'Professional Tax',
    serviceKey: 'ProfessionalTax',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Registration', href: PATH_APP.app2.PayrollCompliances.Registration },
        // { name: 'Payment and Returns', href: PATH_APP.app2.PayrollCompliances.PaymentAndReturn},
        { name: 'Provident Fund'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Business Owners and Proprietors',
                },
                step2: {
                    title: 'Self employed professionals',
                },
               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Obtain professional tax enrollment certificate',
                }, 
                step2 : {
                    title : 'Determine tax liability',
                },
                step3 : {
                    title : 'Deduct tax from employee`s salary',
                },
                step4 : {
                    title : 'Generate challan for tax payment',
                },
                step5 : {
                    title : 'Make tax payment',
                },
                step6 : {
                    title : 'File Professional tax return',
                },
                step7 : {
                    title : 'Retain records',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Professional tax registration certificate',
                },
                step2 : {
                    title : 'Challans for tax payment',
                },
                step3 : {
                    title : 'Employee details',
                },
                step4 : {
                    title : 'Salary/wage register',
                },
                step5 : {
                    title : 'Monthly/Quarterly returns',
                },
                step6 : {
                    title : 'Proof of Tax payment',
                },
                step7 : {
                    title : 'Professional tax assessment order',
                },
            }
        }
    ]
}


export const LabourLicense = {
    pageTitle: 'Labour license (Shops and Establishment Act)',
    heading: 'Labour license (Shops and Establishment Act)',
    serviceKey: 'LabourLicense',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Registration', href: PATH_APP.app2.PayrollCompliances.Registration },        
        { name: 'Labour License'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Any company/ factory/ establishment having more than 10 employees mandatorily need to get regsitered under ESI Act, 1948. In some states, the number of employees is 20.',
                },
                step2: {
                    title: 'Employee State Insurance or ESI is a scheme commenced by the Government of India to offer medical, monetary and other advantages to workers. ESI is managed by an autonomous authority – Employee State Insurance Corporation – which lies under the jurisdiction of the Ministry of Labour and Employment.',
                },
                step3: {
                    title: 'he ESI Scheme is a self financing scheme. The ESI funds are primarily built out of contribution from employers and employees payable monthly at a fixed percentage of wages. The State Government concerned also contributes its share to meet the cost of Medical Benefit.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Determine the applicable labor laws',
                }, 
                step2 : {
                    title : 'Identify the appropriate authority',
                },
                step3 : {
                    title : 'Obtain Application form',
                },
                step4 : {
                    title : 'Gather required documents',
                },
                step5 : {
                    title : 'Fill out the application form and attach supporting documents',
                },
                step6 : {
                    title : 'Submit the application',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'ID proof of employer',
                },
                step2 : {
                    title : 'Photograph of the employer',
                },
                step3 : {
                    title : 'Proof of business registration',
                },
                step4 : {
                    title : 'Proof of address of the business premises',
                },
                step5 : {
                    title : 'Details of the nature of work, number of employees and machinery/equipment used',
                },
                step6 : {
                    title : 'Any other documents specified by the labor department',
                },
                
            }
        }
    ]
}


//Payroll Compliances
export const ProvidentFund = {
    pageTitle: 'Provident Fund',
    heading: 'Provident Fund',
    serviceKey: 'ProvidentFund',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Registration', href: PATH_APP.app2.PayrollCompliances.Registration },
        // { name: 'Payment and Returns', href: PATH_APP.app2.PayrollCompliances.PaymentAndReturn },
        { name: 'Provident Fund'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Entities registered under PF Act',
                },
                step2: {
                    title: 'Employers with covered Employees',
                },
                
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'PF contribution calculation',
                }, 
                step2 : {
                    title : 'Generate Challan',
                },
                step3 : {
                    title : 'Make payment',
                },
                step4 : {
                    title : 'Filing monthly returns',
                },
                step5 : {
                    title : 'Submit return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Employee Details',
                },
                step2 : {
                    title : 'Employer Details',
                },
                step3 : {
                    title : 'Monthly PF contribution details',
                },
                step4 : {
                    title : 'Challans and Payment receipts',
                },
                step5 : {
                    title : 'PF return forms',
                },
                step6 : {
                    title : 'Bank account details',
                },
               
            }
        }
    ]
}
export const ESIRegistration = {
    pageTitle: 'ESI Registration',
    heading: 'ESI Registration',
    serviceKey: 'ESIRegistration',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Registration', href: PATH_APP.app2.PayrollCompliances.Registration },
        { name: 'ESI Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Any company/ factory/ establishment having more than 10 employees mandatorily need to get regsitered under ESI Act, 1948. In some states, the number of employees is 20.',
                },
                step2: {
                    title: 'Employee State Insurance or ESI is a scheme commenced by the Government of India to offer medical, monetary and other advantages to workers. ESI is managed by an autonomous authority – Employee State Insurance Corporation – which lies under the jurisdiction of the Ministry of Labour and Employment.',
                },
                step3: {
                    title: 'he ESI Scheme is a self financing scheme. The ESI funds are primarily built out of contribution from employers and employees payable monthly at a fixed percentage of wages. The State Government concerned also contributes its share to meet the cost of Medical Benefit.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Go to Portal',
                }, 
                step2 : {
                    title : 'Confirmation mail',
                },
                step3 : {
                    title : 'Fill employee Registration form -1',
                },
                step4 : {
                    title : 'Payment for the registration ',
                },
                step5 : {
                    title : 'Registration Letter',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Registration certificate under shop and establishment act license or Factories Act',
                },
                step2 : {
                    title : 'Company Registration certificate',
                },
                step3 : {
                    title : 'Partnership deed (Patnership firm)',
                },
                step4 : {
                    title : 'GST certificate ',
                },
                step5 : {
                    title : 'MOA & AOA',
                },
                step6 : {
                    title : 'Address proof',
                },
                step7 : {
                    title : 'List of all employees working in the establishment',
                },
                step8 : {
                    title : 'PAN card of the business establishment as well as all the employees in the establishment',
                },
                step9 : {
                    title : 'Compensation details of all the employees',
                },
                step10 : {
                    title : 'Cancelled cheque of the bank account of the company',
                },
                step11 : {
                    title : 'List of directors of the company',
                },
                step12 : {
                    title : 'List of the shareholders of the company',
                },
                step13 : {
                    title : 'Register of Employee attendance',
                },
            }
        }
    ]
}

export const ESIReturnFiling= {
    pageTitle: 'ESI Return Filing',
    heading: 'ESI Return Filing',
    serviceKey: 'ESIReturnFiling',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Payment and Returns', href: PATH_APP.app2.PayrollCompliances.PaymentAndReturn },
        { name: 'ESI Return Filing'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Entities registered under ESI',
                   
                }, 
                step2: {
                    title: 'Employers who have employees covered under ESI scheme',
                    
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Go to official website',
                }, 
                step2 : {
                    title : 'Find the complete details',
                },
                step3 : {
                    title : 'Modify if in case any and Verify the complete details',
                },
                step4 : {
                    title : 'Create a miscellaneous challan if there are any payments to be made on omitted wages which is not related to identified worked',
                },
                step5 : {
                    title : 'Self certification',
                },
                step6 : {
                    title : 'Upload chartered accountant certificate if no.of employee>=40',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Attendance Register',
                },
                step2 : {
                    title : 'Registration of Form-6',
                },
                step3 : {
                    title : 'Registration of wages',
                },
                step4 : {
                    title : 'Inspection book',
                },
                step5 : {
                    title : 'Register of any accidents on the premises',
                },
                step6 : {
                    title : 'Monthly challans and returns submitted for ESI',
                },
            }
        }
    ]
}
// export const PayrollProcessing= {
//     pageTitle: 'Payroll Processing',
//     // pageTitle:'Payroll Preparation',
//     // heading: 'Payroll Preparation',
//     heading: 'Payroll Processing',
//     serviceKey: 'PayrollProcessing',
//     // price: 99,
//     defaultDiscount: 0,
//     breadCrumbs : [
//         { name: 'Services', href: PATH_APP.app2.services.root },
         
//         { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
//         { name: 'Payroll Processing'}
//         // {name: 'Payroll Preparation'}
//     ],
//     tabTitles: ['Plan','Process','Documents and Information Required'],
//     planPanel: [
//         {
//             content: {
//                 step1: {
//                     title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
//                 }, 
//                 step2: {
//                     title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
//                 },
//             }
//         },
//     ],
//     tabPanels: [
//         {
//             panelTitle: 'Process',
//             panelContent: {
//                 step1 : {
//                     title : 'Login to Finance Box portal',
//                 }, 
//                 step2 : {
//                     title : 'subscribe for payroll processing service',
//                 },
//                 step3 : {
//                     title : 'Fill in the required details',
//                 },
//                 step4 : {
//                     title : 'Verification of information by Finance Box team',
//                 },
//                 step5 : {
//                     title : 'Sharing of payroll calculations',
//                 },
//                 step6 : {
//                     title : 'Payroll processing',
//                 },
//             }
//         },
//         {
//             panelTitle: 'Documents',
//             panelContent: {
//                 step1 : {
//                     title : 'Attendance details',
//                 },
//                 step2 : {
//                     title : 'Employee agreements',
//                 },
//                 step3 : {
//                     title : 'Bank account details of all the employees',
//                 },
//                 step4 : {
//                     title : 'Employee payroll details',
//                 },
//             }
//         }
//     ]
// }
export const Gratuity= {
    
    pageTitle:'Gratuity',
    heading: 'Gratuity',
    serviceKey: 'Gratuity',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Compliance',  href: PATH_APP.app2.PayrollCompliances.Compliance },
        {name: 'Gratuity'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Determine applicability',
                }, 
                step2 : {
                    title : 'Calculate gratuity amount',
                },
                step3 : {
                    title : 'Notify employees',
                },
                step4 : {
                    title : 'Maintain proper records',
                },
                step5 : {
                    title : 'Establish gratuity fund',
                },
                step6 : {
                    title : 'Process payment',
                },
                step7 : {
                    title : 'Issue payment and documentation',
                },
                step8 : {
                    title : 'Maintain compliance records',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Employee joining and exit documents',   
                },
                step2 : {
                    title : 'Attendance and leave records',
                },
                step3 : {
                    title : 'Salary records',
                },
                step4 : {
                    title : 'Proof of payment of gratuity',
                },
                step5 : {
                    title : 'Employee personal details',
                },
                step6 : {
                    title : 'Nomination forms',
                },
                step7 : {
                    title : 'Proof of termination or retirement',
                },
            }
        }
    ]
}

export const Bonus= {
    
    pageTitle:'Bonus',
    heading: 'Bonus',
    serviceKey: 'Bonus',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Compliance',  href: PATH_APP.app2.PayrollCompliances.Compliance },
        {name: 'Bonus'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Any organization',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Determine applicable bonus laws',
                }, 
                step2 : {
                    title : 'Define bonus policy',
                },
                step3 : {
                    title : 'Identify bonus period',
                },
                step4 : {
                    title : 'Calculate eligibility',
                },
                step5 : {
                    title : 'Calculate bonus amount',
                },
                step6 : {
                    title : 'Obtain management approval',
                },
                step7 : {
                    title : 'Communicate bonus information',
                },
                step8 : {
                    title : 'Process payroll',
                },
                step9 : {
                    title : 'Maintain documentation',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Attendance and leave records',
                },
                step2 : {
                    title : 'Payroll records',
                },
                step3 : {
                    title : 'Employee master data',
                },
                step4 : {
                    title : 'Bonus calculation sheet',
                },
                step5 : {
                    title : 'Bonus payment register',
                },
                step6 : {
                    title : 'Bonus payment vouchers or Bank transfer records',
                },
            }
        }
    ]
}

export const POSH= {
    
    pageTitle:'POSH',
    heading: 'POSH',
    serviceKey: 'POSH',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Compliance',  href: PATH_APP.app2.PayrollCompliances.Compliance },
        {name: 'POSH'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Every organization with 10 or more employees',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Develop a POSH policy',
                }, 
                step2 : {
                    title : 'Establish an Internal complaints committee',
                },
                step3 : {
                    title : 'Communicate the policy',
                },
                step4 : {
                    title : 'Establish a complaint redressal system',
                },
                step5 : {
                    title : 'Investigate and address complaints',
                },
                step6 : {
                    title : 'Conduct regular awareness and training programs',
                },
                step7 : {
                    title : 'Maintain records',
                },
                step8 : {
                    title : 'Submit Annual reports',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'POSH policy',
                    
                    
                    
                    
                    
                    
                    
                },
                step2 : {
                    title : 'Internal Complaints Committee constitution',
                },
                step3 : {
                    title : 'POSH compliant form',
                },
                step4 : {
                    title : 'POSH compliant register',
                },
                step5 : {
                    title : 'POSH awareness training material',
                },
                step6 : {
                    title : 'Noticeboard display',
                },
                step7 : {
                    title : 'Annual POSH report',
                },
                step8 : {
                    title : 'Support resources',
                },
            }
        }
    ]
}
export const PayrollPreparation= {
    
    pageTitle:'Payroll Preparation',
    heading: 'Payroll Preparation',
    serviceKey: 'PayrollPreparation',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Payroll', href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice},
        {name: 'Payroll Preparation'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Establish employer identification number',
                }, 
                step2 : {
                    title : 'Collect relevant employee tax information',
                },
                step3 : {
                    title : 'Choose a payroll schedule',
                },
                step4 : {
                    title : 'Calculate gross pay',
                },
                step5 : {
                    title : 'Determine each employee`s deductions',
                },
                step6 : {
                    title : 'Calculate net pay, and pay',
                },
                step7 : {
                    title : 'Keep payroll records, and make any necessary corrections',
                },
                step8 : {
                    title : 'Be mindful of ongoing considerations',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Employer Identification Number',
                },
                step2 : {
                    title : 'State/Local Tax ID Number',
                },
                step3 : {
                    title : 'State Unemployment ID Number',
                },
                step4 : {
                    title : 'Employee Addresses and SSNs',
                },
                step5 : {
                    title : 'I-9',
                },
                step6 : {
                    title : 'W-4',
                },
                step7 : {
                    title : 'State withholding allowance certifiacate',
                },
                step8 : {
                    title : 'Department of Labor(DOL) Records',
                },
                step9 : {
                    title : 'Worker`s Compensation ID Number',
                },
                step10 : {
                    title : 'Publication 15',
                },
            }
        }
    ]
}

export const Form16Generation= {
    
    pageTitle:'Form 16 Generation',
    heading: 'Form 16 Generation',
    serviceKey: 'From16Generation',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Payroll', href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice},
        {name: 'From 16 Generation'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Go to the respective website',
                }, 
                step2 : {
                    title : 'Check the status of your TDS return',
                },
                step3 : {
                    title : 'Download Form 16',
                },
                step4 : {
                    title : 'Attach the required documents',
                },
                step5 : {
                    title : 'Fill the KYC details',
                },
                step6 : {
                    title : 'Download Form -16A',
                },
                step6 : {
                    title : 'Fill the details',
                },
                step6 : {
                    title : 'Upload the both the forms',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'TDS certificates',
                },
                step2 : {
                    title : 'Interest income and other certificates',
                },
                step3 : {
                    title : 'Annual information statement',
                },
                step4 : {
                    title : 'Form 26AS',
                },
                step5 : {
                    title : 'Tax-saving investment, expenditure proof',
                },
                step6 : {
                    title : 'Capital gains from sale of property, shares, mutual funds',
                },
                step7 : {
                    title : 'Aadhar card',
                },
                step8 : {
                    title : 'Details of investment in unlisted shares',
                },
                step9 : {
                    title : 'Bank account details',
                },
            }
        }
    ]
}

export const PayrollGeneration= {
    
    pageTitle:'Payroll Generation',
    heading: 'Payroll Generation',
    serviceKey: 'PayrollGeneration',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Payroll', href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice},
        {name: 'Payroll Generation'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Filling the basic details about the company',
                }, 
                step2 : {
                    title : 'Employee`s details',
                },
                step3 : {
                    title : 'Income details',
                },
                step4 : {
                    title : 'Generate payslip to download and preview it',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Aadhar Card',
                },
                step3 : {
                    title : 'Bank Account Number',
                },
                step4 : {
                    title : 'EPF Account Number, UAN',
                },
                step5 : {
                    title : 'Total working days, Effective work days, Number of leaves',
                },
                step6 : {
                    title : 'List of Earnings and Deductions',
                },
                step7 : {
                    title : 'Gross Pay and Net pay in numbers as well as words',
                },
            }
        }
    ]
}

export const EmployeeAgreements= {
    
    pageTitle:'Employee Agreements',
    heading: 'Employee Agreements',
    serviceKey: 'EmployeeAgreements',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Payroll', href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice},
        {name: 'Employee Agreements'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the portal',
                }, 
                step2 : {
                    title : 'Draft all the employee details by the Lawyer',
                },
                step3 : {
                    title : 'Approval and signing of the both employer and employee',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Employee application',
                },
                step2 : {
                    title : 'Employee contract',
                },
                step3 : {
                    title : 'Independent Contractor Agreement',
                },
                step4 : {
                    title : 'Employee handbook',
                },
                step5 : {
                    title : 'Non-disclosure Agreement',
                },
                step6 : {
                    title : 'Federal and State ID and Tax Forms',
                },
                
            }
        }
    ]
}
export const SalaryStructure= {
    
    pageTitle:'Salary Structure',
    heading: 'Salary Structure',
    serviceKey: 'SalaryStructure',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Payroll', href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice},
        {name: 'Salary Structure'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'subscribe for payroll processing service',
                },
                step3 : {
                    title : 'Fill in the required details',
                },
                step4 : {
                    title : 'Verification of information by Finance Box team',
                },
                step5 : {
                    title : 'Sharing of payroll calculations',
                },
                step6 : {
                    title : 'Payroll processing',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Attendance details',
                },
                step2 : {
                    title : 'Employee agreements',
                },
                step3 : {
                    title : 'Bank account details of all the employees',
                },
                step4 : {
                    title : 'Employee payroll details',
                },
            }
        }
    ]
}


export const ESOPStructure= {
    
    pageTitle:'ESOP Structure',
    heading: 'ESOP Structure',
    serviceKey: 'ESOPStructure',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        {name: 'Payroll', href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice},
        {name: 'ESOPStructure'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Processing payroll means compensating employees for their work. It involves calculating total wage earnings,  statutory deductions, filing payroll taxes and delivering payment.',
                }, 
                step2: {
                    title: 'These steps can be accomplished manually, but an automated process is usually more accurate and efficient and may help you comply with various payroll regulations.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Prepare the list of eligible employees for ESOP',
                }, 
                step2 : {
                    title : 'Draft ESOP policy and include the quantum of ESOP pool',
                },
                step3 : {
                    title : 'Approval of board for the list of employees',
                },
                step4 : {
                    title : 'Approval of General meeting members for the list of employees',
                },
                step5 : {
                    title : 'Fill the form MGT-14',
                },
                step6 : {
                    title : 'Send a grant letter to all eligible shareholders',
                },
                step7 : {
                    title : '1 year time gap between granting of option and vesting of option',
                },
                step8 : {
                    title : 'After 1 year, employees can apply for shares',
                },
                step9 : {
                    title : 'Fill PAS-3(if shareholders apply for the Shares)',
                },
                step10 : {
                    title : 'Company should issue share certificate and pay stamp duty',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Minutes of the board meeting',
                },
                step2 : {
                    title : 'Minutes of the general meeting',
                },
                step3 : {
                    title : 'Special resolution approving ESOP with an explanatory statement',
                },
                step4 : {
                    title : 'Boards report',
                },
                step5 : {
                    title : 'Register of the ESOP',
                },
                step6 : {
                    title : 'MGT-14 and PAS-3',
                },
            }
        }
    ]
}

export const PFRegistration= {
    pageTitle: 'PF Registration',
    heading: 'PF Registration',
    serviceKey: 'PFRegistration',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'PF', href: PATH_APP.app2.services.PF },
        { name: 'PF Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Employees Provident Fund & Miscellaneous Provisions Act, 1952 is applicable to',
                    substeps: [
                        'Every factory engaged in any industry specified in Schedule 1 in which 20 or more persons are employed',
                        ' Every other establishment employing 20 or more persons or class of such establishments which the Central Govt. may notify;',
                        'Any other establishment so notified by the Central Government even if employing less than 20 persons.'
                    ]
                }, 
                step2: {
                    title: 'Every employee, including the one employed through a contractor (but excluding an apprentice engaged under the Apprentices Act or under the standing orders of the establishment and casual labourers), who is in receipt of wages up to Rs.6,500 p.m., shall be eligible for becoming a member of the funds. The condition of three months’ continuous service or 60 days of actual work, for membership of the scheme.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall validate the information and documents provided',
                },
                step4 : {
                    title : 'Submission of the application',
                },
                step5 : {
                    title : 'Generation of registration acknowledgement',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Name of the company',
                },
                step2 : {
                    title : 'Date of setup of the company',
                },
                step3 : {
                    title : 'Scan copy of company’s PAN (Proprietor’s, in case of proprietorship concern)',
                },
                step4 : {
                    title : 'Scan copy of Licences available in name of the company. (like Service Tax, VAT etc)',
                },
                step5 : {
                    title : "Scan copy cheque of company's bank A/c",
                },
                step6 : {
                    title : 'Address of the company with address proof',
                },
                step7 : {
                    title : 'List of Directors/Partners',
                },
                step8 : {
                    title : 'Address proof of Proprietor/Director/Partner',
                },
                step9 : {
                    title : 'Email address, Mobile Number of Proprietor/Director/Partner',
                },
                step10 : {
                    title : 'Copy of First Sale Bill/Job Work Bill and first Purchase Bill',
                },
                step11: {
                    title : 'Monthly strength of employees from Date of setup',
                },
                step12: {
                    title : 'Current List of employees with details:',
                    substeps: [
                        'Name',
                        "Father's Name",
                        'Date of Joining',
                        'Date of Birth',
                        'Mobile Number',
                        'Postal Address',
                        'Name of Nominee‎',
                        'Grade (Unskilled, Semi, Skilled)',
                        'Salary',
                        'Designation',
                        'ID proof (Aadhar and PAN)',
                        'Bank A/c number with IFS Code',
                        'Digital Signature of Proprietor/Director/Partner'
                    ]
                },
            }
        }
    ]
}

export const PFFiling= {
    pageTitle: 'PF Filing',
    heading: 'PF Filing',
    serviceKey: 'PFFiling',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'PF', href: PATH_APP.app2.services.PF },
        { name: 'PF Filing'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Employees Provident Fund & Miscellaneous Provisions Act, 1952 is applicable to',
                    substeps: [
                        'Every factory engaged in any industry specified in Schedule 1 in which 20 or more persons are employed',
                        ' Every other establishment employing 20 or more persons or class of such establishments which the Central Govt. may notify;',
                        'Any other establishment so notified by the Central Government even if employing less than 20 persons.'
                    ]
                }, 
                step2: {
                    title: 'Every employee, including the one employed through a contractor (but excluding an apprentice engaged under the Apprentices Act or under the standing orders of the establishment and casual labourers), who is in receipt of wages up to Rs.6,500 p.m., shall be eligible for becoming a member of the funds. The condition of three months’ continuous service or 60 days of actual work, for membership of the scheme.',
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall validate the information and documents provided',
                },
                step4 : {
                    title : 'Client is sent the calculations',
                },
                step5 : {
                    title : 'Payment of the PF contribution amount',
                },
                step6 : {
                    title : 'Filing of return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Employee wise payroll calculations',
                },
                step2 : {
                    title : 'PF logins',
                },
            }
        }
    ]
}

export const PTReturnsFilings= {
    pageTitle: 'PT Return Filing',
    heading: 'PT Return Filing',
    serviceKey: 'PTReturnsFilings',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Professional Tax', href: PATH_APP.app2.services.ProfessionalTax },
        { name: 'PT Return Filing'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Every entity having PT registration should file its PT returns on a  monthly basis on/before 10th of every month.', 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Go to the official website',
                }, 
                step2 : {
                    title : 'Download PTRC return template',
                },
                step3 : {
                    title : 'Download form 3B',
                },
                step4 : {
                    title : 'Select of period of return and upload the form',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Form 3B',
                },
                step2 : {
                    title : 'Employee Salary sheet',
                },
                step3 : {
                    title : 'Employee complete details',
                },
                
            }
        }
    ]
}

export const PTRegistrations= {
    pageTitle: 'PT Registration',
    heading: 'PT Registration',
    serviceKey: 'PTRegistrations',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
        { name: 'Professional Tax', href: PATH_APP.app2.services.ProfessionalTax },
        { name: 'PT Registration'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Professional tax is a direct tax that applies to individuals earning an income by way of employment, practising their profession or trading. A practising professional includes a lawyer, teacher, doctor, chartered accountant, etc.', 
                }, 
                step2: {
                    title: "The tax is deducted from the individual’s monthly salary by their employer and is deposited to the state governments. Professionals apart from salaried employees pay it directly to the government.", 
                },
                step3: {
                    title: "Such professionals can claim a tax deduction for the PT paid during the financial year as per the Income Tax Act 1961.", 
                },
                step4: {
                    title: "Applicability of PT", 
                    substeps: [
                        'Up to Rs. 15,000 (Income Tax Slab): Nil(Tax Amount)',
                        'Between Rs.15,001 to Rs.20,000 (Income Tax Slab): Rs. 150 (Tax Amount)',
                        'Rs.20,001 onwards (Income Tax Slab): Rs.200 (Tax Amount)'
                    ]
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Go to the official website',
                }, 
                step2 : {
                    title : 'Validate PAN',
                },
                step3 : {
                    title : 'Act Registration',
                },
                step4 : {
                    title : 'Fill the application form',
                },
                step5 : {
                    title : 'Upload the documents',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Pan of the assessee',
                },
                step2 : {
                    title : 'Aadhar card',
                },
                step3 : {
                    title : 'Cancelled Cheque',
                },
                step4 : {
                    title : 'Photograph',
                },
                step5 : {
                    title : 'Address proofs',
                },
                step6 : {
                    title : 'Photo signature',
                },
                step7 : {
                    title : 'Details of business',
                },
                step8 : {
                    title : 'No of employees',
                },
                step9 : {
                    title : 'Date of Commencement of business',
                },
                step10 : {
                    title : 'Email ID and Mobile number of the assessee',
                },
            }
        }
    ]
}

//ROC Compliances

export const DirectorReport= {
    pageTitle: 'Director Report',
    heading: 'Director Report',
    serviceKey: 'DirectorReport',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Director Report'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Private Company',
                }, 
                step2: {
                    title: 'Public Company',
                },               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Check eligibility',
                }, 
                step2 : {
                    title : 'Obtain consent',
                },
                step3 : {
                    title : 'Director identification number',
                },
                step4 : {
                    title : 'Conduct Board meeting',
                },
                step5 : {
                    title : 'Pass the Board resolution',
                },
                step6 : {
                    title : 'File required forms such as form DIR-12',
                },
                step7 : {
                    title : 'Update Director`s details',
                },
                step8 : {
                    title : 'Intimate ROC',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Director`s content',
                },
                step2 : {
                    title : 'Director Identification number',
                },
                step3 : {
                    title : 'Address Proof',
                },
                step4 : {
                    title : 'PAN card',
                },
                step5 : {
                    title : 'Passport size photographs',
                },
                step6 : {
                    title : 'Declaration of disqualification',
                },
                step7 : {
                    title : 'Director`s report',
                },
            }
        }
    ]
}
export const DirectorAppointment= {
    pageTitle: 'Director Appointment',
    heading: 'Director Appointment',
    serviceKey: 'DirectorAppointment',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Director Appointment'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Private Company',
                }, 
                step2: {
                    title: 'Public Company',
                },               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Check eligibility',
                }, 
                step2 : {
                    title : 'Obtain consent',
                },
                step3 : {
                    title : 'Director identification number',
                },
                step4 : {
                    title : 'Conduct Board meeting',
                },
                step5 : {
                    title : 'Pass the Board resolution',
                },
                step6 : {
                    title : 'File required forms such as form DIR-12',
                },
                step7 : {
                    title : 'Update Director`s details',
                },
                step8 : {
                    title : 'Intimate ROC',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Director`s content',
                },
                step2 : {
                    title : 'Director Identification number',
                },
                step3 : {
                    title : 'Address Proof',
                },
                step4 : {
                    title : 'PAN card',
                },
                step5 : {
                    title : 'Passport size photographs',
                },
                step6 : {
                    title : 'Declaration of disqualification',
                },
                step7 : {
                    title : 'Director`s report',
                },
            }
        }
    ]
}

export const DirectorRemoval= {
    pageTitle: 'Director Removal',
    heading: 'Director Removal',
    serviceKey: 'DirectorRemoval',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Director Removal'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Director`s Term is expired',                    
                }, 
                step2: {
                    title: "On court order", 
                },
                step3: {
                    title: "By Shareholder Resolution", 
                },
                step4: {
                    title: 'By breach of statutory or legal obligations', 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Review the company`s AOA',
                }, 
                step2 : {
                    title : 'Board meeting',
                },
                step3 : {
                    title : 'Board resolution',
                },
                step4 : {
                    title : 'Shareholders approval',
                },
                step5 : {
                    title : 'Filing with ROC',
                },
                step6 : {
                    title : 'Update company records',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Notice of the meeting',
                },
                step2 : {
                    title : 'Special resolution',
                },
                step3 : {
                    title : 'Written consent',
                },
                step4 : {
                    title : 'Board resolution',
                },
                step5 : {
                    title : 'Form DIR-12',
                },
            }
        }
    ]
}
export const SpecialAudits =
{ pageTitle: 'Special Audits',
heading: 'Special Audits',
serviceKey: 'SpecialAudits',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'Special Audits'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Companies suspected of fraud or investigation', 
            }, 
          
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Understanding the organization`s environment',
            }, 
            step2 : {
                title : 'Conduct preliminary assessment',
            },
            step3 : {
                title : 'Evaluation of Controls',
            },
            step4 : {
                title : 'Findings and Recommendations',
            },
            step5 : {
                title : 'Reporting',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Copies of Compliants or Allegations',
            },
            step2 : {
                title : 'Internal control documentation',
            },
            step3 : {
                title : 'Financial Statements and records',
            },
            step4 : {
                title : 'Bank Statements and Transaction records',
            },
            step5 : {
                title : 'Contracts and Agreements',
            },
            step6 : {
                title : 'Invoices and Receipts',
            },
            step7 : {
                title : 'Communication records and Correspondence',
            },
            step8 : {
                title : 'Employee and Personnel records',
            },
            step9 : {
                title : 'Internal Investigation reports',
            },
            step10 : {
                title : 'IT system logs and Audit trails',
            },
            step11 : {
                title : 'Evidence collection documentation',
            },
            step12 : {
                title : 'Other relevant documents',
            },
        }
    }
]
}

export const CertificationServices =
{ pageTitle: 'Certification Services',
heading: 'Certification Services',
serviceKey: 'CertificationServices',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'CertificationServices'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'On Voluntary basis', 
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Application',
            }, 
            step2 : {
                title : 'Document review',
            },
            step3 : {
                title : 'Pre Audit (optional)',
            },
            step4 : {
                title : 'Stage 1 audit : Initial on site audit',
            },
            step5 : {
                title : 'Stage 2 audit : Evaluates organization`s compliancet',
            },
            step6 : {
                title : 'Non conformity management : Organization is given opportunity to address these non conformities',
            },
            step7 : {
                title : 'Corrective actions and follow-up',
            },
            step8 : {
                title : ' Certification decision',
            },
            step9 : {
                title : 'Surveillance audit',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Application forms',
            },
            step2 : {
                title : 'Quality manual',
            },
            step3 : {
                title : 'Standard-specific documents',
            },
            step4 : {
                title : 'Policies and Procedures documents',
            },
            step1 : {
                title : 'Records and Evidence',
            },
            step2 : {
                title : 'Internal Audit reports',
            },
            step3 : {
                title : 'Corrective Action reports',
            },
            step4 : {
                title : 'Training and Competence records',
            },
            step5 : {
                title : 'Supplier and Vendor documentation',
            },
            step6 : {
                title : 'Customer feedback and satisfaction records',
            },
            step7 : {
                title : 'Calibration and measurement records',
            },
            step8 : {
                title : 'Management review documentation',
            },
        }
    }
]
}

export const AgreedUponProcedures =
{ pageTitle: 'Agreed Upon Procedures',
heading: 'Agreed Upon Procedures',
serviceKey: 'AgreedUponProcedures',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'Agreed Upon Procedures'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'On Voluntary basis', 
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Engagement Planning',
            }, 
            step2 : {
                title : 'Designing of procedures',
            },
            step3 : {
                title : 'Data collection',
            },
            step4 : {
                title : 'Procedures execution',
            },
            step5 : {
                title : 'Data Analysis and Evaluation',
            },
            step6 : {
                title : 'Engagement Planning',
            }, 
            step7 : {
                title : 'Reporting',
            },
            step8 : {
                title : 'Distribution of the report',
            },
            step9 : {
                title : 'Comminication with the engaging parties',
            },
            
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Engagement letter',
            },
            step2 : {
                title : 'Procedures documents',
            },
            step3 : {
                title : 'Subject matter information',
            },
            step4 : {
                title : 'Supporting documents for procedures performed',
            },
            step5 : {
                title : 'Test results and analysis',
            },
            step6 : {
                title : 'Workpapers',
            },
            step7 : {
                title : 'Findings report',
            },
            step8 : {
                title : 'Documentation of communication with management',
            },
            step9 : {
                title : 'Management representation letter',
            },
            step10 : {
                title : 'Review and Approval documentation',
            },
        }
    }
]
}
export const SystemsAudit =
{ pageTitle: 'Systems Audit',
heading: 'Systems Audit',
serviceKey: 'SystemsAudit',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'SystemsAudit'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Financial Institutions', 
            }, 
            step2: {
                title: "Healthcare organizations", 
            },
            step3: {
                title: "Technology and IT companies", 
            },
            step4: {
                title: "Government agencies", 
            },
            step5: {
                title: "Utilities and Critical infrastructure", 
            },
            step6: {
                title: "Defense and National security", 
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Understanding the organization`s environment',
            }, 
            step2 : {
                title : 'Conduct preliminary assessment',
            },
            step3 : {
                title : 'Continuing with the field work',
            },
            step4 : {
                title : 'Evaluation of Controls',
            },
            step5 : {
                title : 'Assessing Security',
            },
            step6 : {
                title : 'Analyzing Data analysis',
            },
            step7 : {
                title : 'Findings and Recommendations',
            },
            step8 : {
                title : 'Reporting',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'IT Policies and Procedures',
            },
            step2 : {
                title : 'System Documentation',
            },
            step3 : {
                title : 'IT Asset Inventory',
            },
            step4 : {
                title : 'User access and privileges',
            },
            step5 : {
                title : 'Security Controls Documentation',
            },
            step6 : {
                title : 'Data Backup and Recovery procedures',
            },
            step7 : {
                title : 'Change Management documentation',
            },
            step8 : {
                title : 'Incident Response documentation',
            },
            step9 : {
                title : 'System Testing and Validation Documentation',
            },
            step10 : {
                title : 'IT Vendor and Service provider contracts',
            },
            step11 : {
                title : 'IT Incident and Problem management logs',
            },
            step12 : {
                title : 'UCompliance documentation',
            },
        }
    }
]
}

export const ManagementAudit =
{ pageTitle: 'Management Audit',
heading: 'Management Audit',
serviceKey: 'ManagementAudit',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'ManagementAudit'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Large Corporations', 
            }, 
            step2: {
                title: "Government owned enterprises", 
            },
            step3: {
                title: "Non profit organizations", 
            },
            step4: {
                title: "Family-owned businesses", 
            },
            step5: {
                title: "Companies going through restructuring or turnaround startegies", 
            },
            step6: {
                title: "Companies involved in mergers and acquisitions", 
            },
            step7: {
                title: "On Voluntary basis", 
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Understanding the organization`s environment',
            }, 
            step2 : {
                title : 'Conduct preliminary assessment',
            },
            step3 : {
                title : 'Evaluation of Controls',
            },
            step4 : {
                title : 'Findings and Recommendations',
            },
            step5 : {
                title : 'Reporting',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Policies and Procedures',
            },
            step2 : {
                title : 'Strategic plans',
            },
            step3 : {
                title : 'Performance measurement and reporting',
            },
            step4 : {
                title : 'Financial Statements and reports',
            },
            step5 : {
                title : 'Budgets and Forecasts',
            },
            step6 : {
                title : 'Management meeting minutes',
            },
            step7 : {
                title : 'HR policies and employee records',
            },
            step8 : {
                title : 'Risk management documentation',
            },
            step9 : {
                title : 'Compliance documentation',
            },
            step10 : {
                title : 'Internal audit reports',
            },
            step11 : {
                title : 'Customer and supplier contracts',
            },
           
        }
    }
]
}
export const TaxAudit =
{ pageTitle: 'Tax Audit',
heading: 'Tax Audit',
serviceKey: 'TaxAudit',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'TaxAudit'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Business', 
                substeps : [
                    'Total sales, turnover or gross receipts exceed Rs.1 crore in the FY (If cash transactions are up to 5% of total gross receipts and payments, the threshold limit of turnover for tax audit is increased to Rs.10 crores (w.e.f. FY 2020-21))',
                    'Carrying on business eligible for presumptive taxation under Section 44AE, 44BB or 44BBB',
                    'Carrying on business eligible for presumptive taxation under Section 44AD',
                    'Carrying on the business and is not eligible to claim presumptive taxation under Section 44AD due to opting out for presumptive taxation in any one financial year of the lock-in period i.e. 5 consecutive years from when the presumptive tax scheme was opted'
                ]
            }, 
            step2: {
                title: "Profession", 
                substeps : [
                    'Total gross receipts exceed Rs 50 lakh in the FY',
                    'Carrying on the profession eligible for presumptive taxation under Section 44ADA'
                ]
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Taxpayer have to assign forms for CA i.e 3CB & 3CD etc..',
            }, 
            step2 : {
                title : 'Tax professional (CA) have to upload prefilled JSON file & file forms through worklist Tab.',
            },
            step3 : {
                title : 'Taxpayer have to accept the filed forms i.e 3CB & 3CD etc..',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Tax returns',
            },
            step2 : {
                title : 'Financial statements',
            },
            step3 : {
                title : 'General Ledger',
            },
            step4 : {
                title : 'Bank statements',
            },
            step5 : {
                title : 'Sales and Purchase invoices',
            },
            step6 : {
                title : 'Expense receipts and Supporting Documentation',
            },
            step7 : {
                title : 'Asset and Depreciation schedules',
            },
            step8 : {
                title : 'Payroll records',
            },
            step9 : {
                title : 'Contracts and agreements',
            },
            step10 : {
                title : 'Tax-related correspondence',
            },
            step11 : {
                title : 'Supporting documentation for deductions and credits',
            },
            step12 : {
                title : 'Other relevant documents',
            },
        }
    }
]
}
export const InternalAudit =
{ pageTitle: 'Internal Audit',
heading: 'Internal Audit',
serviceKey: 'InternalAudit',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
     
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'InternalAudit'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Listed Company', 
            }, 
            step2: {
                title: "Unlisted public company satisfying", 
                substeps:[
                    'Turnover of 200 crores or more',
                    'Paid up share capital or 50 crores or more',
                    'Outstanding loans or borrowings from banks/financial institutions exceeding 100 crores or more',
                    'Outstanding deposits of 25 crores or more'
                ]
            },
            step3: {
                title: "Private company satisfying", 
                substeps:[
                    'Turnover of 200 crores or more',
                    'Outstanding loans or borrowings from banks/financial institutions exceeding 100 crores or more ',
                    'On Voluntary Basis'
                ]
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Planning',
                substeps:[
                    'The internal audit team should set internal audit requirement(s).',
                    'Review guidance relevant to the audit (e.g., laws, regulations, industry standards, company policies, procedures, etc.).',
                    'Review the results from previous audits.',
                    'Set a timeline and budget for the audit.'
                ]
            }, 
            step2 : {
                title : 'Fieldwork',
                substeps: [
                    'Interviewing key personnel to confirm an understanding of the process and controls.',
                    'Reviewing relevant documents and artifacts for an example of the execution of controls.',
                    'Testing the controls for a sample over a period of time',
                    'Documenting the work performed.',
                    'Identifying exceptions and recommendations.'
                ]
            },
            step3 : {
                title : 'Reporting and Follow-up',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Policies and Procedures',
            },
            step2 : {
                title : 'Organizational Charts and Job descriptions',
            },
            step3 : {
                title : 'Financial statements',
            },
            step4 : {
                title : 'Accounting records',
            },
            step5 : {
                title : 'Internal control documentation',
            },
            step6 : {
                title : 'Contracts and agreements',
            },
            step7 : {
                title : 'Risk assessments',
            },
            step8 : {
                title : 'Risk management documentation',
            },
            step9 : {
                title : 'Compliance documentation',
            },
            step10 : {
                title : 'Internal audit reports',
            },
            step11 : {
                title : 'Incident and Exception reports',
            },
            step12 : {
                title : 'IT documentationn',
            },
            step13 : {
                title : 'Employee Records',
            },
        }
    }
]
}

export const StatutoryAudit =
{ pageTitle: 'Statutory Audit',
heading: 'Statutory Audit',
serviceKey: 'StatutoryAudit',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit', href: PATH_APP.app2.services.Audit},
    { name: 'StatutoryAudit'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Every company', 
            }, 
        
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Meeting with key stakeholders',
            }, 
            step2 : {
                title : 'Evaluate existing processes & controls',
            },
            step3 : {
                title : 'Assess risks',
            },
            step4 : {
                title : 'Analyse operations & confirm information',
            },
            step5 : {
                title : 'Check with external resources if needed',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Financial Statements',
            },
            step2 : {
                title : 'Trail Balance',
            },
            step3 : {
                title : 'General Ledger',
            },
            step4 : {
                title : 'Bank Statements',
            },
            step5 : {
                title : 'Invoices and receipts',
            },
            step6 : {
                title : 'Purchase orders and supplier invoices',
            },
            step7 : {
                title : 'Inventory records',
            },
            step8 : {
                title : 'Debt and Loan agreements',
            },
            step9 : {
                title : 'Payroll records',
            },
            step10 : {
                title : 'Contracts and Agreements',
            },
            step11 : {
                title : 'Corporate Governance records',
            },
            step12 : {
                title : 'Tax Records',
            },
            step13 : {
                title : 'TDS workings and returns',
            },
        }
    }
]
}

export const PreparingAndFilingofStatutoryPaymentsandReturns =
{ pageTitle: 'Preparing and Filing of Statutory Payments and returns such as GST,TDS/TCS, PT,PF,ESI.',
heading: 'Preparing and Filing of Statutory Payments and returns such as GST,TDS/TCS, PT,PF,ESI.',
serviceKey: 'PreparingAndFilingofStatutoryPaymentsandReturns',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping},
    { name: 'Compliance Services', href: PATH_APP.app2.services.ComplianceServicesUnderPackage },
    { name: 'Preparing and Filing of Statutory Payments and returns such as GST,TDS/TCS, PT,PF,ESI.'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'ADT-1 is filed with the registrar of companies (ROC) as per section 139 (1) of Companies Act 2013 on MCA Website. ', 
            }, 
            step2: {
                title: "ADT-1 needs to be filed within 15 days from the date of appointment of the Auditor. ", 
            },
            step3: {
                title: "It needs to be filed by the company and not appointed Auditor.", 
            },
            step4: {
                title: "Moreover, all the companies are mandatorily required to file Form ADT 1, be it a listed, unlisted, public, private, or any other company.", 
            },
            step5: {
                title: "In case of first auditor, Form ADT-1 has to be filed within 15 days from the date of first board meeting. In all other cases, the auditor has to be appointed/ reappointed within 15 days of AGM in which the auditor is appointed/ reappointed.", 
            },
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Complete the required forms',
            }, 
            step2 : {
                title : 'Attach supporting documentation',
            },
            step3 : {
                title : 'Review the accuracy',
            },
           
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Financial Statements',
            },
            step2 : {
                title : 'Payroll records',
            },
            step3 : {
                title : 'Sales and Purchases records',
            },
            step4 : {
                title : 'Expense records',
            },
            step5 : {
                title : 'Tax forms',
            },
            step6 : {
                title : 'Bank Statements',
            },
            step7 : {
                title : 'Depreciation Schedules',
            },
            step8 : {
                title : 'Inventory records',
            },
            step9 : {
                title : 'Supporting Documentation',
            },
        }
    }
]
}
export const PayrollMaintanance={ pageTitle: 'Payroll Maintanance',
heading: 'Payroll Maintanance',
serviceKey: 'PayrollMaintanance',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingServicesUnderPackage },
    { name: 'Payroll Maintanance'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Every business of various sizes and industries which has employees.', 
            }, 
            
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Collect employee data',
            }, 
            step2 : {
                title : 'Record time and attendance',
            },
            step3 : {
                title : 'Calculate gross pay',
            },
            step4 : {
                title : 'Deduct taxes and contributions',
            },
            step5 : {
                title : 'Account for deductions and benefits',
            },
            step6 : {
                title : 'Calculate net pay',
            }, 
            step7 : {
                title : 'Prepare payroll register',
            },
            step8 : {
                title : 'Process payments',
            },
            step9 : {
                title : 'Submit payroll taxes and reports',
            },
            step10 : {
                title : 'Maintain payroll records',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Employee records',
            },
            step2 : {
                title : 'Time and Attendance records',
            },
            step3 : {
                title : 'Payroll register',
            },
            step4 : {
                title : 'Payroll tax forms',
            },
            step5 : {
                title : 'Benefit Deductions',
            },
            step6 : {
                title : 'Expense reimbursement forms',
            },
            step7 : {
                title : 'Tax forms and Declarations',
            },
            step8 : {
                title : 'Leave and absence records',
            },
            step9 : {
                title : 'Payroll reports',
            },
            step10 : {
                title : 'Direct deposit forms',
            },
        }
    }
]
}


export const MISReport={ pageTitle: 'MIS Report',
heading: 'MIS Report',
serviceKey: 'MISReport',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
    { name: 'Accounting', href: PATH_APP.app2.services.AccountingServicesUnderPackage },
    { name: 'MIS Report'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'Businesses that require detailed analysis and monitoring of KPIs and metrics', 
            }, 
            
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Prepare the MIS report',
            }, 
            step2 : {
                title : 'Review the report',
            },
            step3 : {
                title : 'Obtain approvals',
            },
            step4 : {
                title : 'Secure documentation',
            },
            step5 : {
                title : 'Submit the report',
            },
            step6 : {
                title : 'Maintain record keeping',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Key perfomance indicators dashboard',
            },
            step2 : {
                title : 'Financial reports',
            },
            step3 : {
                title : 'Sales reports',
            },
            step4 : {
                title : 'Inventory reports',
            },
            step5 : {
                title : 'Marketing reports',
            },
            step6 : {
                title : 'Operational reports',
            },
            step7 : {
                title : 'Customer reports',
            },
            step8 : {
                title : 'Human resources reports',
            },
            step9 : {
                title : 'Project reports',
            },
            step10 : {
                title : 'Other supporting documents',
            },
        }
    }
]
}

export const MonthEndReviews=
{ pageTitle: 'Month End Reviews',
heading: 'Month End Reviews',
serviceKey: 'MonthEndReviews',
// price: 1499,
defaultDiscount: 0,
breadCrumbs : [
    
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
    { name: 'Accounting ', href: PATH_APP.app2.services.AccountingServicesUnderPackage },
    { name: 'Month End Reviews'}
],
tabTitles: ['Plan','Process','Documents and Information Required'],
planPanel: [
    {
        content: {
            step1: {
                title: 'On voluntary basis',
                
            }, 
            step2: {
                title: 'Every business which are going for an audit in the financial year',  
            },
            
        }
    },
],
tabPanels: [
    {
        panelTitle: 'Process',
        panelContent: {
            step1 : {
                title : 'Collect Financial Data',
            }, 
            step2 : {
                title : 'Review Financial statements',
            },
            step3 : {
                title : 'Assess KPIs',
            },
            step4 : {
                title : 'Conduct expense analysis',
            },
            step5 : {
                title : 'Identify revenue trends',
            },
            step6 : {
                title : 'Review cash flow',
            }, 
            step7 : {
                title : 'Identify areas of improvement',
            },
            step8 : {
                title : 'Adjust financial projections',
            },
            step9 : {
                title : 'Communicate findings',
            },
            step10 : {
                title : 'Document the review',
            },
        }
    },
    {
        panelTitle: 'Documents',
        panelContent: {
            step1 : {
                title : 'Financial Statements',
            },
            step2 : {
                title : 'Bank Reconciliation',
            },
            step3 : {
                title : 'Accounts payable aging report',
            },
            step4 : {
                title : 'Accounts receivable aging report',
            },
            step5 : {
                title : 'Inventory report',
            },
            step6 : {
                title : 'Expense report',
            },
            step7 : {
                title : 'Budget variance analysis',
            },
            step8 : {
                title : 'Key performance indicators',
            },
            step9 : {
                title : 'Management reports',
            },
            step10 : {
                title : 'Supporting Documentation',
            },
            
        }
    }
]
}

export const BookKeeping= {
    pageTitle: 'BookKeeping',
    heading: 'BookKeeping',
    serviceKey: 'BookKeeping',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
        { name: 'Accounting', href: PATH_APP.app2.services.AccountingServicesUnderPackage },
        { name: 'BookKeeping'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Every Company registered under Company Act 2013.', 
                }, 
               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Set up a chart of accounts',
                }, 
                step2 : {
                    title : 'Record Financial transactions',
                },
                step3 : {
                    title : 'Maintain general ledger',
                },
                step4 : {
                    title : 'Reconcile bank statements',
                },
                step5 : {
                    title : 'Track accounts receivables and payables',
                },
                step6 : {
                    title : 'Calculate and Record depreciation',
                },
                step7 : {
                    title : 'Prepare Financial statements',
                },
                step8 : {
                    title : 'Generate reports',
                },
                step9 : {
                    title : 'Maintain supporting documentation',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Sales Invoices',
                },
                step2 : {
                    title : 'Purchase Invoices',
                },
                step3 : {
                    title : 'Bank statements',
                },
                step4 : {
                    title : 'Receipts',
                },
                step5 : {
                    title : 'Payroll Records',
                },
                step6 : {
                    title : 'Expense reports',
                },
                step7 : {
                    title : 'Bank deposit slips',
                },
                step8 : {
                    title : 'Credit card statements',
                },
                step9 : {
                    title : 'Contracts and Agreements',
                },
                step10 : {
                    title : 'General Ledger',
                },
            }
        }
    ]
}

export const LLPClosing= {
    pageTitle: 'LLP Closing',
    heading: 'LLP Closing',
    serviceKey: 'LLPClosing',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'LLP Closing'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'On voluntary basis', 
                }, 
                step2: {
                    title: "LLPs with", 
                    substeps:[
                        'Expiry of duration',
                        'Insolvency or Inability to pay debts',
                        'Non compliance',
                        'Court order',
                        'Consent to partners'
                    ]
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Partner Resolution'
                }, 
                step2 : {
                    title : 'Notice of closure',
                },
                step3 : {
                    title : 'Settlement of liabilities',
                },
                step4 : {
                    title : 'Asset liquidation',
                },
                step5 : {
                    title : 'Distribution of remaining asets',
                },
                step6 : {
                    title : 'Dissolution application',
                },
                step7 : {
                    title : 'Audit and Final accounts',
                },
                step8 : {
                    title : 'Cancellation of registrations and licenses',
                },
                step9 : {
                    title : 'Public Announcement',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Closure Resolution'  
                },
                step2 : {
                    title : 'Application for closure',
                },
                step3 : {
                    title : 'Consent of partners',
                },
                step4 : {
                    title : ' No objection certificate',
                },
                step5 : {
                    title : 'Final Accounts and Audit',
                },
                step6 : {
                    title : 'Tax clearances',
                },
                step7 : {
                    title : 'Asset and Liability settlement',
                },
                step8 : {
                    title : 'Notice to creditors and Public announcement',
                },
                step9 : {
                    title : 'Consent from regulatory bodies',
                },
                step10 : {
                    title : 'Final Annual return and Annual accounts',
                },
                step11 : {
                    title : 'Affidavit and Indemnity',
                },
                step12 : {
                    title : 'Dissolution forms',
                },
            }
        }
    ]
}
export const ChangestoLLPAgreement= {
    pageTitle: 'Changes to LLP Agreement',
    heading: 'Changes to LLP Agreement',
    serviceKey: 'ChangestoLLPAgreement',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Changes to LLP Agreement'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'LLPs with', 
                    substeps:[
                        'Regulatory or legal requirements',
                        'Change in partner details',
                        'Alterations in capital structure',
                        'Modifications in the business operations',
                        'Resolution of disputes or issues'
                    ]
                }, 
                
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Review the existing LLP agreement',
                }, 
                step2 : {
                    title : 'Consultation with partners',
                },
                step3 : {
                    title : 'Draft the amendment',
                },
                step4 : {
                    title : 'Hold a meeting',
                },
                step5 : {
                    title : 'Execute the amendments',
                },
                step6 : {
                    title : 'File required forms',
                },
                step7 : {
                    title : 'Pay applicable fees',
                },
                step8 : {
                    title : 'Update the LLP agreement',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Board resolution',                    
                },
                step2 : {
                    title : 'Partner consent letters',
                },
                step3 : {
                    title : 'Supplementary agreement or Addendum',
                },
                step4 : {
                    title : 'Notarization and Stamp duty',
                },
                step5 : {
                    title : 'Filing forms',
                },
                step6 : {
                    title : 'Proof of address and Identity',
                },
            }
        }
    ]
}


export const AddDesignatedPartner= {
    pageTitle: 'Add Designated Partner',
    heading: 'Add Designated Partner',
    serviceKey: 'AddDesignatedPartner',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'AddDesignatedPartner'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Limited Liability Partnerships', 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Eligibility and Consent',
                }, 
                step2 : {
                    title : 'Partner meeting',
                },
                step3 : {
                    title : 'Amendment to LLP agreement',
                },
                step4 : {
                    title : 'Filing with ROC',
                },
                step5 : {
                    title : 'Consent and Disclosure',
                },
                step6 : {
                    title : 'Verification and Approval',
                },
                step7 : {
                    title : 'Update the LLP agreement',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Company’s Board resolution copy/resolution passed in annual general meeting',
                },
                step2 : {
                    title : 'Written consent/ Acceptance Letter from the Auditor regarding the appointment',
                },
                step3 : {
                    title : 'A certificate from the Auditor that he/she/it is not disqualified or ineligible to be appointed as an Auditor u/s 141',
                },
                step4 : {
                    title : 'Copy of the intimation given by the company to the auditor',
                },
            }
        }
    ]
}

export const AuditorAppointment= {
    pageTitle: 'Auditor Appointment',
    heading: 'Auditor Appointment',
    serviceKey: 'AuditorAppointment',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Auditor Appointment'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Companies registered under Companies Act,2013', 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Eligibility check',
                }, 
                step2 : {
                    title : 'Board resolution',
                },
                step3 : {
                    title : 'Consent and Engagement',
                },
                step4 : {
                    title : 'Intimation to ROC',
                },
                step5 : {
                    title : 'General meeting',
                },
                step6 : {
                    title : 'Reporting and compliance',
                },
                
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Board Resolution',
                },
                step2 : {
                    title : 'Consent letter',
                },
                step3 : {
                    title : 'Auditor`s certificate of eligibility',
                },
                step4 : {
                    title : 'Form ADT-1',
                },
                step5 : {
                    title : 'Letter of engagement',
                },
                step6 : {
                    title : 'Auditor`s independence declaration',
                },
            }
        }
    ]
}

export const AuditorRemoval= {
    pageTitle: 'Auditor Removal',
    heading: 'Auditor Removal',
    serviceKey: 'AuditorRemoval',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Auditor Removal'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'ADT-3 is filed with the registrar of companies (ROC) as per section 139 (1) of Companies Act 2013 on MCA website for removal of auditor. ', 
                }, 
                step2: {
                    title: "ADT-3 is to be filed within 30 days of resignation by previous Auditor.", 
                },
                step3: {
                    title: "MGT-14 is to be filed within 30days from the date of EGM in which resignation of auditor was accepted, by the Company.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Approval of removal from audit committee',
                }, 
                step2 : {
                    title : 'Convene a meeting of board of directors',
                },
                step3 : {
                    title : 'File an application to the regional director',
                },
                step4 : {
                    title : 'File certified copy of order with ROC',
                },
                step5 : {
                    title : 'Convene a general meeting',
                },
                step6 : {
                    title : 'File form MGT-14 with ROC',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Notice of Board meeting',
                },
                step2 : {
                    title : 'Board Resolution',
                },
                step3 : {
                    title : 'Notice to auditor',
                },
                step4 : {
                    title : 'Special resolution (if required)',
                },
                step5 : {
                    title : 'Form ADT-2',
                },
                
            }
        }
    ]
}

export const ChangeInAuthorizedShareCapital= {
    pageTitle: 'Change In Authorized Share Capital',
    heading: 'Change In Authorized Share Capital',
    serviceKey: 'ChangeInAuthorizedShareCapital',
    // price: 1000,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Change In Authorized Share Capitol'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Authorised share capital is the maximum amount of capital which a company can raise through issue of shares to its shareholders.', 
                }, 
                step2: {
                    title: "Every Company which intends to change its authorised share capital must alter its MOA and has to file certian documents with ROC.", 
                },
                step3: {
                    title: "AoA shall have provisions that allows the company to alter it's authorised share capital. In the absense of the same, AoA shall be altered first, by adding requisite clauses to it, which empowers the company to alter its authorised share capital.", 
                },
                step4: {
                    title: "SH-7 shall be filed with the registrar within 30 days of such alteration or increase.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Check AOA',
                }, 
                step2 : {
                    title : 'Convene a meeting of board of directors and pass necessary board resolutions',
                },
                step3 : {
                    title : 'File form SH-7 with ROC',
                },
                step4 : {
                    title : 'Payment of e-stamp duty',
                },
                step5 : {
                    title : 'Alteration in every copy of MOA and AOA',
                },
                
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Board resolution',
                },
                step2 : {
                    title : 'Special resolution',
                },
                step3 : {
                    title : 'Altered MOA',
                },
                step4 : {
                    title : 'Form MGT-14',
                },
                step5 : {
                    title : 'Form SH-7',
                },
                
            }
        }
    ]
}

export const ChangeInObjects= {
    pageTitle: 'Change In Objects',
    heading: 'Change In Objects',
    serviceKey: 'ChangeInObjects',
    // price: 1000,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Change In Objects'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'After incorporation of a company, it may want to change the object clause. This requires alteration in the MOA of the company.', 
                }, 
                step2: {
                    title: "There can be many reasons for which company alter its objects clause like:", 
                    substeps: [
                        'To attain the goals set by the Company by new or improved methods.',
                        'Where a Company wants to carry on its business on a larger scale by enhancing the scope of its activities.',
                        'To sell or dispose of the part of the business which can alter the structure of the business.',
                        'Where Company is getting amalgamated or combined with other companies, it needs to expand its existing objective.',
                        'To carry such additional business that can easily be combined with the existing business of the company.'
                    ]
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Pass Board resolution',
                }, 
                step2 : {
                    title : 'Special resolution in EGM',
                },
                step3 : {
                    title : 'MGT-14 to be filed with ROC',
                },
                step4 : {
                    title : 'Issuance of fresh certicate of incorporation',
                },
                step5 : {
                    title : 'Incorporating object clause in MOA and AOA',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Board resolution',
                },
                step2 : {
                    title : 'Special resolution',
                },
                step3 : {
                    title : 'Altered MOA',
                },
                step4 : {
                    title : 'Form MGT-14',
                },
                step5 : {
                    title : 'Advertisement in the newspaper',
                },
                
            }
        }
    ]
}

export const CommencementOfBusiness= {
    pageTitle: 'Commencement Of Business',
    heading: 'Commencement Of Business',
    serviceKey: 'CommencementOfBusiness',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Commencement Of Business'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Every company incorporated on or after 2nd November 2018 has to file form INC 20A within 180 days of its incorporation.', 
                }, 
                step2: {
                    title: "Form INC 20A is a declaration that is made by the director of the company stating the receipt of paid up share capital & date of commencement of business.", 
                },
                step3: {
                    title: "The declaration given by the director shall be verified and signed by a Chartered accountant in practice.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Go to required portal',
                }, 
                step2 : {
                    title : 'Download the FORM INC-20A & Fill the form  with required details',
                },
                step3 : {
                    title : 'Upload the valid documents',
                },
                step4 : {
                    title : 'Need to provide DIN number',
                },
                step5 : {
                    title : 'Adding of DSC',
                },
                step6 : {
                    title : 'Upload the form and make payments',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Digital signature certificate',
                },
                step2 : {
                    title : 'Registration certificate issued by RBI for financial companies',
                },
                step3 : {
                    title : 'Complete address of the registered company',
                },
                step4 : {
                    title : 'Proof of payment done by subscribers',
                },
                step5 : {
                    title : 'ID proofs and Address records',
                },
                step6 : {
                    title : 'Name of the company',
                },
            }
        }
    ]
}

export const DPT3= {
    pageTitle: 'DPT3',
    heading: 'DPT3',
    serviceKey: 'DPT3',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'DPT3'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'DPT 3 is a return of deposits that companies must file to furnish information about deposits and/or outstanding receipt of loan or money other than deposits.', 
                }, 
                step2: {
                    title: "The following companies are exempt from filing DPT-3", 
                    substeps: [
                        'Government company',
                        'Banking company',
                        'Non-Banking Financial Company',
                        'A housing finance company registered with National Housing Bank.',
                        'Any other company as notified under proviso to subsection (1) to section 73 of the Act'
                    ]
                },
                step3: {
                    title: "DPT-3 may be two varieties as follows:", 
                    substeps: [
                        'one time return',
                        'Annual Return.'
                    ]
                },
            }
        },
        {
            question: 'Due Date for filing',
            content: {
                step1: {
                    title: 'Due Date for filing return is 30th june of every year.', 
                },
            }
        }
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Preparation of information',
                }, 
                step2 : {
                    title : 'Preparation of supporting documents',
                },
                step3 : {
                    title : 'Online filing of the form DPT-3',
                },
                step4 : {
                    title : 'Fill the form',
                },
                step5 : {
                    title : 'Verification and certification',
                },
                step6 : {
                    title : 'Payment of fees',
                },
                step7 : {
                    title : 'Submission',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Form DPT-3',
                },
                step2 : {
                    title : 'Auditor`s certificate',
                },
                step3 : {
                    title : 'Supporting documents such as loan agreements, deposit receipts,etc',
                },
                step4 : {
                    title : 'Particulars of liquid assets',
                },
               
            }
        }
    ]
}

export const Form11= {
    pageTitle: 'Form 11',
    heading: 'Form 11',
    serviceKey: 'Form11',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
        { name: 'Annual Filings', href: PATH_APP.app2.services.AnnualFilingsLLP },
        { name: 'Form 11'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Form 11 is Form for filing Annual Return of Limited Liability Partnership (LLP) with the Registrar.', 
                }, 
                step2: {
                    title: "Form 11 is to be filed within 60 days of closure of its financial year, the due date for the same is 30th May.", 
                },
                step3: {
                    title: "Apart from the basic information, other details that need to be declared are :", 
                    substeps: [
                        'Total contribution by partners of the LLP',
                        'Details of notices received towards penalties imposed / compounding offenses committed during the financial year.'
                    ]
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Prepare the documents',
                }, 
                step2 : {
                    title : 'Verify the accuracy',
                },
                step3 : {
                    title : 'Obtain Director`s approval',
                },
                step4 : {
                    title : 'Generate DSC',
                },
                step5 : {
                    title : 'Access MCA portal',
                },
                step6 : {
                    title : 'Choose E-form',
                },
                step7 : {
                    title : 'Fill in the details',
                },
                step8 : {
                    title : 'Attach the documents',
                },
                step9 : {
                    title : 'Verify and Submit',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Balance Sheet',
                },
                step2 : {
                    title : 'Profit and Loss statement',
                },
                step3 : {
                    title : 'Auditor`s report',
                },
                step4 : {
                    title : 'Director`s report',
                },
                step6 : {
                    title : 'Annual return',
                },
                step7 : {
                    title : 'List of shareholders',
                },
                step8 : {
                    title : 'Register of Directors and Key Managerial personnel',
                },
                step9 : {
                    title : 'Statutory registers',
                },
                step10 : {
                    title : 'Compliance certificates',
                },
                step11 : {
                    title : 'Other supporting documents',
                },

            }
        }
    ]
}

export const Form8= {
    pageTitle: 'Form 8',
    heading: 'Form 8',
    serviceKey: 'Form8',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
        { name: 'Annual Filings', href: PATH_APP.app2.services.AnnualFilingsLLP },
        { name: 'Form 8'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'LLP Form 8 is an Annual Filing Form, which is to be filed with Registrar of Companies every year.', 
                }, 
                step2: {
                    title: "This Form contains a declaration on the state of solvency of the LLP by the designated partners and also information related to the statement of assets and liabilities and statement of income and expenditure of the LLP.", 
                },
                step3: {
                    title: "The due date for filing LLP Form 8 is 30th October of each financial year.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the required documents',
                },
                step3 : {
                    title : 'Filing of form',
                },
                step4 : {
                    title : 'Payment of fees',
                },
                step5 : {
                    title : 'Generation of acknowledgement',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Disclosure under Micro, Small and Medium Enterprises Development Act, 2006.',
                },
                step2 : {
                    title : 'In case where contingent liabilities exist, statement of contingent liabilities to be attached.',
                },
                step3 : {
                    title : 'Any other optional attachment can be provided.',
                },
            }
        }
    ]
}

export const AOC4= {
    pageTitle: 'AOC 4',
    heading: 'AOC 4',
    serviceKey: 'AOC4',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Annual Filings', href: PATH_APP.app2.services.AnnualFilingsCompany },
        { name: 'AOC 4'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: 'Companies registered under Companies Act,2013', 
                },                 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the required documents',
                },
                step3 : {
                    title : 'Filing of form',
                },
                step4 : {
                    title : 'Payment of fees',
                },
                step5 : {
                    title : 'Generation of acknowledgement',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Company name',
                },
                step2 : {
                    title : 'Signed financials (including notes to accounts)',
                },
                step3 : {
                    title : 'Audit report',
                },
                step4 : {
                    title : 'CARO report if applicable',
                },
                step5 : {
                    title : 'Board report',
                },
                step6 : {
                    title : 'Minutes of AGM if applicable',
                },
                step7 : {
                    title : 'Name of authorised signatory',
                },
                step8 : {
                    title : 'Date of AGM',
                },
                step9 : {
                    title : "Auditor's details",
                },
                step10 : {
                    title : 'Statement of subsidiaries in form AOC-1',
                },
                step11: {
                    title : 'Other relavant documents',
                },
            }
        }
    ]
}

export const DIR3KYC= {
    pageTitle: 'DIR-3 KYC',
    heading: 'DIR-3 KYC',
    serviceKey: 'DIR3KYC',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Annual Filings', href: PATH_APP.app2.services.AnnualFilingsCompany },
        {name: 'Annual Filings LLP', href: PATH_APP.app2.services.AnnualFilingsLLP },
        { name: 'DIR-3 KYC'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: 'Every Director has to update their KYC details with MCA by filing E-form DIR-3KYC, once in a Year( i.e.,by 30th September of every year)', 
                }, 
                step2: {
                    title: "DIN becomes inactive upon non-filing of E-form DIR-3KYC within the due date.", 
                },
                step3: {
                    title: "The due date for filing of DIR-3 KYC is 30th Sepetember, however this date may get extended upon notification by MCA.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Download the form',
                }, 
                step2 : {
                    title : 'Provide DIN details',
                },
                step3 : {
                    title : 'Attach the required documents',
                },
                step4 : {
                    title : 'e-Form certification',
                },
                step5 : {
                    title : 'SRN Generation',
                },
                step6 : {
                    title : 'Email communication with the approval of the receipt',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN',
                },
                step2 : {
                    title : 'Aadhar Card',
                },
                step3 : {
                    title : 'Permanent Address Proofs',
                },
                step4 : {
                    title : 'Mobile Number and Email Address',
                },
                step5 : {
                    title : 'Digital signature',
                },
                step6 : {
                    title : 'Declaration',
                },
                step7 : {
                    title : 'Copy of Passport',
                },
                
            }
        }
    ]
}

export const MGT7= {
    pageTitle: 'MGT-7',
    heading: 'MGT-7',
    serviceKey: 'MGT7',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
        { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
        { name: 'Annual Filings', href: PATH_APP.app2.services.AnnualFilingsCompany },
        { name: 'MGT-7'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: "Form MGT-7 is for filing the company's annual return for every financial year with the Registrar of Companies", 
                }, 
                step2: {
                    title: "Every company which is registered under Companies Act, 2013 or any other act before Companies Act, 2013 has to file MGT-7 on/ before 30th November.", 
                },
                step3: {
                    title: "Note: In case of OPC the time period shall be taken as 60 days from the end of Annual general meeting.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Registration and other details',
                }, 
                step2 : {
                    title : 'Principal business activities of the company',
                },
                step3 : {
                    title : 'Particulars of Holding',
                },
                step4 : {
                    title : 'Share Capital, debentures, and Other securities of the company',
                },
                step5 : {
                    title : 'MGT-7 Turnover Net Worth',
                },
                step6 : {
                    title : 'MGT-7 Details of the directors',
                },
                step7 : {
                    title : 'Meetings of Members, class of Members, Board, committees of the Board of Directors',
                },
                step8 : {
                    title : 'Renumeration of Directors and Key managerial Personnel',
                },
                step9 : {
                    title : 'Matters Related to certification of compliances and disclosures',
                },
                step10 : {
                    title : 'Penalty and punishment',
                },
                step11 : {
                    title : 'Complete List of shareholders',
                },
                step12 : {
                    title : 'Compliance of sub section',
                },
                step13 : {
                    title : 'Declaration',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'List of investors, debenture holders',
                },
                step2 : {
                    title : 'Approval letter for augmentation of AGM',
                },
                step3 : {
                    title : 'MGT-8 copy(if appicable)',
                },
                step4 : {
                    title : 'CIN number of the company',
                },
                step5 : {
                    title : 'Name and Date of Incorporation of the company',
                },
                
            }
        }
    ]
}

//Income Tax Returns

export const IndividualsIncomeTaxReturns = {
    pageTitle: 'Individuals Income Tax Returns',
    heading: 'Individuals Income Tax Returns',
    serviceKey: 'IndividualsIncomeTaxReturns',
    // price: 1499,
    defaultDiscount: 0,
    formURL: PATH_APP.app2.IncomeTaxReturnsBusiness.IndividualsIncomeTaxReturnsForm,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Individuals Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1 : {
                    title : 'If gross total income exceeds the basic exemption limit of 2.5 lakh rupees.'
                },
                step2: {
                    title: 'If there are income from capital gains, house property, business or profession, from sources outside India',
                    
                }, 
                step3 : {
                    title : 'Individuals who wish to claim a refund of excess tax paid during the financial year'
                },
                step4 : {
                    title : 'Voluntary basis'
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit income tax website',
                }, 
                step2 : {
                    title : 'Gather necessary documents (Form 16, bank statements,etc)',
                },
                step3 : {
                    title : 'Choose the correct ITR form based on the income and other details',
                },
                step4 : {
                    title : 'Verify the return',
                },
                step5 : {
                    title : 'E-file the return',
                },
                step6 : {
                    title : 'Pay any tax due',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN',
                },
                step2 : {
                    title : 'Aadhaar number',
                },
                step4 : {
                    title : 'Form 26AS',
                },
                step5 : {
                    title : 'Bank account details',
                },
                step6 : {
                    title : "Challan of any advance tax or self-assessment tax (if paid during the year)",
                },
                step7 : {
                    title : 'Details of original return (if filing a revised return)',
                },
                step8 : {
                    title : 'Income tax logins',
                },
                step9 : {
                    title : 'Income details:',
                    substeps:[
                     'Salary',
                     'House Property',
                     'Capital Gain',
                     'Business details',

                    ],
                },
                step10 : {
                    title : 'Tax payments challan',
                },
                step11: {
                    title : 'Financial statements',
                },
                step12: {
                    title : 'Deduction details',
                },
                step13: {
                    title : 'Bank statements',
                },
                step14: {
                    title : 'Investment details',
                },
                step15: {
                    title : 'Interest statements',
                },
                step16: {
                    title : 'Deposit statements',
                },
            }
        }
    ]
}

export const NidhiCompanyIncomeTaxReturns = {
    pageTitle: 'Nidhi Company Income Tax Returns',
    heading: 'Nidhi Company Income Tax Returns',
    serviceKey: 'NidhiCompanyIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Nidhi Company Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "If the company's total income exceeds the basic exemption limit", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the portal',
                }, 
                step2 : {
                    title : 'Choose the appropriate ITR form',
                },
                step3 : {
                    title : 'Compute total income and tax liability',
                },
                step4 : {
                    title : 'Get the accounts audited if the turnover is more than 1 crore',
                },
                step5 : {
                    title : 'File ITR',
                },
                step6 : {
                    title : 'Verify ITR',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'MOA',
                },
                step3 : {
                    title : 'AOA',
                },
                step4 : {
                    title : 'Bank statement',
                },
                step5 : {
                    title : 'Profit and Loss statement',
                },
                step5 : {
                    title : 'Balance sheet',
                },
                step5 : {
                    title : 'TDS certificates',
                },
                step5 : {
                    title : 'GST returns',
                },
                step5 : {
                    title : 'Depreciation chart',
                },
                step5 : {
                    title : 'Members details',
                },
                step5 : {
                    title : 'Other relevant documents',
                },
            }
        }
    ]
}

export const OnePersonCompanyIncomeTaxReturns = {
    pageTitle: 'One Person Company Income Tax Returns',
    heading: 'One Person Company Income Tax Returns',
    serviceKey: 'OnePersonCompanyIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'One Person Company Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Every partnership firm whether or not registered under the Partnership act, 1932 has to file income tax returns on or before 30th July of every year.", 
                }, 
                step2: {
                    title: "In case where tax audit is applicable the due date for filing income tax returns is 31st October.", 
                }, 
                step3: {
                    title: "Note: Tax audit is applicable to a partnership entity in case where the gross receipts exceed 5 crore.", 
                }, 
                step4: {
                    title: "In case tax audit is applicable, request a call back", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the portal',
                }, 
                step2 : {
                    title : 'Choose the appropriate ITR form',
                },
                step3 : {
                    title : 'Compute total income and tax liability',
                },
                step4 : {
                    title : 'Get the accounts audited if the turnover is more than 1 crore',
                },
                step5 : {
                    title : 'File ITR',
                },
                step6 : {
                    title : 'Verify ITR',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Aadhaar card',
                },
                step3 : {
                    title : 'Bank statement',
                },
                step4 : {
                    title : 'Profit and Loss statement',
                },
                step5 : {
                    title : 'Balance sheet',
                },
                step5 : {
                    title : 'TDS certificates',
                },
                step5 : {
                    title : 'GST returns',
                },
                step5 : {
                    title : 'Depreciation chart',
                },
                step5 : {
                    title : 'Director`s remuneration details',
                },
                step5 : {
                    title : 'Other relevant documents',
                },
            }
        }
    ]
}
export const ProducerCompanyIncomeTaxReturns = {
    pageTitle: 'Producer Company Income Tax Returns',
    heading: 'Producer Company Income Tax Returns',
    serviceKey: 'ProducerCompanyIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Producer Company Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "If the company's total income exceeds the basic exemption limit", 
                }, 
               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the portal',
                }, 
                step2 : {
                    title : 'Choose the appropriate ITR form',
                },
                step3 : {
                    title : 'Compute total income and tax liability',
                },
                step4 : {
                    title : 'Get the accounts audited if the turnover is more than 1 crore',
                },
                step5 : {
                    title : 'File ITR',
                },
                step6 : {
                    title : 'Verify ITR',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'MOA',
                },
                step3 : {
                    title : 'AOA',
                },
                step4 : {
                    title : 'Bank statement',
                },
                step5 : {
                    title : 'Profit and Loss statement',
                },
                step5 : {
                    title : 'Balance sheet',
                },
                step5 : {
                    title : 'TDS certificates',
                },
                step5 : {
                    title : 'GST returns',
                },
                step5 : {
                    title : 'Depreciation chart',
                },
                step5 : {
                    title : 'Shareholder details',
                },
                step5 : {
                    title : 'Other relevant documents',
                },
            }
        }
    ]
}

export const PrivateLimitedCompanyIncomeTaxReturns = {
    pageTitle: 'Private Limited Company Income Tax Returns',
    heading: 'Private Limited Company Income Tax Returns',
    serviceKey: 'PrivateLimitedCompanyIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Private Limited Company Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "All private companies irrespective of profit or loss in the previous financial year", 
                }, 
                step2: {
                    title: "Companies having turnover of over 250 crore rupees", 
                }, 
                step3: {
                    title: "Companies that have entered into international transactions", 
                }, 
                step4: {
                    title: "Companies that have carried forward losses or unabsorbed depreciation", 
                },
                step5: {
                    title: "Companies that have received income from sources outside India", 
                },
                step6: {
                    title: "Companies that have made investments in unlisted equity shares or have held more than 20% of the voting power in any other company.", 
                },
                step7: {
                    title: "Companies that have issued shares to its employees under Employee Stock Option Plans (ESOPs).", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Choose the correct ITR form',
                }, 
                step2 : {
                    title : 'Fill the ITR form',
                },
                step3 : {
                    title : 'Verify the ITR form using digital signature or electronic verification code',
                },
                step4 : {
                    title : 'Submit the ITR form and pay the outstanding liability',
                },
               
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'PAN card',
                },
                step3 : {
                    title : 'Financial Statements',
                },
                step4 : {
                    title : 'Tax audit report',
                },
                step5 : {
                    title : 'Bank Statements',
                },
                step6 : {
                    title : 'Digital signature of principal office',
                },
                step7 : {
                    title : 'TDS certificates',
                },
                step8 : {
                    title : 'Form 16A',
                },
                step9 : {
                    title : 'Capital gains statement',
                },
                step10 : {
                    title : 'Depreciation chart',
                },
                step11 : {
                    title : 'Audit report',
                },
            }
        }
    ]
}

export const Sec8CompanyIncomeTaxReturns = {
    pageTitle: 'Section 8 Company Income Tax Returns',
    heading: 'Section 8 Company Income Tax Returns',
    serviceKey: 'Sec8CompanyIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Section 8 Company Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "If the annual income exceeds 2.5 lakh", 
                }, 
                step2: {
                    title: "Voluntary basis", 
                }, 
                
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the portal',
                }, 
                step2 : {
                    title : 'Choose the appropriate ITR form',
                },
                step3 : {
                    title : 'Compute total income and tax liability',
                },
                step4 : {
                    title : 'Get the accounts audited if the turnover is more than 40 lakhs or a contribution of more than 25 lakhs',
                },
                step5 : {
                    title : 'File ITR',
                },
                step6 : {
                    title : 'Verify ITR',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'MOA',
                },
                step3 : {
                    title : 'AOA',
                },
                step4 : {
                    title : 'Bank statements',
                },
                step5 : {
                    title : 'Income and Expenditure statement',
                },
                step6 : {
                    title : 'Balance Sheet',
                },
                step7 : {
                    title : 'TDS certificates',
                },
                step8 : {
                    title : 'GST returns',
                },
                step9 : {
                    title : 'Depreciation chart',
                },
                step10 : {
                    title : 'Donor Details',
                },
                step11 : {
                    title : 'Othe relevant documentsDonor Details',
                },
            }
        }
    ]
}
export const SoleProprietorIncomeTaxReturns = {
    pageTitle: 'SoleProprietor Income Tax Returns',
    heading: 'SoleProprietor Income Tax Returns',
    serviceKey: 'SoleProprietorIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'SoleProprietor Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "If the annual income exceeds 2.5 lakh", 
                }, 
                step2: {
                    title: "Voluntary basis", 
                }, 
                
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the portal',
                }, 
                step2 : {
                    title : 'Choose the appropriate ITR form',
                },
                step3 : {
                    title : 'Compute total income and tax liability',
                },
                step4 : {
                    title : 'File ITR',
                },
                step5 : {
                    title : 'Verify ITR',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN',
                },
                step2 : {
                    title : 'Bank statement',
                },
                step3 : {
                    title : 'Profit and Loss statement',
                },
                step4 : {
                    title : 'Balance Sheet',
                },
                step5 : {
                    title : 'TDS certificate',
                },
                step6 : {
                    title : 'GST returns',
                },
                step7 : {
                    title : 'Depreciation chart',
                },
                step8 : {
                    title : 'Other relevant documents',
                },
            }
        }
    ]
}

export const PatnershipFirmTaxReturns = {
    pageTitle: 'Patnership Firm Income Tax Returns',
    heading: 'Patnership Firm Income Tax Returns',
    serviceKey: 'PatnershipFirmTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Patnership Firm Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "All partnerships who have exceeded basic exemption limit of taxable income", 
                }, 
               
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the respective portal',
                }, 
                step2 : {
                    title : 'Choose the correct ITR form',
                },
                step3 : {
                    title : 'Fill in the ITR form',
                },
                step4 : {
                    title : 'Verify the ITR form by digitally signing it',
                },
                step5 : {
                    title : 'Submit and keep the record of the filed ITR form',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'Financial Statements',
                },
                step3 : {
                    title : 'Bank statements',
                },
                step4 : {
                    title : 'TDS certificates',
                },
                step5 : {
                    title : 'Partnership deed',
                },
                step6 : {
                    title : 'Digital signature of principal office',
                },
                step7 : {
                    title : 'Capital Account Statement',
                },
                step8 : {
                    title : 'Audit Report',
                },
            }
        }
    ]
}

export const PatnershipLLPIncomeTaxReturns = {
    pageTitle: 'Patnership/LLP Income Tax Returns',
    heading: 'Patnership/LLP Income Tax Returns',
    serviceKey: 'PatnershipLLPIncomeTaxReturns',
    // price: 1999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Patnership/LLP Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "All partnerships who have exceeded basic exemption limit of taxable income.", 
                }, 
                step2: {
                    title: "In case where tax audit is applicable the due date for filing income tax returns is 31st October.", 
                }, 
                step3: {
                    title: "Note: Tax audit is applicable to a partnership entity in case where the gross receipts exceed 5 crore.", 
                }, 
                step4: {
                    title: "In case tax audit is applicable, request a call back", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Visit the respective portal',
                }, 
                step2 : {
                    title : 'Choose the correct ITR form',
                },
                step3 : {
                    title : 'Fill in the ITR form',
                },
                step4 : {
                    title : 'Verify the ITR form by digitally signing it',
                },
                step5 : {
                    title : 'Submit and keep the record of the filed ITR form',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN card',
                },
                step2 : {
                    title : 'LLP agreement',
                },
                step3 : {
                    title : 'Bank statements',
                },
                step4 : {
                    title : 'Profit and loss statement',
                },
                step5 : {
                    title : 'Balance sheet',
                },
                step6 : {
                    title : 'TDS certificates',
                },
                step7 : {
                    title : 'GST returns',
                },
                step8 : {
                    title : 'Depreciation chart',
                },
                step9 : {
                    title : 'Partner`s capital account statement',
                },
                step10 : {
                    title : 'Other relevant documents',
                },
            }
        }
    ]
}

export const CompanyIncomeTaxReturns = {
    pageTitle: 'Company Income Tax Returns',
    heading: 'Company Income Tax Returns',
    serviceKey: 'CompanyIncomeTaxReturns',
    // price: 2999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Company Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Every company has to file income tax returns before 30th September in ITR-6", 
                }, 
                step2: {
                    title: "In case where tax audit is applicable the due date for filing income tax returns is 31st October.", 
                }, 
                step3: {
                    title: "Note: Tax audit is applicable to a any entity in case where the gross receipts exceed 5 crore.", 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall share the computation of Income and financial statements',
                },
                step4 : {
                    title : 'Confirmation from client on tax calculations',
                },
                step5 : {
                    title : 'Filing of Income tax return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'PAN card',
                },
                step3 : {
                    title : 'Bank statements',
                },
                step4 : {
                    title : 'Financial Statements',
                },
                step5 : {
                    title : 'Tax challans paid',
                },
                step6 : {
                    title : 'Digital signature of principal office',
                },
                step7 : {
                    title : 'Details of contribution received',
                },
                step8 : {
                    title : 'Details of amount set apart',
                },
                step9 : {
                    title : 'Form 16A',
                },
                step10 : {
                    title : 'Capital gains statement',
                },
                step11 : {
                    title : 'Receipts and payments statement',
                },
                step12 : {
                    title : 'Audit report',
                },
            }
        }
    ]
}

export const SocietyIncomeTaxReturns = {
    pageTitle: 'Society Income Tax Returns',
    heading: 'Society Income Tax Returns',
    serviceKey: 'SocietyIncomeTaxReturns',
    // price: 2999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Society Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Every company has to file income tax returns before 30th September in ITR", 
                }, 
                step2: {
                    title: "In case where tax audit is applicable the due date for filing income tax returns is 31st October.", 
                }, 
                step3: {
                    title: "Note: Tax audit is applicable to a any entity in case where the gross receipts exceed 5 crore.", 
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Obtain PAN',
                }, 
                step2 : {
                    title : 'Determine the applicable ITR form',
                },
                step3 : {
                    title : 'Prepare the ITR form online or offline',
                },
                step4 : {
                    title : 'Verify and save a copy for future reference',
                },
                step5 : {
                    title : 'Submit the ITR form',
                },
                step6 : {
                    title : 'Generate the acknowledgment',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'Bank statements',
                },
                step3 : {
                    title : 'draft financial statements',
                },
                step4 : {
                    title : 'tax payment challans',
                },
                step5 : {
                    title : 'Digital signature of principal officer',
                },
                step6 : {
                    title : 'Details of contributions received',
                },
                step7 : {
                    title : 'Details of amounts set apart',
                },
            }
        }
    ]
}

export const TrustIncomeTaxReturns = {
    pageTitle: 'Trust Income Tax Returns',
    heading: 'Trust Income Tax Returns',
    serviceKey: 'TrustIncomeTaxReturns',
    // price: 2999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
         
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturnsBusiness },
        { name: 'Trust Income Tax Returns'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "All trusts registered in India that have taxable income exceeding the basic exemption limit of 2.5 lakhs", 
                }, 
                step2: {
                    title: "Any trusts that have received income on behalf of any other person are required to deduct TDS on such income", 
                },  
            }
        },
   
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Obtain PAN',
                }, 
                step2 : {
                    title : 'Choose the correct ITR form based on the nature of trust and income earned.',
                },
                step3 : {
                    title : 'Fill in the details',
                },
                step4 : {
                    title : 'Attach necessary documents',
                },
                step5 : {
                    title : 'Verify the return',
                },
                step6 : {
                    title : 'E-file the return',
                },
                step7 : {
                    title : 'Pay any tax due',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Trust Deed',
                },
                step2 : {
                    title : 'PAN card',
                },
                step3 : {
                    title : 'Audited Financial statements',
                },
                step4 : {
                    title : 'TDS certificates',
                },
                step5 : {
                    title : 'Bank statements',
                },
                step6 : {
                    title : 'Donations received',
                },
                step7 : {
                    title : 'Investments',
                },
                step8 : {
                    title : 'Capital Gains',
                },
                step9 : {
                    title : 'Tax computation',
                },
                step10 : {
                    title : 'Digital signature of principal officer',
                },
               
            }
        }
    ]
}




//INDIVIDUAL SERVICES

//Income Tax Returns Individual
export const SalariedEmployeeLessThan50Lakhs = {
    pageTitle: 'Salaried Employee Earning Less Than 50 Lakhs',
    heading: 'Salaried Employee Earning Less Than 50 Lakhs',
    serviceKey: 'SalariedEmployeeLessThan50Lakhs',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturns },
        { name: 'Salaried Employee', href: PATH_APP.app2.services.SalariedEmployee },
        { name: 'Salaried Employee Earning Less Than 50 Lakhs'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "ITR-1 is to be filed by an individual having income from the following sources:", 
                    substeps: [
                        'Salary/ pension',
                        'One house property',
                        'Other sources'
                    ]
                }, 
                step2: {
                    title: "Due date for filing this ITR is 30th July every year.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall share the computation of Income and financial statements',
                },
                step4 : {
                    title : 'Confirmation from client on tax calculations',
                },
                step5 : {
                    title : 'Filing of Income tax return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'Income details- Salary, House property, Other sources',
                },
                step3 : {
                    title : 'Form 16 along with Part B and 12AA',
                },
                step4 : {
                    title : 'tax payment challans',
                },
                step5 : {
                    title : 'Financials',
                },
                step6 : {
                    title : "Deduction details",
                },
                step7 : {
                    title : 'Bank statements',
                },
                step8 : {
                    title : 'Investment details',
                },
                step9 : {
                    title : 'Interest statements',
                },
                step10 : {
                    title : 'Deposit statements',
                },
                step11: {
                    title : 'any other information as may be necessary',
                },
            }
        }
    ]
}

export const SalariedEmployeeMoreThan50Lakhs = {
    pageTitle: 'Salaried Employee Earning More Than 50 Lakhs',
    heading: 'Salaried Employee Earning More Than 50 Lakhs',
    serviceKey: 'SalariedEmployeeMoreThan50Lakhs',
    // price: 1499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturns },
        { name: 'Salaried Employee', href: PATH_APP.app2.services.SalariedEmployee },
        { name: 'Salaried Employee Earning More Than 50 Lakhs'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "ITR-2 is to be filed by an individual having income from the following sources:", 
                    substeps: [
                        'Salary/ pension exceeding Rs. 50 lakhs',
                        'more than one house property',
                        'Other sources',
                        'Capital gains'
                    ]
                }, 
                step2: {
                    title: "Due date for filing this ITR is 30th July every year.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall share the computation of Income and financial statements',
                },
                step4 : {
                    title : 'Confirmation from client on tax calculations',
                },
                step5 : {
                    title : 'Filing of Income tax return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'Income details- Salary, House property, Other sources',
                },
                step3 : {
                    title : 'Form 16 along with Part B and 12AA',
                },
                step4 : {
                    title : 'tax payment challans',
                },
                step5 : {
                    title : 'Financials',
                },
                step6 : {
                    title : "Deduction details",
                },
                step7 : {
                    title : 'Bank statements',
                },
                step8 : {
                    title : 'Investment details',
                },
                step9 : {
                    title : 'Interest statements',
                },
                step10 : {
                    title : 'Deposit statements',
                },
                step11 : {
                    title : 'Capital gain statements',
                },
                step12: {
                    title : 'any other information as may be necessary',
                },
            }
        }
    ]
}

export const SalaryHousePropertyOtherIncome = { 
    pageTitle: 'Salary + House property + Other income',
    heading: 'Salary + House property + Other income',
    serviceKey: 'SalaryHouseOtherIncome',
    // price: 499,
    defaultDiscount: 0,
    formURL: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.SalaryHousePropertyOtherIncomeForm,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturns },
        { name: 'Salaried Employee', href: PATH_APP.app2.services.SalariedEmployee },
        { name: 'Salary + House property + Other income'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "ITR-1 is to be filed by an individual having income from the following sources:", 
                    substeps: [
                        'Salary/ pension',
                        'One house property',
                        'Other sources',
                    ]
                }, 
                step2: {
                    title: "Due date for filing this ITR is 31th July every year.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall share the computation of Income and financial statements',
                },
                step4 : {
                    title : 'Confirmation from client on tax calculations',
                },
                step5 : {
                    title : 'Filing of Income tax return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'Income details- Salary, House property, Other sources',
                },
                step3 : {
                    title : 'Form 16 along with Part B and 12AA',
                },
                step4 : {
                    title : 'Tax payment challans',
                },
                step5 : {
                    title : 'Financials',
                },
                step6 : {
                    title : "Deduction details",
                },
                step7 : {
                    title : 'Bank statements',
                },
                step8 : {
                    title : 'Investment details',
                },
                step9 : {
                    title : 'Interest statements',
                },
                step10 : {
                    title : 'Deposit statements',
                },
                step11 : {
                    title : 'Capital gain statements'
                },
                step12: {
                    title : 'Any other information as may be necessary',
                },
            }
        }
    ]
}

export const SalaryHousePropertyCapitalGainOtherIncome = {
    pageTitle: 'Salary + House property + Capital gain+ Other income',
    heading: 'Salary + House property + Capital gain+ Other income',
    serviceKey: 'SalaryHouseCapitalGainOtherIncome',
    // price: 1499,
    defaultDiscount: 0,
    formURL: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.SalaryHousePropertyCapitalGainOtherIncomeForm,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Income Tax Returns', href: PATH_APP.app2.services.IncomeTaxReturns },
        { name: 'Salaried Employee', href: PATH_APP.app2.services.SalariedEmployee },
        { name: 'Salary + House property + Capital gain+ Other income'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "ITR-2 is to be filed by an individual having income from the following sources:", 
                    substeps: [
                        'Salary/ pension exceeding Rs. 50 lakhs',
                        'More than one house property',
                        'Other sources',
                        'Capital gains'
                    ]
                }, 
                step2: {
                    title: "Due date for filing this ITR is 30th July every year.", 
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Share the information as per the checklist',
                },
                step3 : {
                    title : 'Finance box team shall share the computation of Income and financial statements',
                },
                step4 : {
                    title : 'Confirmation from client on tax calculations',
                },
                step5 : {
                    title : 'Filing of Income tax return',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Income tax logins',
                },
                step2 : {
                    title : 'Income details- Salary, House property, Other sources',
                },
                step3 : {
                    title : 'Form 16 along with Part B and 12AA',
                },
                step4 : {
                    title : 'Tax payment challans',
                },
                step5 : {
                    title : 'Financials',
                },
                step6 : {
                    title : "Deduction details",
                },
                step7 : {
                    title : 'Bank statements',
                },
                step8 : {
                    title : 'Investment details',
                },
                step9 : {
                    title : 'Interest statements',
                },
                step10 : {
                    title : 'Deposit statements',
                },
                step11: {
                    title : 'any other information as may be necessary',
                },
            }
        }
    ]
}

//TDS on sale of property
export const Form26QBIndividual = {
    pageTitle: 'Form 26QB',
    heading: 'Form 26QB',
    serviceKey: 'Form26QBIndividual',
    // price: 499,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'TDS On Sale Of Property', href: PATH_APP.app2.services.TdsOnSaleOfProperty },
        { name: 'Form 26QB' }
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {   
            content: {
                step1: {
                    title: "Form 26QB is a return-cum-challan form for the payment of Tax Deducted at Source (TDS) to the government for deductions made under Section 194-IA of the Income Tax Act, 1961. This section of the Act specifically deals with transactions involving the sale of immovable property and the applicable TDS along with Form 26QB needs to be submitted within 30 days counted from the end of the month in which TDS was deducted. For instance, if the transaction occurred on 14th March then Form 26QB must be mandatorily submitted by 30th April.", 
                },  
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1: {
                    title : 'Login to finance box portal',
                },
                step2 : {
                    title : 'Share the required documents',
                },
                step3 : {
                    title : 'Submission of application',
                },
                step4 : {
                    title : 'Generation of Acknowledgement',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'PAN of the seller & buyer',
                }, 
                step2 : {
                    title : 'Communication details of seller & buyer',
                },
                step3 : {
                    title : 'Property details',
                },
                step4 : {
                    title : 'Amount paid/credited & tax deposit details',
                },  
            }
        },
    ]
}

//Financial and Legal Advisory
export const DraftingOfDocumentsIndividual = {
    pageTitle: 'Drafting Of Documents',
    heading: 'Drafting Of Documents',
    serviceKey: 'DraftingOfDocumentsIndividual',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Financial and Legal Advisory', href: PATH_APP.app2.services.FinancialAndLegalAdvisory },
        { name: 'Drafting Of Documents'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: 'Any Individual or a business entity who would like to get any document drafted by an expert should by this plan. ',
                }, 
                step2: {
                    title: 'The documents can be anything such as partnership agreements, rental deeds, supplier agreements, franchisee agreements, co-working agrements, employee agreements etc.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Purchase the plan',
                },
                step3 : {
                    title : 'Select the agreement you want to get drafted',
                },
                step4 : {
                    title : 'Provide necessary information and documents',
                },
                step5 : {
                    title : 'Final agreement/ document copy',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'KYC documents of both the parties to contract',
                },
                step2 : {
                    title : 'Any specific clauses you want to get included',
                },
                step3 : {
                    title : 'Subject matter of the agreement',
                },
            }
        }
    ]
}

export const LegalReviewIndividual = {
    pageTitle: 'Legal Review',
    heading: 'Legal Review',
    serviceKey: 'LegalReviewIndividual',
    // price: 999,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Financial and Legal Advisory', href: PATH_APP.app2.services.FinancialAndLegalAdvisory },
        { name: 'Legal Review'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            question: 'Who should buy this plan?',
            content: {
                step1: {
                    title: 'Any Individual or a business entity who would like to get the documents reviewed by an expert who would suggest you with the inclusitivity and exclusitivity of certain terms should opt for this plan. ',
                }, 
                step2: {
                    title: 'The documents can be anything such as partnership agreements, rental deeds, supplier agreements, franchisee agreements, co-working agrements, employee agreements etc.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Purchase the plan',
                },
                step3 : {
                    title : 'Select the agreement you want to get drafted',
                },
                step4 : {
                    title : 'Review of the documents',
                },
                step5 : {
                    title : 'Final suggestions',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Agreement copy',
                },
            }
        }
    ]
}

// loan services
export const HomeLoansIndividual = {
    pageTitle: 'Home Loans',
    heading: 'Home Loans',
    serviceKey: 'HomeLoansIndividual',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Loan Services', href: PATH_APP.app2.services.LoanServices },
        { name: 'Home Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Home loan is a form of credit that helps you buy, construct, renovate and extend a residential property without breaking your savings and investments.',
                }, 
                step2: {
                    title: 'In this, the concerned property is mortgaged to a lender as security till the repayment of loan.',
                }, 
                step3: {
                    title: 'Financial institutions offer lowest possible interest rates to make home loans more affordable',
                }, 
                step4: {
                    title: 'Home loan repayment period usually extends till 30 years, giving you a benefit of lower EMIs and a greater flexibility of repayment.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Proof of identity',
                },
                step2 : {
                    title : 'Proof of residence',
                },
                step3 : {
                    title : 'Proof of age',
                },
                step4 : {
                    title : 'Proof of Income',
                },
                step5 : {
                    title : 'Property related documents',
                },
                step6 : {
                    title : 'Additional details as demanded by the lendor.',
                },
            }
        }
    ]
}

export const TermLoansIndividual = {
    pageTitle: 'Term Loans',
    heading: 'Term Loans',
    serviceKey: 'TermLoansIndividual',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Loan Services', href: PATH_APP.app2.services.LoanServices },
        { name: 'Term Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Term loan is a short-term or long-term loan approved and disbursed by any financial institution, typically repaid in instalments.',
                }, 
                step2: {
                    title: 'Term loans can be taken for various business purposes, such as for business expansion, to purchase equipment, machinery or raw materials, to manage cash flow, to meet working capital requirements, buying office or business space/land, paying-off rent and salaries, hiring new staff, debt consolidation, etc.',
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Passport-sized photographs',
                },
                step2 : {
                    title : 'Business Plan',
                },
                step3 : {
                    title : 'Filled application form',
                },
                step4 : {
                    title : 'KYC Documents (Identity, Address and Age Proofs) – Passport, PAN card, Aadhar Card, Voter’s ID card, Driving License, Utility Bills (Electricity, Telephone or Water), etc.',
                },
                step5 : {
                    title : 'Business address proof: Property papers, rent agreement or lease document',
                },
                step6 : {
                    title : 'Income Proof: Last 12 months’ salary slips',
                },
                step7 : {
                    title : 'CIBIL report, if required by lender (Above 750 is preffered)',
                },
                step8 : {
                    title : 'Last 12 months’ bank statement',
                },
                step9 : {
                    title : 'ITR, Sales Tax report, Profit and loss statement of last 2 years',
                },
                step10 : {
                    title : 'Any other document required by financial institution or lender',
                },
            }
        }
    ]
}

export const VehicleLoansIndividual = {
    pageTitle: 'Vehicle Loans',
    heading: 'Vehicle Loans',
    serviceKey: 'VehicleLoansIndividual',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Loan Services', href: PATH_APP.app2.services.LoanServices },
        { name: 'Vehicle Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Vehicle loans are taken on purchase of various vehicles such as Car, truck, lorries etc. and are repayable generally in instalments.',
                }, 
                step2: {
                    title: 'These loans are mostly secured i.e If you fall behind payments, your lender has the legal right to seize, or repossess, the vehicle.',
                }, 
                step3: {
                    title: 'Once the loan is paid off, the lien is lifted and the lender no longer has the right to repossess the vehicle.',
                }, 
                step4: {
                    title: "Since they're less risky for lenders, secured loans tend to carry lower interest rates than comparable unsecured loans.",
                }, 
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Identity proof',
                },
                step2 : {
                    title : 'Address',
                },
                step3 : {
                    title : 'Form 16',
                },
                step4 : {
                    title : 'Salary slips',
                },
                step5 : {
                    title : 'Latest Income tax returns',
                },
                step6 : {
                    title : 'Last 6 months bank statements',
                },
            }
        }
    ]
}

export const WorkingCapitalLoansIndividual = {
    pageTitle: 'Working Capital Loans',
    heading: 'Working Capital Loans',
    serviceKey: 'WorkingCapitalLoansIndividual',
    // price: 99,
    defaultDiscount: 0,
    breadCrumbs : [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Individual Services', href: PATH_APP.app2.services.IndividualServices },
        { name: 'Loan Services', href: PATH_APP.app2.services.LoanServices },
        { name: 'Working Capital Loans'}
    ],
    tabTitles: ['Plan','Process','Documents and Information Required'],
    planPanel: [
        {
            content: {
                step1: {
                    title: 'Working Capital Loan is a type of funding or credit required by several startups, enterprises or companies to manage their day-to-day business operations and to manage business cash flow. ',
                }, 
                step2: {
                    title: 'These loans are short-term loans to fulfill instant business requirements and can not be used to buy long-term assets or for investment purposes.',
                }, 
                step3: {
                    title: 'This loan is a type of business finance that is availed majorly by companies doing businesses related to manufacturing, providing services, retailing, stocking, distribution, restaurants, supermarkets, multi-brand outlets, departmental stores, etc.',
                }, 
                step4: {
                    title: "Working capital loans are largely offered to MSMEs and are not for large corporate companies. ",
                }, 
                step5: {
                    title: "The loan is intended only for Micro, Small, and Medium Enterprises for meeting their daily operational needs and ensuring they have funds for the daily operational expenditure.",
                },
                step6: {
                    title: "The majority of the working capital loans are unsecured.",
                },
            }
        },
    ],
    tabPanels: [
        {
            panelTitle: 'Process',
            panelContent: {
                step1 : {
                    title : 'Login to Finance Box portal',
                }, 
                step2 : {
                    title : 'Select the loan you want to apply for',
                },
                step3 : {
                    title : 'Submit the premilinary documents',
                },
                step4 : {
                    title : 'Assigning loan provider by Finance Box',
                },
                step5 : {
                    title : 'Verification of documents',
                },
                step6 : {
                    title : 'Granting loan',
                },
            }
        },
        {
            panelTitle: 'Documents',
            panelContent: {
                step1 : {
                    title : 'Applicant’s and Business PAN Card',
                },
                step2 : {
                    title : 'Application form with Passport Sized Photographs',
                },
                step3 : {
                    title : 'Self-written business plan',
                },
                step4 : {
                    title : 'Identity, Age, Address and Income Proofs',
                },
                step5 : {
                    title : 'Last 3 years’ ITR and Income statement',
                },
                step6 : {
                    title : 'CMA (Credit Monitoring Arrangement) report, if business turnover exceeds ₹ 5 crore',
                },
                step7 : {
                    title : 'Last 2 years’ audit report financials',
                },
                step8 : {
                    title : 'Last 12 months’ bank statement along with last years’ P&L statement',
                },
                step9 : {
                    title : 'Partnership deed',
                },
                step10 : {
                    title : 'Certificate of registration and incorporation',
                },
                step11: {
                    title : 'Rent or Lease documents',
                },
                step12: {
                    title : 'Name of all the present directories on company letterhead',
                },
                step13: {
                    title : 'Memorandum of Association (MoA) and Articles of Association (AoA)',
                },
                step14: {
                    title : 'Any other document required by lender',
                },

            }
        }
    ]
}