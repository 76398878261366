import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  salaryAdvance: [],
  error: null,
};

const slice = createSlice({
  name: 'salaryAdvance',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getSalaryAdvanceSuccess(state, action) {
      state.isLoading = false;
      state.salaryAdvance = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addSalaryAdvanceSuccess(state, action) {
      state.isLoading = false;
      state.salaryAdvance.push(action.payload);
    },
    updateSalaryAdvanceSuccess(state, action) {
      state.isLoading = false;
      const updatedDeduction = action.payload;
      state.salaryAdvance = state.salaryAdvance.map(employee =>
        employee.id === updatedDeduction.id ? updatedDeduction : employee
      );
    },
    deleteSalaryAdvanceSuccess(state, action) {
      state.isLoading = false;
      state.salaryAdvance = state.salaryAdvance.filter(employee =>
        employee.id !== action.payload
      );
    },
  },
});

export const {
  startLoading,
  getSalaryAdvanceSuccess,
  hasError,
  addSalaryAdvanceSuccess,
  updateSalaryAdvanceSuccess,
  deleteSalaryAdvanceSuccess,
} = slice.actions;

export const fetchAllSalaryAdvance = (year,businessId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let url = `payroll/getEmployPayroll?type=SalaryAdvanceLoan&financialYear=${year}&business_id=${businessId}`
    let {res} = await Factory("get",url,{});
    // dispatch(slice.actions.getSalaryAdvanceSuccess(res));
    return res;
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

// export const addSelectedEmployee = (employee) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Simulate adding employee logic, e.g., saving to local storage or an API call
//     dispatch(addSelectedEmployeeSuccess(employee));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

// export const updateSelectedEmployee = (employee) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Simulate updating employee logic, e.g., saving to local storage or an API call
//     dispatch(updateSelectedEmployeeSuccess(employee));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

// export const deleteSelectedEmployee = (employeeId) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Simulate deleting employee logic, e.g., removing from local storage or an API call
//     dispatch(deleteSelectedEmployeeSuccess(employeeId));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

export default slice.reducer;
