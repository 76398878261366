import mock from '~/utils/mock'; 

// Define mock data for the post
const Data =[
  { "id": '1',
    "phase_name": "Compliance Testing",
    "user_id": 1,
    "firm_id": 1,
    "phases_steps": [
  ]
  },
  {
    "id": '2',
  "phase_name": "Planning",
    "user_id": 1,
    "firm_id": 1,
    "phases_steps": [
   
    ]
  },
  { "id": '3',
  "phase_name": "DIC",
  "user_id": 1,
  "firm_id": 1,
  "phases_steps": [
]
},
{
  "id": '4',
  "phase_name": "Substantive Testing",
  "user_id": 1,
  "firm_id": 1,
  "phases_steps": [
 
  ]
}
  ];

// Define the mock API endpoint for fetching all posts
mock.onGet('/api/phaseget').reply(200, Data);

// Define the mock API endpoint for creating a post
mock.onPost('/api/phasepost').reply(200, Data);

// Define the mock API endpoint for updating a post
mock.onPut('/api/phaseupdate/:id').reply(200, { message: 'Post updated successfully' });

// Define the mock API endpoint for deleting a post
mock.onDelete('/api/posts/:id/delete').reply(200, { message: 'Post deleted successfully' });

// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: Data }];
});*/

export const phase = () => {
  return Data;
}
