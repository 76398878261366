
import mock from '~/utils/mock'; 

// Define mock data for the post
const Data =[
  {
     "id": '1',
    "type_name": "Status",
    "type_value":"completed",
    "user_id": 1,
    "firm_id": 1,
   
  },
  {
    "id": '2',
    "type_name": "Status",
    "type_value":"In Progress",
    "user_id": 2,
    "firm_id": 2,
  },
 
  ];

// Define the mock API endpoint for fetching all posts
mock.onGet('/api/getstatus').reply(200, Data);
// console.log('Mock API response:', Data);


// Define the mock API endpoint for creating a post
mock.onPost('/api/statuspost').reply(200, Data);

// Define the mock API endpoint for updating a post
mock.onPut('/api/statusput').reply(200, { message: 'Post updated successfully' });

// Define the mock API endpoint for deleting a post
mock.onDelete('/api/status/${id}/toggleVisibility').reply(200, { message: 'Post deleted successfully' });

// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: postData }];
});*/

export const status = () => {
  return Data;
}
