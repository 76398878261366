import mock from '~/utils/mock'; 

// Define mock data for the post
const taskAssignmentData =[
    {
        "task_id": 1,
        "createdAt": "2024-04-10Y11:38:16:3645852",
        "updatedAt": "2024-04-10Y11:38:16:3646162",
        "project_code": "PI001",
        "phase": "Phase 1",
        "step": "step 1",
        "assign_to": "Priyanka",
        "manager": "Prashanth",
        "start_date": "2024-04-10",
        "due_date": "2024-04-20",
        "status": "Active",
        "firm_id": 1,
        "project_autoinc_id": 1,
        "user_id": 1
    },
    {
        "task_id": 2,
        "createdAt": "2024-04-10Y11:38:16:3645852",
        "updatedAt": "2024-04-10Y11:38:16:3646162",
        "project_code": "PI001",
        "phase": "Phase 1",
        "step": "step 2",
        "assign_to": "Mahitha",
        "manager": "Ram",
        "start_date": "2024-04-10",
        "due_date": "2024-04-20",
        "status": "Active",
        "firm_id": 1,
        "project_autoinc_id": 1,
        "user_id": 1
    }
  ];

// Define the mock API endpoint for fetching all posts
mock.onGet('/api/getTaskAssignment').reply(200, taskAssignmentData);

// Define the mock API endpoint for creating a post
mock.onPost('/api/postTaskAssignment').reply(200, taskAssignmentData);

// Define the mock API endpoint for updating a post
mock.onPut('/api/updateTaskAssignment/:id').reply(200, { message: 'Post updated successfully' });

// Define the mock API endpoint for deleting a post
mock.onDelete(/\/api\/deleteTaskAssignment\/\d+/).reply(config => {
  const taskId = parseInt(config.url.split('/').pop()); // Extract the ID from the URL

  // Find and remove the task assignment with the matching ID
  const index = taskAssignmentData.findIndex(task => task.task_id === taskId);
  if (index !== -1) {
    taskAssignmentData.splice(index, 1);
    console.log("success")
    return [200, { message: 'Task assignment deleted successfully' }];
  } else {
    return [404, { message: 'Task assignment not found' }];
  }
});
// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: Data }];
});*/

export const taskAssignment = () => {
  return taskAssignmentData;
}
