import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';
import { BASE_URL } from '~/constants';

const initialState = {
  isLoading: false,
  payrollDetails: {},
};

const slice = createSlice({
  name: 'payrollDetails',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getPayrollDetailsSuccess(state, action) {
      state.isLoading = false;
      state.payrollDetails = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addPayrollDetailsStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addPayrollDetailsSuccess(state, action) {
      state.isLoading = false;
      state.payrollDetails.push(action.payload)
    },
    addPayrollDetailsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // updatePayrollDetailsSuccess(state, action) {
    //   state.isLoading = false;
    //   const updatedPayrollDetails = action.payload;
    //   state.payrollDetails = state.payrollDetails.map(payrollDetail => {
    //     return employee.id === payrollDetail.employee_id ? payrollDetail : payrollDetail;
    //   });
    // },
    
    // // DELETE EVENT
    // deleteEmployeeSuccess(state, action) {
    //   state.areas = action.payload;
    // },
  },
});

export const { startLoading, getPayrollDetailsSuccess, hasError } = slice.actions;

export const getPayrollDetails = (businessId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());

  if (!businessId) {
    dispatch(slice.actions.getPayrollDetailsSuccess({}));
    return;
  }

  try {
    const url = `payroll/getPayrollOrg?business_id=${businessId}`;
    const response = await Factory('get', url, {});

    if (response && typeof response === 'object') {
      const { res } = response;
      console.log(response,"ressssee")

      if (res?.data?.error === "PayrollOrg matching query does not exist." && res?.status === 500) {
        dispatch(slice.actions.getPayrollDetailsSuccess({}));
      } else {
        dispatch(slice.actions.getPayrollDetailsSuccess(res));
      }
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Fetching error:", error);
    dispatch(slice.actions.hasError(error));
  }
};

export const getStatuaryComponents = (businessId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());

  if (!businessId) {
    dispatch(slice.actions.getPayrollDetailsSuccess({}));
    return;
  }

  try {
    const url = `payroll/getComponents?business_id=${businessId}`;
    const response = await Factory('get', url, {});

    if (response && typeof response === 'object') {
      const { res } = response;
      console.log(response,"ressssee")

      if (res?.data?.error === "PayrollOrg matching query does not exist." && res?.status === 500) {
        // dispatch(slice.actions.getPayrollDetailsSuccess({}));
        return res.data;
      } else {
        // dispatch(slice.actions.getPayrollDetailsSuccess(res));
        return res;
      }
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Fetching error:", error);
    dispatch(slice.actions.hasError(error));
  }
};

// export const addEmployee = (employeeData) => {
//   return async dispatch => {
//     dispatch(slice.actions.startLoading());
//     try {
//       let url = `payroll/addEmp`
//       let {variant,res} = await Factory('post',url,employeeData);
//       if (res && variant === "success") {
//         dispatch(slice.actions.addEmployeeSuccess(res));
//         return { success: true }; // Return success status
//       } else {
//         console.error("Error: Unable to add employee");
//         return { success: false, error: "Unable to add employee" }; // Return error message
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };

// };

// export const editEmployee = (employeeData,employeeId) => {
//   return async dispatch => {
//     dispatch(slice.actions.startLoading());
//     try {
//       let url = `payroll/updateEmp/${employeeId}`
//      let {variant,res} =  await Factory('put',url,employeeData);
//       if (res && variant === "success") {
//         dispatch(slice.actions.updateEmployeeSuccess({...employeeData,employee_id:employeeId}))
//         return { success: true }; // Return success status
//       } else {
//         console.error("Error: Unable to update checklist");
//         return { success: false, error: "Unable to update checklist" }; // Return error message
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// };

// export const deleteArea = (areaId) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     const response = await axios.post(`/api/areas/delete`,{areaId});
//     dispatch(slice.actions.deleteAreasSuccess(response.data));
//   } catch (error) {
//     dispatch(hasError(error));
//   }
// };

export default slice.reducer;