import mock from '~/utils/mock'; 

// Define mock data for the post
const Data =[
    {
        "area_id": 1,
        "isDeleted": false,
        "subareas": [
            {
                "code": "A.1",
                "subarea_name": "Share Capital",
                "accounts": [
                   
                ],
                "categories": [
                    {
                        "category": "Shareholders Fund (Balance Sheet)1"
                    },
                    {
                        "category": "Shareholders Fund (Balance Sheet)2"
                    }
                ]
            }
        ],
        "area_name": "ShareHolders Fund",
        "created_at": "2024-04-03T13:24:26.518000Z",
        "updated_at": "2024-04-03T13:24:26.518000Z",
        "firm_id": 1,
        "code": "A",
        "classification": "",
        "user_id": 1
    },
    {
        "area_id": 2,
        "isDeleted": false,
        "subareas": [
            {
                "code": "A.1",
                "subarea_name": "Reserves and Surplus",
                "accounts": [
                    
                ],
                "categories": [
                    {
                        "category": "Tangible"
                    },
                    {
                        "category": "InTangible"
                    }
                ]
            }
        ],
        "area_name": "Reserves and Surplus",
        "created_at": "2024-04-03T13:24:26.518000Z",
        "updated_at": "2024-04-03T13:24:26.518000Z",
        "firm_id": 1,
        "code": "A",
        "classification": "Balance Sheet",
        "user_id": 1
    }
  ];

// Define the mock API endpoint for fetching all posts
mock.onGet('/api/getSubarea').reply(200, Data);

// Define the mock API endpoint for creating a post
mock.onPost('/api/postSubarea').reply(200, Data);

// Define the mock API endpoint for updating a post
mock.onPut('/api/updateSubarea/:id').reply((config) => {
    // Extract the ID from the request URL
    const id = config.url.split('/').pop();
   
    // Get the data from the request body
    const requestData = JSON.parse(config.data);
  
    // Perform the update operation here (e.g., update the data in a database)
    // Replace the following line with your actual update logic
    const updatedData = { id, ...requestData };
  
    // Respond with a success message and the updated data
    return [200, { message: 'Post updated successfully', data: updatedData }];
  });
// Define the mock API endpoint for deleting a post
mock.onDelete('/api/posts/:id/delete').reply(200, { message: 'Post deleted successfully' });

// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: Data }];
});*/

export const subarea = () => {
  return Data;
}
