import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';

const initialState = {
  entitytype: [],
  isLoading: false,
  error: null
};

const entitySlice = createSlice({
  name: 'Entitytype',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getentitySuccess(state, action) {
      state.isLoading = false;
      state.entitytype = action.payload;
    },

    // POST PHASE
    createentitySuccess(state, action) {
      state.isLoading = false;
      state.entitytype.push(action.payload);
    },


    updateentitySuccess(state, action) {
      state.isLoading = false;
      state.entitytype = state.entitytype.map(entitytype => 
        entitytype.id === action.payload.id ? action.payload : entitytype
      );
    },
    fetchPhasesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = entitySlice.actions;

export const getEntitytype = () => async dispatch => {
    dispatch(entitySlice.actions.startLoading());
    try {
      console.log('true');
      const response = await axios.get('/api/getentitytype');
      console.log('yes');
      console.log('Response:', response); // Log the entire response object
      console.log('Response Data:', response.data); // Log the data property
      if (Array.isArray(response.data)) {
          dispatch(entitySlice.actions.getentitySuccess(response.data));
          console.log('Data:', response.data); // Log the data again if it's an array
      } else {
          throw new Error('Invalid response format');
      }
  } catch (error) {
      console.error('Error:', error); // Log any errors
      dispatch(entitySlice.actions.hasError(error.message));
  }
}

  export function createentitytype(newStatus) {
    return async dispatch => {
      dispatch(entitySlice.actions.startLoading());
      try {
        const response = await axios.post('/api/postentitytype', newStatus);
        dispatch(entitySlice.actions.createentitySuccess(newStatus));
        console.log(newStatus);
      } catch (error) {
        dispatch(entitySlice.actions.hasError(error));
      }
    };
  }
  export function updateentitytype(newStatus) {
    return async dispatch => {
      dispatch(entitySlice.actions.startLoading());
      try {
        const response = await axios.post('/api/putentitytype', newStatus);
        dispatch(entitySlice.actions.updateentitySuccess(newStatus));
        console.log(newStatus);
      } catch (error) {
        dispatch(entitySlice.actions.hasError(error));
      }
    };
  }
  export function toggleVisibility(id, isVisible) {
    return async dispatch => {
      dispatch(entitySlice.actions.startLoading());
      try {
        const response = await axios.post('/api/entitytype/${id}/toggleVisibility',  { isVisible });
        dispatch(entitySlice.actions.createentitySuccess(response.data)); // Assu
        console.log(response.data); // Log the updated entitytype
    } catch (error) {
      dispatch(entitySlice.actions.hasError(error));
    };
    }
  }

export default entitySlice.reducer;
