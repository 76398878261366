import React, { useRef, useState, useEffect } from 'react';
// import { services } from '~/servicesConstants';
import Scrollbars from '~/components/Scrollbars';
import PopoverMenu from '~/components/PopoverMenu';
import clsx from 'clsx';
import {
  Typography,
  Popover,
  Box,
  Hidden,
  List,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { MIconButton } from '~/@material-extend';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify-icons/eva/menu-2-fill';
import { PATH_HOME, PATH_APP } from '~/routes/paths';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Factory from '~/utils/factory';
import { useSelector } from 'react-redux';
import { BusinessIncorporations } from '~/servicesConstants';
import { BusinessIncorporationsRegistrations } from '~/servicesConstants';
import { GSTRegistration } from '~/servicesConstants';
import { AnnualFilingsLLP } from '~/servicesConstants';
import { OtherCompanyFilings } from '~/servicesConstants';
import { OtherLLPFilings } from '~/servicesConstants';
import { TDSServices } from '~/servicesConstants';
import { IncomeTaxReturnsBusiness } from '~/servicesConstants';
import { Audit } from '~/servicesConstants';
import { PaymentsinTDS } from '~/servicesConstants';
import { ComplianceServices } from '~/servicesConstants';
import { payrollprocessingservice } from '~/servicesConstants';
import { Registration } from '~/servicesConstants';
import { PaymentAndReturn } from '~/servicesConstants';
import { Compliance } from '~/servicesConstants';
import { AccountingServices } from '~/servicesConstants';
import { MandatoryCompanyFilings } from '~/servicesConstants';
import { getlandingpageData } from '~/redux/slices/landingpageslice';
import { DerivedConsumptionColumns } from '~/views/finance-box/MISReports/MISConstants';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  serviceInfo: {
    margin: '20px',
    display: 'flex',
    cursor: 'pointer',
    fontSize: '13px',
    marginBottom: '10px',
    alignItems: 'center',
    width: '280px',
    [theme.breakpoints.up('xs')]: {
      margin: '5px',
      width: '100px',
      height: '50px'
    },
    [theme.breakpoints.up('sm')]: {
      margin: '10px',
      width: '200px',
      height: '40px'
    },
    [theme.breakpoints.up('md')]: {
      margin: '10px',
      width: '280px',
      height: '38px'
    }
  },
  serviceHeaderInfo: {
    display: 'flex',
    cursor: 'pointer'
    // width: '160px',
    // height: '30px',
    // [theme.breakpoints.up('xs')]: {
    //     width: '120px',
    // },
    // [theme.breakpoints.up('sm')]: {
    //     width: '150px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     width: '160px',
    // }
  },
  HeaderInfo: {
    display: 'flex',
    cursor: 'pointer',
    paddingRight: '40px',
    height: '30px'
  },
  underlineText: {
    textDecoration: 'underline'
  },
  serviceDiv: {
    display: 'flex',
    padding: '10px'
  },
  typoSize: {},
  highlightService: {
    color: theme.palette.primary.main
  },
  listBox: {
    width: '300px',
    [theme.breakpoints.up('xs')]: {
      width: '105px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '300px'
    },
    [theme.breakpoints.up('md')]: {
      width: '300px'
    }
  },
  colorBlack: {
    color: 'black'
  },
  colorWhite: {
    color: 'white',
    paddingTop: 11
  },
  imgClass: {
    width: '18px',
    height: '18px',
    marginRight: '5px'
  },
  headerimgClass: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  }
}));
const MOBILE_MENU_LINKS = [
  // { title: 'Home', icon: homeFill, href: '/' },
  // {
  //   title: 'Services',
  //   // icon: faceAgent,
  //   href: '/app/services'
  // },
  {
    title: 'About Us',
    // icon: roundSpeed,
    href: '/about-us'
  },
  {
    title: 'Contact Us',
    // icon: bookOpenFill,
    href: '/contact-us'
  }
  // {
  //   title: 'Careers',
  //   //  icon: bookOpenFill,
  //   href: '/careers'
  // },
  // {
  //   title: 'Blog',
  //   //  icon: bookOpenFill,
  //   href: '/blog'
  // }
];

const AUTH_LINKS = [
  // {
  //   title: 'Register',
  //   // icon: homeFill,
  //   href: PATH_HOME.register
  // },
  // {
  //   title: 'Login',
  //   // icon: roundStreetview,
  //   href: PATH_HOME.login
  // }
];

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 64;
// ----------------------------------------------------------------------

export const RenderMenuMobileFunc = () => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === '/';

  const getRouteLink = ({ userRole }) => {
    switch (userRole) {
      case 0:
        return PATH_APP.app2.services.root;

      case 1:
        return PATH_APP.app2.notifications;

      case 2:
        return PATH_APP.app2.notifications;

      default:
        break;
    }
  };
  return (
    <Box>
      <Hidden>
        <MIconButton
          ref={anchorRef}
          onClick={() => setOpenMenu(true)}
          className={clsx({
            [classes.isHome]: isHome
          })}
          style={{ color: 'white' }}
        >
          <Icon icon={menu2Fill} />
        </MIconButton>
        <PopoverMenu
          width={220}
          open={openMenu}
          anchorEl={anchorRef.current}
          onClose={() => setOpenMenu(false)}
        >
          <List>
            {MOBILE_MENU_LINKS.map(link => (
              <MenuItem
                exact
                to={link.href}
                key={link.title}
                component={RouterLink}
                onClick={() => setOpenMenu(false)}
                activeClassName={classes.isMobileActive}
                className={classes.mobileMenu}
              >
                <ListItemIcon>
                  <Icon icon={link.icon} width={20} height={20} />
                </ListItemIcon>
                <ListItemText>{link.title}</ListItemText>
              </MenuItem>
            ))}
            {AUTH_LINKS.map(link => (
              <MenuItem
                exact
                to={
                  localStorage.user_details !== undefined
                    ? getRouteLink(
                        JSON.parse(localStorage.getItem('user_details'))
                      )
                    : link.href
                }
                key={link.title}
                component={RouterLink}
                onClick={() => setOpenMenu(false)}
                activeClassName={classes.isMobileActive}
                className={classes.mobileMenu}
              >
                <ListItemIcon>
                  <Icon icon={link.icon} width={20} height={20} />
                </ListItemIcon>
                <ListItemText>{link.title}..</ListItemText>
              </MenuItem>
            ))}
          </List>
        </PopoverMenu>
      </Hidden>
    </Box>
  );
};
function Header(info) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const anchorGSTRef = useRef(null);
  const anchorTDSRef = useRef(null);
  const anchorIncometaxfilingRef = useRef(null);
  const anchorPayrollcompliancesRef = useRef(null);
  const anchorRocfilingsRef = useRef(null);
  const anchorDocumentwalletRef = useRef(null);
  const anchorBusinessincorporationRef = useRef(null);
  const anchorAccountingandCompliancesRef = useRef(null);
  const anchorAuditRef = useRef(null);

  const anchorBusinessServicesRef = useRef(null);
  const [serviceType, setserviceType] = useState('');
  const [isEndNode, setisEndNode] = useState(null);
  const [subServiceType, setsubServiceType] = useState('');
  const [subSubServiceType, setsubSubServiceType] = useState('');

  const [isGSTOpen, setGSTOpen] = useState(false);
  const [isTDSOpen, setTDSOpen] = useState(false);
  const [isincometaxfilingOpen, setIncometaxfilingOpen] = useState(false);
  const [isPayrollcompliancesOpen, setPayrollcompliancesOpen] = useState(false);
  const [isRocfilingsOpen, setRocfilingsOpen] = useState(false);
  const [isDocumentwalletOpen, setDocumentwalletOpen] = useState(false);
  const [isBusinessincorporationOpen, setBusinessincorporationOpen] = useState(
    false
  );
  const [
    isAccountingAndCompliancesOPen,
    setAccountingAndCompliancesOPen
  ] = useState(false);
  const [isAuditOPen, setAuditOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { serviceConstants } = useSelector(state => state.landingpagedetails);

  const { pathname } = useLocation();
  let userData = serviceConstants;

  //   const getColor = info.isLandingPage ? 'white' :
  const getRouteLink = ({ userRole }) => {
    switch (userRole) {
      case 0:
        return PATH_APP.app2.services.root;

      case 1:
        return PATH_APP.app2.notifications;

      case 2:
        return PATH_APP.app2.notifications;

      default:
        break;
    }
  };
  const [services, setServices] = useState({});

  useEffect(() => {
    setServices({ ...serviceConstants });
    setLoading(false);
  }, [userData]);

  const handlePopoverMenu = val => {
    setserviceType(val);
    if (val === 'BusinessIncorporationandRegistrations') {
      setBusinessincorporationOpen(true);
    } else if (val === 'GST') {
      setGSTOpen(true);
    } else if (val === 'TDSTCS') {
      setTDSOpen(true);
    } else if (val === 'IncomeTaxFilings') {
      setIncometaxfilingOpen(true);
    } else if (val === 'Payrollcompliances') {
      setPayrollcompliancesOpen(true);
    } else if (val === 'ROCFilings') {
      setRocfilingsOpen(true);
    } else if (val === 'DocumentWallet') {
      setDocumentwalletOpen(true);
    } else if (val === 'AccountingAndCompliances') {
      setAccountingAndCompliancesOPen(true);
    } else if (val === 'Audit') {
      setAuditOpen(true);
    }
  };

  useEffect(() => {
    if (isEndNode !== null) {
      var a = document.createElement('a');
      a.setAttribute('href', isEndNode.href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [isEndNode]);

  const getClass = service => {
    return service.title === 'Business Incorporation' ||
      service.title === 'GST' ||
      service.title === 'TDS/TCS' ||
      service.title === 'Income Tax Filings' ||
      service.title === 'Payroll compliances' ||
      service.title === 'ROC Filings' ||
      service.title === 'Document Wallet' ||
      service.title === 'Accounting' ||
      service.title === 'Audit'
      ? true
      : false;
  };

  const highlightService = (service, serviceInfo) => {
    return service === serviceType ||
      service === subServiceType ||
      service === subSubServiceType
      ? true
      : false;
  };

  const getServices = (services, key) => {
    return (
      <>
        <Box
          className={clsx(
            classes.typoSize,
            !getClass(services) && highlightService(key, services)
              ? classes.highlightService
              : null
          )}
        >
          <Box
            className={
              !getClass(services)
                ? classes.serviceInfo
                : info.isLandingPage
                ? classes.serviceHeaderInfo
                : classes.HeaderInfo
            }
            key={services.title}
          >
            {!getClass(services) || !info.isLandingPage ? (
              <img
                src={
                  services.icon === ''
                    ? '/static2/ServiceIcons/Business.svg'
                    : services.icon
                }
                alt=""
                className={
                  !getClass(services)
                    ? classes.imgClass
                    : classes.headerimgClass
                }
              />
            ) : null}
            <Typography
              variant={!getClass(services) ? 'body2' : 'body1'}
              sx={{
                fontSize: !getClass(services) && 13,
                transition: 'transform .1s',
                '&:hover': {
                  transform: 'scale(1.1)'
                }
              }}
              className={clsx(
                !getClass(services)
                  ? highlightService(key, services)
                    ? null
                    : null
                  : info.isLandingPage
                  ? classes.colorWhite
                  : classes.colorBlack
              )}
            >
              {services.title}
            </Typography>
            <Box flexGrow={1} />
            {!getClass(services) && !services.endNode ? (
              <ChevronRightOutlinedIcon
                style={{ color: 'rgb(59 62 65 / 53%)' }}
              />
            ) : null}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {!loading &&
        services?.children &&
        Object.entries(services.children).map(([key, value], idx) => (
          <Box
            ref={
              value.title === 'Business Incorporation'
                ? anchorBusinessincorporationRef
                : value.title === 'GST'
                ? anchorGSTRef
                : value.title === 'TDS/TCS'
                ? anchorTDSRef
                : value.title === 'Income Tax Filings'
                ? anchorIncometaxfilingRef
                : value.title === 'Payroll compliances'
                ? anchorPayrollcompliancesRef
                : value.title === 'ROC Filings'
                ? anchorRocfilingsRef
                : value.title === 'Document Wallet'
                ? anchorDocumentwalletRef
                : value.title === 'Accounting'
                ? anchorAccountingandCompliancesRef
                : value.title === 'Audit'
                ? anchorAuditRef
                : null
            }
            aria-owns={
              serviceType === 'Business Incorporation'
                ? isBusinessincorporationOpen
                : serviceType === 'GST'
                ? isGSTOpen
                : serviceType === 'TDSTCS'
                ? isTDSOpen
                : serviceType === 'Income Tax Filings'
                ? isincometaxfilingOpen
                : serviceType === 'Payroll compliances'
                ? isPayrollcompliancesOpen
                : serviceType === 'ROC Filings'
                ? isRocfilingsOpen
                : serviceType === 'Document Wallet'
                ? isDocumentwalletOpen
                : serviceType === 'Accounting'
                ? isAccountingAndCompliancesOPen
                : serviceType === 'Audit' && isAuditOPen
                ? 'mouse-over-popover'
                : undefined
            }
            aria-haspopup="true"
            onClick={() => {
              serviceType === 'Business Incorporation'
                ? setBusinessincorporationOpen(false)
                : serviceType === 'GST'
                ? setGSTOpen(false)
                : serviceType === 'TDSTCS'
                ? setTDSOpen(false)
                : serviceType === 'Income Tax Filings'
                ? setIncometaxfilingOpen(false)
                : serviceType === 'Payroll compliances'
                ? setPayrollcompliancesOpen(false)
                : serviceType === 'ROC Filings'
                ? setRocfilingsOpen(false)
                : serviceType === 'Document Wallet'
                ? setDocumentwalletOpen(false)
                : serviceType === 'Accounting'
                ? setAccountingAndCompliancesOPen(false)
                : serviceType === 'Audit' && setAuditOpen(false);
              setsubSubServiceType('');
              setsubServiceType('');
              handlePopoverMenu(key);
            }}
            key={key}
            style={{ display: 'inline-flex' }}
          >
            {getServices(value, key)}
          </Box>
        ))}

      <Popover
        // width={1020}
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={
          serviceType === 'BusinessIncorporationandRegistrations'
            ? isBusinessincorporationOpen
            : serviceType === 'GST'
            ? isGSTOpen
            : serviceType === 'TDSTCS'
            ? isTDSOpen
            : serviceType === 'IncomeTaxFilings'
            ? isincometaxfilingOpen
            : serviceType === 'Payrollcompliances'
            ? isPayrollcompliancesOpen
            : serviceType === 'ROCFilings'
            ? isRocfilingsOpen
            : serviceType === 'DocumentWallet'
            ? isDocumentwalletOpen
            : serviceType === 'AccountingAndCompliances'
            ? isAccountingAndCompliancesOPen
            : serviceType === 'Audit' && isAuditOPen
        }
        onClose={() => {
          serviceType === 'BusinessIncorporationandRegistrations'
            ? setBusinessincorporationOpen(false)
            : serviceType === 'GST'
            ? setGSTOpen(false)
            : serviceType === 'TDSTCS'
            ? setTDSOpen(false)
            : serviceType === 'IncomeTaxFilings'
            ? setIncometaxfilingOpen(false)
            : serviceType === 'Payrollcompliances'
            ? setPayrollcompliancesOpen(false)
            : serviceType === 'ROCFilings'
            ? setRocfilingsOpen(false)
            : serviceType === 'DocumentWallet'
            ? setDocumentwalletOpen(false)
            : serviceType === 'AccountingAndCompliances'
            ? setAccountingAndCompliancesOPen(false)
            : serviceType === 'Audit' && setAuditOpen(false);
          setsubSubServiceType('');
          setsubServiceType('');
        }}
        anchorEl={
          serviceType === 'BusinessIncorporationandRegistrations'
            ? anchorBusinessincorporationRef.current
            : serviceType === 'GST'
            ? anchorGSTRef.current
            : serviceType === 'TDSTCS'
            ? anchorTDSRef.current
            : serviceType === 'IncomeTaxFilings'
            ? anchorIncometaxfilingRef.current
            : serviceType === 'Payrollcompliances'
            ? anchorPayrollcompliancesRef.current
            : serviceType === 'ROCFilings'
            ? anchorRocfilingsRef.current
            : // serviceType === 'DocumentWallet' ? anchorDocumentwalletRef.current :
            serviceType === 'AccountingAndCompliances'
            ? anchorAccountingandCompliancesRef.current
            : serviceType === 'Audit' && anchorAuditRef.current
        }
      >
        {/* <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 2,
                    px: 2.5,
                    width: '380px'
                }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                            {serviceType === 'GST' ? 'GST' : 'Business Services'}
                        </Typography>
                    </Box>
                </Box>
                <Divider /> */}
        <Box component="div" className={classes.serviceDiv}>
          <Box className={classes.listBox}>
            <Scrollbars>
              {serviceType !== ''
                ? Object.entries(services.children[serviceType].children).map(
                    ([key, value], idx) => (
                      <Box
                        // onMouseOver={() => {
                        //   setsubServiceType(key);
                        //   setsubSubServiceType('');
                        // }}
                        onMouseOver={() => {
                          !value.endNode
                            ? setsubServiceType(key)
                            : setisEndNode(null);
                        }}
                        onClick={() => {
                          if (value.endNode) setisEndNode(value);
                        }}
                        key={key}
                      >
                        {getServices(value, key)}
                      </Box>
                    )
                  )
                : null}
            </Scrollbars>
          </Box>
          {subServiceType !== '' ? (
            <Box
              className={classes.listBox}
              style={{
                borderLeft: '2px solid rgba(145, 158, 171, 0.24)'
              }}
            >
              <Scrollbars>
                {serviceType
                  ? Object.entries(
                      services?.children[serviceType]?.children[subServiceType]
                        .children
                    )?.map(([key, value], idx) => (
                      <Box
                        key={key}
                        onMouseOver={() => {
                          !value.endNode
                            ? setsubSubServiceType(key)
                            : setisEndNode(null);
                        }}
                        onClick={() => {
                          if (value.endNode) setisEndNode(value);
                        }}
                      >
                        {getServices(value, key)}
                      </Box>
                    ))
                  : null}
              </Scrollbars>
            </Box>
          ) : null}
          {subSubServiceType !== '' ? (
            <Box
              sx={{ height: { xs: 340, sm: 'auto' } }}
              className={classes.listBox}
              style={{
                borderLeft: '2px solid rgba(145, 158, 171, 0.24)'
              }}
            >
              <Scrollbars>
                {serviceType
                  ? Object.entries(
                      services.children[serviceType].children[subServiceType]
                        .children[subSubServiceType].children
                    ).map(([key, value], idx) => (
                      <Box
                        key={key}
                        onClick={() => {
                          setisEndNode(value);
                        }}
                      >
                        {getServices(value, key)}
                      </Box>
                    ))
                  : null}
              </Scrollbars>
            </Box>
          ) : null}
        </Box>
      </Popover>
    </>
  );
}

export default Header;
