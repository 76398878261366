import NavItem from "./NavItem";
import PropTypes from "prop-types";
import LogoBlue from "~/components/LogoBlue";
import React, { useEffect, useContext, useState } from "react";
import MyAvatar from "~/components/MyAvatar";
import Scrollbars from "~/components/Scrollbars";
import { PATH_APP } from "~/routes/paths";
import {
  Link as RouterLink,
  useLocation,
  matchPath,
  useHistory
} from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Link,
  List,
  Drawer,
  Hidden,
  Typography,
  ListSubheader
} from "@material-ui/core";
import {
  clientNavItems,
  all_employeeNavItems,
  all_managerNavItems,
  managerNavItemsID48,
  all_firmAdminNavItems,
  fbAdminNavItems,
  all_partnerNavItems,
  clientIndividualNavItems,
  SalesExecutiveNavItems,
  sales_firmAdminNavItems,
  sales_managerNavItems,
  sales_employeeNavItems,
  sales_partnerNavItems,
  task_managerNavItems,
  task_employeeNavItems,
  task_partnerNavItems,
  task_firmAdminNavItems,
  firmAdminNavItems,
  partnerNavItems,
  managerNavItems,
  employeeNavItems
} from "./config";
import { userContext } from "~/ContextProvider";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "~/redux/slices/loginUserDetails";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const useStyles = makeStyles(theme => {
  const isLight = theme.palette.mode === "light";

  return {
    displayType: {
      display: "flex",
      justifyContent: "center"
    },
    buttonType: {
      width: "120px",
      "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: "rgba(24, 144, 255, 0.08)"
      }
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
      }
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
      background: theme.palette.background.default
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.primary
    },
    account: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 2),
      borderRadius: theme.shape.borderRadiusSm,
      // background: theme.palette.grey[isLight ? 200 : 800],
      background: "#d2e9ff"
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter
    },
    profileBox: {
      // textAlign: 'center'
    },
    logoName: {
      fontSize: 20,
      fontWeight: "bold",
      fontFamily: "Montserrat, sans-serif !important",
      marginRight: 20,
      textDecoration: "none",
      color: "#1890FF"
    },
    logoArea: {
      display: "flex",
      color: "#1890FF",
      // filter: 'invert(45%) sepia(53%) saturate(3254%) hue-rotate(187deg) brightness(110%) contrast(105%)',
      alignItems: "center",
      cursor: "pointer"
    }
  };
});

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        open={Boolean(match)}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </NavItem>
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
      />
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool
};

function NavBar({ isOpenNav, onCloseNav }) {
  // const [representativeNavItems, setRepresentativeNavItems] = useState([]);
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  // const { auth, profile } = useSelector(state => state.firebase);
  // const displayName = auth.displayName || profile.displayName;
  // const role = profile.role;
  const [user] = useContext(userContext);
  const { userDetails } = useSelector(state => state.loginUserDetails);

  // const [serviceType, setServiceType] = useState(userDetails.serviceType);
  const [serviceType] = useState("business");

  // const { displayName, userId, roleName } =
  const { displayName, userRole, userId, roleName } =
    user || JSON.parse(localStorage.getItem("user_details")) || {};
  // const userRole = 6;
  // const userRole = 7;

  useEffect(() => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (serviceType) {
      dispatch(
        getUser({
          userDetails: {
            ...userDetails,
            serviceType: serviceType
          }
        })
      );
    }
  }, [serviceType]);

  // useEffect(async () => {

  //   if (userRole === 5) {
  //     // if (!business || !clientId || !tan || !fromValue) return;
  //     // let url = `compliance-manager/tds-payement-status?clientId=${clientId}&businessId=${business}&tan=${tan}&financialYear=${fromValue}`;
  //     // let { res, variant, message } = await Factory('get', url, {});
  //     // if (res) {
  //     //   if (res.error_message) return;
  //     //   setFilingInfo(res);
  //     // }
  //     setRepresentativeNavItems([
  //       {
  //         items: [
  //           {
  //             title: 'home',
  //             href: PATH_APP.app2.services.IndividualServices,
  //             icon: <MIcon src='/static/icons/navbar/home.svg'/>
  //           },
  //           {
  //             title: 'Compliance Manager',
  //             href: PATH_APP.app2.incomeTaxReturns,
  //             icon: <MIcon src='/static/icons/navbar/compliance-manager.svg'/>
  //           },
  //           {
  //             title: 'Track Your Service',
  //             href: PATH_APP.app2.incomeTaxReturns,
  //             icon: <MIcon src='/static/icons/navbar/track-service.svg'/>
  //           },
  //           {
  //             title: 'MIS Forms',
  //             href: PATH_APP.app2.incomeTaxReturns,
  //             icon: <MIcon src='/static/icons/navbar/mis.svg'/>
  //           },
  //           {
  //             title: 'Review Tracking',
  //             href: PATH_APP.app2.incomeTaxReturns,
  //             icon: <MIcon src='/static/icons/navbar/ic_charts.svg'/>
  //           }

  //         ]
  //       }
  //     ])
  //   }
  // }, []);

  const getUserName = name => {
    const str = name;
    // let arr = str.split(' ');
    // arr[0] = arr[0] === 'null' ? '' : arr[0];
    // arr[1] = arr[1] === 'null' ? '' : arr[1];

    // for (var i = 0; i < arr.length; i++) {
    //   arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    // }
    // const str2 = arr.join(' ');
    return str;
  };
  // GET CUSTOMISATION DATA
  let userData = useSelector(e => e?.landingpagedetails?.res);
  if (!userData) {
    return null;
  }
  // const managerNavbarItems =
  //   userId === 48 ? managerNavItemsID48 : userData?.wfm_sales === true ?  sales_managerNavItems : all_managerNavItems;

  const managerNavbarItems =
    userData?.wfm_sales && userData?.wfm_task_management
      ? all_managerNavItems
      : userData?.wfm_task_management
      ? task_managerNavItems
      : userData?.wfm_sales
      ? sales_managerNavItems
      : managerNavItems;
  const firmAdminNavbarItems =
    userData?.wfm_sales && userData?.wfm_task_management
      ? all_firmAdminNavItems
      : userData?.wfm_task_management
      ? task_firmAdminNavItems
      : userData?.wfm_sales
      ? sales_firmAdminNavItems
      : firmAdminNavItems;
  const employeeNavbarItems = userData?.wfm_task_management
    ? task_employeeNavItems
    : all_employeeNavItems;
  const partnerNavbarItems =
    userData?.wfm_sales && userData?.wfm_task_management
      ? all_partnerNavItems
      : userData?.wfm_task_management
      ? task_partnerNavItems
      : userData?.wfm_sales
      ? sales_partnerNavItems
      : partnerNavItems;

  const renderContent = (
    <Scrollbars>
      <Link underline="none" href={PATH_APP.app2.notifications}>
        <Box sx={{ p: 2.5 }} className={classes.logoArea}>
          <LogoBlue color="black" />
          <p color="primary" className={classes.logoName}>
            {userData?.admin_details?.firmName}
          </p>
        </Box>
      </Link>

      <Link
        underline="none"
        component={RouterLink}
        // to={PATH_APP.management.user.account}
        to={PATH_APP.app2.account}
      >
        <div className={classes.account}>
          <MyAvatar />
          <Box sx={{ ml: 2 }} className={classes.profileBox}>
            <Typography variant="subtitle2" color="textPrimary">
              {getUserName(displayName)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {/* {userRole === 0
                ? 'Business Owner'
                : userRole === 1
                ? 'Employee'
                : userRole === 2
                ? 'Manager'
                : userRole === 3
                ? 'Super Manager'
                : userRole === 4
                ? 'Busines Partner'
                : userRole === 5
                ? 'Representative'
                : userRole === 6
                ? 'CA Firm Admin'
                : userRole === 7 && 'FB Admin'} */}

              {userRole === 0
                ? "Financebox Admin"
                : userRole === 1
                ? "CAFirm Admin"
                : userRole === 2
                ? "Partner"
                : userRole === 3
                ? "Client"
                : userRole === 4
                ? "Manager"
                : userRole === 5
                ? "Employee"
                : userRole === 6 && "Sales Executive"}
            </Typography>
          </Box>
        </div>
      </Link>

      {/* {localStorage.getItem('user_details') && userRole == 3 && (
        <div className={classes.displayType}>
          <ToggleButtonGroup
            value={serviceType}
            exclusive
            onChange={(e, val) => {
              if (serviceType !== 'individual') {
                history.push('/app/services/individual-services');
              } else {
                history.push('/app/services/business-services');
              }
              setServiceType(val);
            }}
            aria-label="text alignment"
          >
            <ToggleButton
              value="individual"
              aria-label="left aligned"
              className={classes.buttonType}
            >
              <img
                src="/static2/ServiceIcons/Individual.svg"
                alt=""
                style={{ width: '20px', height: '20px' }}
              />
              &nbsp;
              <Typography color="black" variant="body2">
                Individual
              </Typography>
            </ToggleButton>

            <ToggleButton
              value="business"
              aria-label="centered"
              className={classes.buttonType}
            >
              <img
                src="/static2/ServiceIcons/Business.svg"
                alt=""
                style={{ width: '20px', height: '20px' }}
              />
              &nbsp;
              <Typography color="black" variant="body2">
                Business
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )} */}

      {userRole === 6
        ? SalesExecutiveNavItems.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))
        : userRole === 5
        ? employeeNavbarItems.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))
        : userRole === 4
        ? managerNavbarItems.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))
        : userRole === 2
        ? partnerNavbarItems.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))
        : userRole === 3
        ? serviceType == "individual"
          ? clientIndividualNavItems.map((list, idx) => (
              <List
                disablePadding
                key={idx}
                subheader={
                  <ListSubheader
                    disableSticky
                    disableGutters
                    className={classes.subHeader}
                  >
                    {list.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: list.items,
                  pathname: pathname
                })}
              </List>
            ))
          : clientNavItems.map((list, idx) => (
              <List
                disablePadding
                key={idx}
                subheader={
                  <ListSubheader
                    disableSticky
                    disableGutters
                    className={classes.subHeader}
                  >
                    {list.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: list.items,
                  pathname: pathname
                })}
              </List>
            ))
        : userRole === 5
        ? employeeNavbarItems.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))
        : userRole === 1
        ? firmAdminNavbarItems?.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))
        : userRole === 0 &&
          fbAdminNavItems.map((list, idx) => (
            <List
              disablePadding
              key={idx}
              subheader={
                <ListSubheader
                  disableSticky
                  disableGutters
                  className={classes.subHeader}
                >
                  {list.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: list.items,
                pathname: pathname
              })}
            </List>
          ))}

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <div className={classes.doc}>
          <Box
            component="img"
            alt="doc"
            src="/static/icons/ic_doc.svg"
            sx={{ width: 36, height: 36, mb: 2 }}
          />
          <Box
            component="h6"
            sx={{ mb: 1, typography: 'subtitle1', color: 'grey.800' }}
          >
            Hi, {displayName}
          </Box>
          <Box sx={{ mb: 2, typography: 'body2', color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Box>

          <Button
            fullWidth
            to={PATH_DOCS.root}
            variant="contained"
            component={RouterLink}
          >
            Documentation
          </Button>
        </div>
      </Box> */}
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;
