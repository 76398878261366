import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import createAvatar from '~/utils/createAvatar';
import { MAvatar } from '~/@material-extend';
import { userContext } from '~/ContextProvider'

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string
};

function MyAvatar({ className, ...other }) {

  const [user, setUser] = useContext(userContext);
  const { displayName, photoURL } = user || JSON.parse(localStorage.getItem('user_details')) || {}

  return (
    <MAvatar
      src={photoURL}
      alt={displayName}
      color={photoURL ? 'default' : createAvatar(displayName).color}
      className={className}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}

export default MyAvatar;
