import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
// ----------------------------------------------------------------------

LogoBlue.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  logo: {
    width: 40,
    height: 40,
    marginRight: 10
  }
}));


function LogoBlue({ className, ...other }) {
  const classes = useStyles();
  let userData = useSelector((e) => e?.landingpagedetails?.res)
  if (!userData) {
    return null;
  }
  return (
    <>
      {userData?.url !== 'anthill.financebox.in' && (
        <Box component="img" alt="logo"
          src={
            (userData?.url === 'financebox.in' || userData?.url === 'dev.financebox.in')
              ? "/static2/FinanceBoxLogoNew.svg"
              : userData?.logoPath
          }
          height={40}
          className={clsx(className, classes.logo)}
          {...other}
        />
      )}

    </>

  );
}

export default LogoBlue;
