import React from "react";
import { MIcon } from "~/@material-extend";
import { PATH_APP } from "~/routes/paths";
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path("ic_authenticator")} />,
  blog: <MIcon src={path("ic_blog")} />,
  calendar: <MIcon src={path("ic_calendar")} />,
  cart: <MIcon src={path("ic_cart")} />,
  charts: <MIcon src={path("ic_charts")} />,
  chat: <MIcon src={path("ic_chat")} />,
  components: <MIcon src={path("ic_components")} />,
  dashboard: <MIcon src={path("ic_dashboard")} />,
  editor: <MIcon src={path("ic_editor")} />,
  elements: <MIcon src={path("ic_elements")} />,
  error: <MIcon src={path("ic_error")} />,
  mail: <MIcon src={path("ic_mail")} />,
  map: <MIcon src={path("ic_map")} />,
  page: <MIcon src={path("ic_page")} />,
  user: <MIcon src={path("ic_user")} />,
  upload: <MIcon src={path("ic_upload")} />,
  copy: <MIcon src={path("ic_copy")} />,
  carousel: <MIcon src={path("ic_carousel")} />,
  language: <MIcon src={path("ic_language")} />,
  ITReturns: <MIcon src={path("ic_carousel")} />,
  Investment: <MIcon src={path("my-investments")} />,
  complianceManager: <MIcon src={path("compliance-manager")} />,
  myBusiness: <MIcon src={path("my-business")} />,
  homeIcon: <MIcon src={path("home")} />,
  Insurance: <MIcon src={path("insurance")} />,
  ITReturns: <MIcon src={path("it-returns")} />,
  Mis: <MIcon src={path("mis")} />,
  track: <MIcon src={path("track-service")} />,
  client: <MIcon src={path("client")} />,
  masterdata: <MIcon src={path("ic_masterdata")} />,
  duediligence: <MIcon src={path("ic_duediligence")} />,
  number: <MIcon src={path("ic_number")} />,
  number2: <MIcon src={path("ic_number2")} />,
  documentDrafting: <MIcon src={path("ic_documentdrafting")} />,
  advancetax:<MIcon src={path("ic_user")} />
};
// employee nav items
export const all_employeeNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },

      // {
      //   title: "Task",
      //   href: PATH_APP.app2.taskManagement.jobManagement,
      //   icon: ICONS.calendar,

      // },
      // {
      //   title: 'My Tasks',
      //   href: PATH_APP.app2.myTasks,
      //   icon: ICONS.calendar
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      // {
      //   title: 'My Queries',
      //   href: PATH_APP.app2.chat.myQueries,
      //   icon: ICONS.chat
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
    ]
  }
];
export const task_employeeNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      {
        title: "Tasks",
        href: PATH_APP.app2.chat.root,
        icon: ICONS.calendar,
        items: [
          {
            title: "Dashboards",
            href: PATH_APP.app2.taskManagement.dashboards
          },
          {
            title: "Task Review",
            href: PATH_APP.app2.taskManagement.jobManagement,
            icon: ICONS.calendar
          }
        ]
      },
      // {
      //   title: 'My Tasks',
      //   href: PATH_APP.app2.myTasks,
      //   icon: ICONS.calendar
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      // {
      //   title: 'My Queries',
      //   href: PATH_APP.app2.chat.myQueries,
      //   icon: ICONS.chat
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Advance Tax",
        href: PATH_APP.app2.advancetax.root,
        icon: ICONS.advancetax
      },
    ]
  }
];
export const sales_employeeNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task",
      //   href: PATH_APP.app2.taskManagement.jobManagement,
      //   icon: ICONS.calendar,

      // },
      // {
      //   title: 'My Tasks',
      //   href: PATH_APP.app2.myTasks,
      //   icon: ICONS.calendar
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      // {
      //   title: 'My Queries',
      //   href: PATH_APP.app2.chat.myQueries,
      //   icon: ICONS.chat
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
    ]
  }
];
export const employeeNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },

      // {
      //   title: "Task",
      //   href: PATH_APP.app2.taskManagement.jobManagement,
      //   icon: ICONS.calendar,

      // },
      // {
      //   title: 'My Tasks',
      //   href: PATH_APP.app2.myTasks,
      //   icon: ICONS.calendar
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      // {
      //   title: 'My Queries',
      //   href: PATH_APP.app2.chat.myQueries,
      //   icon: ICONS.chat
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
    ]
  }
];
// manager nav items
export const all_managerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      {
        title: "Tasks",
        href: PATH_APP.app2.chat.root,
        icon: ICONS.calendar,
        items: [
          {
            title: "Dashboards",
            href: PATH_APP.app2.taskManagement.dashboards
          },
          {
            title: "Task Review",
            href: PATH_APP.app2.taskManagement.jobManagement,
            icon: ICONS.calendar
          }
        ]
      },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.user
      // },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      {
        title: "Create Blog Post",
        href: PATH_APP.app2.newPost,
        icon: ICONS.blog
      },
      // {
      //   title: 'Tagging',
      //   href: PATH_APP.app2.tagging,
      //   icon: ICONS.elements
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.page
      },
      {
        title: "Advance Tax",
        href: PATH_APP.app2.advancetax.root,
        icon: ICONS.advancetax
      },
    ]
  }
];
export const task_managerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      {
        title: "Tasks",
        href: PATH_APP.app2.chat.root,
        icon: ICONS.calendar,
        items: [
          {
            title: "Dashboards",
            href: PATH_APP.app2.taskManagement.dashboards
          },
          {
            title: "Task Review",
            href: PATH_APP.app2.taskManagement.jobManagement,
            icon: ICONS.calendar
          }
        ]
      },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.user
      // },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      {
        title: "Create Blog Post",
        href: PATH_APP.app2.newPost,
        icon: ICONS.blog
      },
      // {
      //   title: 'Tagging',
      //   href: PATH_APP.app2.tagging,
      //   icon: ICONS.elements
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.page
      // }
    ]
  }
];
export const sales_managerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       // href: PATH_APP.app2.taskReview
      //       href: PATH_APP.app2.taskManagement.jobManagement,
      //       icon: ICONS.calendar,
      //     },
      //     // {
      //     //   title: 'My Tasks',
      //     //   href: PATH_APP.app2.myTasks
      //     // }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.user
      // },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      {
        title: "Create Blog Post",
        href: PATH_APP.app2.newPost,
        icon: ICONS.blog
      },
      // {
      //   title: 'Tagging',
      //   href: PATH_APP.app2.tagging,
      //   icon: ICONS.elements
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.page
      // }
    ]
  }
];
export const managerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Tasks",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Task Review",
      //       // href: PATH_APP.app2.taskReview
      //       href: PATH_APP.app2.taskManagement.jobManagement,
      //       icon: ICONS.calendar
      //     }
      //     // {
      //     //   title: 'My Tasks',
      //     //   href: PATH_APP.app2.myTasks
      //     // }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.user
      // },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      {
        title: "Create Blog Post",
        href: PATH_APP.app2.newPost,
        icon: ICONS.blog
      },
      // {
      //   title: 'Tagging',
      //   href: PATH_APP.app2.tagging,
      //   icon: ICONS.elements
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.page
      }
    ]
  }
];
// partner nav items
export const all_partnerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      // {
      //   title: "Users",
      //   // href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.user,
      //   items: [
      //     {
      //       title: "Clients",
      //       href: PATH_APP.app2.ClientManagement.clientManagement
      //     },
      //     {
      //       title: "Firm Users",
      //       href: PATH_APP.app2.firmUserManagement.root
      //     }
      //   ]
      // },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.dashboard
      },
      {
        title: "Advance Tax",
        href: PATH_APP.app2.advancetax.root,
        icon: ICONS.advancetax
      },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
export const task_partnerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      // {
      //   title: 'Clients',
      //   href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.client
      // },
      // {
      //   title: "Users",
      //   // href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.user,
      //   items: [
      //     {
      //       title: "Clients",
      //       href: PATH_APP.app2.ClientManagement.clientManagement
      //     },
      //     {
      //       title: "Firm Users",
      //       href: PATH_APP.app2.firmUserManagement.root
      //     }
      //   ]
      // },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.dashboard
      // },
      // {
      //   title: "Onboarding",
      //   href: PATH_APP.app2.onboarding.root,
      //   icon: ICONS.complianceManager,
      // },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
export const sales_partnerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Job Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      // {
      //   title: 'Clients',
      //   href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.client
      // },
      // {
      //   title: "Users",
      //   // href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.user,
      //   items: [
      //     {
      //       title: "Clients",
      //       href: PATH_APP.app2.ClientManagement.clientManagement
      //     },
      //     {
      //       title: "Firm Users",
      //       href: PATH_APP.app2.firmUserManagement.root
      //     }
      //   ]
      // },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.dashboard
      }
      // {
      //   title: "Onboarding",
      //   href: PATH_APP.app2.onboarding.root,
      //   icon: ICONS.complianceManager,
      // },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
export const partnerNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     // {
      //     //   title: "Dashboards",
      //     //   href: PATH_APP.app2.taskManagement.dashboards
      //     // },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      // {
      //   title: "Users",
      //   // href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.user,
      //   items: [
      //     {
      //       title: "Clients",
      //       href: PATH_APP.app2.ClientManagement.clientManagement
      //     },
      //     {
      //       title: "Firm Users",
      //       href: PATH_APP.app2.firmUserManagement.root
      //     }
      //   ]
      // },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.dashboard
      // }
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
// firm admin nav item
export const all_firmAdminNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: "Employee Time Tracking",
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Employee Time Tracking",
        href: PATH_APP.app2.employeeTimeTracking,
        icon: ICONS.copy
      },
      {
        title: 'Payroll & Payroll Compliances',
        // href: PATH_APP.app2.payrollCompliances.root,
        icon: ICONS.dashboard,
        items: [
          {
            title: 'Home',
            href: PATH_APP.app2.payrollCompliances.root
          },
          {
            title: 'Employee Master',
            href: PATH_APP.app2.payrollCompliances.employeeMaster.root
          },
          {
            title: 'Payroll Inputs',
            // href: PATH_APP.app2.payrollCompliances.payrollInput.leavesAndCompOff,
            items :[
              {
                title: 'Leaves & Comp Off',
                href: PATH_APP.app2.payrollCompliances.payrollInput.leavesAndCompOff
              },  
              {
                title: 'Salary Revision',
                href: PATH_APP.app2.payrollCompliances.payrollInput.salaryRevision
              },  
              {
                title: 'Overtime / Comoff Deduction',
                href: PATH_APP.app2.payrollCompliances.payrollInput.overTime
              },  
              {
                title: 'Lop Details',
                href: PATH_APP.app2.payrollCompliances.payrollInput.lop
              },  
              {
                title: 'Variable pay & performance bonus',
                href: PATH_APP.app2.payrollCompliances.payrollInput.variablePay
              },  
              {
                title: 'Deductions',
                href: PATH_APP.app2.payrollCompliances.payrollInput.deduction
              },  
              {
                title: 'Reimbursments',
                href: PATH_APP.app2.payrollCompliances.payrollInput.reimbursment
              },  
              {
                title: 'Salary Advance/Loan',
                href: PATH_APP.app2.payrollCompliances.payrollInput.salaryAdvance
              }, 
              {
                title: 'Salary Arrears',
                href: PATH_APP.app2.payrollCompliances.payrollInput.salaryArrers
              },  
              {
                title: 'Salary On Hold',
                href: PATH_APP.app2.payrollCompliances.payrollInput.salaryOnHold
              },
              {
                title: 'Override TDS(Income Tax)',
                href: PATH_APP.app2.payrollCompliances.payrollInput.overrideTDS
              },  
              {
                title: 'Employee Exit Details',
                href: PATH_APP.app2.payrollCompliances.payrollInput.employeeExistDetails
              },
              {
                title: 'New Joinee',
                href: PATH_APP.app2.payrollCompliances.payrollInput.newJoinee
              },    
                  
            ]
          },
          {
            title: 'Process payroll',
            // href: PATH_APP.app2.payrollCompliances.payrollInput.leavesAndCompOff,
            items :[
              // {
              //   title: 'Salary Register',
              //   href: PATH_APP.app2.payrollCompliances.processpayroll.salaryregister,
              // },  
              // {
              //   title: 'Professional Tax Report',
              //   href: PATH_APP.app2.payrollCompliances.processpayroll.professionaltaxreport,
              // },  
              // {
              //   title: 'Provident Fund Report',
              //   href: PATH_APP.app2.payrollCompliances.processpayroll.providentfundreport,
              // },  
              // {
              //   title: 'ESI Report',
              //   href: PATH_APP.app2.payrollCompliances.processpayroll.esireport,
              // },
              // {
              //   title: 'TDS Report',
              //   href: PATH_APP.app2.payrollCompliances.processpayroll.tdsreport,
              // },
              {
                title: 'Pay runs',
                href: PATH_APP.app2.payrollCompliances.processpayroll.payrun,
              },  
            ]
          }, 
          {
            title: 'Attendance and leave register',
            href: PATH_APP.app2.payrollCompliances.attendanceAndLeaveRegister.root
          },
        ]
      },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      // {
      //   title: 'Clients',
      //   href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.client
      // },
      {
        title: "Users",
        // href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.user,
        items: [
          {
            title: "Clients",
            href: PATH_APP.app2.ClientManagement.clientManagement
          },
          {
            title: "Firm Users",
            href: PATH_APP.app2.firmUserManagement.root
          }
        ]
      },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Advance Tax",
        href: PATH_APP.app2.advancetax.root,
        icon: ICONS.advancetax
      },
      {
        title: "Document Drafting",
        href: PATH_APP.app2.documentDrafting.root,
        icon: ICONS.documentDrafting
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.dashboard
      },
      {
        title: "Due Diligence",
        //  href: PATH_APP.app2.duediligence.root,
        icon: ICONS.duediligence,
        items: [
          {
            title: "Home",
            href: PATH_APP.app2.duediligence.root
          },
          {
            title: "Phase 1 (Planning)",
            items: [
              {
                title: "Basic Info",
                href: PATH_APP.app2.duediligence.basicinfo
              },
              {
                title: "DDP",
                href: PATH_APP.app2.duediligence.dueDiligencePlan
              },
              {
                title: "Input Data",
                href: PATH_APP.app2.InputData
              },
              {
                title: "Trend Analysis",
                href: PATH_APP.app2.trendAnalysis
              },
              {
                title: "Engagement and Scoping",
                href: PATH_APP.app2.duediligence.engagementAndscoping
              }
            ]
          },
          /*  {
            title: "Documentaion and Information Collection",
            href:  PATH_APP.app2.dic.root
          },
          {
            title: "Analytics & Substantive Testing",
            href: PATH_APP.app2.analyticsAndSubstantiveTesting.analytics
          },*/
          {
            title: "Phase 2 (DIC)",
            href: PATH_APP.app2.dic.root,
            items: [
              {
                title: "Document & Information Collection",
                href: PATH_APP.app2.dic.root
              }
            ]
          },
          {
            title: "Phase 3 (Analytics & Substantive Testing)",
            items: [
              {
                title: "Analytics & Substantive Testing",
                href: PATH_APP.app2.analyticsAndSubstantiveTesting.analytics
              }
            ]
          },
          {
            title: "Phase 4 (Compliance Testing)",
            items: [
              {
                title: "Compliance Testing",
                href: PATH_APP.app2.ComplianceTesting
              }
            ]
          },
          {
            title: "Phase 5 (Queries & Conclusions)",
            items: [
              {
                title: "Queries & Conclusions",
                href: PATH_APP.app2.duediligence.queriesAndConclusion
              }
            ]
          }
        ]
      },
      
      /*  {
        title: "Basic Info",

        href: PATH_APP.app2.duediligence.basicinfo,
      },
      {
        title: 'DDP',
        href: PATH_APP.app2.duediligence.dueDiligencePlan
      },
      {
        title: 'Document & Information Collection',
        href: PATH_APP.app2.dic.root
      },
      {
        title: 'Input Data',
        href: PATH_APP.app2.InputData
      },
      {
        title: 'Engagement and Scoping',
        href: PATH_APP.app2.duediligence.engagementAndscoping
      },
      {
        title: "Trend Analysis",
        href: PATH_APP.app2.trendAnalysis
      },
      {
        title: 'Analytics & Substantive Testing',
        href: PATH_APP.app2.analyticsAndSubstantiveTesting.analytics
      },
      {
        title: 'Compliance Testing',
        href: PATH_APP.app2.ComplianceTesting
      },
      {
        title: 'Queries & Conclusions',
        href: PATH_APP.app2.duediligence.queriesAndConclusion
      },*/
      // {
      //   title: "Onboarding",
      //   href: PATH_APP.app2.onboarding.root,
      //   icon: ICONS.complianceManager,
      // },

      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // },
      {
        title: "View Loans",
        // href: PATH_APP.app2.clientLoans.root,
        href: PATH_APP.app2.LoansView,
        icon: ICONS.ITReturns
      }
    ]
  }
];
export const task_firmAdminNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      // {
      //   title: 'Clients',
      //   href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.client
      // },
      {
        title: "Users",
        // href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.user,
        items: [
          {
            title: "Clients",
            href: PATH_APP.app2.ClientManagement.clientManagement
          },
          {
            title: "Firm Users",
            href: PATH_APP.app2.firmUserManagement.root
          }
        ]
      },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.dashboard
      // },
      // {
      //   title: "Onboarding",
      //   href: PATH_APP.app2.onboarding.root,
      //   icon: ICONS.complianceManager,
      // },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
export const sales_firmAdminNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      // {
      //   title: 'Clients',
      //   href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.client
      // },
      {
        title: "Users",
        // href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.user,
        items: [
          {
            title: "Clients",
            href: PATH_APP.app2.ClientManagement.clientManagement
          },
          {
            title: "Firm Users",
            href: PATH_APP.app2.firmUserManagement.root
          }
        ]
      },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.dashboard
      }
      // {
      //   title: "Onboarding",
      //   href: PATH_APP.app2.onboarding.root,
      //   icon: ICONS.complianceManager,
      // },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
export const firmAdminNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: "Task Management",
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: "Dashboards",
      //       href: PATH_APP.app2.taskManagement.dashboards
      //     },
      //     {
      //       title: "Task Details",
      //       href: PATH_APP.app2.taskManagement.root
      //     },
      //     {
      //       title: "Job Details",
      //       href: PATH_APP.app2.taskManagement.jobManagement
      //     }
      //   ]
      // },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: "Employee Time Tracking",
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.copy
      // },
      {
        title: "Compliances",
        href: PATH_APP.app2.compliances,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Tracking',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Time Tracking',
      //       href: PATH_APP.app2.timeTracking
      //     },
      //     {
      //       title: 'Employee Time Tracking',
      //       href: PATH_APP.app2.employeeTimeTracking
      //     }
      //   ]
      // },
      // {
      //   title: 'Clients',
      //   href: PATH_APP.app2.ClientManagement.root,
      //   icon: ICONS.client
      // },
      {
        title: "Users",
        // href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.user,
        items: [
          {
            title: "Clients",
            href: PATH_APP.app2.ClientManagement.clientManagement
          },
          {
            title: "Firm Users",
            href: PATH_APP.app2.firmUserManagement.root
          }
        ]
      },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Manage Tagging",
        // href: PATH_APP.app2.tagging,
        icon: ICONS.authenticator,
        items: [
          {
            title: "Tag Users",
            href: PATH_APP.app2.tagging
          },
          {
            title: "View Tagging",
            href: PATH_APP.app2.viewTagging
          }
        ]
      },
      {
        title: "Incom Tax Dashboard",
        href: PATH_APP.app2.IncomeTax.dashboard,
        icon: ICONS.ITReturns
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.dashboard
      // }
      // {
      //   title: "Onboarding",
      //   href: PATH_APP.app2.onboarding.root,
      //   icon: ICONS.complianceManager,
      // },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // }
    ]
  }
];
//
export const fbAdminNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      {
        title: "Firm Management",
        href: PATH_APP.app2.firmManagement.root,
        icon: ICONS.user
      },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
      // {
      //   title: 'Master Data',
      //   href: PATH_APP.app2.ddmasterdata,
      //   icon: ICONS.masterdata
      // },

      // {
      //   title: "Sales",
      //   href: PATH_APP.app2.sales.root,
      //   icon: ICONS.page
      // }
    ]
  }
];

export const SalesExecutiveNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      {
        title: "Overview",
        href: PATH_APP.app2.firmManagement.root,
        icon: ICONS.user
      },
      // {
      //   title: 'Create Blog Post',
      //   href: PATH_APP.app2.newPost,
      //   icon: ICONS.blog
      // },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.elements
      }
    ]
  }
];
export const managerNavItemsID48 = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.notifications,
        icon: ICONS.homeIcon
      },
      // {
      //   title: 'Tasks',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.calendar,
      //   items: [
      //     {
      //       title: 'Task Review',
      //       href: PATH_APP.app2.taskReview
      //     },
      //     {
      //       title: 'My Tasks',
      //       href: PATH_APP.app2.myTasks
      //     }
      //   ]
      // },
      // {
      //   title: 'Employee Time Tracking',
      //   href: PATH_APP.app2.employeeTimeTracking,
      //   icon: ICONS.user
      // },
      {
        title: "Clients",
        href: PATH_APP.app2.ClientManagement.root,
        icon: ICONS.client
      },
      {
        title: "Create Blog Post",
        href: PATH_APP.app2.newPost,
        icon: ICONS.blog
      },
      {
        title: "Tagging",
        href: PATH_APP.app2.tagging,
        icon: ICONS.elements
      },
      {
        title: "Request Call Backs",
        href: PATH_APP.app2.requestCallback,
        icon: ICONS.mail
      },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Sales",
        href: PATH_APP.app2.sales.root,
        icon: ICONS.page
      }
    ]
  }
];
export const clientNavItems = [
  {
    items: [
      {
        title: "home",
        // href: PATH_APP.app2.ComplianceManagerClient.root,
        href: PATH_APP.app2.services.BusinessServices,
        icon: ICONS.homeIcon
      },
      {
        title: "Income Tax Returns",
        // href: PATH_PAGE.comingSoon,
        href: PATH_APP.app2.incomeTaxReturns,
        icon: ICONS.ITReturns
      },
      {
        title: "MIS Reports",
        href: PATH_APP.app2.MISReports.root,
        // href: PATH_PAGE.comingSoon,
        icon: ICONS.Mis
      },
      {
        title: "Review Tracking",
        href: PATH_APP.app2.services.ReviewTracking,
        icon: ICONS.myBusiness
      },
      {
        title: "My Business",
        href: PATH_APP.app2.myBusiness.root,
        icon: ICONS.myBusiness
      },
      {
        title: "Compliance Manager",
        href: PATH_APP.app2.ComplianceManagerClient.root,
        icon: ICONS.complianceManager
      },
      // {
      //   title: 'Track Your Service',
      //   href: PATH_APP.app2.track.trackService,
      //   // href: PATH_PAGE.comingSoon,
      //   icon: ICONS.track
      // },
      // {
      //   title: 'my investments',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.investment,
      //   icon: ICONS.Investment
      // },
      // {
      //   title: 'my insurance',
      //   href: PATH_APP.app2.insurance,
      //   // href: PATH_PAGE.comingSoon,
      //   icon: ICONS.Insurance
      // },
      // {
      //   title: 'Queries',
      //   // href: PATH_PAGE.comingSoon,
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      },
      {
        title: "Loans",
        href: PATH_APP.app2.clientLoans.root,
        icon: ICONS.ITReturns
      }
    ]
  }
];

export const clientIndividualNavItems = [
  {
    items: [
      {
        title: "home",
        href: PATH_APP.app2.services.IndividualServices,
        icon: ICONS.homeIcon
      },
      {
        title: "Income Tax Returns",
        href: PATH_APP.app2.incomeTaxReturns,
        icon: ICONS.ITReturns
      },

      // {
      //   title: 'Track Your Service',
      //   href: PATH_APP.app2.track,
      //   icon: ICONS.track
      // },
      // {
      //   title: 'my investments',
      //   href: PATH_APP.app2.investment,
      //   icon: ICONS.Investment
      // },
      // {
      //   title: 'my insurance',
      //   href: PATH_APP.app2.insurance,
      //   icon: ICONS.Insurance
      // },
      // {
      //   title: 'Queries',
      //   href: PATH_APP.app2.chat.root,
      //   icon: ICONS.chat,
      //   items: [
      //     {
      //       title: 'New Query',
      //       href: PATH_APP.app2.chat.newQuery
      //     },
      //     {
      //       title: 'My Queries',
      //       href: PATH_APP.app2.chat.myQueries
      //     }
      //   ]
      // },
      {
        title: "Document Wallet",
        href: PATH_APP.app2.userDocumentWallet.root,
        icon: ICONS.elements
      }
    ]
  }
];
/*export default [
  // MAIN DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        icon: ICONS.dashboard,
        href: PATH_APP.main.root,
        items: [
          {
            title: 'app',
            href: PATH_APP.main.root
          },
          {
            title: 'e-commerce',
            href: PATH_APP.main.ecommerce
          },
          {
            title: 'analytics',
            href: PATH_APP.main.analytics
          }
        ]
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        icon: ICONS.user,
        href: PATH_APP.management.user.root,
        items: [
          {
            title: 'profile',
            href: PATH_APP.management.user.profile
          },
          {
            title: 'cards',
            href: PATH_APP.management.user.cards
          },
          {
            title: 'list',
            href: PATH_APP.management.user.list
          },
          {
            title: 'account',
            href: PATH_APP.management.user.account
          }
        ]
      },

      // MANAGEMENT : E-COMMERCE
      // ----------------------------------------------------------------------
      {
        title: 'e-commerce',
        icon: ICONS.cart,
        href: PATH_APP.management.eCommerce.root,
        items: [
          {
            title: 'shop',
            href: PATH_APP.management.eCommerce.products
          },
          {
            title: 'product',
            href: PATH_APP.management.eCommerce.productById
          },
          {
            title: 'list',
            href: PATH_APP.management.eCommerce.list
          },
          {
            title: 'checkout',
            href: PATH_APP.management.eCommerce.checkout
          },
          {
            title: 'invoice',
            href: PATH_APP.management.eCommerce.invoice
          }
        ]
      },

      // MANAGEMENT : BLOG
      // ----------------------------------------------------------------------
      {
        title: 'blog',
        icon: ICONS.blog,
        href: PATH_APP.management.blog.root,
        items: [
          {
            title: 'posts',
            href: PATH_APP.management.blog.root
          },
          {
            title: 'post',
            href: PATH_APP.management.blog.postById
          },
          {
            title: 'new post',
            href: PATH_APP.management.blog.newPost
          }
        ]
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        href: PATH_APP.app.mail.root,
        icon: ICONS.mail
      },
      {
        title: 'chat',
        href: PATH_APP.app.chat.root,
        icon: ICONS.chat
      },
      {
        title: 'calendar',
        href: PATH_APP.app.calendar,
        icon: ICONS.calendar
      }
    ]
  },

  // PAGES
  // ----------------------------------------------------------------------
  {
    subheader: 'pages',
    items: [
      {
        title: 'auth',
        href: PATH_PAGE.auth.loginUnprotected,
        icon: ICONS.authenticator,
        items: [
          {
            title: 'login',
            href: PATH_PAGE.auth.loginUnprotected
          },
          {
            title: 'register',
            href: PATH_PAGE.auth.registerUnprotected
          },
          {
            title: 'reset password',
            href: PATH_PAGE.auth.resetPassword
          },
          {
            title: 'verify code',
            href: PATH_PAGE.auth.verify
          }
        ]
      },
      {
        title: 'errors & status',
        href: '/404',
        icon: ICONS.error,
        items: [
          {
            title: 'page 404',
            href: '/404'
          },
          {
            title: 'page 500',
            href: '/500'
          },
          {
            title: 'maintenance',
            href: PATH_PAGE.maintenance
          },
          {
            title: 'coming soon',
            href: PATH_PAGE.comingSoon
          }
        ]
      },
      {
        title: 'landing page',
        href: '/',
        icon: ICONS.page
      },
      {
        title: 'pricing',
        href: PATH_PAGE.pricing,
        icon: ICONS.page
      },
      {
        title: 'payment',
        href: PATH_PAGE.payment,
        icon: ICONS.page
      }
    ]
  },

  // UI KIT
  // ----------------------------------------------------------------------
  {
    subheader: 'UI kit',
    items: [
      {
        // FOUNDATIONS
        // ----------------------------------------------------------------------
        title: 'foundations',
        href: PATH_APP.foundations.root,
        icon: ICONS.elements,
        items: [
          {
            title: 'colors',
            href: PATH_APP.foundations.colors
          },
          {
            title: 'typography',
            href: PATH_APP.foundations.typography
          },
          {
            title: 'shadows',
            href: PATH_APP.foundations.shadows
          },
          {
            title: 'grid',
            href: PATH_APP.foundations.grid
          },
          {
            title: 'icons',
            href: PATH_APP.foundations.icons
          }
        ]
      },
      {
        // COMPONENTS
        // ----------------------------------------------------------------------
        title: 'components',
        href: PATH_APP.components.root,
        icon: ICONS.components,
        info: (
          <MLabel variant="filled" color="error">
            32+
          </MLabel>
        ),
        items: [
          {
            title: 'accordion',
            href: PATH_APP.components.accordion
          },
          {
            title: 'alert',
            href: PATH_APP.components.alert
          },
          {
            title: 'autocomplete',
            href: PATH_APP.components.autocomplete
          },
          {
            title: 'avatar',
            href: PATH_APP.components.avatar
          },
          {
            title: 'badge',
            href: PATH_APP.components.badge
          },
          {
            title: 'breadcrumbs',
            href: PATH_APP.components.breadcrumbs
          },
          {
            title: 'buttons',
            href: PATH_APP.components.buttons
          },
          {
            title: 'chip',
            href: PATH_APP.components.chip
          },
          {
            title: 'dialog',
            href: PATH_APP.components.dialog
          },
          {
            title: 'label',
            href: PATH_APP.components.label
          },
          {
            title: 'lists',
            href: PATH_APP.components.lists
          },
          {
            title: 'menu',
            href: PATH_APP.components.menu
          },
          {
            title: 'pagination',
            href: PATH_APP.components.pagination
          },
          {
            title: 'pickers',
            href: PATH_APP.components.pickers
          },
          {
            title: 'popover',
            href: PATH_APP.components.popover
          },
          {
            title: 'progress',
            href: PATH_APP.components.progress
          },
          {
            title: 'rating',
            href: PATH_APP.components.rating
          },
          {
            title: 'selection controls',
            href: PATH_APP.components.selectionControls
          },
          {
            title: 'slider',
            href: PATH_APP.components.slider
          },
          {
            title: 'snackbar',
            href: PATH_APP.components.snackbar
          },
          {
            title: 'stepper',
            href: PATH_APP.components.stepper
          },
          {
            title: 'tabs',
            href: PATH_APP.components.tabs
          },
          {
            title: 'table',
            href: PATH_APP.components.table
          },
          {
            title: 'text field',
            href: PATH_APP.components.textfield
          },
          {
            title: 'timeline',
            href: PATH_APP.components.timeline
          },
          {
            title: 'tooltip',
            href: PATH_APP.components.tooltip
          },
          {
            title: 'transfer list',
            href: PATH_APP.components.transferList
          },
          {
            title: 'tree view',
            href: PATH_APP.components.treeView
          }
        ]
      },

      // EXTRA COMPONENTS
      // ----------------------------------------------------------------------
      {
        title: 'charts',
        href: PATH_APP.components.chart.root,
        icon: ICONS.charts,
        items: [
          {
            title: 'apexcharts',
            href: PATH_APP.components.chart.apexcharts
          },
          {
            title: 'recharts',
            href: PATH_APP.components.chart.recharts
          }
        ]
      },
      {
        title: 'map',
        href: PATH_APP.components.map.root,
        icon: ICONS.map,
        items: [
          {
            title: 'google map',
            href: PATH_APP.components.map.google
          },
          {
            title: 'mapbox',
            href: PATH_APP.components.map.mapbox
          }
        ]
      },
      {
        title: 'editor',
        href: PATH_APP.components.editor,
        icon: ICONS.editor
      },
      {
        title: 'copy To clipboard',
        href: PATH_APP.components.copyToClipboard,
        icon: ICONS.copy
      },
      {
        title: 'upload',
        href: PATH_APP.components.upload,
        icon: ICONS.upload
      },
      {
        title: 'carousel',
        href: PATH_APP.components.carousel,
        icon: ICONS.carousel
      },
      {
        title: 'multi language',
        href: PATH_APP.components.multiLanguage,
        icon: ICONS.language
      }
    ]
  }
]; */
