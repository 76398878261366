import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  financialYear: '',
  serviceType: '',
  quarter: '',
  dateFilter: '',
  formType: '',
  section: ''
};

const slice = createSlice({
  name: 'complianceManager',
  initialState: {
    financialYear: '',
    serviceType: '',
    quarter: '',
    dateFilter: '',
    formType: '',
    section: '',
    businessId: '',
    entityType: ''
  },
  reducers: {
    getFilterData: (state = initialState, action) => {
      state.financialYear = action.payload?.financialYear || null;
      state.serviceType = action.payload?.serviceType || null;
      state.quarter = action.payload?.quarter || null;
      state.dateFilter = action.payload?.dateFilter || null;
      state.formType = action.payload?.formType || null;
      state.section = action.payload?.section || null;
      state.businessId = action.payload?.businessId || null;
      state.entityType = action.payload?.entityType || null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFilterData(filters) {
  return async dispatch => {
    dispatch(slice.actions.getFilterData(filters));
  };
}
