import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  clientId: null,
  clientName: ''
};

const slice = createSlice({
  name: 'clientDetails',
  initialState: {
    clientId: null,
    clientName: ''
  },
  reducers: {
    getClientData: (state = initialState, action) => {
      state.clientId = action.payload.id;
      state.clientName = action.payload.name;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClient(client) {
  return async dispatch => {
    dispatch(slice.actions.getClientData(client));
  };
}
