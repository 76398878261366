import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import { BASE_URL } from '~/constants';
import Factory from '~/utils/factory';
import { useEffect } from 'react';

const initialState = {
  departments: [],
  isLoading: false,
  error: null
};

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.departments = action.payload;
    },

    // POST PHASE
    createDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.departments.push(action.payload);
      console.log('action',action.payload);
    },


    fetchDepartmentsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //UPDATE PHASE
    updateDepartmentsSuccess(state, action) {
      state.isLoading = false;
      const updatedDepartment = action.payload;
     // console.log(updatedPhase)
      state.departments = state.departments.map(EditDepartment => {
       return EditDepartment.id === updatedDepartment.id ? updatedDepartment : EditDepartment;
      });
      //console.log(state.phase)
    },
  }
});

export const { onToggleFollow } = departmentsSlice.actions;

export const getAllDepartments = () => async dispatch => {
      dispatch(departmentsSlice.actions.startLoading());
    try {
      let url = "payroll/getAllDepts";
      let { res, variant, message } = await Factory("get", url, {});
      //console.log(res)
      if (Array.isArray(res)) {
        dispatch(departmentsSlice.actions.getDepartmentsSuccess(res));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log(error.message,"Error")
      dispatch(departmentsSlice.actions.hasError(error.message));
    }
       
  };



  export function createDepartment(newDept) {
    console.log("Yeah its coming")
    return async dispatch => {
      dispatch(departmentsSlice.actions.startLoading());
      try {
       // console.log(newPhase);
        const {res} = await Factory('put','payroll/addDept',newDept);
        dispatch(departmentsSlice.actions.createDepartmentsSuccess(newDept));
      } catch (error) {
        dispatch(departmentsSlice.actions.hasError(error));
      }
      dispatch(departmentsSlice.actions.startLoading());
  
    };
  }

  export function updateDepartment(updateDepartment,updateId) {
    return async dispatch => {
      dispatch(departmentsSlice.actions.startLoading());
      try {
       // console.log(newPhase);
        const {res} = await Factory('put',`payroll/addDept`,updateDepartment);
        dispatch(departmentsSlice.actions.updateDepartmentsSuccess(updateDepartment));
      } catch (error) {
        dispatch(departmentsSlice.actions.hasError(error));
      }
      dispatch(departmentsSlice.actions.startLoading());
  
    };
  }


export default departmentsSlice.reducer;
