import { PATH_APP } from '~/routes/paths';
import PLIcon from '../Icons/ProfitandLossReport.svg';
import WorkingCapitalManagementIcon from '../Icons/WorkingCapitalManagementIcon.svg';
import CashFlowReportIcon from '../Icons/ProfitLossAccountBalanceSheet.svg';
import RatioAnalysisIcon from '../Icons/RatioAnalysis.svg';
import RevenueReportIcon from '../Icons/RevenueReport.svg';
import ExpenseAnalysisIcon from '../Icons/SalaryReport.svg';
import BalanceSheetIcon from '../Icons/BalanceSheetReport.svg';

export const RatioNumbers = {
  title: 'Ratio Analysis Numbers',
  icon: '',
  href: PATH_APP.app2.MISReports.RatioAnalysisNumberReports,
  description: '',
  heading: 'Ratio Analysis Numbers',
  breadCrumbs: [
    { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    {
      name: 'Ratio Analysis Reports',
      href: PATH_APP.app2.MISReports.RatioAnalysisReports
    },
    { name: 'Ratio Analysis Numbers' }
  ]
};

export const RatioCharts = {
  title: 'Ratio Analysis Charts',
  icon: '',
  href: PATH_APP.app2.MISReports.RatioAnalysisChartReports,
  description: '',
  heading: 'Ratio Analysis Charts',
  breadCrumbs: [
    { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    {
      name: 'Ratio Analysis Reports',
      href: PATH_APP.app2.MISReports.RatioAnalysisReports
    },
    { name: 'Ratio Analysis Charts' }
  ]
};

export const MISReports = {
  heading: 'MIS Reports',
  breadCrumbs: [{ name: 'MIS Reports', href: PATH_APP.app2.MISReports.root }],
  children: {
    PLSheet: {
      title: 'P&L Sheet Report',
      href: PATH_APP.app2.MISReports.rootIndexPath + '?I. P&L Sheet Report',
      icon: PLIcon,
      key: 'PLSheet',
      description: 'This is P&L Sheet Report',
      heading: 'P&L Sheet Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'P&L Sheet Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    BalanceSheetReport: {
      title: 'Balance Sheet Report',
      href:
        PATH_APP.app2.MISReports.rootIndexPath + '?II. Balance Sheet Report',
      icon: BalanceSheetIcon,
      key: 'BalanceSheetReport',
      description: 'This is Balance Sheet Report',
      heading: 'Balance Sheet Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Balance Sheet Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    CashFlowSheetReport: {
      title: 'Cash Flow Sheet Report',
      href:
        PATH_APP.app2.MISReports.rootIndexPath + '?III. Cash Flow Sheet Report',
      icon: '',
      key: 'CashFlowSheetReport',
      description: 'This is Cash Flow Sheet Report',
      heading: 'CashFlow Sheet Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Cash Flow Sheet Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    RevenueFromOperations: {
      title: 'Revenue From Operations Report',
      href:
        PATH_APP.app2.MISReports.rootIndexPath + '?IV. Revenue From Operations',
      key: 'RevenueFromOperations',
      icon: RevenueReportIcon,
      description: 'This is Revenue From Operations Report',
      heading: 'Revenue From Operations Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Revenue From Operations Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    EmployeeBenefitExpenses: {
      title: 'Employee Benefit Expenses Report',
      href:
        PATH_APP.app2.MISReports.rootIndexPath +
        '?V. Employee Benefit Expenses',
      icon: '',
      key: 'EmployeeBenefitExpenses',
      description: 'This is Employee Benefit Expenses Report',
      heading: 'Employee Benefit Expenses Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Employee Benefit Expenses Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    ExpenseAnalysis: {
      title: 'Expense Analysis Report',
      href: PATH_APP.app2.MISReports.rootIndexPath + '?VI. Expense Analysis',
      icon: ExpenseAnalysisIcon,
      description: 'This is Expense Analysis Report',
      key: 'ExpenseAnalysis',
      heading: 'Expense Analysis Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Expense Analysist Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    WorkingCapitalManagement: {
      title: 'Working Capital Management Report',
      href:
        PATH_APP.app2.MISReports.rootIndexPath +
        '?VII. Working Capital Management',
      icon: WorkingCapitalManagementIcon,
      key: 'WorkingCapitalManagement',
      description: 'This is Working Capital Management Report',
      heading: 'Working Capital Management Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Working Capital Management Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    CashFlowGraph: {
      title: 'Cash Flow Graph',
      href: PATH_APP.app2.MISReports.rootIndexPath + '?VIII. Cash Flow Graph',
      icon: CashFlowReportIcon,
      key: 'CashFlowGraph',
      description: 'This is Cash Flow Graph',
      heading: 'Cash Flow Graph',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Cash Flow Graph',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    Ratio: {
      title: 'Ratio Report',
      href: PATH_APP.app2.MISReports.rootIndexPath + '?IX. Ratio',
      icon: RatioAnalysisIcon,
      key: 'Ratio',
      description: 'This is Ratio Report',
      heading: 'Ratio Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Ratio Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    },
    BudgetvsActual: {
      title: 'Budget vs Actual Report',
      href: PATH_APP.app2.MISReports.rootIndexPath + '?X. Budget vs Actual',
      icon: '',
      key: 'BudgetvsActual',
      description: 'This is Budget vs Actual Report',
      heading: 'Budget vs Actual Report',
      breadCrumbs: [
        { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
        {
          name: 'Budget vs Actual Report',
          href: PATH_APP.app2.MISReports.rootIndexPath
        }
      ]
    }
    // MISRatioReportCards: {
    //   title: 'Ratio Analysis Reports',
    //   href: PATH_APP.app2.MISReports.RatioAnalysisReports,
    //   icon: '',
    //   description: 'This is Ratio Analysis Reports',
    //   heading: 'Ratio Analysis Reports',
    //   breadCrumbs: [
    //     { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    //     {
    //       name: 'Ratio Analysis Reports',
    //       href: PATH_APP.app2.MISReports.RatioAnalysisReports
    //     }
    //   ],
    //   children: {
    //     RatioNumbers: RatioNumbers,
    //     RatioCharts: RatioCharts
    //   }
    // },
    // SalaryReport: {
    //   title: 'Salary Reports',
    //   href: PATH_APP.app2.MISReports.SalaryReport,
    //   icon: '',
    //   description: 'This is Salary Reports',
    //   heading: 'Salary Reports',
    //   breadCrumbs: [
    //     { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    //     { name: 'Salary Reports', href: PATH_APP.app2.MISReports.SalaryReport }
    //   ]
    // },
    // BalanceSheetReport: {
    //   title: 'Balance Sheet Reports',
    //   href: PATH_APP.app2.MISReports.BalanceSheetReport,
    //   icon: '',
    //   description: 'This is Balance Sheet Reports',
    //   heading: 'Balance Sheet Reports',
    //   breadCrumbs: [
    //     { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    //     {
    //       name: 'Balance Sheet Reports',
    //       href: PATH_APP.app2.MISReports.BalanceSheetReport
    //     }
    //   ]
    // },
    // PLBalanceSheetReport: {
    //   title: 'Report on Profit & Loss Account and Balance Sheet',
    //   href: PATH_APP.app2.MISReports.PLBalanceSheetReport,
    //   icon: '',
    //   description: 'This is Profit and Loss, Balance Sheet Reports',
    //   heading: 'Report on Profit & Loss Account and Balance Sheet',
    //   breadCrumbs: [
    //     { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    //     {
    //       name: 'Report on profit and loss Account and Balance Sheet',
    //       href: PATH_APP.app2.MISReports.PLBalanceSheetReport
    //     }
    //   ]
    // },
    // ExpensesReport: {
    //   title:
    //     'Report on Sales, Credit Collection Period, Selling & Distribution Expenses',
    //   href: PATH_APP.app2.MISReports.ExpensesReport,
    //   icon: '',
    //   description:
    //     'This is Report on Sales, Credit Collection Period and Selling & Distribution Expenses Reports',
    //   heading:
    //     'Report on Sales, Credit Collection Period and Selling & Distribution Expenses',
    //   breadCrumbs: [
    //     { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    //     {
    //       name:
    //         'Report on Sales, Credit Collection Period and Selling & Distribution Expenses',
    //       href: PATH_APP.app2.MISReports.ExpensesReport
    //     }
    //   ]
    // },
    // ProfitAndLossReport: {
    //   title: 'Profit and Loss Report',
    //   href: PATH_APP.app2.MISReports.ProfitAndLossReport,
    //   icon: '',
    //   description: 'This is Report on Profit And Loss Reports',
    //   heading: 'Profit And Loss Report',
    //   breadCrumbs: [
    //     { name: 'MIS Reports', href: PATH_APP.app2.MISReports.root },
    //     {
    //       name: 'Profit And Loss Report',
    //       href: PATH_APP.app2.MISReports.ProfitAndLossReport
    //     }
    //   ]
    // }
  }
};
export const SOPColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'number',
    width: 100,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'date',
    width: 200,
    editable: false
  },
  {
    field: 'nameOfProduct',
    headerClassName: 'primary-header',
    headerName: 'Name of the product',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'customerType',
    headerClassName: 'primary-header',
    headerName: 'Customer Type',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'gstin',
    headerClassName: 'primary-header',
    headerName: 'GSTIN',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'clientLocation',
    headerClassName: 'primary-header',
    headerName: 'Client Location',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'salesExecutive',
    headerClassName: 'primary-header',
    headerName: 'Sales Executive',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'salesChannel',
    headerClassName: 'primary-header',
    headerName: 'Sales Channel',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'noOfUnits',
    headerClassName: 'primary-header',
    headerName: 'No of units sold',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'rate',
    headerClassName: 'primary-header',
    headerName: 'Rate',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'taxableValue',
    headerClassName: 'primary-header',
    headerName: 'Taxable Value',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'rateOfGST',
    headerClassName: 'primary-header',
    headerName: 'Rate Of GST',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'cgst',
    headerClassName: 'primary-header',
    headerName: 'CGST',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'sgst',
    headerClassName: 'primary-header',
    headerName: 'SGST',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'igst',
    headerClassName: 'primary-header',
    headerName: 'IGST',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'invoiceValue',
    headerClassName: 'primary-header',
    headerName: 'Invoice Value',
    width: 200,
    type: 'number',
    editable: false
  }
];
export const SOPFields = [
  'invoiceNo',
  'invoiceDate',
  'nameOfProduct',
  'category',
  'branch',
  'customerType',
  'gstin',
  'clientLocation',
  'salesExecutive',
  'salesChannel',
  'noOfUnits',
  'rate',
  'taxableValue',
  'rateOfGST',
  'cgst',
  'sgst',
  'igst',
  'invoiceValue'
];
export const SOSColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'date',
    width: 200,
    editable: false
  },
  {
    field: 'serviceDescription',
    headerClassName: 'primary-header',
    headerName: 'Service Description',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'department',
    headerClassName: 'primary-header',
    headerName: 'Department',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'customerType',
    headerClassName: 'primary-header',
    headerName: 'Customer Type',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'uniqueCIN',
    headerClassName: 'primary-header',
    headerName: 'Unique CIN',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'clientLocation',
    headerClassName: 'primary-header',
    headerName: 'Client Location',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'salesExecutive',
    headerClassName: 'primary-header',
    headerName: 'Sales Executive',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'salesChannel',
    headerClassName: 'primary-header',
    headerName: 'Sales Channel',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 200,
    type: 'number',
    editable: false
  }
];
export const SOSFields = [
  'invoiceNo',
  'invoiceDate',
  'serviceDescription',
  'category',
  'department',
  'branch',
  'customerType',
  'uniqueCIN',
  'clientLocation',
  'salesExecutive',
  'salesChannel',
  'amount'
];
export const ORCColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'date',
    width: 200,
    editable: false
  },
  {
    field: 'natureOfOtherOperatingRevenue',
    headerClassName: 'primary-header',
    headerName: 'Nature of other operating revenue',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'productRelated',
    headerClassName: 'primary-header',
    headerName: 'Product it relates to',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'relevantCategory',
    headerClassName: 'primary-header',
    headerName: 'Relevant category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'customerType',
    headerClassName: 'primary-header',
    headerName: 'Customer Type',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'uniqueCIN',
    headerClassName: 'primary-header',
    headerName: 'Unique CIN',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'clientLocation',
    headerClassName: 'primary-header',
    headerName: 'Client Location',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'salesExecutive',
    headerClassName: 'primary-header',
    headerName: 'Sales Executive',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'salesChannel',
    headerClassName: 'primary-header',
    headerName: 'Sales Channel',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'noOfUnitsSold',
    headerClassName: 'primary-header',
    headerName: 'No of units sold',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'rate',
    headerClassName: 'primary-header',
    headerName: 'Rate',
    type: 'number',
    width: 100,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 100,
    type: 'number',
    editable: false
  }
];
export const ORCFields = [
  'invoiceNo',
  'invoiceDate',
  'natureOfOtherOperatingRevenue',
  'productRelated',
  'relevantCategory',
  'branch',
  'customerType',
  'uniqueCIN',
  'clientLocation',
  'salesExecutive',
  'salesChannel',
  'noOfUnitsSold',
  'rate',
  'amount'
];
export const ORVColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'natureOfOtherOperatingRevenue',
    headerClassName: 'primary-header',
    headerName: 'Nature of other operating revenue',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'productRelated',
    headerClassName: 'primary-header',
    headerName: 'Product it relates to',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'relevantCategory',
    headerClassName: 'primary-header',
    headerName: 'Relevant category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'vendorName',
    headerClassName: 'primary-header',
    headerName: 'Vendor name',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'uniqueVIN',
    headerClassName: 'primary-header',
    headerName: 'Unique CIN',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'vendorName',
    headerClassName: 'primary-header',
    headerName: 'Vendor Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'vendorLocation',
    headerClassName: 'primary-header',
    headerName: 'Vendor Location',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'salesExecutive',
    headerClassName: 'primary-header',
    headerName: 'Sales Executive',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'salesChannel',
    headerClassName: 'primary-header',
    headerName: 'Sales Channel',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'noOfUnitsSold',
    headerClassName: 'primary-header',
    headerName: 'No of units sold',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'rate',
    headerClassName: 'primary-header',
    headerName: 'Rate',
    type: 'string',
    width: 100,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 100,
    type: 'string',
    editable: false
  }
];
export const ORVFields = [
  'invoiceNo',
  'invoiceDate',
  'natureOfOtherOperatingRevenue',
  'productRelated',
  'relevantCategory',
  'branch',
  'customerType',
  'uniqueVIN',
  'vendorName',
  'vendorLocation',
  'salesExecutive',
  'salesChannel',
  'noOfUnitsSold',
  'rate',
  'amount'
];
export const InterestIncomeColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'natureOfInterest',
    headerClassName: 'primary-header',
    headerName: 'Nature of Interest',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'periodOfInterest',
    headerClassName: 'primary-header',
    headerName: 'Period of Interest',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'nameOfCompany',
    headerClassName: 'primary-header',
    headerName: 'Name of the Bank/Company',
    type: 'string',
    width: 350,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 100,
    type: 'string',
    editable: false
  }
];
export const InterestIncomeFields = [
  'invoiceNo',
  'invoiceDate',
  'natureOfInterest',
  'periodOfInterest',
  'nameOfCompany',
  'amount'
];
export const DividendIncomeColumns = [
  {
    field: 'nameOfCompany',
    headerClassName: 'primary-header',
    headerName: 'Name of the company',
    type: 'string',
    flex: 1,
    editable: false
  },
  {
    field: 'periodOfDivided',
    headerClassName: 'primary-header',
    headerName: 'Period of Dividend',
    type: 'number',
    width: 300,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 300,
    type: 'number',
    editable: false
  }
];
export const DividendIncomeFields = [
  'nameOfCompany',
  'periodOfDivided',
  'amount'
];
export const ONOIColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'string',
    width: 100,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'natureOfOtherNonOperatingRevenue',
    headerClassName: 'primary-header',
    headerName: 'Nature of other non-operating revenue',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'serviceCategory',
    headerClassName: 'primary-header',
    headerName: 'Service Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 100,
    type: 'string',
    editable: false
  }
];
export const ONOIFields = [
  'invoiceNo',
  'invoiceDate',
  'natureOfOtherNonOperatingRevenue',
  'serviceCategory',
  'branch',
  'amount'
];
export const OtherExpensesColumns = [
  {
    field: 'NatureOfExpense',
    headerClassName: 'primary-header',
    headerName: 'Nature of Expense',
    type: 'string',
    flex: 1,
    editable: false
  },
  {
    field: 'subHead',
    headerClassName: 'primary-header',
    headerName: 'Sub Head',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category ',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 100,
    type: 'string',
    editable: false
  }
];
export const OtherExpensesFields = [
  'NatureOfExpense',
  'subHead',
  'branch',
  'category',
  'amount'
];
export const InsuranceColumns = [
  {
    field: 'typeOfInsurance',
    headerClassName: 'primary-header',
    headerName: 'Type of Insurance',
    type: 'string',
    flex: 1,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'startDate',
    headerClassName: 'primary-header',
    headerName: 'Start Date ',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'endDate',
    headerClassName: 'primary-header',
    headerName: 'End Date',
    width: 100,
    type: 'string',
    editable: false
  }
];
export const InsuranceFields = [
  'typeOfInsurance',
  'branch',
  'amount',
  'startDate',
  'endDate'
];
export const FinanceCostColumns = [
  {
    field: 'liabilityType',
    headerClassName: 'primary-header',
    headerName: 'Liability Type',
    type: 'string',
    flex: 1,
    editable: false
  },
  {
    field: 'nature',
    headerClassName: 'primary-header',
    headerName: 'Nature',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'closingBalance',
    headerClassName: 'primary-header',
    headerName: 'Closing balance',
    type: 'number',
    width: 150,
    editable: false
  },
  {
    field: 'rateOfInterest',
    headerClassName: 'primary-header',
    headerName: 'Rate of Interest',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'interestForThePeriod',
    headerClassName: 'primary-header',
    headerName: 'Interest for the period',
    width: 250,
    type: 'number',
    editable: false
  }
];
export const FinanceCostFields = [
  'liabilityType',
  'nature',
  'closingBalance',
  'rateOfInterest',
  'interestForThePeriod'
];
export const PSTradeColumns = [
  {
    field: 'invoiceNo',
    headerClassName: 'primary-header',
    headerName: 'Invoice No',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'invoiceDate',
    headerClassName: 'primary-header',
    headerName: 'Invoice Date',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'nameOfProduct',
    headerClassName: 'primary-header',
    headerName: 'Name of the product',
    type: 'string',
    width: 300,
    editable: false
  },
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'vendorType',
    headerClassName: 'primary-header',
    headerName: 'Vendor type(R/URG)',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'uniqueVIN',
    headerClassName: 'primary-header',
    headerName: 'Unique VIN',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'unitsPurchased',
    headerClassName: 'primary-header',
    headerName: 'No of units purchased',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'rate',
    headerClassName: 'primary-header',
    headerName: 'Rate',
    type: 'string',
    width: 100,
    editable: false
  },
  {
    field: 'amount',
    headerClassName: 'primary-header',
    headerName: 'Amount',
    width: 100,
    type: 'string',
    editable: false
  }
];
export const PSTradeFields = [
  'invoiceNo',
  'invoiceDate',
  'nameOfProduct',
  'branch',
  'vendorType',
  'uniqueVIN',
  'unitsPurchased',
  'rate',
  'amount'
];

export const RecordOfConsumptionColumns = [
  {
    field: 'itemName',
    headerClassName: 'primary-header',
    headerName: 'Item Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'groupName',
    headerClassName: 'primary-header',
    headerName: 'Group Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'subgroupName',
    headerClassName: 'primary-header',
    headerName: 'Sub-group Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'uom',
    headerClassName: 'primary-header',
    headerName: 'UoM',
    type: 'string',
    width: 150,
    editable: false
  },
  {
    field: 'qttyConsumed',
    headerClassName: 'primary-header',
    headerName: 'Qtty Consumed',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'value',
    headerClassName: 'primary-header',
    headerName: 'Value',
    type: 'number',
    width: 200,
    editable: false
  }
];
export const RecordOfConsumptionFields = [
  'itemName',
  'category',
  'uom',
  'qttyConsumed',
  'value'
];
// export const RawMaterialCommonColumns = [
//     { field: 'total', headerClassName: 'primary-header', headerName: 'Total', type: 'string', flex: 1, editable: false, },
//     { field: 'categoryA', headerClassName: 'primary-header', headerName: 'Category A', type: 'number', width: 300, editable: false },
//     { field: 'categoryB', headerClassName: 'primary-header', headerName: 'Category B', type: 'string', width: 300, editable: false },
//     { field: 'categoryC', headerClassName: 'primary-header', headerName: 'Category C', type: 'string', width: 300, editable: false },
// ];
export const DerivedConsumptionColumns = [
  {
    field: 'itemName',
    headerClassName: 'primary-header',
    headerName: 'Item Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'groupName',
    headerClassName: 'primary-header',
    headerName: 'Group Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'subgroupName',
    headerClassName: 'primary-header',
    headerName: 'Sub-group Name',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'uom',
    headerClassName: 'primary-header',
    headerName: 'UoM',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'opQtty',
    headerClassName: 'primary-header',
    headerName: 'Opening stock Qtty',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'opValue',
    headerClassName: 'primary-header',
    headerName: ' Opening stock Value',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'purchQtty',
    headerClassName: 'primary-header',
    headerName: 'Purchases Qtty',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'purchValue',
    headerClassName: 'primary-header',
    headerName: 'Purchases Value',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'closeQtty',
    headerClassName: 'primary-header',
    headerName: 'Closing stock Qtty',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'closeValue',
    headerClassName: 'primary-header',
    headerName: 'Closing stock Value',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'dcQtty',
    headerClassName: 'primary-header',
    headerName: 'Derived Consumption Qtty',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'dcValue',
    headerClassName: 'primary-header',
    headerName: 'Derived Consumption Value',
    width: 200,
    type: 'string',
    editable: false
  }
];
export const DerivedConsumptionFields = [
  'itemName',
  'category',
  'uom',
  'opQtty',
  'opValue',
  'purchQtty',
  'purchValue',
  'closeQtty',
  'closeValue',
  'dcQtty',
  'dcValue'
];
export const ChangeInventoryWIPColumns = [
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'itemDescription',
    headerClassName: 'primary-header',
    headerName: 'Item Description',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'openWIP',
    headerClassName: 'primary-header',
    headerName: 'Opening WIP',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'closingWIP',
    headerClassName: 'primary-header',
    headerName: 'Closing WIP',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'change',
    headerClassName: 'primary-header',
    headerName: 'Change',
    type: 'number',
    width: 200,
    editable: false
  }
];
export const ChangeInventoryWIPFields = [
  'category',
  'itemDescription',
  'branch',
  'openWIP',
  'closingWIP',
  'change'
];
export const ChangeInventoryFGColumns = [
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'itemDescription',
    headerClassName: 'primary-header',
    headerName: 'Item Description',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'openFG',
    headerClassName: 'primary-header',
    headerName: 'Opening FG',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'closingFG',
    headerClassName: 'primary-header',
    headerName: 'Closing FG',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'change',
    headerClassName: 'primary-header',
    headerName: 'Change',
    type: 'number',
    width: 200,
    editable: false
  }
];
export const ChangeInventoryFGFields = [
  'category',
  'itemDescription',
  'branch',
  'openFG',
  'closingFG',
  'change'
];
export const ChangeInventorySTColumns = [
  {
    field: 'category',
    headerClassName: 'primary-header',
    headerName: 'Category',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'product',
    headerClassName: 'primary-header',
    headerName: 'Product',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'openStock',
    headerClassName: 'primary-header',
    headerName: 'Opening ST',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'closingStock',
    headerClassName: 'primary-header',
    headerName: 'Closing ST',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'change',
    headerClassName: 'primary-header',
    headerName: 'Change',
    type: 'number',
    width: 200,
    editable: false
  }
];
export const ChangeInventorySTFields = [
  'product',
  'branch',
  'openStock',
  'closingStock',
  'change'
];

export const employeeBenefitColumns = [
  {
    field: 'monthName',
    headerClassName: 'primary-header',
    headerName: 'Month',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'emplopyeeId',
    headerClassName: 'primary-header',
    headerName: 'Employee ID',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'type',
    headerClassName: 'primary-header',
    headerName: 'Type',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'productCategory',
    headerClassName: 'primary-header',
    headerName: 'Product Category',
    width: 200,
    type: 'string',
    editable: false
  },
  {
    field: 'branch',
    headerClassName: 'primary-header',
    headerName: 'Branch',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'team',
    headerClassName: 'primary-header',
    headerName: 'Team',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'revenueGenerating',
    headerClassName: 'primary-header',
    headerName: 'Revenue Generating ? (Y/N)',
    width: 100,
    type: 'string',
    editable: false
  },
  {
    field: 'basicSalary',
    headerClassName: 'primary-header',
    headerName: 'Basic Salary',
    type: 'string',
    width: 200,
    editable: false
  },
  {
    field: 'DA',
    headerClassName: 'primary-header',
    headerName: 'DA',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'HRA',
    headerClassName: 'primary-header',
    headerName: 'HRA',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'medicalAllowance',
    headerClassName: 'primary-header',
    headerName: 'Medical Allowance',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'specialAllowance',
    headerClassName: 'primary-header',
    headerName: 'Special Allowance',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'perquisites',
    headerClassName: 'primary-header',
    headerName: 'Perquisites',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'bonus',
    headerClassName: 'primary-header',
    headerName: 'Bonus',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'incentives',
    headerClassName: 'primary-header',
    headerName: 'Incentives',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'grossSalary',
    headerClassName: 'primary-header',
    headerName: 'Gross Salary',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'ECEPF',
    headerClassName: 'primary-header',
    headerName: 'Employee-Contribution-EPF',
    width: 300,
    type: 'number',
    editable: false
  },
  {
    field: 'ECESI',
    headerClassName: 'primary-header',
    headerName: 'Employee-Contribution-ESI',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'ECGratuity',
    headerClassName: 'primary-header',
    headerName: 'Employee-Contribution-Gratuity',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'ECTotalContributions',
    headerClassName: 'primary-header',
    headerName: 'Employee Total Contributions',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'SBPESOP',
    headerClassName: 'primary-header',
    headerName: 'Share based ESOP',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'SBPESOS',
    headerClassName: 'primary-header',
    headerName: 'Share based ESOS',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'SBPRSU',
    headerClassName: 'primary-header',
    headerName: 'Share based RSU',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'SBPStockOptions',
    headerClassName: 'primary-header',
    headerName: 'Stock Options',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'SBPTotalEmployeeCost',
    headerClassName: 'primary-header',
    headerName: 'Total employee cost',
    width: 300,
    type: 'number',
    editable: false
  },
  {
    field: 'SBPGrossPay',
    headerClassName: 'primary-header',
    headerName: 'Gross pay (Net off non cash elements)',
    type: 'number',
    width: 300,
    editable: false
  },
  {
    field: 'DEPF',
    headerClassName: 'primary-header',
    headerName: 'Deduction-EPF',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'DESI',
    headerClassName: 'primary-header',
    headerName: 'Deduction-ESI',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'DPT',
    headerClassName: 'primary-header',
    headerName: 'Deduction-PT',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'DTDS',
    headerClassName: 'primary-header',
    headerName: 'Deduction-TDS',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'DLoan',
    headerClassName: 'primary-header',
    headerName: 'Deduction-Loan',
    type: 'number',
    width: 200,
    editable: false
  },
  {
    field: 'DTotalDeductions',
    headerClassName: 'primary-header',
    headerName: 'Total deduction',
    width: 200,
    type: 'number',
    editable: false
  },
  {
    field: 'netPay',
    headerClassName: 'primary-header',
    headerName: 'Net-Pay',
    type: 'number',
    width: 200,
    editable: false
  }
];
export const employeeBenefitFields = [
  'monthName',
  'emplopyeeId',
  'type',
  'productCategory',
  'branch',
  'team',
  'revenueGenerating',
  'basicSalary',
  'DA',
  'HRA',
  'medicalAllowance',
  'specialAllowance',
  'perquisites',
  'bonus',
  'incentives',
  'ECGratuity',
  'SBPESOP',
  'SBPESOS',
  'SBPRSU',
  'DEPF',
  'DESI',
  'DPT',
  'DTDS',
  'DLoan'
];
export const HeaderName = {
  monthName: 'Month',
  grossSalary: 'Gross Salary',
  invoiceNo: 'Invoice No',
  invoiceDate: 'Invoice date',
  nameOfProduct: 'Name of the product',
  category: 'Category',
  branch: 'Branch',
  customerType: 'Customer type',
  gstin: 'GSTIN',
  clientLocal: 'Client Location',
  salesExecutive: 'Sale executive',
  salesChannel: 'Sales Channel',
  noOfUnits: 'No of units sold',
  rate: 'Rate',
  taxableValue: 'Taxable Value',
  rateOfGST: 'Rate of GST',
  cgst: 'CGST',
  igst: 'IGST',
  sgst: 'SGST',
  invoiceValue: 'Invoice Value',
  serviceDescription: 'Service Description',
  department: 'Department',
  uniqueCIN: 'Unique CIN',
  clientLocation: 'Client Location',
  amount: 'Amount',
  natureOfOtherOperatingRevenue: 'Nature of other operating revenue',
  natureOfOtherOperatingRevenueC: 'Nature of other operating revenue Customer',
  serviceCategory: 'Service Category',
  productRelated: 'Product it relates to',
  relevantCategory: 'Relevant category',
  clientName: 'Client Name',
  uniqueVIN: 'Unique VIN',
  noOfUnitsSold: 'No of units sold',
  vendorLocation: 'Vendor Location',
  vendorName: 'Vendor Name',
  natureOfInterest: 'Nature of Interest',
  periodOfInterest: 'Period of Interest',
  nameOfCompany: 'Name of the company',
  periodOfDivided: 'Period of Dividend',
  natureOfOtherNonOperatingRevenue: 'Nature of other non operating revenue',
  month: 'Monthly',
  emplopyeeId: 'Employee',
  team: 'Team',
  revenueGenerating: 'Revenue Generating? (Y/N)',
  productCategory: 'Product category',
  basicSalary: 'Basic salary',
  DA: 'DA',
  HRA: 'HRA',
  medicalAllowance: 'Medical allowance',
  specialAllowance: 'Special Allowance',
  perquisites: 'Perquisites',
  bonus: 'Bonus',
  incentives: 'Incentives',
  ECEPF: 'Employee-Contribution-EPF',
  ECESI: 'Employee-Contribution-ESI',
  ECGratuity: 'Employee-Contribution-Gratuity',
  ECTotalContributions: 'Employee Total Contributions',
  SBPESOP: 'Share based SOP',
  SBPESOS: 'Share based SOS',
  SBPRSU: 'Share based RSU',
  SBPStockOptions: 'Share based Stock options',
  SBPTotalEmployeeCost: 'Share based Total employee cost',
  SBPGrossPay: 'Gross pay (Net off non cash elements)',
  DEPF: 'Deduction-EPF',
  DESI: 'Deduction-ESI',
  DPT: 'Deduction-PT',
  DTDS: 'Deduction-TDS',
  DLoan: 'Deduction-Loan',
  type: 'Type',
  netPay: 'Net pay',
  DTotalDeductions: 'Total deduction',
  liabilityType: 'Liability Type',
  nature: 'Nature',
  closingBalance: 'Closing balance',
  rateOfInterest: 'Rate of Interest',
  interestForThePeriod: 'Interest for the period',
  NatureOfExpense: 'Nature of Expense',
  subHead: 'Sub Head',
  typeOfInsurance: 'Type of Insurance',
  startDate: 'Start Date ',
  endDate: 'End Date',
  vendorType: 'Vendor type(R/URG)',
  unitsPurchased: 'No of units purchased',
  itemDescription: 'Item Description',
  openWIP: 'Opening WIP',
  closingWIP: 'Closing WIP',
  change: 'Change',
  openFG: 'Opening FG',
  closingFG: 'Closing FG',
  openStock: 'Opening ST',
  closingStock: 'Closing ST',
  itemName: 'Item Name',
  category: 'Category',
  uom: 'UoM',
  qttyConsumed: 'Qtty Consumed',
  value: 'Value',
  categoryAndProduct: 'Category/Product classification',
  opQtty: 'Opening stock Qtty',
  opValue: ' Opening stock Value',
  purchQtty: 'Purchases Qtty',
  purchValue: 'Purchases Value',
  closeQtty: 'Closing stock Qtty',
  closeValue: 'Closing stock Value',
  dcQtty: 'Derived Consumption Qtty',
  dcValue: 'Derived Consumption Value',
  groupName: 'Group Name',
  subgroupName: 'Sub-group Name',
  specialAllowances: 'Special Allowances',
  da: 'DA',
  ECTotalContributions: 'Total Contributions',
  phantoStocks: 'Phanto Stocks',
  sar: 'SAR',
  SBPTotalEmployeeCost: 'Total Employee Cost',
  DTotalDeductions: 'Total Deductions',
  netPay: 'Net Pay',
  employeeProvidentFund: 'Employee provident fund',
  employeeStateInsurance: 'Employee state Insurance',
  gratuityFund: 'Gratuity fund'
};
export const DataSheetBA = {
  rawMaterialConsumption: 'Raw Material Consumption',
  closingWIP: 'Closing WIP',
  openingWIP: 'Opening WIP',
  directWages: 'Direct Wages',
  otherDirectexpenses: 'Other Direct expenses',
  manufacturingOverhead: 'Manufacturing Overhead',
  productionCost: 'Production Cost',
  totalFactorycostofproduction: 'Total Factory cost of production',
  averageStockofWIP: 'Average Stock of WIP',
  averageDailyConsumptionofRawMaterial:
    'Average Daily Consumption of Raw Material',
  purchaseofStockinTrade: 'Purchase of Stock in Trade',
  closingStock: 'Closing Stock',
  openingStock: 'Opening Stock',
  totalCostofGoodssold: 'Total Cost of Goods sold',
  averageStockinTrade: 'Average Stock in Trade',
  averagedailyCostofGoodssold: 'Average daily Cost of Goods sold',
  averagedailyCostofGoodssoldl: 'Average daily Cost of Goods soldl',
  averageStockOfRawMaterial: 'Average Stock Of Raw Material',
  closingStockofFG: 'Closing Stock of FG',
  openingStockofFG: 'Opening Stock of FG',
  averageStockofFG: 'Average Stock of FG',
  closingBalance: 'Closing Balance',
  creditsaleduringtheperiod: 'Credit sale during the period',
  openingBalance: 'Opening Balance',
  average: 'Average',
  dailyAverage: 'Daily Average',
  creditpurchasesduringtheperiod: 'Credit purchases during the period',
  currentInvestments: 'Current Investments',
  tradeReceivables: 'Trade Receivables',
  'cash&cashEquivalents': 'Cash & Cash Equivalents',
  TOTAL: 'TOTAL',
  otherCurrentAssets: 'Other Current Assets',
  otherCurrentLiabilities: 'Other Current Liabilities',
  prepaidExpenses: 'Prepaid Expenses',
  quickAssets: 'Quick Assets',
  shortTermBorrowings: 'Short Term Borrowings',
  'shortTermLoans&Advances': 'Short Term Loans & Advances',
  shortTermProvisions: 'Short Term Provisions',
  totalCurrentLiabilities: 'Total Current Liabilities',
  tradePayables: 'Trade Payables',
  customerName: 'Customer name',
  invoiceNo: 'Invoice no',
  amountPending: 'Amount pending',
  lessThan30Days: '< 30 Days',
  between30To60Days: '30 to 60 Days',
  between60To90Days: '60 to 90 Days',
  greaterThan120Days: '> 120 Days',
  averageDailyCostOfGoodsSold: 'Average daily Cost of Goods sold',
  averageStockOfFg: 'Average Stock of FG',
  closingStockOfFg: 'Closing Stock of FG',
  openingStockOfFg: 'Opening Stock of FG',
  productionCost: 'Production Cost',
  totalCostOfGoodsSold: 'Total Cost Of Goods Sold'
};
export const Workinprogress = {
  category: 'wcm',
  components: 'Work in Progress Holding period',
  rawMaterialConsumption: 222.0,
  closingWIP: 1.0,
  openingWIP: 50.0,
  directWages: 8.8,
  otherDirectexpenses: 8.8,
  manufacturingOverhead: 8.8,
  productionCost: 248.40000000000003,
  totalFactorycostofproduction: 297.40000000000003,
  averageStockofWIP: 25.5,
  averageDailyConsumptionofRawMaterial: 0.8147945205479453
};
export const Stockintrade = {
  category: 'Working Capital Management',
  components: 'Stock in trade holding period',
  purchaseofStockinTrade: 0.0,
  closingStock: 1.0,
  openingStock: 1.0,
  totalCostofGoodssold: 0.0,
  averageStockinTrade: 1.0,
  averagedailyCostofGoodssold: 0.0,
  averagedailyCostofGoodssoldl: null
};
export const Rawmaterialstorageperiod = {
  category: 'Working Capital Management',
  components: 'Raw Material Storage Period',
  rawMaterialConsumption: 222.0,
  closingStock: 1.0,
  openingStock: 1.0,
  averageStockOfRawMaterial: 1.5,
  averageDailyConsumptionofRawMaterial: 0.6082191780821918
};
export const Finishedgoods = {
  category: 'Working Capital Management',
  components: 'Finished Goods Storage Period',
  rawMaterialConsumption: 222.0,
  closingStockOfFg: 1.0,
  openingStockOfFg: 100.0,
  directWages: 8.8,
  otherDirectexpenses: 8.8,
  manufacturingOverhead: 8.8,
  productionCost: 248.40000000000003,
  totalCostOfGoodsSold: 347.40000000000003,
  averageStockOfFg: 50.5,
  averageDailyCostOfGoodsSold: 0.9517808219178083
};
export const Receivablecollections = {
  category: 'Working Capital Management',
  components: 'Receivables Collection period',
  closingBalance: 1.0,
  creditsaleduringtheperiod: 100.0,
  openingBalance: 1.0,
  average: 1.0,
  dailyAverage: 0.273972602739726
};
export const Creditperiod = {
  category: 'Working Capital Management',
  components: 'Credit Period Allowed by Creditor',
  closingBalance: 1.0,
  creditpurchasesduringtheperiod: 100.0,
  openingBalance: 1.0,
  average: 1.0,
  dailyAverage: 0.273972602739726
};

export const AccountPayableFields = {
  customerName: '',
  invoiceNo: '',
  amountPending: '',
  lessThan30Days: '',
  between30To60Days: '',
  between60To90Days: '',
  greaterThan120Days: ''
};
export const AccountFieldArray = [
  'customerName',
  'invoiceNo',
  'amountPending',
  'lessThan30Days',
  'between30To60Days',
  'between60To90Days',
  'greaterThan120Days'
];

export const StringFields = [
  'emplopyeeId',
  'serviceCategory',
  'type',
  'customerType',
  'clientLocation',
  'salesExecutive',
  'salesChannel',
  'category',
  'department',
  'uniqueVIN',
  'relevantCategory',
  'itemDescription',
  'vendorName',
  'product',
  'subgroupName',
  'groupName'
];

export const FormatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + '01',
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};
export const PDJson = {
  branch: [],
  departmentAndCategory: [],
  productAndCategory: [],
  team: [],
  location: [],
  executive: [],
  channel: [],
  product: [],
  brands: [],
  department: [],
  branch: ['branch1'],
  category: [],
  categoryanditemdescription: [],
  lastWip: 0,
  'department-Category-Product-Brands': []
};
export const CustomerType = ['B2B', 'B2C'];
export const ServiceCategory = ['Sale Of Product', 'Sale Of Service'];
export const tabsdata = {
  //  As per records of consumption: true
  //   Category-Group-Subgroup-Item: true
  Insurance: true,
  costofmaterial: true,
  costofmaterialsubsubpart: 'Disable',
  financecost: true,
  inventories: {
    workInProgress: true,
    finishedGoods: true,
    stockInTrade: true
  },
  finishedGoods: true,
  stockInTrade: true,
  workInProgress: true,
  otherexpensees: true,
  purchaseOfStockInTrade: true
};
