import mock from '~/utils/mock';
import {  reject } from 'lodash';

let areas = [
    {
      id : 1,
      "area_name": "Share Holder Fund",
      "code": "A",
      "classification": "Balance Sheet",
      "subareas":[]
    },
    {
      id : 2,
      "area_name": "Share Holder Incentives",
      "code": "B",
      "classification": "Balance Sheet 1",
      "subareas":[]
    },
    {
      id : 3,
      "area_name": "Reserves and Surplus",
      "code": "c",
      "classification": "Balance Sheet 1",
      "subareas":[]
    },
]

mock.onGet("/api/areas/get").reply(200,areas);

mock.onPost('/api/areas/post').reply((config) => {
  // Parse the request data if needed
  const requestData = JSON.parse(config.data);
  
  // Add the new areas to the mock data
  areas.push(requestData);

  // Return a 200 response with updated mock data
  return [200, areas];
});

mock.onPut('/api/areas/update').reply((config) => {
  const requestData = JSON.parse(config.data);
  const index = areas.findIndex(area => area.id === requestData.id);

  if (index !== -1) {
    areas[index] = requestData;
    return [200, areas];
  } else {
    return [404];
  }
});

mock.onPost('/api/areas/delete').reply(request => {
  try {
    const { areaId } = JSON.parse(request.data);
    areas =areas.filter(area => area.id !== areaId);
    return [200,  areas ];
  } catch (err) {
    console.error(err);
    return [500, { message: 'Internal server error' }];
  }
});
