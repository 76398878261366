import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { formatDistanceToNow } from 'date-fns';
import clockFill from '@iconify-icons/eva/clock-fill';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Avatar,
  ListItem,
  Typography,
  ListItemText,
  ListItemAvatar
} from '@material-ui/core';
import { services } from '~/servicesConstants';
import { PATH_APP } from '~/routes/paths';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5, 2.5),
    '&:not(:last-child)': {
      marginBottom: 1
    }
  },
  isUnRead: {
    backgroundColor: theme.palette.action.selected
  },
  avatar: {
    position: 'relative',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: '#d2e9ff',
    marginLeft: '5px',
    '& .MuiAvatar-img': { borderRadius: '50%', width: '70%', height: '70%' },
    '& .MuiAvatar-root': { width: '100%', height: '100%' }
  }
}));

// ----------------------------------------------------------------------



NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  className: PropTypes.string
};

function NotificationItem({ notification, className }) {
  const classes = useStyles();
  const serviceJSON = JSON.parse(JSON.stringify(services));

  const handleServices = (key) => {
    var data = notification.notification_data
    if (data?.sub_service_child !== '') {
      return serviceJSON.children[data.service_type].children[data.service].children[data.sub_service].children[data.sub_service_child][key]
    } else {
      return serviceJSON.children[data.service_type].children[data.service].children[data.sub_service][key]
    }
  }

  // const handleNotification = () => {
  //   var data = notification.notification_data
  //   const formURL = PATH_APP.app2.notificationForm
  //   if (formURL !== undefined) {
  //     history.push(`${formURL}/${data.order_id}`, data.type);
  //   }
  // }

  return (
    <ListItem
      button
      to={{
        pathname: `${PATH_APP.app2.notificationForm}/${notification.notification_data.order_id}`,
        state: notification.notification_data.type
      }}
      disableGutters
      key={notification.id}
      component={RouterLink}
      className={
        clsx(
          classes.root,
          {
            [classes.isUnRead]: notification.is_read
          },
          className
        )}
    >
      <ListItemAvatar>
        <div>
          <div className={classes.avatar}>
            <Avatar
              alt={notification.title}
              style={{ backgroundColor: '#d2e9ff' }}
              src={handleServices('icon')}
            />
          </div>
        </div>
      </ListItemAvatar>

      <ListItemText
        primary={
          <Box>
            <Typography variant="subtitle2">
              {handleServices('tags')[0]} {notification.notification_type}
              <Typography variant="body2" color="textSecondary">
                {notification.order_id}
              </Typography>
            </Typography>
          </Box>
        }
        secondary={
          <Box
            component="span"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              typography: 'caption',
              color: 'text.disabled'
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {formatDistanceToNow(new Date(notification.created_time))} ago
          </Box>
        }
      />
    </ListItem >
  );
}

export default NotificationItem;
