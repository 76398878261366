import mock from '~/utils/mock'; 

// Create a new instance of MockAdapter


// Define mock data for the analysis
const Data = {
  "analysis_count": 21,
  "phases_count": 0,
  "phase_step_counts": 7,
  "template_count": 31,
  "status_count": 40,
  "entitytype_count": 12,
  "industry_count": 42,
  "area_count": 13,
  "accounts_count": 43,
  "sector_count": 8,
  "subarea_count": 7,
  "checklist_count": 6,
  "step_count": 7,
  "compliance_count": 6
};

// Define the mock API endpoint for fetching analysis count
mock.onGet('/master_data/count').reply(200, Data);
// console.log('Mock API response:', Data);
// Export the data for external use if needed

export const status = () => {
    return Data;
  }

