import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import Factory from '~/utils/factory';
const initialState = {
  taskAssignment: [],
  isLoading: false,
  error: null
};

const taskAssignmentSlice = createSlice({
  name: 'Task Assignment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET SUBAREA
    getTaskAssignmentSuccess(state, action) {
      state.isLoading = false;
      // state.taskAssignment = action.payload;
      state.error = null; // Clear any previous error
      state.taskAssignment = action.payload;
    },

    // POST SUBAREA
    createTaskAssignmentSuccess(state, action) {
      state.isLoading = false;
      state.taskAssignment.push(action.payload);
    },
    updateTaskAssignmentSuccess(state, action) {
      state.isLoading = false;
      state.taskAssignment = state.taskAssignment.map(taskAssignment =>
        taskAssignment.id === action.payload.id
          ? action.payload
          : taskAssignment
      );
    },
    // DELETE TASK ASSIGNMENT
    deleteTaskAssignmentSuccess(state, action) {
      state.isLoading = false;
      state.taskAssignment = state.taskAssignment.filter(
        task => task.id !== action.payload
      );
    }
  }
});

export const { onToggleFollow } = taskAssignmentSlice.actions;

export function getTaskAssignment(newid) {
  return async dispatch => {
    dispatch(taskAssignmentSlice.actions.startLoading());
    try {
      console.log('New ID received:', newid); // Log the newid object received
      console.log('New Project ID:', newid.id); // Log the project ID
      const url = `due_diligence/projecttasks/${newid.id}`; // Ensure id is correctly appended to the URL

      const { res } = await Factory('get', url);
      console.log('API Response:', res); // Log the API response

      // Check if the response is an array
      if (Array.isArray(res)) {
        dispatch(taskAssignmentSlice.actions.getTaskAssignmentSuccess(res)); // Dispatch the array of task assignments
      } else if (typeof res === 'object') {
        // If it's a single object, convert it to an array with a single element and dispatch
        dispatch(taskAssignmentSlice.actions.getTaskAssignmentSuccess([res]));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log('Error:', error.message);
      dispatch(taskAssignmentSlice.actions.hasError(error.message));
    }
  };
}

export function createTaskAssignment(projectId, newTask) {
  return async dispatch => {
    dispatch(taskAssignmentSlice.actions.startLoading());
    try {
      let projectIdValue = projectId; // assuming projectId is passed correctly
      if (typeof projectId === 'object') {
        // If projectId is an object, extract the id property
        projectIdValue = projectId.id;
      }
      let url = `due_diligence/task/${projectIdValue}`;
      // Assuming Factory function is correctly implemented to make the API call
      let { res } = await Factory('post', url, newTask);
      if (Array.isArray(res)) {
        dispatch(taskAssignmentSlice.actions.createTaskAssignmentSuccess(res)); // Assuming res contains the updated taskAssignment
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error(error.message); // Using console.error for errors
      dispatch(taskAssignmentSlice.actions.hasError(error.message));
    }
  };
}

export function updatetaskassignment(Id, updatedTask) {
  return async dispatch => {
    dispatch(taskAssignmentSlice.actions.startLoading());
    dispatch(taskAssignmentSlice.actions.startLoading());
    try {
      // console.log(newTask)
      let url = `due_diligence/updatetasks/${Id}`;
      console.log('New task assigment ID:', Id);

      let { res, variant, message } = await Factory('put', url, updatedTask);
      console.log(res);
      if (res) {
        dispatch(
          taskAssignmentSlice.actions.updateTaskAssignmentSuccess(updatedTask)
        );
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log(error.message, 'Error');
      dispatch(taskAssignmentSlice.actions.hasError(error.message));
    }
  };
}
export function deleteTaskAssignment(taskId) {
  return async dispatch => {
    dispatch(taskAssignmentSlice.actions.startLoading());
    try {
      console.log(taskId);
      const response = await axios.delete(
        `/api/deleteTaskAssignment/${taskId}`
      );
      if (response.status === 200) {
        // If deletion is successful, dispatch the action to update the state
        dispatch(
          taskAssignmentSlice.actions.deleteTaskAssignmentSuccess(taskId)
        );
      } else {
        // Handle the case where the deletion request was not successful
        throw new Error('Failed to delete task assignment');
      }
    } catch (error) {
      dispatch(taskAssignmentSlice.actions.hasError(error));
    }
  };
}

export default taskAssignmentSlice.reducer;
