
import mock from '~/utils/mock'; 

const projects = [
{
"id": 3,
"password": "pbkdf2_sha256$180000$Z6syb27SYeS4$pB4KdvF0B3V7d+w6+69itxaKc8bG+psQYbyQ0SxbVzI=",
"last_login": null,
"is_superuser": false,
"role_id": "3",
"firmName": "",
"firmRep": "",
"firmRegNo": "",
"firmType": "",
"firmID": "1",
"role_name": "Client",
"companyName": null,
"admin_id": "2",
"company_id": "1",
"mobileNumber": "9573980364",
"emailVerified": true,
"mobileNumberVerified": true,
"address": null,
"country": null,
"stateRegion": null,
"city": null,
"zipCode": null,
"documentsS3Key": null,
"profileImageS3Key": null,
"birthDate": null,
"type": "internal",
"profilePictureUrl": null,
"autogenerated_password": "zgcgnagg",
"email": "srinivasu.tummuri@gmail.com",
"username": "srinivasu.tummuri@gmail.com",
"first_name": "Srinivas",
"last_name": "Tummuri",
"startDate": "2023-09-01 05:43:19.478000+00:00",
"about": "",
"is_staff": false,
"is_active": true,
"businessList": [
{
"id": 1,
"headOffice": {
    "address": "hyderabad",
    "pinCode": "500077",
    "state": "Telangana",
    "city": "Hyderabad"
},
"entityType": "soleProprietor",
"PAN": "AAXCA4501C",
"createdAt": "2023-09-01T05:53:56.676000Z",
"updatedAt": "2023-12-05T06:17:37.539000Z",
"nameOfBusiness": "Akshath Biz Brains Pvt Ltd",
"client": 3,
"ca_admin": 2
},
{
"id": 6,
"headOffice": {
    "address": "hyderabad",
    "pinCode": "500077",
    "state": "Telangana",
    "city": "Hyderabad"
},
"entityType": "llp",
"PAN": "ABVFA8372P",
"createdAt": "2023-09-01T07:20:10.840000Z",
"updatedAt": "2023-10-27T12:24:50.926000Z",
"nameOfBusiness": "Arayavarta Infra Ventures",
"client": 3,
"ca_admin": 2
}
]
},
{
    "id": 4,
    "password": "pbkdf2_sha256$180000$Z6syb27SYeS4$pB4KdvF0B3V7d+w6+69itxaKc8bG+psQYbyQ0SxbVzI=",
    "last_login": null,
    "is_superuser": false,
    "role_id": "3",
    "firmName": "",
    "firmRep": "",
    "firmRegNo": "",
    "firmType": "",
    "firmID": "1",
    "role_name": "Client",
    "companyName": null,
    "admin_id": "2",
    "company_id": "1",
    "mobileNumber": "9573980364",
    "emailVerified": true,
    "mobileNumberVerified": true,
    "address": null,
    "country": null,
    "stateRegion": null,
    "city": null,
    "zipCode": null,
    "documentsS3Key": null,
    "profileImageS3Key": null,
    "birthDate": null,
    "type": "internal",
    "profilePictureUrl": null,
    "autogenerated_password": "zgcgnagg",
    "email": "srinivasu.tummuri@gmail.com",
    "username": "srinivasu.tummuri@gmail.com",
    "first_name": "Srinivas",
    "last_name": "Tummuri",
    "startDate": "2023-09-01 05:43:19.478000+00:00",
    "about": "",
    "is_staff": false,
    "is_active": true,
    "businessList": [
    {
    "id": 1,
    "headOffice": {
        "address": "hyderabad",
        "pinCode": "500077",
        "state": "Telangana",
        "city": "Hyderabad"
    },
    "entityType": "soleProprietor",
    "PAN": "AAXCA4501C",
    "createdAt": "2023-09-01T05:53:56.676000Z",
    "updatedAt": "2023-12-05T06:17:37.539000Z",
    "nameOfBusiness": "Akshath Biz Brains Pvt Ltd",
    "client": 3,
    "ca_admin": 2
    },
    {
    "id": 6,
    "headOffice": {
        "address": "hyderabad",
        "pinCode": "500077",
        "state": "Telangana",
        "city": "Hyderabad"
    },
    "entityType": "llp",
    "PAN": "ABVFA8372P",
    "createdAt": "2023-09-01T07:20:10.840000Z",
    "updatedAt": "2023-10-27T12:24:50.926000Z",
    "nameOfBusiness": "Arayavarta Infra Ventures",
    "client": 3,
    "ca_admin": 2
    }
    ]
    },
    {
        "id": 5,
        "password": "pbkdf2_sha256$180000$Z6syb27SYeS4$pB4KdvF0B3V7d+w6+69itxaKc8bG+psQYbyQ0SxbVzI=",
        "last_login": null,
        "is_superuser": false,
        "role_id": "3",
        "firmName": "",
        "firmRep": "",
        "firmRegNo": "",
        "firmType": "",
        "firmID": "1",
        "role_name": "Client",
        "companyName": null,
        "admin_id": "2",
        "company_id": "1",
        "mobileNumber": "9573980364",
        "emailVerified": true,
        "mobileNumberVerified": true,
        "address": null,
        "country": null,
        "stateRegion": null,
        "city": null,
        "zipCode": null,
        "documentsS3Key": null,
        "profileImageS3Key": null,
        "birthDate": null,
        "type": "internal",
        "profilePictureUrl": null,
        "autogenerated_password": "zgcgnagg",
        "email": "srinivasu.tummuri@gmail.com",
        "username": "srinivasu.tummuri@gmail.com",
        "first_name": "Srinivas",
        "last_name": "Tummuri",
        "startDate": "2023-09-01 05:43:19.478000+00:00",
        "about": "",
        "is_staff": false,
        "is_active": true,
        "businessList": [
        {
        "id": 1,
        "headOffice": {
            "address": "hyderabad",
            "pinCode": "500077",
            "state": "Telangana",
            "city": "Hyderabad"
        },
        "entityType": "soleProprietor",
        "PAN": "AAXCA4501C",
        "createdAt": "2023-09-01T05:53:56.676000Z",
        "updatedAt": "2023-12-05T06:17:37.539000Z",
        "nameOfBusiness": "Akshath Biz Brains Pvt Ltd",
        "client": 3,
        "ca_admin": 2
        },
        {
        "id": 6,
        "headOffice": {
            "address": "hyderabad",
            "pinCode": "500077",
            "state": "Telangana",
            "city": "Hyderabad"
        },
        "entityType": "llp",
        "PAN": "ABVFA8372P",
        "createdAt": "2023-09-01T07:20:10.840000Z",
        "updatedAt": "2023-10-27T12:24:50.926000Z",
        "nameOfBusiness": "Arayavarta Infra Ventures",
        "client": 3,
        "ca_admin": 2
        }
        ]
        }
]


// mock.onGet("/api/industries/get").reply(200,industries);

mock.onPost('/api/projects/post').reply((config) => {
  // Parse the request data if needed
  const requestData = JSON.parse(config.data);
  
  // Add the new industry to the mock data
  projects.push(requestData);

  // Return a 200 response with updated mock data
  return [200, projects];
});

// // Mock PUT request to update an industry
// mock.onPut('/api/industries/update').reply((config) => {
//   // Parse the request data if needed
//   const requestData = JSON.parse(config.data);

//   // Find the index of the industry to update in the mock data
//   const index = industries.findIndex(industry => industry.id === requestData.id);

//   if (index !== -1) {
//     // Update the industry in the mock data
//     industries[index] = requestData;

//     // Return a 200 response with updated mock data
//     return [200, industries];
//   } else {
//     // Return a 404 response if industry not found
//     return [404];
//   }
// });

// mock.onPost('/api/industries/delete').reply(request => {
//   try {
//     const { industryId } = JSON.parse(request.data);
//     console.log(industryId,"industryIdindustryIdindustryIdindustryIdindustryId")
//     industries = reject(industries, { id: industryId });
//     console.log(industries,"responsesss")
//     return [200,  industries ];
//   } catch (err) {
//     console.error(err);
//     return [500, { message: 'Internal server error' }];
//   }
// });
