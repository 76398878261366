import mock from '~/utils/mock'; 

// Define mock data for the post
const trialBalanceData =[
    {
        "user_id":1,
        "firm_id":1,
        "project_id":4,
     
       "areas":[
            {
                "area_name": "Non Current Assets",
                "sub_areas": [
                    {
                        "subarea_name": "G.1. Revenue from Operations",
                        "accounts": [
                            {
                                "account_name": "Sale of Products",
                                "ledgers": [
                                    {
                                        "ledger_name": "Discount",
                                        "amount_details": [
                                            {
                                                "year": "2021-2022",
                                                "amount": 527712,
                                                "type": "Cr"
                                            },
                                            {
                                                "year": "2022-2023",
                                                "amount": 527712,
                                                "type": "Cr"
                                            },
                                            {
                                                "year": "2023-2024",
                                                "amount": 527712,
                                                "type": "Cr"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "area_name": "Shareholders Fund",
                "sub_areas": [
                    {
                        "subarea_name": "G.1. Revenue from Operations",
                        "accounts": [
                            {
                                "account_name": "Sale of Products",
                                "ledgers": [
                                    {
                                        "ledger_name": "Discount",
                                        "amount_details": [
                                            {
                                                "year": "2021-2022",
                                                "amount": 527712,
                                                "type": "Cr"
                                            },
                                            {
                                                "year": "2022-2023",
                                                "amount": 527712,
                                                "type": "Cr"
                                            },
                                            {
                                                "year": "2023-2024",
                                                "amount": 527712,
                                                "type": "Cr"
                                            }
                                        ]
                                    }
                                      
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
  ];

// Define the mock API endpoint for fetching all posts
mock.onGet('/api/getTrialBalance').reply(200, trialBalanceData);

// Define the mock API endpoint for creating a post
mock.onPost('/api/postTrialBalance').reply(200, trialBalanceData);

// Define the mock API endpoint for updating a post
mock.onPut('/api/updateTrialBalance/:id').reply(200, { message: 'Post updated successfully' });

// Define the mock API endpoint for deleting a post
// Define the mock API endpoint for fetching a single post by ID
/*mock.onGet('/api/posts/:id').reply(config => {
  const { id } = config.params;
  // Here you would retrieve the post data based on the provided ID
  // For simplicity, let's just return the postData object
  return [200, { post: Data }];
});*/

export const trialBalance = () => {
  return trialBalanceData;
}
