import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Link,
  CardMedia,
  Typography,
  CardContent
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { MIconButton } from '~/@material-extend';
import closeFill from '@iconify-icons/eva/close-fill';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      background: '#d2e9ff'
    }
  },
  cardMediaWrap: {
    position: 'relative',
    width: '100px',
    height: '100px',
    margin: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: '80px',
      height: '80px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '80px',
      height: '80px'
    },
    [theme.breakpoints.up('md')]: {
      width: '90px',
      height: '90px'
    }
  },
  cardMedia: {
    padding: 15,
    top: 0,
    width: '100px',
    height: '100px',
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      width: '80px',
      height: '80px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
      width: '80px',
      height: '80px'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      width: '90px',
      height: '90px'
    }
  },
  title: {
    paddingTop: 0,
    textAlign: 'center'
    // [theme.breakpoints.up('xs')]: {
    //   fontSize: '10px'
    // },
    // [theme.breakpoints.up('sm')]: {
    //   fontSize: '12px'
    // },
    // [theme.breakpoints.up('md')]: {
    //   fontSize: '13px'
    // }
  },
  cardMediaIcon: {
    fill: 'red'
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 0,
    height: '80px',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
      height: '50px'
    },
    [theme.breakpoints.up('sm')]: {
      height: '60px'
    },
    [theme.breakpoints.up('md')]: {
      height: '80px'
    }
  },
  linkCard: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}));

// ----------------------------------------------------------------------

ProductItem.propTypes = {
  service: PropTypes.object,
  className: PropTypes.string
};

function ProductItem({ service, className, ...other }) {
  const classes = useStyles();
  const { title, icon, href, heading } = service;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function ServiceCards({ title, icon }) {
    return (
      <Card className={clsx(classes.root, className)} {...other}>
        <div className={classes.cardMediaWrap}>
          <CardMedia
            component="img"
            title={title}
            src={icon == '' ? '/static2/ServiceIcons/Business.svg' : icon}
            data-sizes="auto"
            className={clsx(classes.cardMedia, 'lazyload blur-up')}
          />
        </div>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const handleCallBack = () => {
    if (title == 'Stock Trading') {
      enqueueSnackbar(
        'Request Submitted. You will get a call back on your registered Mobile Number.',
        {
          variant: 'success',
          action: key => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        }
      );
    }
  };

  return (
    <>
      {/^https?:\/\/(.*)/.test(href) ? (
        <div>
          <a
            color="inherit"
            href={href}
            target="_blank"
            className={classes.linkCard}
          >
            <ServiceCards title={title} icon={icon} />
          </a>
        </div>
      ) : (
        <Link
          to={href !== '' && href}
          name={heading !== '' && heading}
          color="inherit"
          component={href !== '' && RouterLink}
          className={classes.linkCard}
        >
          <div onClick={handleCallBack}>
            <ServiceCards title={title} icon={icon} />
          </div>
        </Link>
      )}
    </>
  );
}

export default ProductItem;
