import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';
import { BASE_URL } from '~/constants';

const initialState = {
  isLoading: false,
  employees: [],
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.employees = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addEmployeeStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addEmployeeSuccess(state, action) {
      state.isLoading = false;
      state.employees.push(action.payload)
    },
    addEmployeeFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateEmployeeSuccess(state, action) {
      state.isLoading = false;
      const updatedEmployee = action.payload;
      state.employees = state.employees.map(employee => {
        return employee.id === updatedEmployee.employee_id ? updatedEmployee : employee;
      });
    },
    
    // DELETE EVENT
    deleteEmployeeSuccess(state, action) {
      state.areas = action.payload;
    },
  },
});

export const { startLoading, getEmployeeSuccess, hasError } = slice.actions;

export const getAllEmployees = (businessId) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  console.log(businessId,"businessIdbusinessIdbusinessId")
  if(!businessId){
    dispatch(slice.actions.getEmployeeSuccess([]));
  }else{
    try {
      let url = `payroll/getAllEmployees?business_id=${businessId}`
      const {res} = await Factory('get',url,{});
      if(Array.isArray(res)){
        dispatch(slice.actions.getEmployeeSuccess(res));
      }else{
        throw new Error("Invalid response form")
      }
    } catch (error) {
      console.log(error,"fetching error")
      dispatch(slice.actions.hasError(error))
    }
  }
  

};

export const addEmployee = (employeeData) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `payroll/addEmp`
      let {variant,res} = await Factory('post',url,employeeData);
      if (res && variant === "success") {
        dispatch(slice.actions.addEmployeeSuccess(res));
        return { success: true }; // Return success status
      } else {
        console.error("Error: Unable to add employee");
        return { success: false, error: "Unable to add employee" }; // Return error message
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };

};

export const editEmployee = (employeeData,employeeId) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `payroll/updateEmp/${employeeId}`
     let {variant,res} =  await Factory('put',url,employeeData);
      if (res && variant === "success") {
        dispatch(slice.actions.updateEmployeeSuccess({...employeeData,employee_id:employeeId}))
        return { success: true }; // Return success status
      } else {
        console.error("Error: Unable to update checklist");
        return { success: false, error: "Unable to update checklist" }; // Return error message
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export const deleteArea = (areaId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.post(`/api/areas/delete`,{areaId});
    dispatch(slice.actions.deleteAreasSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

export default slice.reducer;