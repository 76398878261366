import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import Factory from '~/utils/factory';

const initialState = {
    trialBalance: [],
    isLoading: false,
    error: null,
};

const trialBalanceSlice = createSlice({
    name: 'Trial Balance',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
          state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
      // GET SUBAREA
      getTrialBalanceSuccess(state, action) {
        state.isLoading = false;
        state.trialBalance= action.payload;
      },
      createTrialBalanceSuccess(state, action){
        state.isLoading = false;
        state.trialBalance.push(action.payload);
      }
  
    }
})

export const { onToggleFollow } = trialBalanceSlice.actions;


export const getTrialBalance = (userId,trialId) => async dispatch => {
   
  dispatch(trialBalanceSlice.actions.startLoading());
  try {
    console.log("TRUE")
   let url = `due_diligence/gettrialbalance/${userId}/${trialId}`;
   const response = await axios.get(`due_diligence/gettrialbalance/${userId}/${trialId}`);
   dispatch(trialBalanceSlice.actions.getPhasesSuccess(response.data));
    //let url = `due_diligence/gettrialbalance`;
    //let { res, variant, message } = await Factory("get", url, {});
    console.log(response)
    
    dispatch(trialBalanceSlice.actions.getTrialBalanceSuccess(response));
    console.log("INPUT DATA:",response); 
    
  } catch (error) {
    dispatch(trialBalanceSlice.actions.hasError(error.message));
  }
};

export function createTrialBalance(newData) {
  return async dispatch => {
    dispatch(trialBalanceSlice.actions.startLoading());
    try {
     console.log(newData);
      const response = await axios.post('/api/postTrialBalance', newData);
      dispatch(trialBalanceSlice.actions.createTrialBalanceSuccess(newData));
    } catch (error) {
      dispatch(trialBalanceSlice.actions.hasError(error));
    }
  };
}


export default trialBalanceSlice.reducer;