import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  userDetails: {}
};

const slice = createSlice({
  name: 'loginUserDetails',
  initialState: {
    userDetails: {}
  },
  reducers: {
    getUserData: (state = initialState, action) => {
      state.userDetails = action.payload.userDetails;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUser(client) {
  return async dispatch => {
    dispatch(slice.actions.getUserData(client));
  };
}
