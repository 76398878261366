import { PATH_APP, PATH_PAGE, PATH_HOME } from '~/routes/paths';
import { TDSServicesListCards } from './views/finance-box/Clients/ComplianceManager/TDSServices/TDSServiceConstants';
import { EWayBillRegistration, TradeLicense } from './views/finance-box/ServiceViews/ServicesDetails';
import PayRollServiceCards from './views/finance-box/ComplianceManager/PayRollServices/PayRollServiceCards';
// import {data} from '~/layouts/HomeLayout/Header';

//GST Registrations
// export const GSTRegistration = {
//   title: 'GST Registration',
//   icon: '/static2/ServiceIcons/GSTServices/GstRegistration.svg',
//   href: PATH_APP.app2.services.GSTRegistration,
//   description: '',
//   heading: 'GST Registration',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
//     { name: 'GST Registration' }
//   ],
//   children: {
//     GSTRegistrationIndividual: {
//       title: 'GST Registration - Individual',
//       icon: '/static2/ServiceIcons/GSTServices/Individual.svg',
//       href: PATH_APP.app2.GSTServices.registrationIndividual,
//       endNode: true,
//       description: '',
//       tags: ['Registration: Individual', 'GST', 'Business']
//     },
//     GSTRegistrationCompany: {
//       title: 'GST Registration - Company',
//       icon: '/static2/ServiceIcons/GSTServices/Company.svg',
//       href: PATH_APP.app2.GSTServices.registrationcompany,
//       endNode: true,
//       description: '',
//       tags: ['Registration: Company', 'GST', 'Business']
//     }
//   }
// };

// //INDIVIDUAL SERVICES

// export const SalariedEmployee = {
//   title: 'Salaried Employee',
//   icon: '/static2/ServiceIcons/individual/income tax/Salary income.svg',
//   href: PATH_APP.app2.services.SalariedEmployee,
//   description: '',
//   heading: 'Salaried Employee',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     {
//       name: 'Income Tax Returns',
//       href: PATH_APP.app2.services.IncomeTaxReturns
//     },
//     { name: 'Salaried Employee' }
//   ],
//   children: {
//     // MoreThan50Lakh: {
//     //     title: 'Salaried Employees More than 50 Lakhs',
//     //     icon: '/static2/ServiceIcons/individual/income tax/Salary income.svg',
//     //     href: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.MoreThan50Lakh,
//     //     endNode: true,
//     //     description: ''
//     // },
//     salaryHPCPOI: {
//       title: 'Salary + House Property + Capital gain + Other Income',
//       icon: '/static2/ServiceIcons/individual/income tax/Salary+hp+Cg.svg',
//       href:
//         PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
//           .SalaryHousePropertyCapitalGainOtherIncome,
//       endNode: true,
//       description: '',
//       tags: ['Capital Gains', 'IT Returns', 'Individual']
//     },
//     salaryHPOI: {
//       title: 'Salary + House Property + Other Income',
//       icon:
//         '/static2/ServiceIcons/individual/income tax/Salary income+ House property.svg',
//       href:
//         PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
//           .SalaryHousePropertyOtherIncome,
//       endNode: true,
//       description: '',
//       tags: ['Other Income', 'IT Returns', 'Individual']
//     }
//   }
// };

// export const IncomeTaxReturns = {
//   title: 'Income Tax Returns',
//   icon: '/static2/ServiceIcons/individual/income tax/ITR.svg',
//   href: PATH_APP.app2.services.IncomeTaxReturns,
//   description: '',
//   heading: 'Income Tax Returns',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     { name: 'Income Tax Returns' }
//   ],
//   children: {
//     SalariedEmployee: SalariedEmployee
//     // TaxNoticeManagement: {
//     //     title: 'Tax notice management',
//     //     icon:   '',
//     //     href: PATH_PAGE.comingSoon,
//     //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
//     // },
//     // TaxQueries: {
//     //     title: 'Tax Queries',
//     //     icon:   '',
//     //     href: PATH_PAGE.comingSoon,
//     //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
//     // },
//   }
// };

// export const TDSOnSaleOfProperty = {
//   title: 'TDS on Sale of Property',
//   icon: '/static2/ServiceIcons/BusinessServices/TDS.svg',
//   href: PATH_APP.app2.services.TdsOnSaleOfProperty,
//   description: 'This is TDS On Sale of property',
//   heading: 'TDS on Sale of Property',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     { name: 'TDS on Sale of Property' }
//   ],
//   children: {
//     Form26QB: {
//       title: 'Form 26QB',
//       icon:
//         '/static2/ServiceIcons/TDSServices/TDS on sale of immovable property.svg',
//       href: PATH_APP.app2.TDSOnSaleOfProperty.Form26QB,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const FinancialAndLegalAdvisory = {
//   title: 'Financial And Legal Advisory',
//   icon:
//     '/static2/ServiceIcons/BusinessServices/Financial Advisory and legal services.svg',
//   href: PATH_APP.app2.services.FinancialAndLegalAdvisory,
//   heading: 'Financial And Legal Advisory',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     { name: 'Financial And Legal Advisory' }
//   ],
//   children: {
//     DraftingOfDocuments: {
//       title: 'Drafting Of Documents',
//       icon:
//         '/static2/ServiceIcons/individual/Financial and legal services/Drafting of documents.svg',
//       href: PATH_APP.app2.FinancialAndLegalAdvisory.DraftingOfDocuments,
//       endNode: true,
//       description: ''
//     },
//     LegalReview: {
//       title: 'Legal Review',
//       icon:
//         '/static2/ServiceIcons/individual/Financial and legal services/Legal review.svg',
//       href: PATH_APP.app2.FinancialAndLegalAdvisory.LegalReview,
//       endNode: true,
//       description: ''
//     }
//     // ReviewBusinessDocuments: {
//     //     title: 'Legal Review of Business Documents',
//     //     icon:   '',
//     //     href: PATH_PAGE.comingSoon,
//     //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
//     // },
//     // ReviewPropertyDocuments: {
//     //     title: 'Legal Review of Property Documents',
//     //     icon:   '',
//     //     href: PATH_PAGE.comingSoon,
//     //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
//     // },
//     // LegalDrafting: {
//     //     title: 'Legal Drafting',
//     //     icon:   '',
//     //     href: PATH_PAGE.comingSoon,
//     //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
//     // },
//   }
// };

// export const HealthInsurances = {
//   title: 'Health Insurances',
//   icon: '/static2/ServiceIcons/Insurance/Health Insurance.svg',
//   href: PATH_APP.app2.services.HealthInsuranceIndividual,
//   description: '',
//   heading: 'Health Insurances',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     {
//       name: 'Insurance Services',
//       href: PATH_APP.app2.services.InsuranceServices
//     },
//     { name: 'Health Insurances' }
//   ],
//   children: {
//     CoronavirusInsurance: {
//       title: 'Coronavirus Insurance',
//       icon:
//         '/static2/ServiceIcons/individual/Insurance/Corono virus insurance.svg',
//       href: PATH_APP.app2.InsuranceServices.CoronavirusInsurance,
//       endNode: true,
//       description: ''
//     },
//     HealthInsurance: {
//       title: 'Health Insurance',
//       icon: '/static2/ServiceIcons/individual/Insurance/Health Insurance.svg',
//       href: PATH_APP.app2.InsuranceServices.HealthInsurance,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const VehicleInsurances = {
//   title: 'Vehicle Insurances',
//   icon: '/static2/ServiceIcons/Insurance/Vehicle Insurance.svg',
//   href: PATH_APP.app2.services.VehicleInsuranceIndividual,
//   description: '',
//   heading: 'Vehicle Insurances',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     {
//       name: 'Insurance Services',
//       href: PATH_APP.app2.services.InsuranceServices
//     },
//     { name: 'Vehicle Insurances' }
//   ],
//   children: {
//     CommercialVehicle: {
//       title: 'Commercial Insurance',
//       icon: '',
//       href: PATH_APP.app2.InsuranceServices.CommercialVehicle,
//       endNode: true,
//       description: ''
//     },
//     CarInsurance: {
//       title: 'Car Insurance',
//       icon: '/static2/ServiceIcons/individual/Insurance/car insurance.svg',
//       href: PATH_APP.app2.InsuranceServices.CarInsurance,
//       endNode: true,
//       description: ''
//     },
//     TwoWheelerInsurance: {
//       title: 'Two Wheeler Insurance',
//       icon:
//         '/static2/ServiceIcons/individual/Insurance/Two wheeler Insurance.svg',
//       href: PATH_APP.app2.InsuranceServices.TwoWheelerInsurance,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const InsuranceServices = {
//   title: 'Insurance Services',
//   icon: '/static2/ServiceIcons/BusinessServices/Insurance.svg',
//   href: PATH_APP.app2.services.InsuranceServices,
//   heading: 'Insurance Services',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     { name: 'Insurance Services' }
//   ],
//   children: {
//     HealthInsurances: HealthInsurances,
//     VehicleInsurances: VehicleInsurances
//   }
// };

// export const LoanServices = {
//   title: 'Loan Services',
//   icon: '/static2/ServiceIcons/BusinessServices/Loans.svg',
//   href: PATH_APP.app2.services.LoanServices,
//   heading: 'Loan Services',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices
//     },
//     { name: 'Loan Services' }
//   ],
//   children: {
//     HomeLoan: {
//       title: 'Home Loan',
//       icon: '/static2/ServiceIcons/Loans/Home loans.svg',
//       href: PATH_APP.app2.LoanServices.HomeLoan,
//       endNode: true,
//       description: ''
//     },
//     TermLoan: {
//       title: 'Term Loan',
//       icon: '/static2/ServiceIcons/Loans/Term  Loans.svg',
//       endNode: true,
//       href: PATH_APP.app2.LoanServices.TermLoan,
//       description: ''
//     },
//     VehicleLoans: {
//       title: 'Vehicle Loans',
//       icon: '/static2/ServiceIcons/Loans/Vehicle Loan.svg',
//       endNode: true,
//       href: PATH_APP.app2.LoanServices.VehicleLoan,
//       description: ''
//     },
//     WorkingCapitolLoan: {
//       title: 'Working Capitol Loans',
//       icon: '/static2/ServiceIcons/Loans/Working Capital Loans.svg',
//       endNode: true,
//       href: PATH_APP.app2.LoanServices.WorkingCapitolLoan,
//       description: ''
//     }
//   }
// };

// //Business Services

// export const Company = {
//   title: 'Company Incorporations',
//   icon: '/static2/ServiceIcons/Business Incorporations/company.svg',
//   href: PATH_APP.app2.services.Company,
//   description: '',
//   heading: 'Company Incorporations',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Business Incorporations',
//       href: PATH_APP.app2.services.BusinessIncorporations
//     },
//     { name: 'Company Incorporations' }
//   ],
//   children: {
//     PrivateLimitedCompany: {
//       title: 'Private Limited Company Incorporation',
//       icon:
//         '/static2/ServiceIcons/Business Incorporations/COMPANY/Private Limited Co.svg',
//       href:
//         PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
//           .PvtLtdCompanyIncorporation,
//       endNode: true,
//       description: ''
//     },
//     // PublicLimitedCompany: {
//     //     title: 'Public Limited Company',
//     //     icon: '/static2/ServiceIcons/Business Incorporations/Company/Public Limited Co.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     OnePersonCompany: {
//       title: 'OPC Incorporation',
//       icon:
//         '/static2/ServiceIcons/Business Incorporations/COMPANY/One Person Co.svg',
//       href:
//         PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
//           .OPCIncorporation,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const SolePropriership = {
//   title: 'Sole Proprietorship',
//   icon: '/static2/ServiceIcons/Business Incorporations/Sole Proprietor.svg',
//   href: PATH_APP.app2.services.SoleProprietorshipIncorporations,
//   description: '',
//   heading: 'Sole Proprietorship Incorporations',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Business Incorporations',
//       href: PATH_APP.app2.services.BusinessIncorporations
//     },
//     { name: 'Sole Proprietorship' }
//   ],
//   children: {
//     GSTRegistrationIndividual:
//       GSTRegistration.children.GSTRegistrationIndividual
//   }
// };

// export const BusinessIncorporations = {
//   title: 'Business Incorporations',
//   icon: '/static2/ServiceIcons/BusinessServices/Business Incorporation.svg',
//   href: PATH_APP.app2.services.BusinessIncorporations,
//   description: '',
//   heading: 'Business Incorporations',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Business Incorporations' }
//   ],
//   children: {
//     Company: Company,
//     LimitedLiabilitypartnership
//       title: 'Limited Liability Patnership',
//       icon: '/static2/ServiceIcons/Business Incorporations/LLP.svg',
//       href: PATH_APP.app2.BusinessIncorporations.LLPIncorporation,
//       endNode: true,
//       description: ''
//     },
//     TrustSociety: {
//       title: 'Trust / Society/ Sec 8 Company',
//       icon: '/static2/ServiceIcons/Business Incorporations/Section 8.svg',
//       href: PATH_APP.app2.BusinessIncorporations.Sec8CompanyIncorporation,
//       endNode: true,
//       description: ''
//     },
//     PatnershipFirm: {
//       title: 'Patnership Firm',
//       icon: '/static2/ServiceIcons/Business Incorporations/Partnership.svg',
//       href: PATH_APP.app2.BusinessIncorporations.PatnershipFirm,
//       endNode: true,
//       description: ''
//     },
//     SolePropriership: SolePropriership
//   }
// };

// export const AccountingAndBookkeeping = {
//   title: 'Accounting And Bookkeeping',
//   icon:
//     '/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg',
//   href: PATH_APP.app2.services.AccountingAndBookkeeping,
//   description: '',
//   heading: 'Accounting And Bookkeeping',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Accounting And Bookkeeping' }
//   ],
//   children: {
//     StartUpAccounting: {
//       title: 'Start Up Accounting',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     GrowthAccounting: {
//       title: 'Growth Accounting',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     DedicatedAccounting: {
//       title: 'Dedicated Accounting',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     ReviewAccounting: {
//       title: 'Review Accounting',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     AccountsReceivableManagement: {
//       title: 'Accounts Receivable Management',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const EWayBillServices = {
//   title: 'EWay Bill Services',
//   icon: '/static2/ServiceIcons/GSTServices/E Way Bill.svg',
//   href: PATH_APP.app2.services.EwayBillServices,
//   description: '',
//   heading: 'EWay Bill Services',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
//     { name: 'EWay Bill Services' }
//   ],
//   children: {
//     GSTRegistrationIndividual: {
//       title: 'GST Registration Individual',
//       icon: '',
//       href: PATH_APP.app2.GSTServices.EwayBillGeneration,
//       endNode: true,
//       description: ''
//     },
//     GSTRegistrationCompany: {
//       title: 'GST Registration Company',
//       icon: '',
//       href: PATH_APP.app2.GSTServices.EwayBillRegistration,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const GSTCancellationAndRevocation = {
//   title: 'GST Cancellation And Revocation',
//   icon: '/static2/ServiceIcons/GSTServices/Gst Cancellation.svg',
//   href: PATH_APP.app2.services.GSTCancellationAndRevocation,
//   description: '',
//   heading: 'GST Cancellation And Revocation',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
//     { name: 'GST Cancellation And Revocation' }
//   ],
//   children: {
//     GSTCancellation: {
//       title: 'GST Cancellation',
//       icon: '/static2/ServiceIcons/GSTServices/Gst Cancellation.svg',
//       href: PATH_APP.app2.GSTServices.GSTCancellation,
//       endNode: true,
//       description: ''
//     },
//     GSTRevocation: {
//       title: 'GST Revocation',
//       icon: '/static2/ServiceIcons/GSTServices/GST revocation.svg',
//       href: PATH_APP.app2.GSTServices.GSTRevocation,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const GSTServices = {
//   title: 'GST Services',
//   icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
//   href: PATH_APP.app2.services.GSTServices,
//   description: '',
//   heading: 'GST Services',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'GST Services' }
//   ],
//   children: {
//     GSTRegistration: GSTRegistration,
//     GSTReturns: {
//       title: 'GST Returns',
//       icon: '/static2/ServiceIcons/GSTServices/gst returns.svg',
//       href: PATH_APP.app2.GSTServices.GSTReturns,
//       endNode: true,
//       description: '',
//       tags: ['GST Returns', 'Business']
//     },
//     GSTR9: {
//       title: 'GSTR9 (Annual Filings)',
//       icon: '/static2/ServiceIcons/GSTServices/Gstr- 9.svg',
//       href: PATH_APP.app2.GSTServices.GSTR9AnuualReturn,
//       endNode: true,
//       description: ''
//     },
//     LUTFilings: {
//       title: 'LUT Filings',
//       icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
//       href: PATH_APP.app2.GSTServices.LUTFilings,
//       endNode: true,
//       description: ''
//     },
//     EWayBillServices: EWayBillServices,
//     GSTCancellationAndRevocation: GSTCancellationAndRevocation,
//     AmendmentInGSTRegistration: {
//       title: 'Amendment In GST Registration',
//       icon:
//         '/static2/ServiceIcons/GSTServices/Amendment in Gst Registration.svg',
//       href: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
//       endNode: true,
//       description: ''
//     },
//     GSTNotice: {
//       title: 'GST Notice',
//       icon: '/static2/ServiceIcons/GSTServices/GstNotice.svg',
//       href: PATH_APP.app2.GSTServices.GSTNotice,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const TDSServices = {
//   title: 'TDS Services',
//   icon: '/static2/ServiceIcons/BusinessServices/TDS.svg',
//   href: PATH_APP.app2.services.TDSServices,
//   description: '',
//   heading: 'TDS Services',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'TDS Services' }
//   ],
//   children: {
//     TDSFilingsForSalaries: {
//       title: 'TDS Filings For Salaries (Form 24Q)',
//       icon: '/static2/ServiceIcons/TDSServices/Form-24Q.svg',
//       href: PATH_APP.app2.TDSServices.Form24Q,
//       endNode: true,
//       description: ''
//     },
//     TDSForPaymentsOtherThanSalaries: {
//       title: 'TDS For Payments Other Than Salaries (Form 26Q)',
//       icon: '/static2/ServiceIcons/TDSServices/Form-26Q.svg',
//       href: PATH_APP.app2.TDSServices.Form26Q,
//       endNode: true,
//       description: ''
//     },
//     TDSOnSaleOfImmovableProperty: {
//       title: 'TDS on sale of Immovable Property (Form 26QB)',
//       icon:
//         '/static2/ServiceIcons/TDSServices/TDS on sale of immovable property.svg',
//       href: PATH_APP.app2.TDSServices.Form26QB,
//       endNode: true,
//       description: ''
//     },
//     TDSForNonResidents: {
//       title: 'TDS For Non Residents (Form 27Q)',
//       icon: '/static2/ServiceIcons/TDSServices/Non-Resident.svg',
//       href: PATH_APP.app2.TDSServices.Form27Q,
//       endNode: true,
//       description: ''
//     },
//     StatementForTaxCollectionAtSource: {
//       title: 'Statement For Tax Collection At Source (Form 27EQ)',
//       icon: '/static2/ServiceIcons/TDSServices/TCS.svg',
//       href: PATH_APP.app2.TDSServices.Form27EQ,
//       endNode: true,
//       description: ''
//     }
//     // GenerationOfForm16: {
//     //     title: 'Generation Of Form 16',
//     //     icon: '/static2/ServiceIcons/TDSServices/Form-16.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//   }
// };

// export const IncomeTaxReturnsBusiness = {
//   title: 'Income Tax Returns',
//   icon: '/static2/ServiceIcons/BusinessServices/Tax Returns.svg',
//   href: PATH_APP.app2.services.IncomeTaxReturnsBusiness,
//   description: '',
//   heading: 'Income Tax Returns',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Income Tax Returns' }
//   ],
//   children: {
//     IndividualsIncomeTaxReturns: {
//       title: 'Individual/Business Having Income From Business/Profession',
//       icon: '/static2/ServiceIcons/IncomeTaxReturns/Income Tax Return-3.svg',
//       href: PATH_APP.app2.IncomeTaxReturnsBusiness.IndividualsIncomeTaxReturns,
//       endNode: true,
//       description: '',
//       tags: ['Business/Profession', 'IT Returns', 'Business']
//     },
//     // IndividualHavingBusinessProfession: {
//     //     title: 'Individual Having Business/Profession (ITR 3)',
//     //     icon: '/static2/ServiceIcons/IncomeTaxReturns/Income Tax Return-3.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     // IndividualHUFPatnershipFirmUnderPresumptiveBasis: {
//     //     title: 'Individual/HUF/Patnership Firm Under Presumptive Basis',
//     //     icon: '/static2/ServiceIcons/IncomeTaxReturns/Income Tax Return.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     // ProfessionalIndividualUnderPresumptiveBasis: {
//     //     title: 'Professional Individual Under Presumptive Basis',
//     //     icon: '/static2/ServiceIcons/IncomeTaxReturns/Presumptive.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     PatnershipLLPReturns: {
//       title: 'Patnership/LLP Income Tax Returns',
//       icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
//       href: PATH_APP.app2.IncomeTaxReturnsBusiness.PatnershipLLPReturns,
//       endNode: true,
//       description: ''
//     },
//     CompanyIncomeTaxReturns: {
//       title: 'Company Income Tax Returns',
//       icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
//       href: PATH_APP.app2.IncomeTaxReturnsBusiness.CompanyReturns,
//       endNode: true,
//       description: ''
//     },
//     Society: {
//       title: 'Society Income Tax Returns',
//       icon: '/static2/ServiceIcons/IncomeTaxReturns/Others.svg',
//       href: PATH_APP.app2.IncomeTaxReturnsBusiness.SocietyReturns,
//       endNode: true,
//       description: ''
//     },
//     Trust: {
//       title: 'Trust Income Tax Returns',
//       icon: '/static2/ServiceIcons/IncomeTaxReturns/Others.svg',
//       href: PATH_APP.app2.IncomeTaxReturnsBusiness.TrustReturns,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const AnnualFilingsCompany = {
//   title: 'Annual Filings',
//   icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
//   href: PATH_APP.app2.services.AnnualFilingsCompany,
//   description: '',
//   heading: 'Annual Filings',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
//     { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
//     { name: 'Annual Filings' }
//   ],
//   children: {
//     AOC4: {
//       title: 'AOC-4',
//       icon: '/static2/ServiceIcons/ROCServices/AOC-4.svg',
//       href: PATH_APP.app2.ROCCompliances.AOC4,
//       endNode: true,
//       description: ''
//     },
//     DIR3KYC: {
//       title: 'DIR3KYC',
//       icon: '/static2/ServiceIcons/ROCServices/Dir-3 KYC.svg',
//       href: PATH_APP.app2.ROCCompliances.DIR3KYC,
//       endNode: true,
//       description: ''
//     },
//     MGT7: {
//       title: 'MGT-7',
//       icon: '/static2/ServiceIcons/ROCServices/MGT-7.svg',
//       href: PATH_APP.app2.ROCCompliances.MGT7,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const CompanyFilings = {
//   title: 'Company Filings',
//   icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
//   href: PATH_APP.app2.services.CompanyFilings,
//   description: '',
//   heading: 'Company Filings',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
//     { name: 'Company Filings' }
//   ],
//   children: {
//     AnnualFilingsCompany: AnnualFilingsCompany,
//     AuditorAppointment: {
//       title: 'Auditor Appointment',
//       icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
//       href: PATH_APP.app2.ROCCompliances.AuditorAppointment,
//       endNode: true,
//       description: ''
//     },
//     AuditorRemoval: {
//       title: 'Auditor Removal',
//       icon: '/static2/ServiceIcons/ROCServices/Auditor removal.svg',
//       href: PATH_APP.app2.ROCCompliances.AuditorRemoval,
//       endNode: true,
//       description: ''
//     },
//     ChangeInAuthorizedShareCapital: {
//       title: 'Change In Authorized Share Capitol',
//       icon: '/static2/ServiceIcons/ROCServices/change in share capital.svg',
//       href: PATH_APP.app2.ROCCompliances.ChangeInAuthorizedShareCapital,
//       endNode: true,
//       description: ''
//     },
//     ChangeInObjects: {
//       title: 'Change In Objects',
//       icon: '/static2/ServiceIcons/ROCServices/change in objects.svg',
//       href: PATH_APP.app2.ROCCompliances.ChangeInObjects,
//       endNode: true,
//       description: ''
//     },
//     CommencementOfBusiness: {
//       title: 'Commencement Of Business',
//       icon: '/static2/ServiceIcons/ROCServices/Commencement of business.svg',
//       href: PATH_APP.app2.ROCCompliances.CommencementOfBusiness,
//       endNode: true,
//       description: ''
//     },
//     DPT3: {
//       title: 'DPT-3',
//       icon: '/static2/ServiceIcons/ROCServices/DPT-3.svg',
//       href: PATH_APP.app2.ROCCompliances.DPT3,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const AnnualFilingsLLP = {
//   title: 'Annual Filings',
//   icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
//   href: PATH_APP.app2.services.AnnualFilingsLLP,
//   description: '',
//   heading: 'Annual Filings',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
//     { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
//     { name: 'Annual Filings' }
//   ],
//   children: {
//     Form11: {
//       title: 'Form 11',
//       icon: '/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 8.svg',
//       href: PATH_APP.app2.ROCCompliances.Form11,
//       endNode: true,
//       description: ''
//     },
//     Form8: {
//       title: 'Form 8',
//       icon: '/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 11.svg',
//       href: PATH_APP.app2.ROCCompliances.Form8,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const LLPFilings = {
//   title: 'LLP Filings',
//   icon: '/static2/ServiceIcons/ROCServices/LLP Filings.svg',
//   href: PATH_APP.app2.services.LLPFilings,
//   description: '',
//   heading: 'LLP Filings',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
//     { name: 'LLP Filings' }
//   ],
//   children: {
//     AnnualFilingsLLP: AnnualFilingsLLP
//   }
// };

// export const ROCCompliances = {
//   title: 'ROC Compliances',
//   icon: '/static2/ServiceIcons/BusinessServices/Roc Compliance.svg',
//   href: PATH_APP.app2.services.ROCCompliances,
//   description: '',
//   heading: 'ROC Compliances',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'ROC Compliances' }
//   ],
//   children: {
//     CompanyFilings: CompanyFilings,
//     LLPFilings: LLPFilings
//     // AppointmentResignationOfAuditor: {
//     //     title: 'Appointment/Resignation Of Auditor',
//     //     icon: '/static2/ServiceIcons/ROCServices/Appointment and Removal.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     // INC20A: {
//     //     title: 'INC20A (Commencement of Business)',
//     //     icon: '/static2/ServiceIcons/ROCServices/INC-20A.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     // ChangeInMOA: {
//     //     title: 'Change In MOA',
//     //     icon: '/static2/ServiceIcons/ROCServices/Change in MOA.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     // IncreaseInAuthorizedShareCapital: {
//     //     title: 'Increase In Authorized Share Capital',
//     //     icon: '/static2/ServiceIcons/ROCServices/Increase in capital.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//   }
// };

// export const ESI = {
//   title: 'ESI',
//   icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
//   href: PATH_APP.app2.services.ESI,
//   description: '',
//   heading: 'ESI',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Payroll Compliances',
//       href: PATH_APP.app2.services.PayrollCompliances
//     },
//     { name: 'ESI' }
//   ],
//   children: {
//     ESIRegistration: {
//       title: 'ESI Registration',
//       icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
//       href: PATH_APP.app2.PayrollCompliances.ESIRegistration,
//       endNode: true,
//       description: ''
//     },
//     ESIReturns: {
//       title: 'ESI Returns Filing',
//       icon: '/static2/ServiceIcons/PayrollServices/ESI returns.svg',
//       href: PATH_APP.app2.PayrollCompliances.ESIReturnFiling,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const PF = {
//   title: 'PF',
//   icon: '/static2/ServiceIcons/PayrollServices/Provident fund.svg',
//   href: PATH_APP.app2.services.PF,
//   description: '',
//   heading: 'PF',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Payroll Compliances',
//       href: PATH_APP.app2.services.PayrollCompliances
//     },
//     { name: 'PF' }
//   ],
//   children: {
//     PFRegistration: {
//       title: 'PF Registration',
//       icon: '/static2/ServiceIcons/PayrollServices/PF Reg`n.svg',
//       href: PATH_APP.app2.PayrollCompliances.PFRegistration,
//       endNode: true,
//       description: ''
//     },
//     PFReturnsFilings: {
//       title: 'PF Returns Filings',
//       icon: '/static2/ServiceIcons/PayrollServices/PF Filing.svg',
//       endNode: true,
//       href: PATH_APP.app2.PayrollCompliances.PFFiling,
//       description: ''
//     }
//   }
// };

// export const PT = {
//   title: 'PT',
//   icon: '/static2/ServiceIcons/PayrollServices/Professional tax.svg',
//   href: PATH_APP.app2.services.PT,
//   description: '',
//   heading: 'PT',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Payroll Compliances',
//       href: PATH_APP.app2.services.PayrollCompliances
//     },
//     { name: 'PT' }
//   ],
//   children: {
//     PTRegistrations: {
//       title: 'PT Registrations',
//       icon: '/static2/ServiceIcons/PayrollServices/PT Reg`n.svg',
//       href: PATH_APP.app2.PayrollCompliances.PTRegistration,
//       endNode: true,
//       description: ''
//     },
//     PTReturnsFilings: {
//       title: 'PT Returns Filings',
//       icon: '/static2/ServiceIcons/PayrollServices/PT Return filing.svg',
//       href: PATH_APP.app2.PayrollCompliances.PTReturnFiling,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const PayrollCompliances = {
//   title: 'Payroll Compliances',
//   icon: '/static2/ServiceIcons/BusinessServices/Payroll.svg',
//   href: PATH_APP.app2.services.PayrollCompliances,
//   description: '',
//   heading: 'Payroll Compliances',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Payroll Compliances' }
//   ],
//   children: {
//     ESI: ESI,
//     PayrollProcessing: {
//       title: 'Payroll Processing',
//       icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
//       href: PATH_APP.app2.PayrollCompliances.PayrollProcessing,
//       endNode: true,
//       description: ''
//     },
//     PF: PF,
//     PT: PT
//   }
// };

// export const Loans = {
//   title: 'Loans',
//   icon: '/static2/ServiceIcons/BusinessServices/Loans.svg',
//   href: PATH_APP.app2.services.Loans,
//   description: '',
//   heading: 'Loans',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Loans' }
//   ],
//   children: {
//     HomeLoan: {
//       title: 'Home Loan',
//       icon: '/static2/ServiceIcons/Loans/Home loans.svg',
//       href: PATH_APP.app2.Loans.HomeLoan,
//       endNode: true,
//       description: ''
//     },
//     TermLoan: {
//       title: 'Term Loan',
//       icon: '/static2/ServiceIcons/Loans/Term  Loans.svg',
//       endNode: true,
//       href: PATH_APP.app2.Loans.TermLoan,
//       description: ''
//     },
//     VehicleLoans: {
//       title: 'Vehicle Loans',
//       icon: '/static2/ServiceIcons/Loans/Vehicle Loan.svg',
//       endNode: true,
//       href: PATH_APP.app2.Loans.VehicleLoan,
//       description: ''
//     },
//     WorkingCapitolLoan: {
//       title: 'Working Capitol Loans',
//       icon: '/static2/ServiceIcons/Loans/Working Capital Loans.svg',
//       endNode: true,
//       href: PATH_APP.app2.Loans.WorkingCapitolLoan,
//       description: ''
//     }
//   }
// };

// export const FinancialAdviosryAndLegalServices = {
//   title: 'Financial Advisory And Legal Services',
//   icon:
//     '/static2/ServiceIcons/BusinessServices/Financial Advisory and legal services.svg',
//   href: PATH_APP.app2.services.FinancialAdviosryAndLegalServices,
//   description: '',
//   heading: 'Financial Advisory And Legal Services',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Financial Advisory And Legal Services' }
//   ],
//   children: {
//     DraftingOfDocuments: {
//       title: 'Drafting Of Documents',
//       icon:
//         '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Drafting of documents.svg',
//       href: PATH_APP.app2.FinancialAdviosryAndLegalServices.DraftingOfDocuments,
//       endNode: true,
//       description: ''
//     },
//     LegalReview: {
//       title: 'Legal Review',
//       icon:
//         '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Legal  Review.svg',
//       href: PATH_APP.app2.FinancialAdviosryAndLegalServices.LegalReview,
//       endNode: true,
//       description: ''
//     }
//     // LegalOpinion: {
//     //     title: 'Legal Opinion',
//     //     icon: '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Legal Advisory.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//     // AskAQuery: {
//     //     title: 'Ask A Query',
//     //     icon: '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/ask a query.svg',
//     //     href: PATH_PAGE.comingSoon,
//     //     description: '',
//     // },
//   }
// };

// export const Investments = {
//   title: 'Investments',
//   icon: '/static2/ServiceIcons/BusinessServices/Investments.svg',
//   href: PATH_APP.app2.services.Investments,
//   description: '',
//   heading: 'Investments',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Investments' }
//   ],
//   children: {
//     AggressiveFunds: {
//       title: 'Aggressive Funds',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     BalancedGrowthFund: {
//       title: 'Balanced Growth Fund',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     TaxSavingsFund: {
//       title: 'Tax Savings Fund',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     },
//     LiquidFunds: {
//       title: 'Liquid Funds',
//       icon: '',
//       href: PATH_PAGE.comingSoon,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const HealthInsuranceBusiness = {
//   title: 'Health Insurance',
//   icon: '/static2/ServiceIcons/Insurance/Health Insurance.svg',
//   href: PATH_APP.app2.services.HealthInsuranceBusiness,
//   description: '',
//   heading: 'Health Insurance',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Insurance Advisory',
//       href: PATH_APP.app2.services.InsuranceAdvisory
//     },
//     { name: 'Health Insurance' }
//   ],
//   children: {
//     CoronavirusInsurance: {
//       title: 'Coronavirus Insurance',
//       icon: '',
//       href: PATH_APP.app2.InsuranceAdvisory.CoronavirusInsurance,
//       endNode: true,
//       description: ''
//     },
//     HealthInsurance: {
//       title: 'Health Insurance',
//       icon: '',
//       href: PATH_APP.app2.InsuranceAdvisory.HealthInsurance,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const VehicleInsuranceBusiness = {
//   title: 'Vehicle Insurance',
//   icon: '/static2/ServiceIcons/Insurance/Vehicle Insurance.svg',
//   href: PATH_APP.app2.services.VehicleInsuranceBusiness,
//   description: '',
//   heading: 'Vehicle Insurance',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     {
//       name: 'Insurance Advisory',
//       href: PATH_APP.app2.services.InsuranceAdvisory
//     },
//     { name: 'Vehicle Insurance' }
//   ],
//   children: {
//     CommercialVehicle: {
//       title: 'Commercial Insurance',
//       icon: '',
//       href: PATH_APP.app2.InsuranceAdvisory.CommercialVehicle,
//       endNode: true,
//       description: ''
//     },
//     CarInsurance: {
//       title: 'Car Insurance',
//       icon: '',
//       href: PATH_APP.app2.InsuranceAdvisory.CarInsurance,
//       endNode: true,
//       description: ''
//     },
//     TwoWheelerInsurance: {
//       title: 'Two Wheeler Insurance',
//       icon: '',
//       href: PATH_APP.app2.InsuranceAdvisory.TwoWheelerInsurance,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const InsuranceAdvisory = {
//   title: 'Insurance Advisory',
//   icon: '/static2/ServiceIcons/BusinessServices/Insurance.svg',
//   href: PATH_APP.app2.services.InsuranceAdvisory,
//   description: '',
//   heading: 'Insurance Advisory',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Insurance Advisory' }
//   ],
//   children: {
//     HealthInsuranceBusiness: HealthInsuranceBusiness,
//     VehicleInsuranceBusiness: VehicleInsuranceBusiness
//   }
// };

// export const OtherGovernmentRegistrations = {
//   title: 'Other Government Registrations',
//   icon: '/static2/ServiceIcons/BusinessServices/Other Govt Registrations.svg',
//   href: PATH_APP.app2.services.OtherGovernmentRegistrations,
//   description: '',
//   heading: 'Other Government Registrations',
//   breadCrumbs: [
//     { name: 'Services', href: PATH_APP.app2.services.root },
//     {
//       name: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices
//     },
//     { name: 'Other Government Registrations' }
//   ],
//   children: {
//     DigitalSignatures: {
//       title: 'Digital Signatures',
//       icon:
//         '/static2/ServiceIcons/OtherGovtRegistrations/Digital Signatures.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.DigitalSignature,
//       endNode: true,
//       description: ''
//     },
//     FSSAIFoodLicenseRegistrations: {
//       title: 'Food License Registrations',
//       icon: '/static2/ServiceIcons/OtherGovtRegistrations/Food License.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.FoodLicenseRegistration,
//       endNode: true,
//       description: ''
//     },
//     Form15CA15CB: {
//       title: 'Form 15CA and 15CB',
//       icon: '/static2/ServiceIcons/OtherGovtRegistrations/15CA and 15CB.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.Form15CAAnd15CB,
//       endNode: true,
//       description: ''
//     },
//     IECRegistration: {
//       title: 'IEC Registration',
//       icon: '/static2/ServiceIcons/OtherGovtRegistrations/IEC Registration.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.IECRegistration,
//       endNode: true,
//       description: ''
//     },
//     MSMERegistration: {
//       title: 'MSME Registration',
//       icon:
//         '/static2/ServiceIcons/OtherGovtRegistrations/MSME Registration.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.MSMERegistration,
//       endNode: true,
//       description: ''
//     },
//     PANRegistration: {
//       title: 'PAN Registration',
//       icon: '/static2/ServiceIcons/OtherGovtRegistrations/Pan Card.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.PANRegistration,
//       endNode: true,
//       description: ''
//     },
//     ShopsAndEstablishmentActRegistrations: {
//       title: 'Shops And Establishment Act Registrations',
//       icon:
//         '/static2/ServiceIcons/OtherGovtRegistrations/Shops & Establishment License.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.ShopsAndEshtablishments,
//       endNode: true,
//       description: ''
//     },
//     TrademarkRegistration: {
//       title: 'Trademark Registration',
//       icon: '/static2/ServiceIcons/OtherGovtRegistrations/Trademark.svg',
//       href: PATH_APP.app2.OtherGovtRegistrations.TrademarkRegistration,
//       endNode: true,
//       description: ''
//     }
//   }
// };

// export const services = {
//   heading: 'Services',
//   breadCrumbs: [{ name: 'Services', href: PATH_APP.app2.services.root }],
//   children: {
//     individualServices: {
//       title: 'Individual Services',
//       href: PATH_APP.app2.services.IndividualServices,
//       icon: '/static2/ServiceIcons/Individual.svg',
//       description: 'This is Individual Services',
//       heading: 'Individual Services',
//       breadCrumbs: [
//         { name: 'Services', href: PATH_APP.app2.services.root },
//         {
//           name: 'Individual Services',
//           href: PATH_APP.app2.services.IndividualServices
//         }
//       ],
//       children: {
//         IncomeTaxReturns: IncomeTaxReturns,
//         TDSOnSaleOfProperty: TDSOnSaleOfProperty,
//         FinancialAndLegalAdvisory: FinancialAndLegalAdvisory,
//         InsuranceServices: InsuranceServices,
//         LoanServices: LoanServices
//       }
//     },
//     businessServices: {
//       title: 'Business Services',
//       href: PATH_APP.app2.services.BusinessServices,
//       icon: '/static2/ServiceIcons/Business.svg',
//       description: 'This is Business Services',
//       heading: 'Business Services',
//       breadCrumbs: [
//         { name: 'Services', href: PATH_APP.app2.services.root },
//         {
//           name: 'Business Services',
//           href: PATH_APP.app2.services.BusinessServices
//         }
//       ],
//       children: {
//         BusinessIncorporations: BusinessIncorporations,
//         AccountingAndBookkeeping: AccountingAndBookkeeping,
//         GSTServices: GSTServices,
//         TDSServices: TDSServices,
//         IncomeTaxReturnsBusiness: IncomeTaxReturnsBusiness,
//         ROCCompliances: ROCCompliances,
//         PayrollCompliances: PayrollCompliances,
//         Loans: Loans,
//         FinancialAdviosryAndLegalServices: FinancialAdviosryAndLegalServices,
//         Investments: Investments,
//         InsuranceAdvisory: InsuranceAdvisory,
//         OtherGovernmentRegistrations: OtherGovernmentRegistrations
//       }
//     }
//   }
// };

/////////////////////////////////////////////////////////////////////


///////////// anand  Business Incorporations
export const BusinessIncorporations = {

  children: {
    PrivateLimitedCompany: {
      title: 'Private Limited Company ',
      icon:
        '/static2/ServiceIcons/Business Incorporations/COMPANY/Private Limited Co.svg',
      href: PATH_APP.app2.BusinessIncorporations.Privatelimitedcompany,
      endNode: true,
      description: ''
    },
    OnePersonCompany: {
      title: 'One Person company',
      icon:
        '/static2/ServiceIcons/Business Incorporations/COMPANY/One Person Co.svg',
      href: PATH_APP.app2.BusinessIncorporations.OPCIncorporation,
      endNode: true,
      description: ''
    },
    LimitedLiabilitypartnership: {
      title: 'Limited Liability Patnership',
      icon: '/static2/ServiceIcons/Business Incorporations/LLP.svg',
      href: PATH_APP.app2.BusinessIncorporations.LLPIncorporation,
      endNode: true,
      description: ''
    },
    PartnershipFirm: {
      title: 'Partnership Firm',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
      href: PATH_APP.app2.BusinessIncorporations.Partnershipfirm,
      endNode: true,
      description: ''
    },
    SoleProprietor: {
      title: 'Sole Proprietorship',
      icon: '/static2/ServiceIcons/Business Incorporations/Section 8.svg',
      href: PATH_APP.app2.BusinessIncorporations.SoleProprietorshipIncorporation,
      endNode: true,
      description: ''
    },
    Section8Company: {
      title: 'Section 8 Company',
      icon: '/static2/ServiceIcons/Business Incorporations/Section 8.svg',
      href: PATH_APP.app2.BusinessIncorporations.Sec8CompanyIncorporationView,
      endNode: true,
      description: ''
    },
    Producercompany: {
      title: 'Producer company',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
      href: PATH_APP.app2.BusinessIncorporations.ProducerCompanyView,
      endNode: true,
      description: ''
    },
    NidhiCompany: {
      title: 'Nidhi Company',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
      href: PATH_APP.app2.BusinessIncorporations.NidhiCompanyView,
      endNode: true,
      description: ''
    },
  }
};
export const BusinessIncorporationsRegistrations = {
  title: 'Registrations',
  icon: '/static2/ServiceIcons/BusinessServices/Other Govt Registrations.svg',
  href: PATH_APP.app2.services.OtherGovernmentRegistrations,
  description: '',
  heading: 'Other Government Registrations',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Other Government Registrations' }
  ],

  children: {
    TrustDetails: {
      title: 'Trust/Society',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Others.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.TrustDetails,
      endNode: true,
      description: ''
    },
    MSMERegistration: {
      title: 'MSME',
      icon:
        '/static2/ServiceIcons/OtherGovtRegistrations/MSME Registration.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.MSMERegistration,
      endNode: true,
      description: ''
    },
    IECRegistration: {
      title: 'Import export code (IEC)',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/IEC Registration.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.IECRegistration,
      endNode: true,
      description: ''
    },
    FSSAIFoodLicenseRegistrations: {
      title: 'Food License Registrations',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Food License.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.FoodLicenseRegistration,
      endNode: true,
      description: ''
    },
    TradeLicense: {
      title: 'Trade License',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Food License.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.TradeLicense,
      endNode: true,
      description: ''
    },
    DigitalSignature: {
      title: 'Digital Signatures',
      icon:
        '/static2/ServiceIcons/OtherGovtRegistrations/Digital Signatures.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.DigitalSignature,
      endNode: true,
      description: ''
    },

    Labourlicense: {
      title: 'Labour license (Shops and Establishment Act)',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.Labourlicense,
      endNode: true,
      description: ''
    },
    PANRegistration: {
      title: 'PAN Registration',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Pan Card.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.PANRegistration,
      endNode: true,
      description: ''
    },
    // TrademarkRegistration: {
    //   title: 'Trademark Registration',
    //   icon: '/static2/ServiceIcons/OtherGovtRegistrations/Trademark.svg',
    //   href: PATH_APP.app2.OtherGovtRegistrations.TrademarkRegistration,
    //   endNode: true,
    //   description: ''
    // },

  }
};
//GST 
export const GSTRegistration = {
  title: 'GST Registration',
  icon: '/static2/ServiceIcons/GSTServices/GstRegistration.svg',
  href: PATH_APP.app2.services.GSTRegistration,
  description: '',
  heading: 'GST Registration',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
    { name: 'GST Registration' }
  ],
  children: {


    GSTRegistration: {
      title: 'GST Registration',
      icon: '/static2/ServiceIcons/GSTServices/Individual.svg',
      href: PATH_APP.app2.GSTServices.registrationIndividual,
      endNode: true,
      description: '',
      tags: ['Registration: Individual', 'GST', 'Business']
    },
    GSTFilings: {
      title: 'GST Filings',
      icon: '/static2/ServiceIcons/GSTServices/Gstr- 9.svg',
      href: PATH_APP.app2.GSTServices.GSTR9AnuualReturn,
      endNode: true,
      description: ''
    },
    EwayBillservices: {
      title: 'E Way Bill Services',
      icon: '',
      href: PATH_APP.app2.GSTServices.EwayBillRegistration,
      endNode: true,
      description: ''
    },

    GSTcancellationandrevocation: {
      title: 'GST Cancellation and Revocation',
      icon: '/static2/ServiceIcons/GSTServices/Gst Cancellation.svg',
      href: PATH_APP.app2.GSTServices.GSTCancellation,
      endNode: true,
      description: ''
    },
    AmendmentinGSTregistration: {
      title: 'Amendment In GST Registration',
      icon:
        '/static2/ServiceIcons/GSTServices/Amendment in Gst Registration.svg',
      href: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
      endNode: true,
      description: ''
    },
    GSTNotices: {
      title: 'GST Notices',
      icon: '/static2/ServiceIcons/GSTServices/GstNotice.svg',
      href: PATH_APP.app2.GSTServices.GSTNotice,
      endNode: true,
      description: ''
    },
    LUTFilings: {
      title: 'LUT Filings',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.GSTServices.LUTFilings,
      endNode: true,
      description: ''
    },
    Reconciliations: {
      title: 'Reconciliations',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.GSTServices.Reconcilliations,
      endNode: true,
      description: ''
    },
  }
};
// tds
export const PaymentsinTDS = {
  title: 'TDS /TCS',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  href: PATH_APP.app2.PaymentinTDS,
  description: '',
  heading: 'Payments',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'TDS Services',
      href: PATH_APP.app2.services.TDSServices
    },
    { name: 'Payments' }
  ],
  children: {
    Payment: {
      title: 'Payment',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.PaymentinTDS.PaymentsinTDS,
      endNode: true,
      description: ''
    },
  }
}
// income tax return
export const IncomeTaxReturnsBusiness = {
  title: 'Income Tax Returns',
  icon: '/static2/ServiceIcons/BusinessServices/Tax Returns.svg',
  href: PATH_APP.app2.services.IncomeTaxReturnsBusiness,
  description: '',
  heading: 'Income Tax Returns',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Income Tax Returns' }
  ],
  children: {
    IndividualsIncomeTaxReturns: {
      title: 'Individuals',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Income Tax Return-3.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.IndividualsIncomeTaxReturns,
      endNode: true,
      description: '',
      tags: ['Business/Profession', 'IT Returns', 'Business']
    },

    PrivateLimitedcompany: {
      title: 'PrivateLimitedcompany',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.PrivateLimitedCompanyIncomeTaxReturns,
      endNode: true,
      description: ''
    },
    OnePersoncompany: {
      title: 'OnePersonCompany',
      icon:
        '/static2/ServiceIcons/Business Incorporations/COMPANY/One Person Co.svg',
      href:
        PATH_APP.app2.IncomeTaxReturnsBusiness.OnePersonCompanyIncomeTaxReturns,
      endNode: true,
      description: ''
    },
    LimitedLiabilityPartnership: {
      title: 'LimitedLiabilityPartnership',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.PatnershipLLPReturns,
      endNode: true,
      description: ''
    },
    PartnershipFirm: {
      title: 'PatnershipFirm',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.PatnershipFirmTaxReturns,
      endNode: true,
      description: ''
    },
    Soleproprietor: {
      title: 'Soleproprietor',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.SoleProprietorIncomeTaxReturns,
      endNode: true,
      description: ''
    },
    Section8Company: {
      title: 'Section 8 Company',
      icon: '/static2/ServiceIcons/Business Incorporations/Section 8.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.Sec8CompanyIncomeTaxReturns,
      endNode: true,
      description: ''
    },
    Producercompany: {
      title: 'Producer company',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.ProducerCompanyReturns,
      endNode: true,
      description: ''
    },
    NidhiCompany: {
      title: 'Nidhi Company',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.NidhiCompanyReturns,
      endNode: true,
      description: ''
    },
    Society: {
      title: 'Society',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Others.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.SocietyReturns,
      endNode: true,
      description: ''
    },

    Trust: {
      title: 'Trust',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Others.svg',
      href: PATH_APP.app2.IncomeTaxReturnsBusiness.TrustReturns,
      endNode: true,
      description: ''
    },





  }
};

//payroll
export const payrollprocessingservice = {
  title: 'Payroll Preparation',
  icon: '/static2/ServiceIcons/BusinessServices/Payroll.svg',
  href: PATH_APP.app2.PayrollCompliances.payrollprocessingservice,
  description: '',
  heading: 'Payroll',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Payroll and Compliances', href: PATH_APP.app2.services.PayrollCompliances },
    { name: 'Payroll' }
  ],
  children: {
    PayrollPreparation: {
      title: 'Payroll Preparation',
      icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
      href: PATH_APP.app2.PayrollCompliances.PayrollPreparation,
      endNode: true,
      description: ''
    },
    PayrollGeneration: {
      title: 'Payroll Generation',
      icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
      href: PATH_APP.app2.PayrollCompliances.PayrollGeneration,
      endNode: true,
      description: ''
    },
    // PayrollGeneration: {
    //   title: 'Payslip Generation',
    //   icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
    //   href: PATH_APP.app2.PayrollCompliances.PayrollProcessing,
    //   endNode: true,
    //   description: ''
    // },
    Form16Generation: {
      title: 'Form 16 Generation',
      icon: '/static2/ServiceIcons/TDSServices/Form-16.svg',
      href: PATH_APP.app2.PayrollCompliances.Form16Generation,
      endNode: true,
      description: '',
    },
    EmployeeAgreements: {
      title: 'Employee Agreements',
      icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
      href: PATH_APP.app2.PayrollCompliances.EmployeeAgreements,
      endNode: true,
      description: ''
    },
    SalaryStructure: {
      title: 'Salary Structure',
      icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
      href: PATH_APP.app2.PayrollCompliances.SalaryStructure,
      endNode: true,
      description: ''
    },
    ESOPStructure: {
      title: 'ESOP Structure',
      icon: '/static2/ServiceIcons/PayrollServices/Payroll Processing.svg',
      href: PATH_APP.app2.PayrollCompliances.ESOPStructure,
      endNode: true,
      description: ''
    },
  }
};
export const Registration = {
  title: 'Registration',
  icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
  href: PATH_APP.app2.PayrollCompliances.Registration,
  description: '',
  heading: 'Registration',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'Payroll Compliances',
      href: PATH_APP.app2.services.PayrollCompliances
    },
    { name: 'Registration' }
  ],
  children: {
    ESI: {
      title: 'ESI Registration',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.ESIRegistration,
      endNode: true,
      description: ''
    },
    ProvidentFund: {
      title: 'Provident Fund',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.ProvidentFund,
      endNode: true,
      description: ''
    },

    ProfessionalTax: {
      title: 'Professional Tax',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.ProfessionalTax,
      endNode: true,
      description: ''
    },
    Labourlicense: {
      title: 'Labour license (Shops and Establishment Act)',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.Labourlicense,
      endNode: true,
      description: ''
    },

  }
};

export const PaymentAndReturn = {
  title: 'Payment And Return',
  icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
  href: PATH_APP.app2.PayrollCompliances.PaymentAndReturn,
  description: '',
  heading: 'Payment and Return',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'Payroll Compliances',
      href: PATH_APP.app2.services.PayrollCompliances
    },
    { name: 'Payment and Return' }
  ],
  children: {
    ESI: {
      title: 'ESI Returns Filing',
      icon: '/static2/ServiceIcons/PayrollServices/ESI returns.svg',
      href: PATH_APP.app2.PayrollCompliances.ESIReturnFiling,
      endNode: true,
      description: ''
    },
    ProvidentFund: {
      title: 'Provident Fund',
      icon: '/static2/ServiceIcons/PayrollServices/ESI returns.svg',
      href: PATH_APP.app2.PayrollCompliances.ProvidentFund,
      endNode: true,
      description: ''
    },
    ProfessionalTax: {
      title: 'Professional Tax',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.ProfessionalTax,
      endNode: true,
      description: ''
    },
  }
};

export const Compliance = {
  title: 'Compliance ',
  icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
  href: PATH_APP.app2.PayrollCompliances.Compliance,
  description: '',
  heading: 'Compliance',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'Payroll Compliances',
      href: PATH_APP.app2.services.PayrollCompliances
    },
    { name: 'Compliance' }
  ],
  children: {
    POSH: {
      title: 'POSH',
      icon: '/static2/ServiceIcons/PayrollServices/ESI returns.svg',
      href: PATH_APP.app2.PayrollCompliances.POSH,
      endNode: true,
      description: ''
    },
    Bonus: {
      title: 'Bonus',
      icon: '/static2/ServiceIcons/PayrollServices/ESI returns.svg',
      href: PATH_APP.app2.PayrollCompliances.Bonus,
      endNode: true,
      description: ''
    },
    Gratuity: {
      title: 'Gratuity',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.Gratuity,
      endNode: true,
      description: ''
    },

  }
};

//Roc filings
export const MandatoryCompanyFilings = {
  title: 'Annual Filings',
  icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
  href: PATH_APP.app2.services.AnnualFilingsCompany,
  description: '',
  heading: 'Annual Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
    { name: 'Annual Filings' }
  ],
  children: {
    BusinessCommencementCertificate: {
      title: 'Business Commencement Certificate',
      icon: '/static2/ServiceIcons/ROCServices/AOC-4.svg',
      href: PATH_APP.app2.ROCCompliances.CommencementOfBusiness,
      endNode: true,
      description: ''
    },
    AuditorAppointment: {
      title: 'Auditor Appointment',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.ROCCompliances.AuditorAppointment,
      endNode: true,
      description: ''
    },
    AOC4: {
      title: 'AOC-4',
      icon: '/static2/ServiceIcons/ROCServices/AOC-4.svg',
      href: PATH_APP.app2.ROCCompliances.AOC4,
      endNode: true,
      description: ''
    },

    MGT7: {
      title: 'MGT-7',
      icon: '/static2/ServiceIcons/ROCServices/MGT-7.svg',
      href: PATH_APP.app2.ROCCompliances.MGT7,
      endNode: true,
      description: ''
    },
    DirectorsReport: {
      title: 'Directors Report',
      icon: '/static2/ServiceIcons/ROCServices/Dir-3 KYC.svg',
      href: PATH_APP.app2.ROCCompliances.DirectorReport,
      endNode: true,
      description: ''
    },
  }
};

export const MandatoryLLPFilingsFilings = {
  title: 'Mandatory LLP Filings',
  icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
  href: PATH_APP.app2.services.AnnualFilingsLLP,
  description: '',
  heading: 'Mandatory LLP Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
    { name: 'Mandatory LLP Filings' }
  ],
  children: {
    Form11: {
      title: 'Form 11',
      icon: '/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 8.svg',
      href: PATH_APP.app2.ROCCompliances.Form11,
      endNode: true,
      description: ''
    },
    Form8: {
      title: 'Form 8',
      icon: '/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 11.svg',
      href: PATH_APP.app2.ROCCompliances.Form8,
      endNode: true,
      description: ''
    },
    DIR3KYC: {
      title: 'DIR3KYC',
      icon: '/static2/ServiceIcons/ROCServices/Dir-3 KYC.svg',
      href: PATH_APP.app2.ROCCompliances.DIR3KYC,
      endNode: true,
      description: ''
    },
  }
};

export const OtherCompanyFilings = {
  title: 'Other Company Filings',
  icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
  href: PATH_APP.app2.services.OtherCompanyFilings,
  description: '',
  heading: 'Company Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'Company Filings' }
  ],
  children: {
    DirectorAppointment: {
      title: 'Appointment Of Director',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.ROCCompliances.DirectorAppointment,
      endNode: true,
      description: ''
    },
    DirectorRemoval: {
      title: 'Removal Of Director',
      icon: '/static2/ServiceIcons/ROCServices/Auditor removal.svg',
      href: PATH_APP.app2.ROCCompliances.DirectorRemoval,
      endNode: true,
      description: ''
    },
    AuditorAppointment: {
      title: 'Appointment of Auditor',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.ROCCompliances.AuditorAppointment,
      endNode: true,
      description: ''
    },
    AuditorRemoval: {
      title: 'Removal of Auditor',
      icon: '/static2/ServiceIcons/ROCServices/Auditor removal.svg',
      href: PATH_APP.app2.ROCCompliances.AuditorRemoval,
      endNode: true,
      description: ''
    },
    ChangeInAuthorizedShareCapital: {
      title: 'Increase In Authorized Capitol',
      icon: '/static2/ServiceIcons/ROCServices/change in share capital.svg',
      href: PATH_APP.app2.ROCCompliances.ChangeInAuthorizedShareCapital,
      endNode: true,
      description: ''
    },
    ChangeInObjects: {
      title: 'Object Clause',
      icon: '/static2/ServiceIcons/ROCServices/change in objects.svg',
      href: PATH_APP.app2.ROCCompliances.ChangeInObjects,
      endNode: true,
      description: ''
    },
    // DPT3: {
    //   title: 'DPT-3',
    //   icon: '/static2/ServiceIcons/ROCServices/DPT-3.svg',
    //   href: PATH_APP.app2.ROCCompliances.DPT3,
    //   endNode: true,
    //   description: ''
    // }
  }
};
export const OtherLLPFilings = {
  title: 'Other LLP Filings',
  icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
  href: PATH_APP.app2.services.OtherLLPFilings,
  description: '',
  heading: 'Other LLP Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
    { name: 'Other LLP Filings' }
  ],
  children: {
    AddDesignatedPartner: {
      title: 'Add Designated Partner',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.ROCCompliances.AddDesignatedPartner,
      endNode: true,
      description: ''
    },
    ChangestoLLPAgreement: {
      title: 'Changes to LLp Agreement',
      icon: '/static2/ServiceIcons/ROCServices/Auditor removal.svg',
      href: PATH_APP.app2.ROCCompliances.ChangestoLLPAgreement,
      endNode: true,
      description: ''
    },
    LLPClosing: {
      title: 'close the LLp',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.ROCCompliances.LLPClosing,
      endNode: true,
      description: ''
    },

  }
};
/////////////////////////



export const EWayBillServices = {
  title: 'EWay Bill Services',
  icon: '/static2/ServiceIcons/GSTServices/E Way Bill.svg',
  href: PATH_APP.app2.services.EwayBillServices,
  description: '',
  heading: 'EWay Bill Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
    { name: 'EWay Bill Services' }
  ],
  children: {
    GSTRegistrationIndividual: {
      title: 'GST Registration Individual',
      icon: '',
      href: PATH_APP.app2.GSTServices.EwayBillGeneration,
      endNode: true,
      description: ''
    },
    GSTRegistrationCompany: {
      title: 'GST Registration Company',
      icon: '',
      href: PATH_APP.app2.GSTServices.EwayBillRegistration,
      endNode: true,
      description: ''
    }
  }
};
export const GSTCancellationAndRevocation = {
  title: 'GST Cancellation And Revocation',
  icon: '/static2/ServiceIcons/GSTServices/Gst Cancellation.svg',
  href: PATH_APP.app2.services.GSTCancellationAndRevocation,
  description: '',
  heading: 'GST Cancellation And Revocation',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services', href: PATH_APP.app2.services.GSTServices },
    { name: 'GST Cancellation And Revocation' }
  ],
  children: {
    GSTCancellation: {
      title: 'GST Cancellation',
      icon: '/static2/ServiceIcons/GSTServices/Gst Cancellation.svg',
      href: PATH_APP.app2.GSTServices.GSTCancellation,
      endNode: true,
      description: ''
    },
    GSTRevocation: {
      title: 'GST Revocation',
      icon: '/static2/ServiceIcons/GSTServices/GST revocation.svg',
      href: PATH_APP.app2.GSTServices.GSTRevocation,
      endNode: true,
      description: ''
    }
  }
};
export const AmendmentinGSTregistration = {
  title: 'Amendment in GST registration',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  href: PATH_APP.app2.services.GSTServices,
  description: '',
  heading: 'GST Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services' }
  ],
  children: {
    AmendmentInGSTRegistration: {
      title: 'Amendment In GST Registration',
      icon:
        '/static2/ServiceIcons/GSTServices/Amendment in Gst Registration.svg',
      href: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
      endNode: true,
      description: ''
    },
  }
};
export const GSTNotices = {
  title: 'GST Notice',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  href: PATH_APP.app2.services.GSTServices,
  description: '',
  heading: 'GST Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services' }
  ],
  children: {
    GSTNotices: {
      title: 'GST Notices',
      icon: '/static2/ServiceIcons/GSTServices/GstNotice.svg',
      href: PATH_APP.app2.GSTServices.GSTNotice,
      endNode: true,
      description: ''
    }
  }
};
export const LUTFilings = {
  title: 'LUT Filings',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  href: PATH_APP.app2.services.GSTServices,
  description: '',
  heading: 'GST Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services' }
  ],
  children: {
    LUTFilings: {
      title: 'LUT Filings',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.GSTServices.LUTFilings,
      endNode: true,
      description: ''
    },
  }
};
export const Reconciliations = {
  title: 'Reconciliations',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  href: PATH_APP.app2.services.GSTServices,
  description: '',
  heading: 'GST Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services' }
  ],
  children: {
    Reconciliations: {
      title: 'Reconciliations',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.Reconciliations,
      endNode: true,
      description: ''
    },
  }
};

//TDS/TCS

export const Returns = {
  title: 'Returns',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  // href: PATH_APP.app2.services.GSTServices,
  // description: '',
  // heading: 'TDS Services',
  // breadCrumbs: [
  //   { name: 'Services', href: PATH_APP.app2.services.root },
  //   {
  //     name: 'TDS Services',
  //     href: PATH_APP.app2.services.BusinessServices
  //   },
  //   { name: 'TDS Services' }
  // ],
  children: {
    Payment: {
      title: 'Reconciliations',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.GSTServices.Reconcilliations,
      endNode: true,
      description: ''
    },
  }
}
export const TDSServices = {
  title: 'Returns',
  icon: '/static2/ServiceIcons/BusinessServices/TDS.svg',
  href: PATH_APP.app2.services.TDSServices,
  description: '',
  heading: 'TDS Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'TDS Services' }
  ],
  children: {
    TDSFilingsForSalaries: {
      title: 'Salaries (24Q)',
      icon: '/static2/ServiceIcons/TDSServices/Form-24Q.svg',
      href: PATH_APP.app2.TDSServices.Form24Q,
      endNode: true,
      description: ''
    },
    TDSForPaymentsOtherThanSalaries: {
      title: 'Other Than Salaries (26Q)',
      icon: '/static2/ServiceIcons/TDSServices/Form-26Q.svg',
      href: PATH_APP.app2.TDSServices.Form26Q,
      endNode: true,
      description: ''
    },
    TDSOnSaleOfImmovableProperty: {
      title: 'sale of Immovable Property (26QB)',
      icon:
        '/static2/ServiceIcons/TDSServices/TDS on sale of immovable property.svg',
      href: PATH_APP.app2.TDSServices.Form26QB,
      endNode: true,
      description: ''
    },
    TDSForNonResidents: {
      title: 'Payment to Non Residents (27Q)',
      icon: '/static2/ServiceIcons/TDSServices/Non-Resident.svg',
      href: PATH_APP.app2.TDSServices.Form27Q,
      endNode: true,
      description: ''
    },
    StatementForTaxCollectionAtSource: {
      title: 'TCS (27EQ)',
      icon: '/static2/ServiceIcons/TDSServices/TCS.svg',
      href: PATH_APP.app2.TDSServices.Form27EQ,
      endNode: true,
      description: ''
    }

  }
};
// incometaxfilings





// Accounting & Compliances
export const AccountingServices = {
  title: 'Accounting Services Under package',
  icon:
    '/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg',
  href: PATH_APP.app2.services.AccountingAndBookkeeping,
  description: '',
  heading: 'Accounting Services Under package',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
    { name: 'Accounting Services Under package' }
  ],
  children: {
    BookKeeping: {
      title: 'Bookkeeping',
      icon: '',
      href: PATH_APP.app2.AccountingandComplinaces.BookKeeping,
      endNode: true,
      description: ''
    },
    Monthendreviews: {
      title: 'Month end reviews',
      icon: '',
      href: PATH_APP.app2.AccountingandComplinaces.MonthEndReviews,
      endNode: true,
      description: ''
    },
    MISReport: {
      title: 'MIS Report',
      icon: '',
      href: PATH_APP.app2.AccountingandComplinaces.MISReport,
      endNode: true,
      description: ''
    },
    PayrollMaintenance: {
      title: 'Payroll Maintenance',
      icon: '',
      href: PATH_APP.app2.AccountingandComplinaces.PayrollMaintanance,
      endNode: true,
      description: ''
    },

  }
};
export const ComplianceServices = {
  title: 'Compliance Services Under package',
  icon:
    '/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg',
  href: PATH_APP.app2.services.ComplianceServicesUnderPackage,
  description: '',
  heading: 'Compliance Services Under package',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Accounting and Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
    { name: 'Compliance Services Under package' }
  ],
  children: {
    PreparingandFilingofStatutoryPaymentsandreturns: {
      title: 'Preparing and Filing of Statutory Payments and returns such as GST,TDS/TCS, PT,PF,ESI.',
      icon: '',
      href: PATH_APP.app2.AccountingandComplinaces.PreparingAndFilingofStatutoryPaymentsandReturns,
      endNode: true,
      description: ''
    },
  }
};

// Audit

export const Audit = {
  title: 'Audit',
  icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
  href: PATH_APP.app2.services.Audit,
  description: '',
  heading: 'Audit',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Audit' }
  ],
  children: {
    StatutoryAudit: {
      title: 'Statutory Audit',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.StatutoryAudit,
      endNode: true,
      description: ''
    },
    InternalAudit: {
      title: 'Internal Audit',
      icon: '/static2/ServiceIcons/ROCServices/Auditor removal.svg',
      href: PATH_APP.app2.Audit.InternalAudit,
      endNode: true,
      description: ''
    },
    TaxAudit: {
      title: 'Tax Audit',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.TaxAudit,
      endNode: true,
      description: ''
    },
    SystemsAudit: {
      title: 'Systems Audit',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.SystemsAudit,
      endNode: true,
      description: ''
    },
    ManagementAudit: {
      title: 'Management Audit',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.ManagementAudit,
      endNode: true,
      description: ''
    },
    AgreedUponProcedures: {
      title: 'Agreed Upon Procedures',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.AgreedUponProcedures,
      endNode: true,
      description: ''
    },
    CertificationServices: {
      title: 'Certification Services',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.CertificationServices,
      endNode: true,
      description: ''
    },
    SpecialAudits: {
      title: 'Special Audits',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.Audit.SpecialAudits,
      endNode: true,
      description: ''
    },

  }
};

//GST

export const SalariedEmployee = {
  title: 'Salaried Employee',
  icon: '/static2/ServiceIcons/individual/income tax/Salary income.svg',
  href: PATH_APP.app2.services.SalariedEmployee,
  description: '',
  heading: 'Salaried Employee',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    {
      name: 'Income Tax Returns',
      href: PATH_APP.app2.services.IncomeTaxReturns
    },
    { name: 'Salaried Employee' }
  ],
  children: {
    // MoreThan50Lakh: {
    //     title: 'Salaried Employees More than 50 Lakhs',
    //     icon: '/static2/ServiceIcons/individual/income tax/Salary income.svg',
    //     href: PATH_APP.app2.IncomeTaxReturns.SalariedEmployee.MoreThan50Lakh,
    //     endNode: true,
    //     description: ''
    // },
    salaryHPCPOI: {
      title: 'Salary + House Property + Capital gain + Other Income',
      icon: '/static2/ServiceIcons/individual/income tax/Salary+hp+Cg.svg',
      href:
        PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
          .SalaryHousePropertyCapitalGainOtherIncome,
      endNode: true,
      description: '',
      tags: ['Capital Gains', 'IT Returns', 'Individual']
    },
    salaryHPOI: {
      title: 'Salary + House Property + Other Income',
      icon:
        '/static2/ServiceIcons/individual/income tax/Salary income+ House property.svg',
      href:
        PATH_APP.app2.IncomeTaxReturns.SalariedEmployee
          .SalaryHousePropertyOtherIncome,
      endNode: true,
      description: '',
      tags: ['Other Income', 'IT Returns', 'Individual']
    }
  }
};

export const IncomeTaxReturns = {
  title: 'Income Tax Returns',
  icon: '/static2/ServiceIcons/individual/income tax/ITR.svg',
  href: PATH_APP.app2.services.IncomeTaxReturns,
  description: '',
  heading: 'Income Tax Returns',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    { name: 'Income Tax Returns' }
  ],
  children: {
    SalariedEmployee: SalariedEmployee
    // TaxNoticeManagement: {
    //     title: 'Tax notice management',
    //     icon:   '',
    //     href: PATH_PAGE.comingSoon,
    //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
    // },
    // TaxQueries: {
    //     title: 'Tax Queries',
    //     icon:   '',
    //     href: PATH_PAGE.comingSoon,
    //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
    // },
  }
};

export const TDSOnSaleOfProperty = {
  title: 'TDS on Sale of Property',
  icon: '/static2/ServiceIcons/BusinessServices/TDS.svg',
  href: PATH_APP.app2.services.TdsOnSaleOfProperty,
  description: 'This is TDS On Sale of property',
  heading: 'TDS on Sale of Property',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    { name: 'TDS on Sale of Property' }
  ],
  children: {
    Form26QB: {
      title: 'Form 26QB',
      icon:
        '/static2/ServiceIcons/TDSServices/TDS on sale of immovable property.svg',
      href: PATH_APP.app2.TDSOnSaleOfProperty.Form26QB,
      endNode: true,
      description: ''
    }
  }
};

export const FinancialAndLegalAdvisory = {
  title: 'Financial And Legal Advisory',
  icon:
    '/static2/ServiceIcons/BusinessServices/Financial Advisory and legal services.svg',
  href: PATH_APP.app2.services.FinancialAndLegalAdvisory,
  heading: 'Financial And Legal Advisory',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    { name: 'Financial And Legal Advisory' }
  ],
  children: {
    DraftingOfDocuments: {
      title: 'Drafting Of Documents',
      icon:
        '/static2/ServiceIcons/individual/Financial and legal services/Drafting of documents.svg',
      href: PATH_APP.app2.FinancialAndLegalAdvisory.DraftingOfDocuments,
      endNode: true,
      description: ''
    },
    LegalReview: {
      title: 'Legal Review',
      icon:
        '/static2/ServiceIcons/individual/Financial and legal services/Legal review.svg',
      href: PATH_APP.app2.FinancialAndLegalAdvisory.LegalReview,
      endNode: true,
      description: ''
    }
    // ReviewBusinessDocuments: {
    //     title: 'Legal Review of Business Documents',
    //     icon:   '',
    //     href: PATH_PAGE.comingSoon,
    //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
    // },
    // ReviewPropertyDocuments: {
    //     title: 'Legal Review of Property Documents',
    //     icon:   '',
    //     href: PATH_PAGE.comingSoon,
    //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
    // },
    // LegalDrafting: {
    //     title: 'Legal Drafting',
    //     icon:   '',
    //     href: PATH_PAGE.comingSoon,
    //     description : 'A job description is an internal document that clearly states the essential job requirements, job duties, job responsibilities, and skills required to perform a specific role'
    // },
  }
};

export const HealthInsurances = {
  title: 'Health Insurances',
  icon: '/static2/ServiceIcons/Insurance/Health Insurance.svg',
  href: PATH_APP.app2.services.HealthInsuranceIndividual,
  description: '',
  heading: 'Health Insurances',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    {
      name: 'Insurance Services',
      href: PATH_APP.app2.services.InsuranceServices
    },
    { name: 'Health Insurances' }
  ],
  children: {
    CoronavirusInsurance: {
      title: 'Coronavirus Insurance',
      icon:
        '/static2/ServiceIcons/individual/Insurance/Corono virus insurance.svg',
      href: PATH_APP.app2.InsuranceServices.CoronavirusInsurance,
      endNode: true,
      description: ''
    },
    HealthInsurance: {
      title: 'Health Insurance',
      icon: '/static2/ServiceIcons/individual/Insurance/Health Insurance.svg',
      href: PATH_APP.app2.InsuranceServices.HealthInsurance,
      endNode: true,
      description: ''
    }
  }
};

export const VehicleInsurances = {
  title: 'Vehicle Insurances',
  icon: '/static2/ServiceIcons/Insurance/Vehicle Insurance.svg',
  href: PATH_APP.app2.services.VehicleInsuranceIndividual,
  description: '',
  heading: 'Vehicle Insurances',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    {
      name: 'Insurance Services',
      href: PATH_APP.app2.services.InsuranceServices
    },
    { name: 'Vehicle Insurances' }
  ],
  children: {
    CommercialVehicle: {
      title: 'Commercial Insurance',
      icon: '',
      href: PATH_APP.app2.InsuranceServices.CommercialVehicle,
      endNode: true,
      description: ''
    },
    CarInsurance: {
      title: 'Car Insurance',
      icon: '/static2/ServiceIcons/individual/Insurance/car insurance.svg',
      href: PATH_APP.app2.InsuranceServices.CarInsurance,
      endNode: true,
      description: ''
    },
    TwoWheelerInsurance: {
      title: 'Two Wheeler Insurance',
      icon:
        '/static2/ServiceIcons/individual/Insurance/Two wheeler Insurance.svg',
      href: PATH_APP.app2.InsuranceServices.TwoWheelerInsurance,
      endNode: true,
      description: ''
    }
  }
};

export const InsuranceServices = {
  title: 'Insurance Services',
  icon: '/static2/ServiceIcons/BusinessServices/Insurance.svg',
  href: PATH_APP.app2.services.InsuranceServices,
  heading: 'Insurance Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    { name: 'Insurance Services' }
  ],
  children: {
    HealthInsurances: HealthInsurances,
    VehicleInsurances: VehicleInsurances
  }
};

export const LoanServices = {
  title: 'Loan Services',
  icon: '/static2/ServiceIcons/BusinessServices/Loans.svg',
  href: PATH_APP.app2.services.LoanServices,
  heading: 'Loan Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    {
      name: 'GST',
      href: PATH_APP.app2.services.GST
    },
    { name: 'Loan Services' }
  ],
  children: {
    HomeLoan: {
      title: 'Home Loan',
      icon: '/static2/ServiceIcons/Loans/Home loans.svg',
      href: PATH_APP.app2.LoanServices.HomeLoan,
      endNode: true,
      description: ''
    },
    TermLoan: {
      title: 'Term Loan',
      icon: '/static2/ServiceIcons/Loans/Term  Loans.svg',
      endNode: true,
      href: PATH_APP.app2.LoanServices.TermLoan,
      description: ''
    },
    VehicleLoans: {
      title: 'Vehicle Loans',
      icon: '/static2/ServiceIcons/Loans/Vehicle Loan.svg',
      endNode: true,
      href: PATH_APP.app2.LoanServices.VehicleLoan,
      description: ''
    },
    WorkingCapitolLoan: {
      title: 'Working Capitol Loans',
      icon: '/static2/ServiceIcons/Loans/Working Capital Loans.svg',
      endNode: true,
      href: PATH_APP.app2.LoanServices.WorkingCapitolLoan,
      description: ''
    }
  }
};

//Business Services

export const Company = {
  title: 'Business Incorporations',
  icon: '/static2/ServiceIcons/Business Incorporations/company.svg',
  href: PATH_APP.app2.services.Company,
  description: '',
  heading: 'Business Incorporations',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Business Incorporations' }
  ],
  children: {
    PrivateLimitedCompany: {
      title: 'Private Limited Company ',
      icon:
        '/static2/ServiceIcons/Business Incorporations/COMPANY/Private Limited Co.svg',
      href:
        PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
          .PvtLtdCompanyIncorporation,
      endNode: true,
      description: ''
    },
    OnePersonCompany: {
      title: 'One Person company',
      icon:
        '/static2/ServiceIcons/Business Incorporations/COMPANY/One Person Co.svg',
      href:
        PATH_APP.app2.BusinessIncorporations.CompanyIncorporations
          .OPCIncorporation,
      endNode: true,
      description: ''
    },
    LimitedLiabilityPartnership: {
      title: 'Limited Liability Patnership',
      icon: '/static2/ServiceIcons/Business Incorporations/LLP.svg',
      href: PATH_APP.app2.BusinessIncorporations.LLPIncorporation,
      endNode: true,
      description: ''
    },
    PartnershipFirm: {
      title: 'Partnership Firm',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Partnership.svg',
      href: PATH_APP.app2.BusinessIncorporations.PatnershipFirm,
      endNode: true,
      description: ''
    },
    SoleProprietor: {
      title: 'Sole Proprietorship',
      icon: '/static2/ServiceIcons/Business Incorporations/Section 8.svg',
      href: PATH_APP.app2.BusinessIncorporations.SoleProprietorshipIncorporation,
      endNode: true,
      description: ''
    },
    Section8Company: {
      title: 'Section 8 Company',
      icon: '/static2/ServiceIcons/Business Incorporations/Section 8.svg',
      href: PATH_APP.app2.BusinessIncorporations.Sec8CompanyIncorporationView,
      endNode: true,
      description: ''
    },
    Producercompany: {
      title: 'Producer company',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
      href: PATH_APP.app2.BusinessIncorporations.ProducerCompanyView,
      endNode: true,
      description: ''
    },
    NidhiCompany: {
      title: 'Nidhi Company',
      icon: '/static2/ServiceIcons/IncomeTaxReturns/Company.svg',
      href: PATH_APP.app2.BusinessIncorporations.NidhiCompanyView,
      endNode: true,
      description: ''
    },
  }
};

export const BusinessIncorporationandRegistrations = {
  title: 'Business Incorporations and Registrations',
  icon: '/static2/ServiceIcons/BusinessServices/Business Incorporation.svg',
  href: PATH_APP.app2.services.BusinessIncorporationandRegistrations,
  description: '',
  heading: 'Business Incorporations and Registrations',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Business Incorporations and Registrations' }
  ],
  children: {
    Company: Company,
    Registration: BusinessIncorporationsRegistrations,
  },

};

export const AccountingAndBookkeeping = {
  title: 'Accounting And Bookkeeping',
  icon:
    '/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg',
  href: PATH_APP.app2.services.AccountingAndBookkeeping,
  description: '',
  heading: 'Accounting And Bookkeeping',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Accounting And Bookkeeping' }
  ],
  children: {
    AccountingServices: {
      title: 'Accounting Services Under package',
      icon:
        '/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg',
      href: PATH_APP.app2.services.AccountingServicesUnderPackage,
      description: '',
      heading: 'Accounting Services Under package',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
        { name: 'Accounting Services Under package' }
      ],
    },

    ComplianceServices: ComplianceServices
  }
};

export const GSTServices = {
  title: 'GST Services',
  icon: '/static2/ServiceIcons/BusinessServices/GST.svg',
  href: PATH_APP.app2.services.GSTServices,
  description: '',
  heading: 'GST Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'GST Services' }
  ],
  children: {
    GSTRegistration: GSTRegistration,
    // GSTReturns: {
    //   title: 'GST Returns',
    //   icon: '/static2/ServiceIcons/GSTServices/gst returns.svg',
    //   href: PATH_APP.app2.GSTServices.GSTReturns,
    //   endNode: true,
    //   description: '',
    //   tags: ['GST Returns', 'Business']
    // },
    // GSTR9: {
    //   title: 'GSTR9 (Annual Filings)',
    //   icon: '/static2/ServiceIcons/GSTServices/Gstr- 9.svg',
    //   href: PATH_APP.app2.GSTServices.GSTR9AnuualReturn,
    //   endNode: true,
    //   description: ''
    // },
    // LUTFilings: {
    //   title: 'LUT Filings',
    //   icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
    //   href: PATH_APP.app2.GSTServices.LUTFilings,
    //   endNode: true,
    //   description: ''
    // },
    // EWayBillServices: EWayBillRegistration,
    // GSTCancellationAndRevocation: GSTCancellationAndRevocation,
    AmendmentInGSTRegistration: {
      title: 'Amendment In GST Registration',
      icon:
        '/static2/ServiceIcons/GSTServices/Amendment in Gst Registration.svg',
      href: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
      endNode: true,
      description: ''
    },
    GSTNotice: {
      title: 'GST Notice',
      icon: '/static2/ServiceIcons/GSTServices/GstNotice.svg',
      href: PATH_APP.app2.GSTServices.GSTNotice,
      endNode: true,
      description: ''
    },
    GSTRegistration: {
      title: 'GST Registration',
      icon: '/static2/ServiceIcons/GSTServices/Individual.svg',
      href: PATH_APP.app2.GSTServices.registrationIndividual,
      endNode: true,
      description: '',
      tags: ['Registration: Individual', 'GST', 'Business']
    },
    GSTFilings: {
      title: 'GST Filings',
      icon: '/static2/ServiceIcons/GSTServices/Gstr- 9.svg',
      href: PATH_APP.app2.GSTServices.GSTR9AnuualReturn,
      endNode: true,
      description: ''
    },
    EwayBillservices: {
      title: 'E Way Bill Services',
      icon: '',
      href: PATH_APP.app2.GSTServices.EwayBillRegistration,
      endNode: true,
      description: ''
    },

    GSTcancellationandrevocation: {
      title: 'GST Cancellation and Revocation',
      icon: '/static2/ServiceIcons/GSTServices/Gst Cancellation.svg',
      href: PATH_APP.app2.GSTServices.GSTCancellation,
      endNode: true,
      description: ''
    },
    AmendmentinGSTregistration: {
      title: 'Amendment In GST Registration',
      icon:
        '/static2/ServiceIcons/GSTServices/Amendment in Gst Registration.svg',
      href: PATH_APP.app2.GSTServices.AmendmentInGSTRegistration,
      endNode: true,
      description: ''
    },
    GSTNotices: {
      title: 'GST Notices',
      icon: '/static2/ServiceIcons/GSTServices/GstNotice.svg',
      href: PATH_APP.app2.GSTServices.GSTNotice,
      endNode: true,
      description: ''
    },
    LUTFilings: {
      title: 'LUT Filings',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.GSTServices.LUTFilings,
      endNode: true,
      description: ''
    },
    Reconciliations: {
      title: 'Reconciliations',
      icon: '/static2/ServiceIcons/GSTServices/LUT Filings.svg',
      href: PATH_APP.app2.GSTServices.Reconcilliations,
      endNode: true,
      description: ''
    },
  }
};



export const AnnualFilingsCompany = {
  title: 'Annual Filings',
  icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
  href: PATH_APP.app2.services.AnnualFilingsCompany,
  description: '',
  heading: 'Annual Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
    { name: 'Annual Filings' }
  ],
  children: {
    AOC4: {
      title: 'AOC-4',
      icon: '/static2/ServiceIcons/ROCServices/AOC-4.svg',
      href: PATH_APP.app2.ROCCompliances.AOC4,
      endNode: true,
      description: ''
    },
    DIR3KYC: {
      title: 'DIR3KYC',
      icon: '/static2/ServiceIcons/ROCServices/Dir-3 KYC.svg',
      href: PATH_APP.app2.ROCCompliances.DIR3KYC,
      endNode: true,
      description: ''
    },
    MGT7: {
      title: 'MGT-7',
      icon: '/static2/ServiceIcons/ROCServices/MGT-7.svg',
      href: PATH_APP.app2.ROCCompliances.MGT7,
      endNode: true,
      description: ''
    }
  }
};

export const CompanyFilings = {
  title: 'Company Filings',
  icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
  href: PATH_APP.app2.services.CompanyFilings,
  description: '',
  heading: 'Company Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'Company Filings' }
  ],
  children: {
    AnnualFilingsCompany: AnnualFilingsCompany,
    OtherCompanyFilings: OtherCompanyFilings,
    AuditorAppointment: {
      title: 'Auditor Appointment',
      icon: '/static2/ServiceIcons/ROCServices/Auditor appointment.svg',
      href: PATH_APP.app2.ROCCompliances.AuditorAppointment,
      endNode: true,
      description: ''
    },
    AuditorRemoval: {
      title: 'Auditor Removal',
      icon: '/static2/ServiceIcons/ROCServices/Auditor removal.svg',
      href: PATH_APP.app2.ROCCompliances.AuditorRemoval,
      endNode: true,
      description: ''
    },
    ChangeInAuthorizedShareCapital: {
      title: 'Change In Authorized Share Capital',
      icon: '/static2/ServiceIcons/ROCServices/change in share capital.svg',
      href: PATH_APP.app2.ROCCompliances.ChangeInAuthorizedShareCapital,
      endNode: true,
      description: ''
    },
    ChangeInObjects: {
      title: 'Change In Objects',
      icon: '/static2/ServiceIcons/ROCServices/change in objects.svg',
      href: PATH_APP.app2.ROCCompliances.ChangeInObjects,
      endNode: true,
      description: ''
    },
    // CommencementOfBusiness: {
    //   title: 'Commencement Of Business',
    //   icon: '/static2/ServiceIcons/ROCServices/Commencement of business.svg',
    //   href: PATH_APP.app2.ROCCompliances.CommencementOfBusiness,
    //   endNode: true,
    //   description: ''
    // },
    DPT3: {
      title: 'DPT-3',
      icon: '/static2/ServiceIcons/ROCServices/DPT-3.svg',
      href: PATH_APP.app2.ROCCompliances.DPT3,
      endNode: true,
      description: ''
    }
  }
};

export const AnnualFilingsLLP = {
  title: 'Annual Filings',
  icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
  href: PATH_APP.app2.services.AnnualFilingsLLP,
  description: '',
  heading: 'Annual Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
    { name: 'Annual Filings' }
  ],
  children: {
    Form11: {
      title: 'Form 11',
      icon: '/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 8.svg',
      href: PATH_APP.app2.ROCCompliances.Form11,
      endNode: true,
      description: ''
    },
    Form8: {
      title: 'Form 8',
      icon: '/static2/ServiceIcons/ROCServices/AnnualFiling/FORM 11.svg',
      href: PATH_APP.app2.ROCCompliances.Form8,
      endNode: true,
      description: ''
    },
    DIR3KYC: {
      title: 'DIR3KYC',
      icon: '/static2/ServiceIcons/ROCServices/Dir-3 KYC.svg',
      href: PATH_APP.app2.ROCCompliances.DIR3KYC,
      endNode: true,
      description: ''
    },
  }
};

export const LLPFilings = {
  title: 'LLP Filings',
  icon: '/static2/ServiceIcons/ROCServices/LLP Filings.svg',
  href: PATH_APP.app2.services.LLPFilings,
  description: '',
  heading: 'LLP Filings',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
    { name: 'LLP Filings' }
  ],
  children: {
    AnnualFilingsLLP: AnnualFilingsLLP,
    OtherLLPFilings: OtherLLPFilings
  }
};

export const ROCCompliances = {
  title: 'ROC Compliances',
  icon: '/static2/ServiceIcons/BusinessServices/Roc Compliance.svg',
  href: PATH_APP.app2.services.ROCCompliances,
  description: '',
  heading: 'ROC Compliances',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'ROC Compliances' }
  ],
  children: {
    CompanyFilings: CompanyFilings,
    LLPFilings: LLPFilings
    // AppointmentResignationOfAuditor: {
    //     title: 'Appointment/Resignation Of Auditor',
    //     icon: '/static2/ServiceIcons/ROCServices/Appointment and Removal.svg',
    //     href: PATH_PAGE.comingSoon,
    //     description: '',
    // },
    // INC20A: {
    //     title: 'INC20A (Commencement of Business)',
    //     icon: '/static2/ServiceIcons/ROCServices/INC-20A.svg',
    //     href: PATH_PAGE.comingSoon,
    //     description: '',
    // },
    // ChangeInMOA: {
    //     title: 'Change In MOA',
    //     icon: '/static2/ServiceIcons/ROCServices/Change in MOA.svg',
    //     href: PATH_PAGE.comingSoon,
    //     description: '',
    // },
    // IncreaseInAuthorizedShareCapital: {
    //     title: 'Increase In Authorized Share Capital',
    //     icon: '/static2/ServiceIcons/ROCServices/Increase in capital.svg',
    //     href: PATH_PAGE.comingSoon,
    //     description: '',
    // },
  }
};

export const ESI = {
  title: 'ESI',
  icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
  href: PATH_APP.app2.services.ESI,
  description: '',
  heading: 'ESI',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    {
      name: 'Payroll Compliances',
      href: PATH_APP.app2.services.PayrollCompliances
    },
    { name: 'ESI' }
  ],
  children: {
    ESIRegistration: {
      title: 'ESI Registration',
      icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
      href: PATH_APP.app2.PayrollCompliances.ESIRegistration,
      endNode: true,
      description: ''
    },
    ESIReturns: {
      title: 'ESI Returns Filing',
      icon: '/static2/ServiceIcons/PayrollServices/ESI returns.svg',
      href: PATH_APP.app2.PayrollCompliances.ESIReturnFiling,
      endNode: true,
      description: ''
    }
  }
};

export const PF = {
  title: 'PF',
  icon: '/static2/ServiceIcons/PayrollServices/Provident fund.svg',
  href: PATH_APP.app2.services.PF,
  description: '',
  heading: 'PF',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    {
      name: 'Payroll Compliances',
      href: PATH_APP.app2.services.PayrollCompliances
    },
    { name: 'PF' }
  ],
  children: {
    PFRegistration: {
      title: 'PF Registration',
      icon: '/static2/ServiceIcons/PayrollServices/PF Reg`n.svg',
      href: PATH_APP.app2.PayrollCompliances.PFRegistration,
      endNode: true,
      description: ''
    },
    PFReturnsFilings: {
      title: 'PF Returns Filings',
      icon: '/static2/ServiceIcons/PayrollServices/PF Filing.svg',
      endNode: true,
      href: PATH_APP.app2.PayrollCompliances.PFFiling,
      description: ''
    }
  }
};

export const PT = {
  title: 'PT',
  icon: '/static2/ServiceIcons/PayrollServices/Professional tax.svg',
  href: PATH_APP.app2.services.PT,
  description: '',
  heading: 'PT',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    {
      name: 'Payroll Compliances',
      href: PATH_APP.app2.services.PayrollCompliances
    },
    { name: 'PT' }
  ],
  children: {
    PTRegistrations: {
      title: 'PT Registrations',
      icon: '/static2/ServiceIcons/PayrollServices/PT Reg`n.svg',
      href: PATH_APP.app2.PayrollCompliances.PTRegistration,
      endNode: true,
      description: ''
    },
    PTReturnsFilings: {
      title: 'PT Returns Filings',
      icon: '/static2/ServiceIcons/PayrollServices/PT Return filing.svg',
      href: PATH_APP.app2.PayrollCompliances.PTReturnFiling,
      endNode: true,
      description: ''
    }
  }
};

export const PayrollCompliances = {
  title: 'Payroll Compliances',
  icon: '/static2/ServiceIcons/BusinessServices/Payroll.svg',
  href: PATH_APP.app2.services.PayrollCompliances,
  description: '',
  heading: 'Payroll Compliances',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },
    { name: 'Payroll' }
  ],
  children: {
    PayrollPreparation: payrollprocessingservice,
    Registration: Registration,
    PaymentAndReturn: PaymentAndReturn,
    Compliance: Compliance,
  }
};

export const Loans = {
  title: 'Loans',
  icon: '/static2/ServiceIcons/BusinessServices/Loans.svg',
  href: PATH_APP.app2.services.Loans,
  description: '',
  heading: 'Loans',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Loans' }
  ],
  children: {
    HomeLoan: {
      title: 'Home Loan',
      icon: '/static2/ServiceIcons/Loans/Home loans.svg',
      href: PATH_APP.app2.Loans.HomeLoan,
      endNode: true,
      description: ''
    },
    TermLoan: {
      title: 'Term Loan',
      icon: '/static2/ServiceIcons/Loans/Term  Loans.svg',
      endNode: true,
      href: PATH_APP.app2.Loans.TermLoan,
      description: ''
    },
    VehicleLoans: {
      title: 'Vehicle Loans',
      icon: '/static2/ServiceIcons/Loans/Vehicle Loan.svg',
      endNode: true,
      href: PATH_APP.app2.Loans.VehicleLoan,
      description: ''
    },
    WorkingCapitolLoan: {
      title: 'Working Capitol Loans',
      icon: '/static2/ServiceIcons/Loans/Working Capital Loans.svg',
      endNode: true,
      href: PATH_APP.app2.Loans.WorkingCapitolLoan,
      description: ''
    }
  }
};

export const FinancialAdviosryAndLegalServices = {
  title: 'Financial Advisory And Legal Services',
  icon:
    '/static2/ServiceIcons/BusinessServices/Financial Advisory and legal services.svg',
  href: PATH_APP.app2.services.FinancialAdviosryAndLegalServices,
  description: '',
  heading: 'Financial Advisory And Legal Services',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Financial Advisory And Legal Services' }
  ],
  children: {
    DraftingOfDocuments: {
      title: 'Drafting Of Documents',
      icon:
        '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Drafting of documents.svg',
      href: PATH_APP.app2.FinancialAdviosryAndLegalServices.DraftingOfDocuments,
      endNode: true,
      description: ''
    },
    LegalReview: {
      title: 'Legal Review',
      icon:
        '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Legal  Review.svg',
      href: PATH_APP.app2.FinancialAdviosryAndLegalServices.LegalReview,
      endNode: true,
      description: ''
    }
    // LegalOpinion: {
    //     title: 'Legal Opinion',
    //     icon: '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/Legal Advisory.svg',
    //     href: PATH_PAGE.comingSoon,
    //     description: '',
    // },
    // AskAQuery: {
    //     title: 'Ask A Query',
    //     icon: '/static2/ServiceIcons/FinancialAdvisoryAndLegalServices/ask a query.svg',
    //     href: PATH_PAGE.comingSoon,
    //     description: '',
    // },
  }
};

export const Investments = {
  title: 'Investments',
  icon: '/static2/ServiceIcons/BusinessServices/Investments.svg',
  href: PATH_APP.app2.services.Investments,
  description: '',
  heading: 'Investments',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Investments' }
  ],
  children: {
    AggressiveFunds: {
      title: 'Aggressive Funds',
      icon: '',
      href: PATH_PAGE.comingSoon,
      endNode: true,
      description: ''
    },
    BalancedGrowthFund: {
      title: 'Balanced Growth Fund',
      icon: '',
      href: PATH_PAGE.comingSoon,
      endNode: true,
      description: ''
    },
    TaxSavingsFund: {
      title: 'Tax Savings Fund',
      icon: '',
      href: PATH_PAGE.comingSoon,
      endNode: true,
      description: ''
    },
    LiquidFunds: {
      title: 'Liquid Funds',
      icon: '',
      href: PATH_PAGE.comingSoon,
      endNode: true,
      description: ''
    }
  }
};

export const HealthInsuranceBusiness = {
  title: 'Health Insurance',
  icon: '/static2/ServiceIcons/Insurance/Health Insurance.svg',
  href: PATH_APP.app2.services.HealthInsuranceBusiness,
  description: '',
  heading: 'Health Insurance',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    {
      name: 'Insurance Advisory',
      href: PATH_APP.app2.services.InsuranceAdvisory
    },
    { name: 'Health Insurance' }
  ],
  children: {
    CoronavirusInsurance: {
      title: 'Coronavirus Insurance',
      icon: '',
      href: PATH_APP.app2.InsuranceAdvisory.CoronavirusInsurance,
      endNode: true,
      description: ''
    },
    HealthInsurance: {
      title: 'Health Insurance',
      icon: '',
      href: PATH_APP.app2.InsuranceAdvisory.HealthInsurance,
      endNode: true,
      description: ''
    }
  }
};

export const VehicleInsuranceBusiness = {
  title: 'Vehicle Insurance',
  icon: '/static2/ServiceIcons/Insurance/Vehicle Insurance.svg',
  href: PATH_APP.app2.services.VehicleInsuranceBusiness,
  description: '',
  heading: 'Vehicle Insurance',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    {
      name: 'Insurance Advisory',
      href: PATH_APP.app2.services.InsuranceAdvisory
    },
    { name: 'Vehicle Insurance' }
  ],
  children: {
    CommercialVehicle: {
      title: 'Commercial Insurance',
      icon: '',
      href: PATH_APP.app2.InsuranceAdvisory.CommercialVehicle,
      endNode: true,
      description: ''
    },
    CarInsurance: {
      title: 'Car Insurance',
      icon: '',
      href: PATH_APP.app2.InsuranceAdvisory.CarInsurance,
      endNode: true,
      description: ''
    },
    TwoWheelerInsurance: {
      title: 'Two Wheeler Insurance',
      icon: '',
      href: PATH_APP.app2.InsuranceAdvisory.TwoWheelerInsurance,
      endNode: true,
      description: ''
    }
  }
};

export const InsuranceAdvisory = {
  title: 'Insurance Advisory',
  icon: '/static2/ServiceIcons/BusinessServices/Insurance.svg',
  href: PATH_APP.app2.services.InsuranceAdvisory,
  description: '',
  heading: 'Insurance Advisory',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Insurance Advisory' }
  ],
  children: {
    HealthInsuranceBusiness: HealthInsuranceBusiness,
    VehicleInsuranceBusiness: VehicleInsuranceBusiness
  }
};

export const OtherGovernmentRegistrations = {
  title: 'Other Government Registrations',
  icon: '/static2/ServiceIcons/BusinessServices/Other Govt Registrations.svg',
  href: PATH_APP.app2.services.OtherGovernmentRegistrations,
  description: '',
  heading: 'Other Government Registrations',
  breadCrumbs: [
    { name: 'Services', href: PATH_APP.app2.services.root },

    { name: 'Other Government Registrations' }
  ],
  children: {
    DigitalSignatures: {
      title: 'Digital Signatures',
      icon:
        '/static2/ServiceIcons/OtherGovtRegistrations/Digital Signatures.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.DigitalSignature,
      endNode: true,
      description: ''
    },
    FSSAIFoodLicenseRegistrations: {
      title: 'Food License Registrations',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Food License.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.FoodLicenseRegistration,
      endNode: true,
      description: ''
    },
    // Form15CA15CB: {
    //   title: 'Form 15CA and 15CB',
    //   icon: '/static2/ServiceIcons/OtherGovtRegistrations/15CA and 15CB.svg',
    //   href: PATH_APP.app2.OtherGovtRegistrations.Form15CAAnd15CB,
    //   endNode: true,
    //   description: ''
    // },
    // Labourlicense: {
    //   title: '  Labour license (Shops and Establishment Act)',
    //   icon: '/static2/ServiceIcons/PayrollServices/Esi Registration.svg',
    //   href: PATH_APP.app2.PayrollCompliances.Labourlicense,
    //   endNode: true,
    //   description: ''
    // },
    // TrustDetails: {
    //   title: 'Trust/Society',
    //   icon: '/static2/ServiceIcons/IncomeTaxReturns/Others.svg',
    //   href: PATH_APP.app2.OtherGovtRegistrations.TrustDetails,
    //   endNode: true,
    //   description: ''
    // },
    // IECRegistration: {
    //   title: 'IEC Registration',
    //   icon: '/static2/ServiceIcons/OtherGovtRegistrations/IEC Registration.svg',
    //   href: PATH_APP.app2.OtherGovtRegistrations.IECRegistration,
    //   endNode: true,
    //   description: ''
    // },
    // MSMERegistration: {
    //   title: 'MSME Registration',
    //   icon:
    //     '/static2/ServiceIcons/OtherGovtRegistrations/MSME Registration.svg',
    //   href: PATH_APP.app2.OtherGovtRegistrations.MSMERegistration,
    //   endNode: true,
    //   description: ''
    // },
    // PANRegistration: {
    //   title: 'PAN Registration',
    //   icon: '/static2/ServiceIcons/OtherGovtRegistrations/Pan Card.svg',
    //   href: PATH_APP.app2.OtherGovtRegistrations.PANRegistration,
    //   endNode: true,
    //   description: ''
    // },
    ShopsAndEstablishmentActRegistrations: {
      title: 'Shops And Establishment Act Registrations',
      icon:
        '/static2/ServiceIcons/OtherGovtRegistrations/Shops & Establishment License.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.ShopsAndEshtablishments,
      endNode: true,
      description: ''
    },
    TrademarkRegistration: {
      title: 'Trademark Registration',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Trademark.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.TrademarkRegistration,
      endNode: true,
      description: ''
    },
    TradeLicense: {
      title: 'Trade License',
      icon: '/static2/ServiceIcons/OtherGovtRegistrations/Food License.svg',
      href: PATH_APP.app2.OtherGovtRegistrations.TradeLicense,
      endNode: true,
      description: ''
    },
  }
};

let obj = [
  {
    "id": 319,
    "customisationSections": {
      "header": {
        "businessIncorporationList": {
          "checked": false,
          "data": {
            "incorporations": [
              {
                "name": "Private Limited Company",
                "field": "PrivateLimitedCompany",
                "checked": false
              },
              {
                "name": "One Person Company",
                "field": "OnePersonCompany",
                "checked": false
              },
              {
                "name": "Limited Liability partnership",
                "field": "LimitedLiabilitypartnership",
                "checked": false
              },
              {
                "name": "Partnership Firm",
                "field": "PartnershipFirm",
                "checked": false
              },
              {
                "name": "Sole Proprietor",
                "field": "SoleProprietor",
                "checked": false
              },
              {
                "name": "Section 8 Company",
                "field": "Section8Company",
                "checked": false
              },
              {
                "name": "Producer company",
                "field": "Producercompany",
                "checked": false
              },
              {
                "name": "Nidhi Company",
                "field": "NidhiCompany",
                "checked": false
              }
            ],
            "registrationList": [
              {
                "name": "Trust/Society",
                "field": "TrustSociety",
                "checked": false
              },
              {
                "name": "MSME",
                "field": "MSMERegistration",
                "checked": false
              },
              {
                "name": "Import export code (IEC)",
                "field": "Importexportcode(IEC)",
                "checked": false
              },
              {
                "name": "Food license(FSSAI)",
                "field": "Foodlicense(FSSAI)",
                "checked": false
              },
              {
                "name": "Trade License",
                "field": "Trade License",
                "checked": false
              },
              {
                "name": "Digital Signature",
                "field": "DigitalSignature",
                "checked": false
              },
              {
                "name": "Labour license (Shops and Establishment Act)",
                "field": "Labourlicense",
                "checked": false
              },
              {
                "name": "PAN",
                "field": "PAN",
                "checked": false
              }
            ]
          }
        },
        "gstList": {
          "checked": false,
          "data": [
            {
              "name": "GST Registration",
              "field": "GSTRegistration",
              "checked": false
            },
            {
              "name": "GST Filings",
              "field": "GSTFilings",
              "checked": false
            },
            {
              "name": "E way Bill services",
              "field": "EwayBillservices",
              "checked": false
            },
            {
              "name": "GST cancellation and revocation",
              "field": "GSTcancellationandrevocation",
              "checked": false
            },
            {
              "name": "Amendment in GST registration",
              "field": "AmendmentinGSTregistration",
              "checked": false
            },
            {
              "name": "GST Notices",
              "field": "GSTNotices",
              "checked": false
            },
            {
              "name": "LUT Filings",
              "field": "LUTFilings",
              "checked": false
            },
            {
              "name": "Reconciliations",
              "field": "Reconciliations",
              "checked": false
            }
          ]
        },
        "tdsList": {
          "checked": false,
          "data": {
            "payment": [
              {
                "name": "Payment",
                "field": "Payment",
                "checked": false
              }
            ],
            "Returns": [
              {
                "name": "Salaries(24Q)",
                "field": "TDSFilingsForSalaries",
                "checked": false
              },
              {
                "name": "Other than Salaries(26Q)",
                "field": "TDSForPaymentsOtherThanSalaries",
                "checked": false
              },
              {
                "name": "Sale of immovable properties(26QB)",
                "field": "TDSOnSaleOfImmovableProperty",
                "checked": false
              },
              {
                "name": "Payment to Non resident(27Q)",
                "field": "TDSForNonResidents",
                "checked": false
              },
              {
                "name": "TCS(27EQ)",
                "field": "StatementForTaxCollectionAtSource",
                "checked": false
              }
            ]
          }
        },
        "incometaxFilingList": {
          "checked": false,
          "data": [
            {
              "name": "Individual",
              "field": "Individual",
              "checked": false
            },
            {
              "name": "Sole proprietor",
              "field": "Soleproprietor",
              "checked": false
            },
            {
              "name": "Private Limited company",
              "field": "PrivateLimitedcompany",
              "checked": false
            },
            {
              "name": "Partnership Firm",
              "field": "PartnershipFirm",
              "checked": false
            },
            {
              "name": "Limited Liability Partnership",
              "field": "LimitedLiabilityPartnership",
              "checked": false
            },
            {
              "name": "Trust",
              "field": "Trust",
              "checked": false
            },
            {
              "name": "Society",
              "field": "Society",
              "checked": false
            },
            {
              "name": "Section 8 Company",
              "field": "Section8Company",
              "checked": false
            },
            {
              "name": "One Person company",
              "field": "OnePersoncompany",
              "checked": false
            },
            {
              "name": "Nidhi Company",
              "field": "NidhiCompany",
              "checked": false
            }
          ]
        },
        "PayrollCompliancesList": {
          "checked": false,
          "data": {
            "payrollprocessingservice": [
              {
                "name": "Payroll Preparation",
                "field": "PayrollPreparation",
                "checked": false
              },
              {
                "name": "Payslip Generation",
                "field": "PayrollGeneration",
                "checked": false
              },
              {
                "name": "Form 16 Generation",
                "field": "Form16Generation",
                "checked": false
              },
              {
                "name": "Employee Agreements",
                "field": "EmployeeAgreements",
                "checked": false
              },
              {
                "name": "Salary Structure",
                "field": "SalaryStructure",
                "checked": false
              },
              {
                "name": "ESOP Structure",
                "field": "ESOPStructure",
                "checked": false
              }
            ],
            "registration": [
              {
                "name": "ESI",
                "field": "ESI",
                "checked": false
              },
              {
                "name": "Provident Fund",
                "field": "ProvidentFund",
                "checked": false
              },
              {
                "name": "Professional Tax",
                "field": "ProfessionalTax",
                "checked": false
              },
              {
                "name": "Labour license (Shops and Establishment Act)",
                "field": "Labourlicense",
                "checked": false
              }
            ],
            "paymentandreturn": [
              {
                "name": "ESI",
                "field": "ESI",
                "checked": false
              },
              {
                "name": "Provident Fund",
                "field": "ProvidentFund",
                "checked": false
              },
              {
                "name": "Professional Tax",
                "field": "ProfessionalTax",
                "checked": false
              }
            ],
            "compliance": [
              {
                "name": "POSH",
                "field": "POSH",
                "checked": false
              },
              {
                "name": "Bonus",
                "field": "Bonus",
                "checked": false
              },
              {
                "name": "Gratuity",
                "field": "Gratuity",
                "checked": false
              }
            ]
          }
        },
        "rocFilingsList": {
          "checked": false,
          "data": {
            "mandatorycompanyfilings": [
              {
                "name": "Business Commencement Certificate",
                "field": "BusinessCommencementCertificate",
                "checked": false
              },
              {
                "name": "Auditor Appointment",
                "field": "AuditorAppointment",
                "checked": false
              },
              {
                "name": "FORM AOC-4",
                "field": "AOC4",
                "checked": false
              },
              {
                "name": "FORM MGT-7/ 7A",
                "field": "MGT7",
                "checked": false
              },
              {
                "name": "Directors Report",
                "field": "DirectorsReport",
                "checked": false
              }
            ],
            "mandatoryllpfilings": [
              {
                "name": "Form 11",
                "field": "Form11",
                "checked": false
              },
              {
                "name": "Form 8",
                "field": "Form8",
                "checked": false
              },
              {
                "name": "DIR 3KYC",
                "field": "DIR3KYC",
                "checked": false
              }
            ],
            "othercompanyfilings": [
              {
                "name": "Appointment of director",
                "field": "DirectorAppointment",
                "checked": false
              },
              {
                "name": "Removal of Director",
                "field": "DirectorRemoval",
                "checked": false
              },
              {
                "name": "Appointment of Auditor",
                "field": "AuditorAppointment",
                "checked": false
              },
              {
                "name": "Removal of Auditor",
                "field": "AuditorRemoval",
                "checked": false
              },
              {
                "name": "Increase in Authorised capital",
                "field": "ChangeInAuthorizedShareCapitol",
                "checked": false
              },
              {
                "name": "Object clause",
                "field": "ChangeInObjects",
                "checked": false
              }
            ],
            "otherllpfilings": [
              {
                "name": "Add Designated partner",
                "field": "AddDesignatedPartnet",
                "checked": false
              },
              {
                "name": "Changes to LLP Agreement",
                "field": "ChangestoLLpAgreement",
                "checked": false
              },
              {
                "name": "Close the LLP",
                "field": "closetheLLp",
                "checked": false
              }
            ]
          }
        },
        "accountingandcompliancesList": {
          "checked": false,
          "data": {
            "AccountingServicesUnderPackage": [
              {
                "name": "Bookkeeping",
                "field": "BookKeeping",
                "checked": true
              },
              {
                "name": "Month end reviews",
                "field": "MonthEndReviews",
                "checked": true
              },
              {
                "name": "MIS Report",
                "field": "MISReport",
                "checked": false
              },
              {
                "name": "Payroll Maintenance",
                "field": "PreparingandFilingofStatutoryPaymentsandreturns",
                "checked": false
              }
            ],
            "ComplianceServicesUnderPackage": [
              {
                "name": "Preparing and Filing of Statutory Payments and returns such as GST,TDS/TCS, PT,PF,ESI.",
                "checked": false
              }
            ]
          }
        },
        "auditList": {
          "checked": true,
          "data": [
            {
              "name": "Statutory Audit",
              "field": "StatutoryAudit",
              "checked": true
            },
            {
              "name": "Internal Audit",
              "field": "InternalAudit",
              "checked": true
            },
            {
              "name": "Tax Audit",
              "field": "TaxAudit",
              "checked": true
            },
            {
              "name": "Systems Audit",
              "field": "SystemsAudit",
              "checked": true
            },
            {
              "name": "Management Audit",
              "field": "ManagementAudit",
              "checked": true
            },
            {
              "name": "Agreed Upon Procedures",
              "field": "AgreedUponProcedures",
              "checked": true
            },
            {
              "name": "Certification Services",
              "field": "CertificationServices",
              "checked": true
            },
            {
              "name": "Special Audits",
              "field": "SpecialAudits",
              "checked": true
            }
          ]
        }
      },
      "about": {
        "whoarewe": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of mo",
        "whatwedo": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of mo",
        "coreTeam": [
          {
            "id": 216,
            "coreteam_id": 216,
            "remarks": null,
            "createdAt": "2023-06-09T05:21:55.774000Z",
            "updatedAt": "2023-06-12T06:47:39.893000Z",
            "name": "Krishna",
            "position": "Frontend",
            "attachmentKey": "admin/Picture/Krishna/Screenshot (16) (3).png",
            "customisation": 319,
            "attachmentFileUrl": "https://fb-customization.s3.amazonaws.com/admin/Picture/Krishna/Screenshot%20%2816%29%20%283%29.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY2IDP2WHTRJAR3QQ%2F20230613%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230613T091205Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=8b57c09b8c8f64046a79ced124bd09a645d41fb2c7ca4a564fd1c7b4f30e7224"
          },
          {
            "id": 217,
            "coreteam_id": 217,
            "remarks": null,
            "createdAt": "2023-06-09T13:03:31.609000Z",
            "updatedAt": "2023-06-12T12:53:24.237000Z",
            "name": "Sudheer",
            "position": "Backend",
            "attachmentKey": "admin/Picture/Sudheer/Screenshot (16) (1).png",
            "customisation": 319,
            "attachmentFileUrl": "https://fb-customization.s3.amazonaws.com/admin/Picture/Sudheer/Screenshot%20%2816%29%20%281%29.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY2IDP2WHTRJAR3QQ%2F20230613%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230613T091205Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=2ad8ab7a276585535fc19275fb84c3b10b49b0988bdd26c178dd2f9facd48618"
          },
          {
            "id": 218,
            "coreteam_id": 218,
            "remarks": null,
            "createdAt": "2023-06-09T13:04:07.967000Z",
            "updatedAt": "2023-06-12T06:47:28.499000Z",
            "name": "Sudeep",
            "position": "QA",
            "attachmentKey": "admin/Picture/Sudeep/Screenshot (24) (3).png",
            "customisation": 319,
            "attachmentFileUrl": "https://fb-customization.s3.amazonaws.com/admin/Picture/Sudeep/Screenshot%20%2824%29%20%283%29.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY2IDP2WHTRJAR3QQ%2F20230613%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230613T091205Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=04f61b3a6266e489097282801a9041f011cb63af25d4cbc091467fabeda32558"
          },
          {
            "id": 221,
            "coreteam_id": 221,
            "remarks": null,
            "createdAt": "2023-06-12T06:27:41.487000Z",
            "updatedAt": "2023-06-12T06:47:52.124000Z",
            "name": "Raju",
            "position": "Backend",
            "attachmentKey": "admin/Picture/Raju/Screenshot (16) (1).png",
            "customisation": 319,
            "attachmentFileUrl": "https://fb-customization.s3.amazonaws.com/admin/Picture/Raju/Screenshot%20%2816%29%20%281%29.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY2IDP2WHTRJAR3QQ%2F20230613%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230613T091205Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=c67408e627bf4e02a258dc224afaf2474079a306c7de5c7cd1c2011b9a5cf420"
          },
          {
            "id": 222,
            "coreteam_id": 222,
            "remarks": null,
            "createdAt": "2023-06-12T06:47:16.833000Z",
            "updatedAt": "2023-06-12T06:47:16.833000Z",
            "name": "Anand",
            "position": "Frontend",
            "attachmentKey": "admin/Picture/Anand/Screenshot (16) (10).png",
            "customisation": 319,
            "attachmentFileUrl": "https://fb-customization.s3.amazonaws.com/admin/Picture/Anand/Screenshot%20%2816%29%20%2810%29.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAY2IDP2WHTRJAR3QQ%2F20230613%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20230613T091205Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=39b2e2f1b5660179d5ba0d993354b8dd2779e3744a8300a372b6cbe9e1846ba1"
          }
        ]
      },
      "contact": {
        "headOffice": {
          "country": "india",
          "state": "Telangana",
          "city": "Hyderabad",
          "streetaddress": "Sagacity Heights",
          "phoneNumber": "9182043376",
          "email": "garikapati.2014@gmail.com",
          "pincode": "5000333"
        },
        "branches": [
          {
            "country": "india",
            "state": "Andhra Pradesh",
            "city": "Hyderabad",
            "streetaddress": "Sagacity Heights",
            "phoneNumber": "9182043376",
            "email": "garikapati.2014@gmail.com",
            "pincode": "5000333"
          },
          {
            "country": "india",
            "state": "Andhra Pradesh",
            "city": "vijayawada",
            "streetaddress": "Sagacity Heights",
            "phoneNumber": "9182043376",
            "email": "nagababu1194@gmail.com",
            "pincode": "5000333"
          },
          {
            "country": "india",
            "state": "Andhra Pradesh",
            "city": "Guntur",
            "streetaddress": "Sagacity Heights",
            "phoneNumber": "9182043376",
            "email": "nagababu1194@gmail.com",
            "pincode": "5000333"
          }
        ]
      },
      "footer": {
        "facebook": "realtbox@facebook.com",
        "instagram": "realtbox@instagram",
        "twitter": "realtbox@twitter",
        "linkedin": "realtbox@linkedin",
        "firmemail": "realtbox@gmail.com"
      },
      "body": {
        "textOneContent": "emaining essentially unchanged. It was popularised in the 1960s with the release of Letr It has survived not only five centuries, but also the leap into electronic typesetting, remaiemaining essentially unchanged. It was popularised in the 1960s with the release of Letr It has survived not only five centuries, but also the leap into electronic typesetting, remai",
        "textTwoContent": "emaining essentially unchanged. It was popularised in the 1960s with the release of Letr It has survived not only five centuries, but also the leap into electronic typesetting, remaiemaining essentially unchanged. It was popularised in the 1960s with the release of Letr It has survived not only five centuries, but also the leap into electronic typesetting, remai",
        "textThreeContent": "emaining essentially unchanged. It was popularised in the 1960s with the release of Letr It has survived not only five centuries, but also the leap into electronic typesetting, remaiemaining essentially unchanged. It was popularised in the 1960s with the release of Letr It has survived not only five centuries, but also the leap into electronic typesetting, remai",
        "selectedOptions": [
          {
            "title": "GST"
          },
          {
            "title": "MIS"
          },
          {
            "title": "Nidi Company"
          },
          {
            "title": "Registration"
          },
          {
            "title": "Audit"
          },
          {
            "title": "Individual"
          }
        ]
      }
    },

  }
]
let headerData = obj[0].customisationSections.header

let gstChildren = {};
let businessIncorporationChildren = {};
let businessRegistrationChildren = {};
let tdsChildren = {};
let incomeTaxChildren = {};
let payrollPreparationChildren = {};
let paymentandreturnChildren = {};
let registrationChildren = {};
let complianceChildren = {};
let rocFilingsChildren = {};
let rocmandatoryllpChildren = {};
let othercompanyfilingsChildren = {};
let otherllpfilingsChildren = {};
let accountingChildren = {};
let auditChildren = {};
// let tdspaymentChildren = {};
headerData.businessIncorporationList.data.incorporations.map((item) => {
  if (item.checked) {
    businessIncorporationChildren[item.field] = BusinessIncorporations.children[item.field]
  }
}
)
headerData.businessIncorporationList.data.registrationList.map((item) => {
  if (item.checked) {
    businessRegistrationChildren[item.field] = BusinessIncorporationsRegistrations.children[item.field]
  }
}
)

headerData.accountingandcompliancesList.data.AccountingServicesUnderPackage.map((item) => {
  if (item.checked) {
    accountingChildren[item.field] = AccountingServices.children[item.field]
  }
}
)


headerData.PayrollCompliancesList.data.payrollprocessingservice.map((item) => {
  if (item.checked) {
    payrollPreparationChildren[item.field] = payrollprocessingservice.children[item.field]
  }
}
)
headerData.PayrollCompliancesList.data.registration.map((item) => {
  if (item.checked) {
    registrationChildren[item.field] = Registration.children[item.field]
  }
}
)
headerData.PayrollCompliancesList.data.paymentandreturn.map((item) => {
  if (item.checked) {
    paymentandreturnChildren[item.field] = PaymentAndReturn.children[item.field]
  }
}
)
headerData !== undefined && headerData.tdsList.data.payment.map((item) => {
  if (item.checked) {
    tdsChildren[item.field] = TDSServices.children[item.field]
  }
})
headerData.PayrollCompliancesList.data.compliance.map((item) => {
  if (item.checked) {
    complianceChildren[item.field] = Compliance.children[item.field]
  }
}
)
headerData.rocFilingsList.data.mandatorycompanyfilings.map((item) => {
  if (item.checked) {
    rocFilingsChildren[item.field] = MandatoryCompanyFilings.children[item.field]
  }
}
)
headerData.rocFilingsList.data.mandatoryllpfilings.map((item) => {
  if (item.checked) {
    rocmandatoryllpChildren[item.field] = AnnualFilingsLLP.children[item.field]
  }
}
)
headerData.rocFilingsList.data.othercompanyfilings.map((item) => {
  if (item.checked) {

    othercompanyfilingsChildren[item.field] = OtherCompanyFilings.children[item.field]
  }
}
)
headerData.rocFilingsList.data.otherllpfilings.map((item) => {
  if (item.checked) {

    otherllpfilingsChildren[item.field] = OtherLLPFilings.children[item.field]
  }
}
)

headerData.gstList.data.map((item) => {
  if (item.checked) {
    gstChildren[item.field] = GSTRegistration.children[item.field]
  }
})

headerData.tdsList.data.Returns.map((item) => {
  if (item.checked) {
    tdsChildren[item.field] = TDSServices.children[item.field]
  }
})
headerData.incometaxFilingList.data.map((item) => {
  if (item.checked) {
    incomeTaxChildren[item.field] = IncomeTaxReturnsBusiness.children[item.field]
  }
})
headerData.auditList.data.map((item) => {
  if (item.checked) {
    auditChildren[item.field] = Audit.children[item.field]
  }
})


const filteredServices = {
  gstChildren,
  businessIncorporationChildren,
  businessRegistrationChildren,
  tdsChildren,
  incomeTaxChildren,
  payrollPreparationChildren,
  registrationChildren,
  paymentandreturnChildren,
  complianceChildren,
  rocFilingsChildren,
  rocmandatoryllpChildren,
  othercompanyfilingsChildren,
  otherllpfilingsChildren,
  accountingChildren,
  auditChildren,
};


export const services = {
  heading: 'Services',
  breadCrumbs: [{ name: 'Services', href: PATH_APP.app2.services.root }],
  children: {
    BusinessIncorporationandRegistrations: {
      title: 'Business Incorporation & Registrations',
      href: PATH_APP.app2.services.BusinessIncorporationandRegistrations,
      icon: '/static2/ServiceIcons/Individual.svg',
      description: 'This is Business Incorporation & Registrations',
      heading: 'Business Incorporation & Registrations',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'Business Incorporation & Registrations',
        }
      ],

      children:
      {
        BusinessIncorporations: {
          title: 'Business Incorporations',
          icon: '/static2/ServiceIcons/Business Incorporations/company.svg',
          href: PATH_APP.app2.services.Company,
          description: '',
          heading: 'Business Incorporations',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'Business Incorporations' }
          ],

          children: filteredServices.businessIncorporationChildren
        },
        BusinessIncorporationsRegistrations: {
          title: 'Registrations',
          icon: '/static2/ServiceIcons/BusinessServices/Other Govt Registrations.svg',
          href: PATH_APP.app2.services.OtherGovernmentRegistrations,
          description: '',
          heading: 'Other Government Registrations',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'Other Government Registrations' }
          ],

          children: filteredServices.businessRegistrationChildren
        },
      }
    },
    GST: {
      title: 'GST',
      href: PATH_APP.app2.services.GSTServices,
      icon: '/static2/ServiceIcons/Individual.svg',
      description: 'This is GST',
      heading: 'GST',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'GST',
          href: PATH_APP.app2.services.GSTServices
        }
      ],


      children: filteredServices.gstChildren
    },
    TDSTCS: {
      title: 'TDS/TCS',
      href: PATH_APP.app2.services.TDSServices,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is TDSTCS',
      heading: 'TDSTCS',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'TDSTCS',
          href: PATH_APP.app2.services.TDSServices
        }
      ],
      // children: {
      //   // PaymentsinTDS: PaymentsinTDS,
      //   PaymentsinTDS: ,
      // // Returns: Returns,
      //   TDSServices: TDSServices
      // //   Other than Salaries (26Q)
      // //   Sale of immovable properties (26QB)
      // //   Payment to Non resident (27Q)
      // //   TCS (27EQ)
      // }
      children: {
        Payment: PaymentsinTDS
        ,
        // Returns: Returns,
        TDSServices:
        {
          title: 'Returns',
          icon: '/static2/ServiceIcons/BusinessServices/TDS.svg',
          href: PATH_APP.app2.services.TDSServices,
          description: '',
          heading: 'TDS Services',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'TDS Services' }
          ],
          children: filteredServices.tdsChildren
        }
      }
    },
    IncomeTaxFilings: {
      title: 'Income Tax Filings',
      href: PATH_APP.app2.services.IncomeTaxReturnsBusiness,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is Income Tax Filings',
      heading: 'Income Tax Filings',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'Income Tax Filings',
          href: PATH_APP.app2.services.IncomeTaxReturns
        }
      ],
      children: filteredServices.incomeTaxChildren

    },
    Payrollcompliances: {
      title: 'Payroll compliances',
      href: PATH_APP.app2.services.PayrollCompliances,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is Payroll compliances',
      heading: 'Payroll compliances',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'Payroll compliances',
          href: PATH_APP.app2.services.PayrollCompliances
        }
      ],
      children: {
        payrollprocessingservice: {
          title: 'Payroll Preparation',
          icon: '/static2/ServiceIcons/BusinessServices/Payroll.svg',
          href: PATH_APP.app2.services.PayrollCompliances.payrollprocessingservice,
          description: '',
          heading: 'Payroll',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },
            { name: 'Payroll Compliances', href: PATH_APP.app2.services.PayrollCompliances },
            { name: 'Payroll' }
          ],
          children: filteredServices.payrollPreparationChildren
        },
        Registration: {
          title: 'Registration',
          icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
          href: PATH_APP.app2.services.ESI,
          description: '',
          heading: 'Registration',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            {
              name: 'Payroll Compliances',
              href: PATH_APP.app2.services.PayrollCompliances
            },
            { name: 'ESI' }
          ], children: filteredServices.registrationChildren
        },
        PaymentAndReturn: {
          title: 'Payment And Return',
          icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
          href: PATH_APP.app2.PayrollCompliances.PaymentAndReturn,
          description: '',
          heading: 'Payment and Return',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            {
              name: 'Payroll Compliances',
              href: PATH_APP.app2.services.PayrollCompliances
            },
            { name: 'Payment and Return' }
          ],
          children: filteredServices.paymentandreturnChildren
        },
        Compliance: {
          title: 'Compliance ',
          icon: '/static2/ServiceIcons/PayrollServices/ESI.svg',
          href: PATH_APP.app2.services.ESI,
          description: '',
          heading: 'Compliance ',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            {
              name: 'Payroll Compliances',
              href: PATH_APP.app2.services.PayrollCompliances
            },
            { name: 'ESI' }
          ],

          children: filteredServices.complianceChildren
        }
      }
    },

    ROCFilings: {
      title: 'ROC Filings',
      href: PATH_APP.app2.services.ROCCompliances,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is ROC Filings',
      heading: 'ROC Filings',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'ROC Filings',
          href: PATH_APP.app2.services.ROCCompliances
        }
      ],
      children: {
        MandatoryCompanyFilings: {
          title: 'Annual Filings',
          icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
          href: PATH_APP.app2.services.AnnualFilingsCompany,
          description: '',
          heading: 'Annual Filings',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
            { name: 'Company Filings', href: PATH_APP.app2.services.CompanyFilings },
            { name: 'Annual Filings' }
          ],
          children: filteredServices.rocFilingsChildren
        },

        MandatoryLLPFilingsFilings: {
          title: 'Mandatory LLP Filings',
          icon: '/static2/ServiceIcons/ROCServices/Annual filings.svg',
          href: PATH_APP.app2.services.AnnualFilingsLLP,
          description: '',
          heading: 'Annual Filings',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
            { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
            { name: 'Annual Filings' }
          ], children: filteredServices.rocmandatoryllpChildren
        },
        OtherCompanyFilings: {
          title: 'Other Company Filings',
          icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
          href: PATH_APP.app2.services.CompanyFilings,
          description: '',
          heading: 'Company Filings',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
            { name: 'Company Filings' }
          ], children: filteredServices.othercompanyfilingsChildren
        },
        OtherLLPFilings: {
          title: 'Other LLP Filings Filings',
          icon: '/static2/ServiceIcons/ROCServices/Company Roc Filings.svg',
          href: PATH_APP.app2.services.LLPFilings,
          description: '',
          heading: 'Other LLP Filings',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },

            { name: 'ROC Compliances', href: PATH_APP.app2.services.ROCCompliances },
            { name: 'LLP Filings', href: PATH_APP.app2.services.LLPFilings },
            { name: 'Other LLP Filings' }
          ], children: filteredServices.otherllpfilingsChildren
        },

      }
    },
    DocumentWallet: {
      title: 'Document Wallet',
      href: PATH_APP.app2.services.BusinessServices,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is Document Wallet',
      heading: 'Document Wallet',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'Document Wallet',
          href: PATH_APP.app2.services.BusinessServices
        }
      ],
      children: {

      }
    },
    AccountingAndCompliances: {
      title: 'Accounting & Compliances',
      href: PATH_APP.app2.services.AccountingAndBookkeeping,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is Accounting & Compliances',
      heading: 'Accounting & Compliances',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'Accounting & Compliances',
          href: PATH_APP.app2.services.AccountingAndBookkeeping
        }
      ],
      children: {
        AccountingServices: {
          title: 'Accounting Services Under package',
          icon:
            '/static2/ServiceIcons/BusinessServices/Accounting and Book Keeping Services.svg',
          href: PATH_APP.app2.services.AccountingServicesUnderPackage,
          description: '',
          heading: 'Accounting Services Under package',
          breadCrumbs: [
            { name: 'Services', href: PATH_APP.app2.services.root },
            { name: 'Accounting & Compliances', href: PATH_APP.app2.services.AccountingAndBookkeeping },
            { name: 'Accounting Services Under Package' }

          ], children: filteredServices.accountingChildren
        },

        ComplianceServices: ComplianceServices
      }
    },
    Audit: {
      title: 'Audit',
      href: PATH_APP.app2.services.Audit,
      icon: '/static2/ServiceIcons/Business.svg',
      description: 'This is Audit',
      heading: 'Audit',
      breadCrumbs: [
        { name: 'Services', href: PATH_APP.app2.services.root },
        {
          name: 'Audit',
          href: PATH_APP.app2.services.Audit
        }
      ],
      children: filteredServices.auditChildren
    },
  }
};
