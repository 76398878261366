import React, { useEffect, useState } from 'react';
import ThemeConfig from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from '~/routes';
import ScrollToTop from '~/components/ScrollToTop';
import GoogleAnalytics from '~/components/GoogleAnalytics';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import NotistackProvider from '~/components/NotistackProvider';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { ContextProvider } from '~/ContextProvider';
import Watermark from './utils/Watermark';
import { BASE_URL } from '~/constants';
import axios from 'axios';
import { getlandingpageData } from '~/redux/slices/landingpageslice';
import { useDispatch } from 'react-redux';
import Page404View from './views/errors/Page404View';
import { CircularProgress, Container } from '@material-ui/core';
import Servicesloader from './ServicesLoader';
// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch()
  // let domainName = window.location.host

  const [loading, setLoading] = useState(true);
  const [errorPage, setErrorPage] = useState('')


  return (
    <ThemeConfig>
      <Servicesloader loading={loading} setLoading={setLoading} setErrorPage={setErrorPage} />
      {!loading ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <NotistackProvider>
            <Router history={history}>
              <ScrollToTop />
              <GoogleAnalytics />
              <ContextProvider>
                <Watermark />
                {errorPage === null ? <Page404View flag={false} /> :  renderRoutes(routes)}
              </ContextProvider>
            </Router>
          </NotistackProvider>
        </LocalizationProvider>
      ) :(
        <Container>
          <CircularProgress
            color="primary"
            sx={{
              width: '100%',
              display: 'block',
              margin: 'auto',
              maxHeight: 240,
              my: { xs: 5, sm: 10 }
            }}
          />
        </Container>
      )}
    </ThemeConfig>
  );
}

export default App;
