import axios from '~/utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import { reject } from 'lodash';
import Factory from '~/utils/factory';
const initialState = {
  isLoading: false,
  projects: [],
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getProjectsSuccess(state, action) {
      state.isLoading = false;
      state.projects = action.payload; 
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; 
    },
    addProjectStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    addProjectSuccess(state, action) {
      state.isLoading = false;
      state.projects.push(action.payload)
    },
    addProjectFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateProjectSuccess(state, action) {
      state.isLoading = false;
      const updatedProject = action.payload;
      state.projects = state.projects.map(project => {
        return project.id === updatedProject.id ? updatedProject : project;
      });
    },
    
    // DELETE EVENT
    deleteProjectSuccess(state, action) {
      state.projects = action.payload;
    },
  },
});

export const { startLoading, getIndustriesSuccess, hasError } = slice.actions;

export const getAllProjects = () => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    let url = `due_diligence/project`
    const {res} = await Factory('get',url,{});
    if(Array.isArray(res)){
      dispatch(slice.actions.getProjectsSuccess(res));
    }else{
      throw new Error("Invalid response form")
    }
  } catch (error) {
    console.log(error,"fetching error")
    dispatch(slice.actions.hasError(error))
  }

};

export const addProject = (projectData) => {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `due_diligence/project`;
      const { res, variant } = await Factory('post', url, projectData);
      if (res && variant === "success") {
        dispatch(slice.actions.addProjectSuccess(res));
        return { success: true }; // Return success status
      } else {
        console.error("Error: Unable to add project");
        return { success: false, error: "Unable to add project" }; // Return error message
      }
    } catch (error) {
      console.error("Project error:", error);
      return { success: false, error: error.message }; // Return error message
    }
  };
};



export const updateProject = (projectData,projectId) => {
  return async dispatch => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `due_diligence/project/${projectId}`
      const {res} = await Factory('put',url,projectData);
      console.log(res,"updateResponse")

      dispatch(slice.actions.updateProjectSuccess({projectData,...projectData,id:projectId}))
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

// export const deleteIndustry = (industryData) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     let industryId = industryData.id
//     const response = await axios.post(`/api/industries/delete`,{industryId});
//       // Fetch updated industries data after deletion
//     dispatch(slice.actions.deleteIndustrySuccess(response.data));
//   } catch (error) {
//     dispatch(hasError(error));
//   }
// };

export default slice.reducer;
