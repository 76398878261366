import React, { createContext, useState } from 'react';

// Create Context Object
export const userContext = createContext(); // Create a context object
export const tokenContext = createContext();
export const clientDetailsContext = createContext();
// export const serviceTypeContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const ContextProvider = props => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [client, setClient] = useState();
  // const [serviceTypeC, setServiceTypeC] = useState('individual');

  return (
    <userContext.Provider value={[user, setUser]}>
      <tokenContext.Provider value={[token, setToken]}>
        <clientDetailsContext.Provider value={[client, setClient]}>
          {/* <serviceTypeContext.Provider value={[serviceTypeC, setServiceTypeC]}> */}
          {props.children}
          {/* </serviceTypeContext.Provider> */}
        </clientDetailsContext.Provider>
      </tokenContext.Provider>
    </userContext.Provider>
  );
};
