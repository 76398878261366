import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  business: {}
};

const slice = createSlice({
  name: 'business',
  initialState: {
    business: {}
  },
  reducers: {
    getBusinessData: (state = initialState, action) => {
      state.business = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBusiness(business) {
  return async dispatch => {
    dispatch(slice.actions.getBusinessData(business));
  };
}
