import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  basicinfo: null, // Change to null since basicinfo is an object, not an array
  isLoading: false,
  error: null
};

const basicinfoSlice = createSlice({
  name: 'BasicInfo',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getbasicinfoSuccess(state, action) {
      state.isLoading = false;
      state.error = null; // Clear any previous error
      state.basicinfo = action.payload; // Set basicinfo to the fetched data
    },
    fetchanalysisFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = basicinfoSlice.actions;

export function getbasicinfo(newid) {
  return async dispatch => {
    dispatch(basicinfoSlice.actions.startLoading());
    try {
      console.log("New ID received:", newid); // Log the newid object received
      console.log("New Project ID:", newid.id); // Log the project ID
      const url = `due_diligence/project/${newid.id}`; // Ensure id is correctly appended to the URL

      const { res, variant, message } = await Factory("get", url);
      //console.log("API Response:", res); // Log the API response

      if (res && typeof res === 'object' && !Array.isArray(res)) {
        // Check if the response is an object and not an array
        dispatch(basicinfoSlice.actions.getbasicinfoSuccess(res));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.log("Error:", error.message);
      dispatch(basicinfoSlice.actions.hasError(error.message));
    }
  };
}

export default basicinfoSlice.reducer;
