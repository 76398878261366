import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';

const initialState = {
  phase: [],
  isLoading: false,
  error: null
};

const editProjectSlice = createSlice({
  name: 'editProject',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    // GET PHASE
    getPrjectSuccess(state, action) {
      state.isLoading = false;
      state.phase = action.payload;
    },

    // POST PHASE
    createPhasesSuccess(state, action) {
      state.isLoading = false;
      state.phase.push(action.payload);
    },
    
    // PUT PHASE
    editProjectSuccess(state, action) {
    state.isLoading = false;
    state.phase.push(action.payload);
  },

    fetchPhasesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }
  }
});

export const { onToggleFollow } = editProjectSlice.actions;

export const getProjectData = () => async dispatch => {
    dispatch(editProjectSlice.actions.startLoading());
    try {
      const response = await axios.get('/api/projectget');
      if (Array.isArray(response.data)) {
        dispatch(editProjectSlice.actions.getPrjectSuccess(response.data));
        //console.log(response.data)
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      dispatch(editProjectSlice.actions.hasError(error.message));
    }
  };

//   export function createPhase(newPhase) {
//     return async dispatch => {
//       dispatch(phasesSlice.actions.startLoading());
//       try {
//        // console.log(newPhase);
//         const response = await axios.post('/api/phasepost', newPhase);
//         dispatch(phasesSlice.actions.createPhasesSuccess(newPhase));
//       } catch (error) {
//         dispatch(phasesSlice.actions.hasError(error));
//       }
//     };
//   }

  export function updateProject(newPhase) {
    return async dispatch => {
      dispatch(editProjectSlice.actions.startLoading());
      try {
       // console.log(newPhase);

        const response = await axios.put('/api/projectupdate/:id', newPhase);
        dispatch(editProjectSlice.actions.editProjectSuccess(newPhase));
      } catch (error) {
        dispatch(editProjectSlice.actions.hasError(error));
      }
    };
  }


export default editProjectSlice.reducer;
