import { createSlice } from '@reduxjs/toolkit';
import axios from '~/utils/axios';
import Factory from '~/utils/factory';

const initialState = {
    subarea: [],
    isLoading: false,
    error: null,
};

const subAreaSlice = createSlice({
    name: 'Sub Area',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
          state.isLoading = true;
        },
    
        // HAS ERROR
        hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
        },
      // GET SUBAREA
      getSubAreaSuccess(state, action) {
        state.isLoading = false;
        state.subarea = action.payload;
      },
  
      // POST SUBAREA
      createSubAreaSuccess(state, action) {
       
        state.isLoading = false;
        const updatedSubarea = action.payload;    
        state.subarea = state.subarea.map(s => {
          return s.area_id === updatedSubarea.area_id ? updatedSubarea : s;
        }); 
      },

      //UPDATE SUBAREA
      updateSubareaSuccess(state, action) {
        state.isLoading = false;
        const updatedSubarea = action.payload;
        console.log(updatedSubarea)
        const index = state.subarea.findIndex(area => area.area_id === updatedSubarea.area_id);
        if (index !== -1) {
          state.subarea[index] = {
            ...updatedSubarea,
            subareas: updatedSubarea.subareas.map(subarea => ({
              ...subarea,
              accounts: subarea.accounts.map(account => ({
                ...account,
                categories: subarea.categories.map(category => ({
                  ...category,
                  category: category.category
                })),
                subaccounts: account.subaccounts.map(subaccount => ({
                  ...subaccount,
                  subaccount_name: subaccount.subaccount_name
                }))
              })),
              
            }))
          };
        } 
      }
      
      
    }
});

export const { onToggleFollow } = subAreaSlice.actions;


export const getSubarea = () => async dispatch => {
   
  dispatch(subAreaSlice.actions.startLoading());
  try {
    let url = "master_data/areas";
    let { res, variant, message } = await Factory("get", url, {});
   // console.log(res)
    if (Array.isArray(res)) {
      dispatch(subAreaSlice.actions.getSubAreaSuccess(res));
    //console.log(response.data); 
    } else {
      throw new Error('Invalid response format');
    }
  } catch (error) {
    dispatch(subAreaSlice.actions.hasError(error.message));
  }
};

export function createSubarea(newSubarea) {
  return async dispatch => {
    dispatch(subAreaSlice.actions.startLoading());
    try {
      //console.log(newSubarea)
      let url = "master_data/subareas";
      let { res, variant, message } = await Factory("put", url, newSubarea);
      //console.log(res);
      dispatch(subAreaSlice.actions.createSubAreaSuccess(newSubarea));
    } catch (error) {
      dispatch(subAreaSlice.actions.hasError(error));
    }
  };
}

export function updateSubarea(updatedSubarea,areaId) {
  return async dispatch => {
    dispatch(subAreaSlice.actions.startLoading());
    try {
    // console.log(updatedSubarea,"Update");
     let url = `master_data/subareas`;
     let { res, variant, message } = await Factory("put", url, updatedSubarea);
      console.log(res)
      dispatch(subAreaSlice.actions.updateSubareaSuccess(res));
     // console.log(updatedSubarea)
    } catch (error) {
      dispatch(subAreaSlice.actions.hasError(error));
    }
  };
}


export default subAreaSlice.reducer;