import React, { useRef, useState, useEffect, useContext } from 'react';
import { services } from '~/servicesConstants';
import Scrollbars from '~/components/Scrollbars';
import PopoverMenu from '~/components/PopoverMenu';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Typography,
  Popover,
  Box,
  Divider,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
  serviceInfo: {
    margin: '20px',
    display: 'flex',
    cursor: 'pointer',
    fontSize: '13px',
    marginBottom: '10px',
    alignItems: 'center',
    width: '280px',
    [theme.breakpoints.up('xs')]: {
      margin: '5px',
      width: '100px',
      height: '50px'
    },
    [theme.breakpoints.up('sm')]: {
      margin: '10px',
      width: '200px',
      height: '40px'
    },
    [theme.breakpoints.up('md')]: {
      margin: '10px',
      width: '280px',
      height: '38px'
    }
  },
  serviceHeaderInfo: {
    display: 'flex',
    cursor: 'pointer'
    // width: '160px',
    // height: '30px',
    // [theme.breakpoints.up('xs')]: {
    //     width: '120px',
    // },
    // [theme.breakpoints.up('sm')]: {
    //     width: '150px',
    // },
    // [theme.breakpoints.up('md')]: {
    //     width: '160px',
    // }
  },
  HeaderInfo: {
    display: 'flex',
    cursor: 'pointer',
    paddingRight: '40px',
    height: '30px'
  },
  underlineText: {
    textDecoration: 'underline'
  },
  serviceDiv: {
    display: 'flex',
    padding: '10px'
  },
  typoSize: {},
  highlightService: {
    color: '#1890FF'
  },
  listBox: {
    width: '300px',
    [theme.breakpoints.up('xs')]: {
      width: '105px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '300px'
    },
    [theme.breakpoints.up('md')]: {
      width: '300px'
    }
  },
  colorBlack: {
    color: 'black'
  },
  colorWhite: {
    color: 'white'
  },
  imgClass: {
    width: '18px',
    height: '18px',
    marginRight: '5px'
  },
  headerimgClass: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  }
}));

// ----------------------------------------------------------------------

function Header(info) {
  const classes = useStyles();
  const anchorIndividualServicesRef = useRef(null);
  const anchorBusinessServicesRef = useRef(null);
  const [serviceType, setserviceType] = useState('');
  const [isEndNode, setisEndNode] = useState(null);
  const [subServiceType, setsubServiceType] = useState('');
  const [subSubServiceType, setsubSubServiceType] = useState('');
  const [isIndividualServicesOpen, setIndividualServicesOpen] = useState(false);
  const [isBusinessServicesOpen, setBusinessServicesOpen] = useState(false);
  const { userDetails } = useSelector(state => state.loginUserDetails);
  const history = useHistory();
  //   const getColor = info.isLandingPage ? 'white' :

  const handlePopoverMenu = val => {
    setserviceType(val);
    if (val === 'individualServices') {
      setIndividualServicesOpen(true);
    } else if (val === 'businessServices') {
      setBusinessServicesOpen(true);
    }
  };

  useEffect(() => {
    if (isEndNode !== null) {
      var a = document.createElement('a');
      a.setAttribute('href', isEndNode.href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [isEndNode]);

  const getClass = service => {
    return service.title === 'Individual Services' ||
      service.title === 'Business Services'
      ? true
      : false;
  };

  const highlightService = (service, serviceInfo) => {
    return service === serviceType ||
      service === subServiceType ||
      service === subSubServiceType
      ? true
      : false;
  };

  const getServices = (services, key) => {
    return (
      <>
        <Box
          className={clsx(
            classes.typoSize,
            !getClass(services) && highlightService(key, services)
              ? classes.highlightService
              : null
          )}
        >
          <Box
            className={
              !getClass(services)
                ? classes.serviceInfo
                : info.isLandingPage
                ? classes.serviceHeaderInfo
                : classes.HeaderInfo
            }
            key={services.title}
          >
            {!getClass(services) || !info.isLandingPage ? (
              <img
                src={
                  services.icon === ''
                    ? '/static2/ServiceIcons/Business.svg'
                    : services.icon
                }
                alt=""
                className={
                  !getClass(services)
                    ? classes.imgClass
                    : classes.headerimgClass
                }
              />
            ) : null}
            <Typography
              variant={!getClass(services) ? 'body2' : 'body1'}
              sx={{
                fontSize: !getClass(services) && 13
              }}
              className={clsx(
                !getClass(services)
                  ? highlightService(key, services)
                    ? null
                    : null
                  : info.isLandingPage
                  ? classes.colorWhite
                  : classes.colorBlack
              )}
            >
              {services.title}
            </Typography>
            <Box flexGrow={1} />
            {!getClass(services) && !services.endNode ? (
              <ChevronRightOutlinedIcon
                style={{ color: 'rgb(59 62 65 / 53%)' }}
              />
            ) : null}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {/* {Object.entries(services.children).map(([key, value], idx) => {
        if (userDetails.serviceType === 'individual' && idx === 0) {
          return (
            <Box
              ref={
                value.title === 'Individual Services'
                  ? anchorIndividualServicesRef
                  : anchorBusinessServicesRef
              }
              aria-owns={
                serviceType === 'individualServices'
                  ? isIndividualServicesOpen
                  : isBusinessServicesOpen
                  ? 'mouse-over-popover'
                  : undefined
              }
              aria-haspopup="true"
              onClick={() => {
                serviceType === 'individualServices'
                  ? setIndividualServicesOpen(false)
                  : setBusinessServicesOpen(false);
                setsubSubServiceType('');
                setsubServiceType('');
                handlePopoverMenu(key);
              }}
              key={key}
              style={{ display: 'inline-flex' }}
            >
              {getServices(value, key)}
            </Box>
          );
        }
        if (userDetails.serviceType === 'business' && idx === 1) {
          return (
            <Box
              ref={
                value.title === 'Individual Services'
                  ? anchorIndividualServicesRef
                  : anchorBusinessServicesRef
              }
              aria-owns={
                serviceType === 'individualServices'
                  ? isIndividualServicesOpen
                  : isBusinessServicesOpen
                  ? 'mouse-over-popover'
                  : undefined
              }
              aria-haspopup="true"
              onClick={() => {
                serviceType === 'individualServices'
                  ? setIndividualServicesOpen(false)
                  : setBusinessServicesOpen(false);
                setsubSubServiceType('');
                setsubServiceType('');
                handlePopoverMenu(key);
              }}
              key={key}
              style={{ display: 'inline-flex' }}
            >
              {getServices(value, key)}
            </Box>
          );
        }
      })} */}

      <Popover
        // width={1020}
        id="mouse-over-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={
          serviceType === 'individualServices'
            ? isIndividualServicesOpen
            : isBusinessServicesOpen
        }
        onClose={() => {
          serviceType === 'individualServices'
            ? setIndividualServicesOpen(false)
            : setBusinessServicesOpen(false);
          setsubSubServiceType('');
          setsubServiceType('');
        }}
        anchorEl={
          serviceType === 'individualServices'
            ? anchorIndividualServicesRef.current
            : anchorBusinessServicesRef.current
        }
      >
        {/* <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    py: 2,
                    px: 2.5,
                    width: '380px'
                }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                            {serviceType === 'individualServices' ? 'Individual Services' : 'Business Services'}
                        </Typography>
                    </Box>
                </Box>
                <Divider /> */}
        <Box component="div" className={classes.serviceDiv}>
          <Box className={classes.listBox}>
            <Scrollbars>
              {serviceType !== ''
                ? Object.entries(services.children[serviceType].children).map(
                    ([key, value], idx) => (
                      <Box
                        onMouseOver={() => {
                          setsubServiceType(key);
                          setsubSubServiceType('');
                        }}
                        key={key}
                      >
                        {getServices(value, key)}
                      </Box>
                    )
                  )
                : null}
            </Scrollbars>
          </Box>
          {subServiceType !== '' ? (
            <Box
              className={classes.listBox}
              style={{
                borderLeft: '2px solid rgba(145, 158, 171, 0.24)'
              }}
            >
              <Scrollbars>
                {serviceType
                  ? Object.entries(
                      services.children[serviceType].children[subServiceType]
                        .children
                    ).map(([key, value], idx) => (
                      <Box
                        key={key}
                        onMouseOver={() => {
                          !value.endNode
                            ? setsubSubServiceType(key)
                            : setisEndNode(null);
                        }}
                        onClick={() => {
                          if (value.endNode) setisEndNode(value);
                        }}
                      >
                        {getServices(value, key)}
                      </Box>
                    ))
                  : null}
              </Scrollbars>
            </Box>
          ) : null}
          {subSubServiceType !== '' ? (
            <Box
              sx={{ height: { xs: 340, sm: 'auto' } }}
              className={classes.listBox}
              style={{
                borderLeft: '2px solid rgba(145, 158, 171, 0.24)'
              }}
            >
              <Scrollbars>
                {serviceType
                  ? Object.entries(
                      services.children[serviceType].children[subServiceType]
                        .children[subSubServiceType].children
                    ).map(([key, value], idx) => (
                      <Box
                        key={key}
                        onClick={() => {
                          setisEndNode(value);
                        }}
                      >
                        {getServices(value, key)}
                      </Box>
                    ))
                  : null}
              </Scrollbars>
            </Box>
          ) : null}
        </Box>
      </Popover>
    </>
  );
}

export default Header;
