import React from 'react';
import Page from '~/components/Page';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import ProductList from '~/views/finance-box/services/ProductList';
import { ComplianceManagerConstants } from '../ComplianceConstants';
import HeaderDashboard from '~/components/HeaderDashboard';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const PayRollServiceCards = () => {
  const classes = useStyles();
  const {
    heading,
    breadCrumbs,
    children
  } = ComplianceManagerConstants.children.payroll;

  return (
    <Page title="TDS Services" className={classes.root}>
      <Container>
        <HeaderDashboard heading={heading} links={breadCrumbs} />
        <ProductList services={Object.entries(children)} />
      </Container>
    </Page>
  );
};

export default PayRollServiceCards;
