import mock from '~/utils/mock'; 

// Define mock data for the analysis
const Data = {
  "id": 4,
  "reviewTeam": {
      "engagement_partner_id": 4,
      "engagement_partner": "Ravi",
      "review_partner_id": 5,
      "review_partner": "",
      "manager_id": 6,
      "manager": "Rambabu",
      "associate_id": 7,
      "associate": "Sowmya"
  },
  "createdAt": "2024-04-10T06:46:47.537000Z",
  "updatedAt": "2024-04-10T06:46:47.537000Z",
  "project_id": "DD-001",
  "target_company_id": 1,
  "target_company": "Wipro",
  "client_company_id": 2,
  "client_company": "ABC Ltd",
  "execution_partner_id": 3,
  "execution_partner": "Priyanka",
  "date_of_incorporation": "2024-04-08",
  "entity_type_id": 1,
  "entity_type": "LLP",
  "industry_id": 1,
  "industry": "Textile",
  "PAN": "1j7cctRY8A",
  "GST": "RcX5X1FbkW",
  "CIN": "ZrfA5DWz9V",
  "address": "23-6/1,Hyderabad",
  "type_of_DD": "Buy Sell DD",
  "sector_id" : 1,
  "sector_name": "Textile",
  "period_from_date": "2024-04-08",
  "period_to_date": "2024-04-09",
  "no_of_fys": 2,
  "timeline_start_date": "2024-04-08",
  "timeline_end_date": "2024-04-08",
  "firm_id": 1,
  "user_id": 1
};

// Define the mock API endpoint for fetching analysis count
mock.onGet('api/getbasicinfo').reply(200, Data);
// console.log('Mock API response:', Data);
// Export the data for external use if needed

export const status = () => {
    return Data;
}
