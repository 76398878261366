import { createSlice } from '@reduxjs/toolkit';
import Factory from '~/utils/factory';

const initialState = {
  isLoading: false,
  salaryOnHold: [],
  error: null,
};

const slice = createSlice({
  name: 'salaryOnHold',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getSalaryOnHoldSuccess(state, action) {
      state.isLoading = false;
      state.salaryOnHold = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addSalaryOnHoldSuccess(state, action) {
      state.isLoading = false;
      state.salaryOnHold.push(action.payload);
    },
    updateSalaryOnHoldSuccess(state, action) {
      state.isLoading = false;
      const updatedDeduction = action.payload;
      state.salaryOnHold = state.salaryOnHold.map(employee =>
        employee.id === updatedDeduction.id ? updatedDeduction : employee
      );
    },
    deleteSalaryOnHoldSuccess(state, action) {
      state.isLoading = false;
      state.salaryOnHold = state.salaryOnHold.filter(employee =>
        employee.id !== action.payload
      );
    },
  },
});

export const {
  startLoading,
  getSalaryOnHoldSuccess,
  hasError,
  addSalaryOnHoldSuccess,
  updateSalaryOnHoldSuccess,
  deleteSalaryOnHoldSuccess,
} = slice.actions;

export const fetchAllSalaryOnHold = (year,businessId) => async (dispatch) => {
  dispatch(startLoading());
  try {
    let url = `payroll/getEmployPayroll?type=SalaryOnHold&financialYear=${year}&business_id=${businessId}`
    let {res} = await Factory("get",url,{});
    // dispatch(slice.actions.getSalaryOnHoldSuccess(res));
    return res;
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

// export const addSelectedEmployee = (employee) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Simulate adding employee logic, e.g., saving to local storage or an API call
//     dispatch(addSelectedEmployeeSuccess(employee));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

// export const updateSelectedEmployee = (employee) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Simulate updating employee logic, e.g., saving to local storage or an API call
//     dispatch(updateSelectedEmployeeSuccess(employee));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

// export const deleteSelectedEmployee = (employeeId) => async (dispatch) => {
//   dispatch(startLoading());
//   try {
//     // Simulate deleting employee logic, e.g., removing from local storage or an API call
//     dispatch(deleteSelectedEmployeeSuccess(employeeId));
//   } catch (error) {
//     dispatch(hasError(error.toString()));
//   }
// };

export default slice.reducer;
